import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../../../components/Checkbox';
import { AccessibleModal } from '../../../components/AccessibleModal';
import './index.css';
import messages from './messages';

function RoomScanModel({ onRoomScanComplete, isOpen }) {
  const [pageChanger, setPageChanger] = useState(false);
  const [reader, setReader] = useState(true);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isCheckBox1Checked, setIsCheckBox1Checked] = useState(false);
  const [isCheckBox2Checked, setIsCheckBox2Checked] = useState(false);
  const [isCheckBox3Checked, setIsCheckBox3Checked] = useState(false);
  const [isCheckBox4Checked, setIsCheckBox4Checked] = useState(false);
  const [isCheckBox5Checked, setIsCheckBox5Checked] = useState(false);
  const [isCheckBox6Checked, setIsCheckBox6Checked] = useState(false);
  const [modalButton, setModalButton] = useState(false);

  const checkboxNames = {
    checkbox1: 'checkbox1',
    checkbox2: 'checkbox2',
    checkbox3: 'checkbox3',
    checkbox4: 'checkbox4',
    checkbox5: 'checkbox5',
    checkbox6: 'checkbox6',
  };

  function onCheckboxChanged(isChecked, name) {
    switch (name) {
      case checkboxNames.checkbox1: {
        setIsCheckBox1Checked(isChecked);
        break;
      }
      case checkboxNames.checkbox2: {
        setIsCheckBox2Checked(isChecked);
        break;
      }
      case checkboxNames.checkbox3: {
        setIsCheckBox3Checked(isChecked);
        break;
      }
      case checkboxNames.checkbox4: {
        setIsCheckBox4Checked(isChecked);
        break;
      }
      case checkboxNames.checkbox5: {
        setIsCheckBox5Checked(isChecked);
        break;
      }
      case checkboxNames.checkbox6: {
        setIsCheckBox6Checked(isChecked);
        break;
      }
      default: {
        break;
      }
    }
  }

  useEffect(() => {
    if (
      isCheckBox1Checked &&
      isCheckBox2Checked &&
      isCheckBox3Checked &&
      isCheckBox4Checked &&
      isCheckBox5Checked &&
      isCheckBox6Checked &&
      modalButton
    ) {
      setIsButtonEnabled(true);
      setReader(false);
    } else {
      setIsButtonEnabled(false);
      setReader(true);
    }
  }, [
    isCheckBox1Checked,
    isCheckBox2Checked,
    isCheckBox3Checked,
    isCheckBox4Checked,
    isCheckBox5Checked,
    isCheckBox6Checked,
    modalButton,
    isButtonEnabled,
  ]);

  const handleButtonClick = () => {
    setPageChanger(!pageChanger);
    setTimeout(() => {
      setModalButton(true);
    }, 30000);
  };

  return (
    <div>
      {pageChanger ? (
        <AccessibleModal isOpen={isOpen} modalClassName="room-scan-modal">
          <h2 className="room-scan-modal-header">
            <FormattedMessage {...messages.heading2} />
          </h2>
          <p className="room-scan-modal-warning">
            <strong>
              <FormattedMessage {...messages.warning} />
            </strong>
          </p>
          <hr aria-hidden="true" className="room-scan-modal-hr" />
          <ModalBody className="room-scan-modal-body">
            <span>
              <em>
                <p className="room-scan-modal-score-warning">
                  {messages.instructions2.heading}
                </p>
              </em>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: messages.instructions2.defaultMessage,
                }}
              />
            </span>
            <div className="room-scan-modal-checklist-item-container">
              <Checkbox
                id={checkboxNames.checkbox1}
                disabled={false}
                className="room-scan-modal-checkbox"
                onCheckChanged={checked =>
                  onCheckboxChanged(checked, checkboxNames.checkbox1)
                }
                checked={false}
                labelClassName="room-scan-modal-checklist-text"
                labelTextContent={messages.checkboxText1.defaultMessage}
              />
            </div>
            <div className="room-scan-modal-checklist-item-container">
              <Checkbox
                id={checkboxNames.checkbox6}
                disabled={false}
                className="room-scan-modal-checkbox"
                onCheckChanged={checked =>
                  onCheckboxChanged(checked, checkboxNames.checkbox6)
                }
                checked={false}
                labelClassName="room-scan-modal-checklist-text"
                labelTextContent={messages.checkboxText6.defaultMessage}
              />
            </div>
            <div className="room-scan-modal-checklist-item-container">
              <Checkbox
                id={checkboxNames.checkbox2}
                disabled={false}
                className="room-scan-modal-checkbox"
                onCheckChanged={checked =>
                  onCheckboxChanged(checked, checkboxNames.checkbox2)
                }
                checked={false}
                labelClassName="room-scan-modal-checklist-text"
                labelTextContent={messages.checkboxText2.defaultMessage}
              />
            </div>
            <div className="room-scan-modal-checklist-item-container">
              <Checkbox
                id={checkboxNames.checkbox3}
                disabled={false}
                className="room-scan-modal-checkbox"
                onCheckChanged={checked =>
                  onCheckboxChanged(checked, checkboxNames.checkbox3)
                }
                checked={false}
                labelClassName="room-scan-modal-checklist-text"
                labelTextContent={messages.checkboxText3.defaultMessage}
              />
            </div>
            <div className="room-scan-modal-checklist-item-container">
              <Checkbox
                id={checkboxNames.checkbox4}
                disabled={false}
                className="room-scan-modal-checkbox"
                onCheckChanged={checked =>
                  onCheckboxChanged(checked, checkboxNames.checkbox4)
                }
                checked={false}
                labelClassName="room-scan-modal-checklist-text"
                labelTextContent={messages.checkboxText4.defaultMessage}
              />
            </div>
            <div className="room-scan-modal-checklist-item-container">
              <Checkbox
                id={checkboxNames.checkbox5}
                disabled={false}
                className="room-scan-modal-checkbox"
                onCheckChanged={checked =>
                  onCheckboxChanged(checked, checkboxNames.checkbox5)
                }
                checked={false}
                labelClassName="room-scan-modal-checklist-text"
                labelTextContent={messages.checkboxText5.defaultMessage}
              />
            </div>
            <div
              className={
                modalButton &&
                isCheckBox1Checked &&
                isCheckBox2Checked &&
                isCheckBox3Checked &&
                isCheckBox4Checked &&
                isCheckBox5Checked &&
                isCheckBox6Checked
                  ? 'room-scan-modal-button-container-enabled'
                  : 'room-scan-modal-button-container-disabled'
              }
            >
              <Button
                disabled={!modalButton}
                onClick={isButtonEnabled ? onRoomScanComplete : null}
                enabled={isButtonEnabled}
                aria-disabled={reader}
              >
                <FormattedMessage {...messages.buttonText2} />
              </Button>
            </div>
          </ModalBody>
        </AccessibleModal>
      ) : (
        <AccessibleModal isOpen={isOpen} modalClassName="room-scan-modal">
          <h2 className="room-scan-modal-header">
            <FormattedMessage {...messages.heading} />
          </h2>
          <p className="room-scan-modal-warning">
            <strong>
              <FormattedMessage {...messages.warning} />
            </strong>
          </p>
          <hr aria-hidden="true" className="room-scan-modal-hr" />
          <ModalBody className="room-scan-modal-body">
            <span>
              <em>
                <p className="room-scan-modal-score-warning">
                  {messages.instructions.heading}
                </p>
              </em>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: messages.instructions.defaultMessage,
                }}
              />
            </span>
            <div className="room-scan-modal-button-container-enabled">
              <Button onClick={handleButtonClick}>
                <FormattedMessage {...messages.buttonText} />
              </Button>
            </div>
          </ModalBody>
        </AccessibleModal>
      )}
    </div>
  );
}

RoomScanModel.propTypes = {
  onRoomScanComplete: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default RoomScanModel;
