/*
 * CardsSlider Messages
 *
 * This contains all the text for the CardsSlider component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.CardsSlider';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: '{heading}',
  },
  viewAllLink: {
    id: `${scope}.viewAllLink`,
    defaultMessage: 'View all',
  },
  viewAllLinkTitleText: {
    id: `${scope}.viewAllLinkTitleText`,
    defaultMessage: '{sliderHeading}',
  },
  viewMoreLink: {
    id: `${scope}.viewMoreLink`,
    defaultMessage: 'View more',
  },
  noEventsCard: {
    id: `${scope}.noEventsCard`,
    defaultMessage: 'No upcoming events',
  },
  noCoursesCard: {
    id: `${scope}.noCoursesCard`,
    defaultMessage: 'No upcoming courses',
  },
  noPodcastsCard: {
    id: `${scope}.noPodcastsCard`,
    defaultMessage: 'No episodes are available',
  },
  noToolsExploreCard: {
    id: `${scope}.noToolsExploreCard`,
    defaultMessage: 'No explore items',
  },
  noCardDataGeneric: {
    id: `${scope}.noCardDataGeneric`,
    defaultMessage: 'Nothing to see here',
  },
  previousNavButtonScrollBackText: {
    id: `${scope}.previousNavButtonScrollBackText`,
    defaultMessage: '{sliderHeading}, scroll back',
  },
  previousNavButtonScrollForwardText: {
    id: `${scope}.previousNavButtonScrollForwardText`,
    defaultMessage: '{sliderHeading}, scroll forward',
  },
  previousNavButtonScrollBackTextTwoLines: {
    id: `${scope}.previousNavButtonScrollBackTextTwoLines`,
    defaultMessage: '{sliderHeading},\nscroll back',
  },
  previousNavButtonScrollForwardTextTwoLines: {
    id: `${scope}.previousNavButtonScrollForwardTextTwoLines`,
    defaultMessage: '{sliderHeading},\nscroll forward',
  },
});
