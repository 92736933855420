import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Button } from 'reactstrap';
import SuccessIcon from '../../../images/check-mark-in-square-green-circle.svg';
import messages from '../messages';
import './index.css';

function RedemptionSuccess({ intl, onCloseModal }) {
  return (
    <div className="redeem-success">
      <div className="success-content">
        <div className="success-icon">
          <img src={SuccessIcon} alt="success" />
        </div>
        <div className="success-text">
          <p className="success-heading">
            {intl.formatMessage({ ...messages.redeemSuccessHeading })}
          </p>
          <p className="success-body">
            {intl.formatMessage({ ...messages.redeemSuccessMsg })}
          </p>
        </div>
      </div>
      <Button className="submit success-button" onClick={() => onCloseModal()}>
        OK
      </Button>
    </div>
  );
}

RedemptionSuccess.propTypes = {
  intl: intlShape,
  onCloseModal: PropTypes.func,
};

export default RedemptionSuccess;
