/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-useless-path-segments */
// #region Imports
import React from 'react';
import PropTypes from 'prop-types';
import '../AriadneLoading/AriadneLoading.css';
// #endregion
// #region class
/**
 * AriadneLoading
 * @class
 * @property {String} props.children Inner text of the button. (Required)
 * @example <AriadneLoading text="Section 1" />
 */
class AriadneLoading extends React.Component {
  timerID = null;

  sillymessages = [
    'Preparing docket...',
    'Checking case files...',
    'Giving opening statements...',
    'Approaching the bench...',
    'Giving testimony...',
    'Presenting evidence...',
    'Preparing defense...',
    'Starting deliberations...',
  ];

  state = {
    statusIndex: 0,
    statusUpdate: this.sillymessages[0],
  };

  constructor(props) {
    super(props);
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    this.timerID = setInterval(this.updateStatus, 2500);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateStatus() {
    if (this.state.statusIndex === this.sillymessages.length) {
      this.setState((state, props) => ({
        statusIndex: 0,
        statusUpdate: this.sillymessages[0],
      }));
    } else {
      this.setState((state, props) => ({
        statusIndex: state.statusIndex + 1,
        statusUpdate: this.sillymessages[this.state.statusIndex],
      }));
    }
  }

  render() {
    return (
      <div className="loading_panel">
        <div className="content">
          <i className="fas fa-gavel gavelstyles" />
          <br />
          <div className="ariadneMessageSection">
            <span className="message">
              Loading <span className="textvalue">{this.props.text}</span>
              <span className="m1">.</span>
              <span className="m2">.</span>
              <span className="m3">.</span>
            </span>
          </div>
          <br />
          <div className="notes">{this.state.statusUpdate}</div>
        </div>
      </div>
    );
  }
}
// #endregion
AriadneLoading.defaultProps = {
  text: 'view',
};

AriadneLoading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default AriadneLoading;
