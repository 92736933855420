/*
 *
 * Application Status Online Page constants
 *
 */
export const GET_USER_APPLICATION_STATUS =
  'Athena/ApplicationStatusOnlinePage/GetUserApplicationStatus';
export const GET_USER_APPLICATION_STATUS_SUCCESS =
  'Athena/ApplicationStatusOnlinePage/GetUserApplicationStatusSuccess';
export const GET_USER_APPLICATION_STATUS_ERROR =
  'Athena/ApplicationStatusOnlinePage/GetUserApplicationStatusError';

export const POST_APPLICATION_NOTIFICATION_STATUS =
  'Athena/ApplicationStatusOnlinePage/PostApplicationNotificationStatus';
export const POST_APPLICATION_NOTIFICATION_STATUS_SUCCESS =
  'Athena/ApplicationStatusOnlinePage/PostApplicationNotificationStatusSuccess';
export const POST_APPLICATION_NOTIFICATION_STATUS_ERROR =
  'Athena/ApplicationStatusOnlinePage/PostApplicationNotificationStatusError';

export const GET_APPLICATION_NOTIFICATION_STATUS =
  'Athena/ApplicationStatusOnlinePage/GetApplicationNotificationStatus';
export const GET_APPLICATION_NOTIFICATION_STATUS_SUCCESS =
  'Athena/ApplicationStatusOnlinePage/GetApplicationNotificationStatusSuccess';
export const GET_APPLICATION_NOTIFICATION_STATUS_ERROR =
  'Athena/ApplicationStatusOnlinePage/GetApplicationNotificationStatusError';
