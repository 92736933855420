/**
 *
 * encrypt helper
 *
 */
import { config } from '../config';
const CryptoJS = require('crypto-js');
/**
 * Encrypt a string
 *
 * @param  {string} message The message to be encrypted
 * @param  {string} passPhrase The pass code
 *
 */
export function encrypt(message, passPhrase) {
  return CryptoJS.TripleDES.encrypt(message, passPhrase).toString();
}

/**
 * Encrypt a string
 *
 * @param  {string} data The data to be decrypted
 * @param  {string} passPhrase The pass code
 *
 */
export function decrypt(data, passPhrase) {
  return CryptoJS.TripleDES.decrypt(data, passPhrase).toString(
    CryptoJS.enc.Utf8,
  );
}

export function getHashedPasswordForExam(password) {
  if (
    config.REACT_APP_EXAM_PASSWORD_HAS_SEQUENCE.toLowerCase() === 'true' &&
    password.length >= 8
  ) {
    const pw = password.substring(0, password.length - 8);
    const seq = password.substring(password.length - 8);
    const hash = `${HashSha512(pw)}|${HashSha512(seq)}`;
    return hash;
  }
  return HashSha512(password);
}

export function HashSha512(value) {
  return CryptoJS.SHA512(value).toString();
}
