import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Button } from 'reactstrap';
import messages from '../messages';
import './index.css';

function RedemptionError({ intl, offerErrorMsg, handleRestart }) {
  return (
    <div className="redeem-error vertical-layout">
      <div className="wrapper">
        <p className="redeem-error-heading">
          {intl.formatMessage({ ...messages.redeemErrorHeading })}
        </p>
        <p>{intl.formatMessage({ ...offerErrorMsg })}</p>
      </div>
      <Button className="submit" onClick={() => handleRestart()}>
        {intl.formatMessage({ ...messages.tryAgainBtn })}
      </Button>
    </div>
  );
}

RedemptionError.propTypes = {
  intl: intlShape,
  offerErrorMsg: PropTypes.object,
  handleRestart: PropTypes.func,
};

export default RedemptionError;
