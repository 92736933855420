import { defineMessages } from 'react-intl';

export const scope = 'app.components.WritingErrorUI';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Oops, looks like something went wrong!',
  },
  message: {
    id: `${scope}.message`,
    defaultMessage:
      'An issue is preventing you from continuing your Writing Exam. Please log out of the Writing Exam, and shut down your browser. Relaunch your Writing Exam from your Online Account. When you Relaunch you will be taken through the security checks again, and will resume your Writing Exam where you left off.',
  },
});
