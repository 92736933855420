import isNullOrUndefined from '../utils/isNullOrUndefined';
import { getUser } from '../authProvider';

/**
 *
 * user helper
 *
 */
let isUserVerified = false;
let loggedInUserName = null;
let loggedInUserDetails = null;

export const extensionCandSeq = {
  value: null,
};

/**
 * Get user ID for curretly logged in user
 */
export function getUserId() {
  if (loggedInUserName === null) {
    const token = getUser();
    if (!isNullOrUndefined(token)) {
      // Ensure that the username is uppercase for compatability
      loggedInUserName = token?.idTokenClaims?.username?.toUpperCase();

      /* eslint-disable camelcase */
      if (token?.idTokenClaims?.extension_cand_seq) {
        /* eslint-disable camelcase */
        extensionCandSeq.value = token.idTokenClaims.extension_cand_seq;
      }
    }
  }
  return loggedInUserName;
}

/**
 * Set user ID for later retrieval
 * @param {string} userId the user name of the logged in user
 */
export function setUserId(userId) {
  loggedInUserName = userId;
}

export function getIsUserVerified() {
  return isUserVerified;
}

export function setIsUserVerified(isVerified) {
  isUserVerified = isVerified;
}

export function setUserDetails(userDetails) {
  loggedInUserDetails = userDetails;
}

export function getUserDetails() {
  return loggedInUserDetails;
}

function getPLUSProgram(userDetails) {
  if (!userDetails) {
    return null;
  }
  const programs = userDetails.userProgramsSettings || userDetails.userPrograms;
  if (programs) {
    const plusProgram = programs.filter(p => p.programId === 'LSACPlus')[0];
    return plusProgram;
  }
  return null;
}

export function isPLUSUser(userDetails) {
  const user = userDetails || getUserDetails();
  if (getPLUSProgram(user)) {
    return true;
  }
  return false;
}

export function isPLUSRegisteredUser(userDetails) {
  const user = userDetails || getUserDetails();
  const plusProgram = getPLUSProgram(user);
  if (plusProgram) {
    const regCompleteSetting = plusProgram.userSettings.filter(
      s => s.settingName === 'registrationComplete',
    )[0];
    if (regCompleteSetting && regCompleteSetting.settingValue === 'true') {
      return true;
    }
  }
  return false;
}
