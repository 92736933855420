/**
 *
 * PopupComponent
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import * as AriaModal from 'react-aria-modal';
import { isNullOrUndefined } from 'util';

/**
 * Popup component
 * @class PopupComponent
 */
function PopupComponent({
  onClose,
  show,
  message,
  titleText,
  buttonText,
  onKeyPress,
}) {
  useEffect(() => {
    const appDivElement = document.getElementById('app');
    if (!isNullOrUndefined(appDivElement)) {
      appDivElement.setAttribute('aria-hidden', show ? 'true' : 'false');
    }
  }, [show]);

  return show ? (
    <AriaModal
      titleText={titleText}
      onExit={onClose}
      underlayClickExits={false}
      focusDialog
    >
      <div className="popup-content">
        <h1
          className="popup-message"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: message }}
        />
        {onClose ? (
          <div className="closeButtonDiv">
            <button
              type="button"
              className="closeButton"
              onClick={onClose}
              onKeyPress={onKeyPress}
            >
              {buttonText}
            </button>
          </div>
        ) : null}
      </div>
    </AriaModal>
  ) : null;
}

PopupComponent.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  message: PropTypes.string,
  titleText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onKeyPress: PropTypes.func,
};

export default PopupComponent;
