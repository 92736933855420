/**
 *
 * persistence storage helper
 *
 */

import { isNullOrUndefined } from 'util';
import { config } from '../../../config';
import { logException } from '../../logHelper';
import { resetCrypto } from '../../webCryptoHelper';
import { restoreStateFromDB, saveInstanceState } from './idbHelper';
import { CURRENT_REPO_VERSION, persistenceState } from './persistenceState';
import * as logHelper from '../../logHelper';
import { LogLevelType } from '../../../enumerations/LogLevelType';
import {
  removeItemFromLocal,
  storeValueInLocal,
} from '../../localStorageHelper';

const offlineRepoKey = 'offlineStateRepo';

export async function cleanupDB(doAlways) {
  if (doAlways || persistenceState.useIndexedDB) {
    try {
      await resetCrypto();
    } catch (ex) {
      logException(ex, 'cleanupDB');
    }
  }
}

/**
 * Force timer to save state on next tick
 */
export function persistNow() {
  requestSaveToLocal();
  persistenceState.secondsBetweenRequests = 0;
}

export async function getStateFromLocal() {
  let repo = null;
  if (persistenceState.useIndexedDB) {
    try {
      await migrateIfNeeded();
      repo = await restoreStateFromDB();
      return repo;
    } catch (ex) {
      logHelper.log(LogLevelType.Error, ex);
      logException(ex, 'getStateFromLocal');
      await cleanupDB();
      return null;
    }
  } else {
    let jsonState = await migrateBackToLocalIfNeeded(jsonState);
    if (isNullOrUndefined(jsonState)) {
      jsonState = localStorage.getItem(offlineRepoKey);
    }
    if (!isNullOrUndefined(jsonState)) {
      try {
        const r = JSON.parse(jsonState);
        return r;
      } catch {
        removeItemFromLocal(offlineRepoKey);
        return null;
      }
    }
  }
  return null;
}
async function migrateIfNeeded() {
  const jsonState = localStorage.getItem(offlineRepoKey);
  if (!isNullOrUndefined(jsonState)) {
    try {
      const r = JSON.parse(jsonState);
      removeItemFromLocal(offlineRepoKey);
      if (r.version === CURRENT_REPO_VERSION) {
        await saveStateToIndexedDB(r, true);
      }
    } catch {
      removeItemFromLocal(offlineRepoKey);
    }
  }
}

async function migrateBackToLocalIfNeeded() {
  let jsonState = null;
  try {
    if (!persistenceState.isIE) {
      const oldRepo = await restoreStateFromDB();
      if (!isNullOrUndefined(oldRepo) && oldRepo.instances.length > 0) {
        jsonState = JSON.stringify(oldRepo);
      }
      await cleanupDB(true);
    }
  } catch {
    return null;
  }
  return jsonState;
}

/**
 * Write the repo to local storage
 */
export function requestSaveToLocal() {
  if (persistenceState.useIndexedDB) {
    if (!persistenceState.isDbDirty) {
      persistenceState.dbRequestTime = Date.now();
    }
    persistenceState.isDbDirty = true;
  } else {
    saveStateToLocalStorage();
  }
}

export async function checkForSaveToDb() {
  if (persistenceState.useIndexedDB && persistenceState.isDbDirty) {
    if (
      Date.now() - persistenceState.dbRequestTime >=
      parseInt(config.REACT_APP_DB_INTERVAL, 10)
    ) {
      await saveStateToIndexedDB(persistenceState.repo);
    }
  }
}

export async function saveStateToIndexedDB(r, forceAllChanged = false) {
  try {
    persistenceState.isDbDirty = false;
    for (let i = 0; i < r.instances.length; i += 1) {
      const instance = r.instances[i];
      let changedValues =
        persistenceState.changedValues[instance.testInstanceId];
      if (forceAllChanged) {
        changedValues = { state: {} };
        changedValues.state['*'] = true;
      }
      // eslint-disable-next-line no-await-in-loop
      await saveInstanceState(instance, changedValues);
      if (persistenceState.changedValues[instance.testInstanceId]) {
        delete persistenceState.changedValues[instance.testInstanceId];
      }
    }
  } catch (ex) {
    logException(ex, 'saveStateToIndexedDB');
  }
}

let lastJson = null;
function saveStateToLocalStorage() {
  try {
    const json = JSON.stringify(persistenceState.repo);
    if (json !== lastJson) {
      storeValueInLocal(offlineRepoKey, json);
      lastJson = json;
    }
  } catch (ex) {
    logException(ex, 'saveStateToLocalStorage');
  }
}
