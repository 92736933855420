import './index.css';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useInjectReducer } from 'utils/injectReducer';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

// support for getting state that contains the acknowledgements
import directionsPageReducer from '../DirectionsPage/reducer';
import {
  persistAllLocally,
  pausePersistence,
  resumePersistence,
} from '../../helpers/persistence/persistenceManagementHelper';

export function ErrorPage() {
  // required in order to get test instances
  useInjectReducer({ key: 'directionsPage', reducer: directionsPageReducer });

  // required in order to get user details
  useEffect(() => {
    pausePersistence();
    document.title = 'LawHub Diagnostics';
    return () => {
      resumePersistence();
    };
  }, []);

  // accessibility support for keyboard navigation
  document.body.addEventListener('mousedown', () => {
    document.body.classList.remove('keyboard-focus');
    document.body.classList.add('mouse-focus');
  });

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('mouse-focus');
    document.body.classList.add('keyboard-focus');
  });

  function saveLocally() {
    persistAllLocally();
  }

  return (
    <div className="test-complete-flex-page-container">
      <div className="test-complete-flex-page-header">
        <div className="test-complete-flex-page-header-div">
          <div className="test-complete-flex-page-header-text">
            <FormattedMessage {...messages.header} />
          </div>
        </div>
      </div>
      <div className="test-complete-flex-page-top-decoration" />
      <div className="test-complete-flex-page">
        <div>
          <h1 id="test-complete-flex-page-heading1">
            <FormattedMessage {...messages.heading1} />
          </h1>
          <div className="text-center">
            <p className="test-complete-flex-page-text-content">
              <FormattedHTMLMessage {...messages.message1} />
            </p>
            <br />
            <p className="test-complete-flex-page-text-content">
              <FormattedHTMLMessage {...messages.message2} />
            </p>
            <button
              className="diag-save-button"
              type="button"
              onClick={saveLocally}
            >
              <span className="diag-save-button-text">
                <FormattedMessage {...messages.saveText} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(connect())(injectIntl(ErrorPage));
