import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Button } from 'reactstrap';
import ReactLoading from 'react-loading';
import ConfirmIcon from '../../../images/check-mark-with-person-red-circle.svg';
import { dateToDayMonthYearLongWithComma } from '../../../helpers/timeHelper';
import messages from '../messages';
import './index.css';

function ConfirmOffer({
  intl,
  offerCode,
  offerDetails,
  isSubmitting,
  onCloseModal,
  debouncedRedeem,
}) {
  const handleRedeem = e => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }

    debouncedRedeem(offerCode);
  };

  const renderOfferDetails = offerDetails?.products?.map(product => {
    const {
      displayName,
      productId,
      term,
      termFrequency,
      productStartDate,
    } = product;

    const productDetails = () => {
      const offerDuration = () => {
        const frequencyDict = {
          yearly: 'year',
          monthly: 'month',
          weekly: 'week',
          daily: 'day',
        };

        if (termFrequency && term) {
          const lcFrequency = termFrequency?.toLowerCase();
          const frequencyUnit = frequencyDict[lcFrequency];

          return `${term}-${frequencyUnit}`;
        }
        return intl.formatMessage({ ...messages.noTermOrFrequency });
      };

      const productStart = () => {
        if (productStartDate) {
          return dateToDayMonthYearLongWithComma(new Date(productStartDate));
        }
        return intl.formatMessage({ ...messages.noProductStartDate });
      };

      return (
        <span>
          {offerDuration()} access to {displayName || productId} beginning{' '}
          {productStart()}.
        </span>
      );
    };

    return (
      <li className="offer-item" key={productId}>
        {productDetails()}
      </li>
    );
  });

  return (
    <div className="confirm-offer vertical-layout">
      <div className="offer-details">
        <div className="confirm-icon">
          <img src={ConfirmIcon} alt="confirm" />
        </div>
        <div className="offer-text">
          <p className="confirm-heading">
            {intl.formatMessage({ ...messages.confirmOfferDetails })}
          </p>
          <ul>{renderOfferDetails}</ul>
        </div>
      </div>
      <div className="button-container">
        <Button
          className="submit"
          onClick={e => handleRedeem(e)}
          data-testid="confirm-button"
        >
          {isSubmitting && (
            <div className="vertical-layout">
              <ReactLoading
                type="spin"
                height="100%"
                width="1rem"
                aria-label="Loading"
              />
            </div>
          )}
          <div
            data-testid="hide-text-div"
            className={isSubmitting ? 'hide-text' : undefined}
          >
            {intl.formatMessage({ ...messages.confirmCodeBtn })}
          </div>
        </Button>
        <Button
          color="link"
          className="cancel-button"
          onClick={() => onCloseModal()}
        >
          {intl.formatMessage({ ...messages.cancelCodeBtn })}
        </Button>
      </div>
    </div>
  );
}

ConfirmOffer.propTypes = {
  intl: intlShape,
  offerCode: PropTypes.string,
  offerDetails: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onCloseModal: PropTypes.func,
  debouncedRedeem: PropTypes.func,
};

export default ConfirmOffer;
