/*
 * ToolsPage Messages
 *
 * This contains all the text for the ToolsPage container.
 */

import { defineMessages } from 'react-intl';
import { config } from 'config';
import { TITLE_TOOLS_PAGE } from '../MasterPage/constants';

export const scope = 'app.container.tools';

export default defineMessages({
  docTitle: {
    id: `${scope}.docTitle`,
    defaultMessage: TITLE_TOOLS_PAGE,
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: config.REACT_APP_META_TOOLS_PAGE_DESCRIPTION,
  },
  tools: {
    id: `${scope}.tols`,
    defaultMessage: 'Tools',
  },
  subHeader: {
    id: `${scope}.subHeader`,
    defaultMessage:
      'Resources to help you start your legal education journey right.',
  },
  opensInNewWindow: {
    id: `${scope}.opensInNewWindow`,
    defaultMessage: 'Opens in a new browser window.',
  },
  findYourLawSchool: {
    id: `${scope}.findYourLawSchool`,
    defaultMessage: 'Find Your Law School',
  },
  findYourLawSchoolDescription: {
    id: `${scope}.findYourLawSchoolDescription`,
    defaultMessage:
      'Use our quiz to find relevant law schools so you’re making an informed choice about where you apply and attend.',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign in',
  },
  goToPersonalReport: {
    id: `${scope}.goToPersonalReport`,
    defaultMessage: 'Go to personal report',
  },
  exploreCareers: {
    id: `${scope}.exploreCareers`,
    defaultMessage: 'Explore Careers',
  },
  exploreCareersDescription: {
    id: `${scope}.exploreCareersDescription`,
    defaultMessage:
      'Which career in law is the best fit for your skills and interests? We’ll help you find out.',
  },
  lawSchoolScholarships: {
    id: `${scope}.lawSchoolScholarships`,
    defaultMessage: 'Law School Scholarships',
  },
  lawSchoolScholarshipsDescription: {
    id: `${scope}.lawSchoolScholarshipsDescription`,
    defaultMessage:
      'Scholarships can help with the cost of a legal education. Here’s some of what’s available.',
  },
  learnMore: {
    id: `${scope}.learnMore`,
    defaultMessage: 'Learn more',
  },
  applicationStatusTracker: {
    id: `${scope}.applicationStatusTracker`,
    defaultMessage: 'Application Status Tracker',
  },
  applicationStatusTrackerDescription: {
    id: `${scope}.applicationStatusTrackerDescription`,
    defaultMessage:
      'Securely and conveniently monitor all your law school applications from our dashboard.',
  },
  viewApps: {
    id: `${scope}.viewApps`,
    defaultMessage: 'View Apps',
  },
  otherTools: {
    id: `${scope}.otherTools`,
    defaultMessage: 'Other Tools',
  },
  legalCareerCompass: {
    id: `${scope}.legalCareerCompass`,
    defaultMessage: 'Legal Career Compass',
  },
  legalCareerCompassDescription: {
    id: `${scope}.legalCareerCompassDescription`,
    defaultMessage:
      'A 30-minute assessment that pays dividends at each milestone of your legal career.',
  },
  seeWhereYouFit: {
    id: `${scope}.seeWhereYouFit`,
    defaultMessage: 'See Where You Fit',
  },
  serious: {
    id: `${scope}.serious`,
    defaultMessage: 'SeRiouS',
  },
  seriousDesc: {
    id: `${scope}.seriousDesc`,
    defaultMessage:
      'Prepare for your law school exams using the award-winning repetition technique for memorization. Use code LAWHUBSRS10 to receive a 10% discount on all purchases.',
  },
  seriousDescUnAuth: {
    id: `${scope}.seriousDescUnAuth`,
    defaultMessage:
      'Prepare for your law school exams using the award-winning repetition technique for memorization.',
  },
  takeAdvToday: {
    id: `${scope}.takeAdvToday`,
    defaultMessage: 'Take Advantage Now',
  },
  tryItNow: {
    id: `${scope}.tryItNow`,
    defaultMessage: 'TRY IT NOW',
  },
  explore: {
    id: `${scope}.explore`,
    defaultMessage: 'Explore',
  },
  knowB4UGo: {
    id: `${scope}.knowB4UGo`,
    defaultMessage: 'KnowB4UGo',
  },
  knowB4UGoDesc: {
    id: `${scope}.knowB4UGoDesc`,
    defaultMessage:
      'Exclusively on LawHub: Use a proven memorization technique to learn more terms for 1L in less time—and be ready to ace your law school exams.',
  },
  upgradeNow: {
    id: `${scope}.upgradeNow`,
    defaultMessage: 'Upgrade Now',
  },
  signInBannerMessage: {
    id: `${scope}.signInBannerMessage`,
    defaultMessage: 'Sign in or sign up to take advantage of these tools.',
  },
  signInToLawHub: {
    id: `${scope}.signInToLawHub`,
    defaultMessage: 'SIGN-IN TO LAWHUB',
  },
});
