import { defineMessages } from 'react-intl';
// import image from '../../images/view-site-setting-icon-chrome.png';

export const scope = 'app.container.ExamStartPage.NotificationsModal';

export default defineMessages({
  heading: {
    id: `${scope}.heading`,
    defaultMessage: 'Notifications',
  },
  firstPart: {
    id: `${scope}.firstPart`,
    defaultMessage:
      'Notifications must be allowed in order to continue. If you do not see on-screen prompts to allow notifications, please follow the steps below or allow notifications using your browser settings options.',
  },
  secondPart: {
    id: `${scope}.secondPart`,
    defaultMessage:
      '<strong>Chrome</strong>: select the "view site information" icon (',
  },

  thirdPart: {
    id: `${scope}.thirdPart`,
    defaultMessage:
      ') to the left of the URL in the address bar, navigate to "Notifications" and toggle the button on.',
  },

  fourthPart: {
    id: `${scope}.fourthPart`,
    defaultMessage:
      'You may need to reload this page for the changes to your settings to take effect. This modal will close automatically once notifications are allowed.',
  },
});
