/* eslint-disable indent */
import PropTypes from 'prop-types';
import UserProgramsModel from './userProgramsModel';
import UserSettingsModel from './userSettingsModel';

/**
 * User model
 * @class
 */
class UserModel {
  /**
   *
   * @param {object} dto The data transfer object
   * @returns {UserModel} The newly created user model
   */
  static fromDto(dto) {
    return new UserModel(
      dto.userId,
      dto.emailAddress,
      dto.firstName,
      dto.fullName,
      dto.lastName,
      dto.mostRecentHistory,
      dto.userSettings,
      dto.subscriptionStatus,
      dto.isExamScheduled,
      dto.userProgramsSettings,
      dto.userOrganizationIdentifiers,
      dto.activeProducts,
      dto.programIds,
      dto.surveyStatuses,
    );
  }

  /**
   *
   * @param {string} userId The user id
   * @param {string} emailAddress The email address
   * @param {string} firstName The first name
   * @param {string} lastName The last name
   * @param {string} fullName The full name
   * @param {Object} mostRecentHistory Most recent prep test info
   * @param {UserSettingsModel} userSettings The user settings model
   * @param {Object} subscriptionStatus The subscription status
   * @param {bool} isExamScheduled Is the exam sheduled
   * @param {UserProgramsModel} userPrograms The user program model
   * @param {Object} userOrganizationIdentifiers User organizations, ID and name
   * @param {Object} activeProducts Products the user has access to
   * @param {Object} programIds Array of programIds
   * @param {Object[]} surveyStatuses Array of available surveys
   */
  constructor(
    userId,
    emailAddress,
    firstName,
    fullName,
    lastName,
    mostRecentHistory,
    userSettings,
    subscriptionStatus,
    isExamScheduled,
    userPrograms,
    userOrganizationIdentifiers,
    activeProducts,
    programIds,
    surveyStatuses,
  ) {
    this.userId = userId;
    this.emailAddress = emailAddress;
    this.firstName = firstName;
    this.fullName = fullName;
    this.lastName = lastName;
    this.mostRecentHistory = mostRecentHistory;
    this.userSettings = new UserSettingsModel(
      userSettings.isExtraTime,
      userSettings.extraTimeAmount,
      userSettings.minutesPerSection,
      userSettings.isUsingFindTextFeature,
      userSettings.minutesPerAnalysis,
    );
    this.subscriptionStatus = subscriptionStatus;
    this.isExamScheduled = isExamScheduled;
    this.userPrograms = userPrograms
      ? userPrograms.map(
          program =>
            new UserProgramsModel(
              program.programId,
              program.programName,
              program.userSettings,
            ),
        )
      : [];
    this.userOrganizationIdentifiers = userOrganizationIdentifiers
      ? userOrganizationIdentifiers.map(organizationObj => ({
          organization: organizationObj?.organization,
          organizationIdentifier: organizationObj?.organizationIdentifier,
        }))
      : [];
    this.activeProducts = activeProducts;
    this.programIds = programIds;
    this.surveyStatuses = surveyStatuses;
  }
}

UserModel.propTypes = {
  userId: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  isExamScheduled: PropTypes.bool.isRequired,
  userSettings: PropTypes.instanceOf(UserSettingsModel),
  userPrograms: PropTypes.arrayOf(UserProgramsModel),
  userOrganizationIdentifiers: PropTypes.array,
  activeProducts: PropTypes.object,
  subscriptionStatus: PropTypes.object,
};

export default UserModel;
