/* eslint-disable indent */
import PropTypes from 'prop-types';

/**
 * User EmbeddedHighChartModel
 * @class
 */
class EmbeddedHighChartModel {
  /**
   *
   * @param {object} dto The data transfer object
   * @returns {EmbeddedHighChartModel} The newly created EmbeddedHighChart model
   */
  static fromDto(dto) {
    return new EmbeddedHighChartModel(
      dto.id,
      dto.referenceId,
      dto.highChartJSONOptions,
    );
  }

  /**
   *
   * @param {string} id The entity id
   * @param {string} referenceId user friendly Id
   * @param {object} chartOptions chart Options that define the chart
   */
  constructor(id, referenceId, chartOptions) {
    this.id = id;
    this.referenceId = referenceId;
    this.chartOptions = chartOptions;
  }
}

EmbeddedHighChartModel.propTypes = {
  id: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired,
  chartOptions: PropTypes.object.isRequired,
};

export default EmbeddedHighChartModel;
