/* eslint-disable prettier/prettier */
import './index.css';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { connect } from 'react-redux';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { userDetailsSelector } from 'domains/session/selectors';
import { useSessionRedux } from 'domains/session/hooks';
import messages from './messages';
import { TITLE_PREP_PLUS_WELCOME } from '../MasterPage/constants';

// images
import welcomeLogo from '../../images/plus-program-welcome.svg';

import {
  flexExamErrorSelector,
} from '../TestSelectPage/selectors';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
import { getPlusProgramRedirectUri } from '../../helpers/plusProgramHelper';

export function PlusProgramWelcome({
  userDetails,
}) {
  // required in order to get user details
  useSessionRedux();
  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  // required in order to get user details
  useEffect(() => {
    document.title = TITLE_PREP_PLUS_WELCOME;
  }, []);

  // accessibility support for keyboard navigation
  document.body.addEventListener('mousedown', () => {
    document.body.classList.remove('keyboard-focus');
    document.body.classList.add('mouse-focus');
  });

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('mouse-focus');
    document.body.classList.add('keyboard-focus');
  });

  const uri = getPlusProgramRedirectUri(userDetails);

  return (
    <div className="plus-program-welcome-page-container">
      <div className="plus-program-welcome-page">
        <div className="plus-program-welcome-page-head">
          <img
            className="plus-program-welcome-page-welcome-image"
            id="pageHeader"
            src={welcomeLogo}
            alt=""
          />
          <h1 id="plus-program-welcome-page-heading1">
            <FormattedMessage {...messages.heading1} />
          </h1>
        </div>
        <hr
          className="plus-program-welcome-page-horizontal-rule"
          aria-hidden="true"
        />
        <div>
          <p className="plus-program-welcome-page-heading">
            <FormattedMessage {...messages.heading2} />
          </p>
          <h2 className="plus-program-welcome-page-heading2">
            <FormattedMessage {...messages.heading3} />
          </h2>
          <a
            id="plus-welcome-registration"
            data-testid="plus-welcome"
            className="plus-program-welcome-page-survey-button"
            href={uri}
          >
            <span className="plus-program-welcome-page-survey-button-text">
              <FormattedMessage {...messages.buttonText} />
            </span>
          </a>
          <h3 className="plus-program-welcome-page-heading3">
            <FormattedMessage {...messages.heading4} />
          </h3>
          <ul className="plus-program-welcome-page-text-content" type="1">
            <li>
              <FormattedHTMLMessage {...messages.bulletPoint1} />
            </li>
            <li>
              <FormattedHTMLMessage {...messages.bulletPoint2} />
            </li>
            <li>
              <FormattedHTMLMessage {...messages.bulletPoint3} />
            </li>
            <li>
              <FormattedHTMLMessage {...messages.bulletPoint4} />
            </li>
            <li>
              <FormattedHTMLMessage {...messages.bulletPoint5} />
            </li>
          </ul>
          <div style={{ clear: 'right' }}>
            <p className="plus-program-welcome-page-text-content plus-prog-content">
              <FormattedHTMLMessage {...messages.text1} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

PlusProgramWelcome.propTypes = {
  userDetails: PropTypes.object,
  intl: intlShape.isRequired,
  error: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  userDetails: userDetailsSelector(),
  error: flexExamErrorSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(PlusProgramWelcome));
