import PropTypes from 'prop-types';

/**
 * Setting model to hold the font size, line height and highlight type
 * @class
 */

class SettingModel {
  constructor() {
    /**
     * @property {number} fontSize The font size
     */
    this.fontSize = 0;

    /**
     * @property {number} lineHeight The line height
     */
    this.lineHeight = 0;

    /**
     * @property {number} highlightType The highlight type
     */
    this.highlightType = 0;
  }
}

SettingModel.propTypes = {
  fontSize: PropTypes.number.isRequired,
  lineHeight: PropTypes.number.isRequired,
  highlightType: PropTypes.number.isRequired,
};

export default SettingModel;
