/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import ModulePage from 'containers/ModulePage';
import SectionPage from 'containers/SectionPage/Loadable';
import PassagePage from 'containers/PassagePage/Loadable';
import DirectionsPage from 'containers/DirectionsPage/Loadable';
import HistoryPage from 'containers/HistoryPage/Loadable';
import TestReview from '../TestReview/index';
import TestComplete from '../TestComplete';
import ExamStartPage from '../ExamStartPage/index';
import ExamCompletePage from '../ExamCompletePage/index';
import IntermissionPage from '../IntermissionPage/index';
import RegistrationPage from '../RegistrationPage/Loadable';
import TestCompleteFlexPage from '../TestCompleteFlexPage';
import CertifyingStatementPage from '../CertifyingStatementPage';
import FlexCongratsPage from '../FlexCongratsPage';
import WritingExamPage from '../WritingExamPage';
import DuplicatePage from '../DuplicatePage';
import PausePage from '../PausePage';
import ProctorWritingPage from '../ProctorWritingPage';
import ConfirmUserPage from '../ConfirmUserPage/Loadable';
import DeepLinkPage from '../DeepLinkPage/Loadable';
import PMTestDurationEndedPage from '../PMTestDurationEndedPage';

import {
  MODULE_PATH,
  EXAM_MODULE_PATH,
  QUESTION_PATH,
  EXAM_QUESTION_PATH,
  DIRECTION_PATH,
  EXAM_DIRECTION_PATH,
  PASSAGE_PATH,
  EXAM_PASSAGE_PATH,
  HISTORY_PATH,
  TEST_COMPLETE_PATH,
  EXAM_TEST_COMPLETE_PATH,
  TEST_REVIEW_PATH,
  EXAM_TEST_REVIEW_PATH,
  TEST_COMPLETE_FLEX_PATH,
  LSAT_FLEX_PATH,
  FLEX_LANDING_PATH,
  CERTIFYING_STATEMENT_PATH,
  SCORE_REPORT_PATH,
  FLEX_CONGRATS_PATH,
  EXAM_START_PATH,
  EXAM_COMPLETE_PATH,
  EXAM_CONFIRM_COMPLETE_PATH,
  INTERMISSION_PATH,
  WRITING_EXAM_PAGE,
  EXAM_PAUSE_PATH,
  PROCTORING_INITIALIZE_PATH,
  CONFIRM_EMAIL,
  DEEPLINK_PATH,
  TEST_DURATION_ENDED,
} from './constants';

// Routes related to the Test Driver and preceded by
// /prep or /exam should be moved here
const TestDriverRouter = props => (
  <Switch>
    <Route
      path={[
        `${EXAM_MODULE_PATH}/:testInstanceId`,
        `${MODULE_PATH}/:testInstanceId`,
      ]}
      component={ModulePage}
    />
    <Route
      path={[
        `${QUESTION_PATH}/:testInstanceId/:sectionId`,
        `${EXAM_QUESTION_PATH}/:testInstanceId/:sectionId`,
      ]}
      component={SectionPage}
    />
    <Route
      path={[
        `${EXAM_DIRECTION_PATH}/:testInstanceId/:sectionId`,
        `${DIRECTION_PATH}/:testInstanceId/:sectionId`,
      ]}
      component={DirectionsPage}
    />
    <Route
      path={[
        `${PASSAGE_PATH}/:testInstanceId/:sectionId`,
        `${EXAM_PASSAGE_PATH}/:testInstanceId/:sectionId`,
      ]}
      component={PassagePage}
    />
    <Route exact path={`${HISTORY_PATH}/:moduleId`} component={HistoryPage} />
    <Route
      path={[
        `${TEST_COMPLETE_PATH}/:testInstanceId`,
        `${EXAM_TEST_COMPLETE_PATH}/:testInstanceId`,
      ]}
      component={TestComplete}
    />
    <Route
      path={[
        `${TEST_REVIEW_PATH}/:testInstanceId/:sectionId`,
        `${EXAM_TEST_REVIEW_PATH}/:testInstanceId/:sectionId`,
      ]}
      component={TestReview}
    />
    <Route
      path={`${TEST_COMPLETE_FLEX_PATH}/:id?`}
      render={
        props.testPageCheck ? DuplicatePage : () => <TestCompleteFlexPage />
      }
    />
    <Route
      exact
      path={LSAT_FLEX_PATH}
      component={props.testPageCheck ? DuplicatePage : CertifyingStatementPage}
    />
    <Route exact path={FLEX_LANDING_PATH} component={RegistrationPage} />
    <Route
      exact
      path={CERTIFYING_STATEMENT_PATH}
      component={props.testPageCheck ? DuplicatePage : CertifyingStatementPage}
    />
    <Route exact path={EXAM_PAUSE_PATH} component={PausePage} />
    <Route
      path={`${SCORE_REPORT_PATH}/:testInstanceId`}
      render={({ match }) => (
        <TestComplete match={match} isScoreReport shouldLoadInitially />
      )}
    />
    <Route
      path={`${FLEX_CONGRATS_PATH}/:id?`}
      render={props.testPageCheck ? DuplicatePage : () => <FlexCongratsPage />}
    />
    <Route
      path={`${EXAM_START_PATH}/:examType`}
      render={
        props.testPageCheck
          ? DuplicatePage
          : ({ match }) => <ExamStartPage examType={match.params.examType} />
      }
    />
    <Route
      path={`${EXAM_COMPLETE_PATH}/:examType/:testInstanceId?`}
      render={
        props.testPageCheck
          ? DuplicatePage
          : ({ match }) => (
              <ExamCompletePage
                testInstanceId={match.params.testInstanceId}
                examType={match.params.examType}
              />
            )
      }
    />
    <Route
      path={`${EXAM_CONFIRM_COMPLETE_PATH}/:examType`}
      render={
        props.testPageCheck
          ? DuplicatePage
          : ({ match }) => (
              <TestCompleteFlexPage examType={match.params.examType} />
            )
      }
    />
    <Route
      exact
      path={`${INTERMISSION_PATH}/:testInstanceId`}
      render={
        props.testPageCheck
          ? DuplicatePage
          : ({ match }) => (
              <IntermissionPage testInstanceId={match.params.testInstanceId} />
            )
      }
    />
    <Route
      path={`${INTERMISSION_PATH}/:testInstanceId/:sectionId`}
      render={
        props.testPageCheck
          ? DuplicatePage
          : ({ match }) => (
              <IntermissionPage
                testInstanceId={match.params.testInstanceId}
                sectionId={match.params.sectionId}
              />
            )
      }
    />
    <Route path={PROCTORING_INITIALIZE_PATH} component={ProctorWritingPage} />
    <Route
      path={`${WRITING_EXAM_PAGE}`}
      component={props.testPageCheck ? DuplicatePage : WritingExamPage}
    />
    <Route path={CONFIRM_EMAIL} component={ConfirmUserPage} />
    <Route path={DEEPLINK_PATH} component={DeepLinkPage} />
    <Route path={TEST_DURATION_ENDED} component={PMTestDurationEndedPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

TestDriverRouter.propTypes = {
  testPageCheck: PropTypes.bool,
};

export default TestDriverRouter;
