/**
 *
 * persistence instance helper
 *
 */

/* eslint-disable no-param-reassign */
import { isNullOrUndefined } from 'util';
import { currentTimeAsISO } from '../../timeHelper';
import { createArrayIfNecessary, getArrayItemByKey } from './arrayHelper';
import { persistenceState } from './persistenceState';
import { getInstanceRepo } from './repoHelper';
import * as logHelper from '../../logHelper';
import { LogLevelType } from '../../../enumerations/LogLevelType';
import { CHANGE_BREAK_TIME_REMAINING } from '../../../containers/DirectionsPage/constants';

export function setEntireInstanceDirty(instance) {
  setInstanceValueChanged(instance.testInstanceId, '*');
}

export function setSectionValueChanged(testInstanceId, sectionId, actionType) {
  if (persistenceState.changedValues[testInstanceId] === undefined) {
    persistenceState.changedValues[testInstanceId] = {};
  }
  const cv = persistenceState.changedValues[testInstanceId];
  if (cv.sections === undefined) {
    cv.sections = {};
  }
  const { sections } = cv;
  if (sections[sectionId] === undefined) {
    sections[sectionId] = {};
  }
  sections[sectionId][actionType] = true;
}

/**
 * Mark the instance as changed
 */
export function setInstanceChanged(section, actionType) {
  if (isNullOrUndefined(actionType)) {
    logHelper.log(
      LogLevelType.Info,
      'Action type is required on setInstanceChanged',
    );
    throw new Error('Action type is required on setInstanceChanged');
  }
  const now = currentTimeAsISO();
  let firstStatusForSection = false;
  if (!isNullOrUndefined(section)) {
    if (isNullOrUndefined(section.startTime)) {
      // Check to update the start time of section only when that section begins
      if (
        actionType === 'isCompleted' ||
        actionType === CHANGE_BREAK_TIME_REMAINING
      ) {
        return;
      }
      section.startTime = now;
      setSectionValueChanged(
        persistenceState.testInstanceId,
        section.formId,
        'startTime',
      );
      firstStatusForSection = true;
    }
    // Sets the isSectionExposed flag in section
    if (!section.isSectionExposed && actionType === 'isSectionExposed') {
      section.isSectionExposed = true;
      setSectionValueChanged(
        persistenceState.testInstanceId,
        section.formId,
        'isSectionExposed',
      );
      firstStatusForSection = true;
    }
    section.lastUpdateTime = now;
  }

  const instanceRepo = getInstanceRepo();
  if (!isNullOrUndefined(instanceRepo)) {
    const { state } = instanceRepo;

    if (!isNullOrUndefined(state)) {
      if (isNullOrUndefined(state.startTime)) {
        state.startTime = now;
      }
      state.lastUpdateTime = now;
      state.lastChangedTime = Date.now();
    }
    if (isNullOrUndefined(section)) {
      setInstanceValueChanged(instanceRepo.testInstanceId, actionType);
    } else {
      setSectionValueChanged(
        instanceRepo.testInstanceId,
        section.formId,
        actionType,
      );
    }
    instanceRepo.serverNeedsLatest = true;
  }
  if (firstStatusForSection) {
    persistenceState.refreshSummary();
  }
}

function setInstanceValueChanged(testInstanceId, actionType) {
  if (persistenceState.changedValues[testInstanceId] === undefined) {
    persistenceState.changedValues[testInstanceId] = {};
  }
  if (persistenceState.changedValues[testInstanceId].state === undefined) {
    persistenceState.changedValues[testInstanceId].state = {};
  }
  persistenceState.changedValues[testInstanceId].state[actionType] = true;
}

/**
 * get a section repository based on sectionId, create a new one if necessary
 * @param {string} sectionId the section id
 */
export function getSection(sectionId) {
  const instanceRepo = getInstanceRepo();
  if (
    !isNullOrUndefined(sectionId) &&
    !isNullOrUndefined(instanceRepo) &&
    !isNullOrUndefined(instanceRepo.state)
  ) {
    createArrayIfNecessary(instanceRepo.state, 'sections');
    const section = getArrayItemByKey(
      instanceRepo.state.sections,
      'formId',
      sectionId,
    );
    return section;
  }
  return null;
}

/**
 * remove the completed test instance from instnaces repo
 * @param {string} testInstanceId the test id
 */
export function removeInstanceFromRepo(testInstanceId) {
  const { instances } = persistenceState.repo;
  if (instances?.length > 0) {
    const indexOfElement = instances.findIndex(
      test => test.testInstanceId === testInstanceId,
    );
    if (indexOfElement > -1) {
      instances.splice(indexOfElement, 1);
    }
  }
}
