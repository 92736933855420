import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { APPLICATION_ONLINE_STATUS_PAGE } from 'containers/App/constants';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import './ASOUnAuthView.css';
import Accordion from '../../components/Accordion';
import { AstUnauthProfile } from './commonImages';
import messages from './messages';
import { signIn } from '../../authProvider';
import ASOSubscriptionPage from './ASOSubscriptionPage';

const ASOUnAuthView = ({ defaultLoading }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(defaultLoading);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setLoading(false), 1000);

    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const subscribeToLawHub = e => {
    e.preventDefault();
    history.replace(`${APPLICATION_ONLINE_STATUS_PAGE}?subCheck=true`);
    signIn();
  };

  if (!loading) {
    return (
      <div className="aso-unauth-container">
        <section className="layout-container hero-section bg-light-blue">
          <div className="central-column aso-unauth-header">
            <div className="aso-unauth-header">
              <div className="aso-left-content">
                <h1 id="pageHeader">{messages.heading.defaultMessage}</h1>
                <p className="sub-title">
                  <FormattedHTMLMessage {...messages.astHeadingHelperText} />
                </p>
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="primary-btn"
                    onClick={e => subscribeToLawHub(e)}
                  >
                    {messages.subscribeToLawhubAdvantage.defaultMessage}
                  </button>
                </div>
              </div>
              <div className="aso-right-content">
                <div className="profile-container hide-sm">
                  <img src={AstUnauthProfile} alt="" className="profile-pic" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <ASOSubscriptionPage
          authView={false}
          subscribeToLawHub={subscribeToLawHub}
        />

        <section className="layout-container faq-section">
          <div className="central-column">
            <h2 className="section-header">
              {messages.frequentlyAskedQuestions.defaultMessage}
            </h2>
            <Accordion
              staticDescription={messages.faqAnswer1.defaultMessage}
              accordionTitle={messages.faqQuestion1.defaultMessage}
              ariaLabel={messages.faqQuestion1.defaultMessage}
            />
            <Accordion
              staticDescription={messages.faqAnswer2.defaultMessage}
              accordionTitle={messages.faqQuestion2.defaultMessage}
              ariaLabel={messages.faqQuestion2.defaultMessage}
            />
            <Accordion
              staticDescription={messages.faqAnswer3.defaultMessage}
              accordionTitle={messages.faqQuestion3.defaultMessage}
              ariaLabel={messages.faqQuestion3.defaultMessage}
            />
          </div>
        </section>
      </div>
    );
  }

  return (
    <Container
      fluid
      style={{
        width: '100vw',
      }}
    >
      <Row>
        <AriadneLoading text="Application Status Online Page" />
      </Row>
    </Container>
  );
};

ASOUnAuthView.propTypes = {
  defaultLoading: PropTypes.bool,
};

ASOUnAuthView.defaultProps = {
  defaultLoading: true,
};

export default ASOUnAuthView;
