import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ArticlePage state domain
 */

const articlePageSelector = state => state.articlePage || initialState;

/**
 * Default selector used by ArticlePage
 */

const makeArticlePage = () =>
  createSelector(
    articlePageSelector,
    substate => substate,
  );

/**
 * Selector used for ArticleContent
 */
const makeArticleContent = () =>
  createSelector(
    articlePageSelector,
    substate => substate.articleContent,
  );

/**
 * error selector
 */
const errorSelector = () =>
  createSelector(
    articlePageSelector,
    substate => substate.error,
  );

export default articlePageSelector;
export { makeArticlePage, makeArticleContent, errorSelector };
