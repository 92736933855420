import { getApi } from 'helpers/sagaHelper';
import { takeLatest, call, put } from 'redux-saga/effects';
import { config } from '../../config';
import handleAPIErrorAndRetry from '../../helpers/apiResponseHelper';
import { getAPIFullPath } from '../../helpers/configHelper';
import {
  fulfillProctoringTestErrorAction,
  fulfillProctoringTestSuccessAction,
  initializeProctoringTestErrorAction,
  initializeProctoringTestSuccessAction,
} from './actions';
import {
  FULFILL_PROCTORING_TEST,
  INITIALIZE_PROCTORING_TEST,
} from './constants';

const util = require('util');

export function* getReservationURL(data) {
  function* successCallback(repoData) {
    yield put(initializeProctoringTestSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(initializeProctoringTestErrorAction(error));
  }

  let api;

  try {
    const { examId, userId } = data.payload;
    const apiEndURL = `${util.format(
      config.REACT_APP_INITIALIZE_PROCTOR_EXAM_URL,
      userId,
      examId,
    )}`;
    const payload = { examStartUrl: window.location.origin };

    api = () => getApi('post', `${getAPIFullPath(apiEndURL)}`, payload);
    const repos = yield call(api);

    // send the result back to the reducer
    yield put(initializeProctoringTestSuccessAction(repos.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

export function* fulfillProctorWritingExam(data) {
  function* successCallback(repoData) {
    yield put(fulfillProctoringTestSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(fulfillProctoringTestErrorAction(error));
  }

  let api;

  try {
    const { examId, userId } = data.payload;
    const apiEndURL = `${util.format(
      config.REACT_APP_FULFILL_PROCTOR_EXAM_URL,
      userId,
      examId,
    )}`;

    api = () => getApi('post', `${getAPIFullPath(apiEndURL)}`);
    const repos = yield call(api);
    // send the result back to the reducer
    yield put(fulfillProctoringTestSuccessAction(repos.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * Question saga manages watcher lifecycle
 */
export default function* questionData() {
  // Watches for action and call get when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(INITIALIZE_PROCTORING_TEST, getReservationURL);
  yield takeLatest(FULFILL_PROCTORING_TEST, fulfillProctorWritingExam);
}
