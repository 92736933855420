/**
 *
 * BrowserErrorPage
 *
 */
import './index.css';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import history from 'utils/history';
import lsatLogo from '../../images/lsat-logo.png';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
import { HOME_PATH } from '../App/constants';
import { config } from '../../config';

export function BrowserErrorPage({ browserName, examType }) {
  // required in order to get user details
  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  function closeTheTestHandler() {
    history.push(HOME_PATH);
  }

  const isFlex = examType && examType.toLowerCase() !== 'writing';
  const examTypeName = isFlex ? config.REACT_APP_LSAT_NAME : 'LSAT Writing';

  return (
    <div className="browser-error-page-container">
      <div className="browser-error-page-header">
        <div className="browser-error-page-header-div">
          <div className="browser-error-page-header-text">{examTypeName}</div>
        </div>
      </div>
      <div className="browser-error-page-top-decoration" />
      <div className="browser-error-page">
        {isFlex && (
          <img
            className="browser-error-page-lsat-flex-logo"
            src={lsatLogo}
            alt={config.REACT_APP_LSAT_NAME}
          />
        )}
        {!isFlex && (
          <div id="heading1" className="heading-color">
            LSAT Writing
          </div>
        )}
        <hr className="browser-error-page-horizontal-rule" role="none" />
        <div>
          <div className="browser-error-page-text-content">
            Progress on your test was stored in {browserName}. To continue with
            your test, please log out of LawHub in this browser and then log
            back in to LawHub using {browserName}.
          </div>
          <button
            className="browser-error-page-close-button"
            type="button"
            onClick={closeTheTestHandler}
          >
            <span className="browser-error-page-close-button-text">
              Return to LawHub
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

BrowserErrorPage.propTypes = {
  browserName: PropTypes.string,
  examType: PropTypes.string,
};

const withConnect = connect(
  null,
  null,
);

export default compose(withConnect)(BrowserErrorPage);
