import { call, delay, put } from 'redux-saga/effects';
import { push, goBack } from 'react-router-redux';
import { API_ERROR_PATH, ERROR_SCREEN_PATH } from '../containers/App/constants';
import history from '../utils/history';
import * as persistence from './persistence/common/persistenceState';
import { logout } from '../authProvider';
import { config } from '../config';
import { EXAM_PATHS } from './constants';

const ErrorCodesToHandle = [0, 401, 403, 408, 429, 500, 502, 503, 504];

export default function* handleAPIErrorAndRetry(
  error,
  api,
  successCallback,
  failureCallback,
  data = {},
  callCount = 0,
) {
  if (
    error &&
    error.response &&
    ErrorCodesToHandle.includes(error.response.status)
  ) {
    // logout if status code 401.
    if (error.response.status === 401) {
      logout();
      return;
    }

    // do not go into retry flow is user is on a exam page and config is true
    // or if user's network is offline
    const { pathname } = window?.location;
    const isExamRoute = EXAM_PATHS.some(path => pathname?.indexOf(path) >= 0);
    if (
      isExamRoute &&
      (!navigator.onLine || config.REACT_APP_SKIP_EXAM_RETRY === 'true')
    ) {
      yield* failureCallback(error, data.callbackData);
      return;
    }

    persistence.persistenceState.shouldStopRetry = true;
    try {
      const repos = yield call(api, data.apiData);
      // send the result back to the reducer
      yield* successCallback(repos.data, data.callbackData);
    } catch (err) {
      // retry 10 times and show error page if api still fails
      if (callCount <= 8) {
        yield* handleAPIErrorAndRetry(
          err,
          api,
          successCallback,
          failureCallback,
          data,
          callCount + 1,
        );
      } else {
        // navigate to api error page only once, in case multiple apis are in retry flow
        if (history.location.pathname !== API_ERROR_PATH) {
          yield put(push(API_ERROR_PATH, { handlingFailedAPI: true }));
        }
        persistence.persistenceState.shouldStopRetry = false;
        yield delay(config.REACT_APP_API_RETRY_DELAY);
        yield* retryAPIInInterval(api, successCallback, failureCallback, data);
      }
    }
  } else if (error?.response?.status === 400) {
    // reroute to /error page here
    yield put(push(ERROR_SCREEN_PATH, { handlingFailedAPI: true }));
  } else {
    yield* failureCallback(error, data.callbackData);
  }
}

export function* retryAPIInInterval(
  api,
  successCallback,
  failureCallback,
  data = {},
) {
  try {
    const repos = yield call(api, data.apiData);
    // stop retrying, send the result back to the reducer, and show the last page
    persistence.persistenceState.shouldStopRetry = true;
    yield put(goBack());
    yield* successCallback(repos, data.callbackData);
  } catch (error) {
    // retry every 5 minutes until api responds success, or another user action is performed
    if (
      error &&
      error.response &&
      ErrorCodesToHandle.includes(error.response.status) &&
      !persistence.persistenceState.shouldStopRetry
    ) {
      yield delay(config.REACT_APP_API_RETRY_DELAY);
      yield* retryAPIInInterval(api, successCallback, failureCallback, data);
    } else {
      yield* failureCallback(error, data.callbackData);
    }
  }
}
