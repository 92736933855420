/**
 *
 * persistence repo helper
 *
 */

import { storeValueInLocal } from '../../localStorageHelper';
import { getArrayItemByKey } from './arrayHelper';
import { persistenceState } from './persistenceState';

/**
 * Given a test instance id, get its entry in the repo
 * @param {string} testInstanceId  the test instance id
 */
export function getInstanceById(testInstanceId, createIfNotFound = true) {
  const instance = getArrayItemByKey(
    persistenceState.repo.instances,
    'testInstanceId',
    testInstanceId,
    createIfNotFound,
  );
  return instance;
}

export function getInstanceRepo() {
  return getInstanceById(persistenceState.testInstanceId);
}

/**
 * Appends received module to the instance of given test instance id
 * @param {string} testInstanceId  the test instance id
 * @param {object} module  module information for the test instance
 */
export function appendModuleToInstance(testInstanceId, module) {
  const instance = getInstanceById(testInstanceId);

  if (!instance.module) instance.module = module;
}

/**
 * Save current in progress test instance in local storage to avoid duplication test
 * @param {string} testInstanceId  the test instance id
 */
export function saveTestToLocal(testInstanceId) {
  let existingTests = JSON.parse(localStorage.getItem('testInstanceInLocal'));
  if (existingTests == null) existingTests = [];
  if (existingTests.includes(testInstanceId)) return;
  existingTests.push(testInstanceId);
  storeValueInLocal('testInstanceInLocal', JSON.stringify(existingTests));
}

/**
 * Remove test instance from local storage when test is either paused or completed
 */
export function removeTestFromLocal(testInstanceId) {
  const existingTests = JSON.parse(localStorage.getItem('testInstanceInLocal'));
  if (!existingTests) return;

  const index = existingTests.indexOf(testInstanceId);
  if (index !== -1) {
    existingTests.splice(index, 1);
  }
  storeValueInLocal('testInstanceInLocal', JSON.stringify(existingTests));
}

/**
 * Check whether current test started is already in local storage
 * @param {string} testInstanceId  the test instance id
 */
export function isTestAlreadyInLocal(testInstanceId) {
  const activeTest = localStorage.getItem('testInstanceInLocal');
  if (!activeTest) return false;

  return activeTest.includes(testInstanceId);
}
