/**
 * Option state enumeration that describes the state of the answer option
 * @category enumerations
 * @enum {number}
 */
const OptionState = {
  /**
   * @type {number}
   * @description The answer option is unselected
   */
  Unselected: 0,
  /**
   * @type {number}
   * @description The answer option is selected
   */
  Selected: 1,
  /**
   * @type {number}
   * @description The answer option is eliminated
   */
  Eliminated: 2,
};

export { OptionState };
