/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint no-console: */
import './index.css';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { Container, Row } from 'reactstrap';
import { useSessionReduxAndSaga } from 'domains/session/hooks';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import {
  clearExamListErrorAction,
  getFlexExamListAction,
} from '../TestSelectPage/actions';
import {
  getFlexInstanceAction,
  getInstanceAction,
  resumeSectionAction,
  onProctorDisconnectAction,
  clearInstanceErrorAction,
} from '../DirectionsPage/actions';
import makeSelectTestSelectPage, {
  initSagaLibrarySelector,
  retrievedFlexExamsSelector,
  flexExamsSelector,
  errorSelector,
  flexExamErrorSelector,
} from '../TestSelectPage/selectors';
import { subscribeUserSelector } from '../../domains/session/selectors';
import { fetchKeyAction } from '../../domains/session/actions';
import { getInstanceErrorSelector } from '../DirectionsPage/selectors';
import testSelectPageReducer from '../TestSelectPage/reducer';
import directionsPageReducer from '../DirectionsPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
import arrowInCircle from '../../images/arrow-in-circle.png';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import { getUserId, getUserDetails } from '../../helpers/userHelper';
import messages from './messages';
import CertifyingContent from '../../components/CertifyingContent';
import { subscribePush } from '../../helpers/pushHelper';
import RoomScanModal from './RoomScanModal/index';
import NotificationsModal from './NotificationsModal';
import RedirectModal from './RedirectModal';
import * as pageStates from './constants';
import * as logHelper from '../../helpers/logHelper';
import { LogLevelType } from '../../enumerations/LogLevelType';

export function ExamStartPage({
  instanceError,
  initSagaLibrary,
  getExamList,
  modules,
  retrievedExamList,
  sectionResume,
  getExistingInstance,
  getNewInstance,
  examListError,
  examType,
  subscribeUser,
  onFetchKeyAction,
  proctorDisconnect,
  clearInstanceError,
  clearExamListError,
}) {
  // #region state declaration
  // holds all the different states of the pages state machine
  const [state, setState] = useState(pageStates.STATE_WAITING_FOR_SAGA);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [message, setMessage] = useState('');

  // notification permissions can be granted, denied and default. default means
  // that the user decision is unkown so the application will act as if the
  // permission was denied
  const [isNotificationsEnabled] = useState(
    Notification.permission === 'granted',
  );

  // determines if the user is subscribed to the push notifications
  // from proctoru
  const [
    isSubscribedToNotifications,
    setIsSubscribedToNotifications,
  ] = useState(false);

  const [isRetrievingExam, setIsRetrievingExam] = useState(false);
  const [exam, setExam] = useState(undefined);
  // #endregion state declaration

  // #region useEffects
  useEffect(() => {
    switch (state) {
      case pageStates.STATE_WAITING_FOR_SAGA:
        logHelper.log(LogLevelType.Info, pageStates.STATE_WAITING_FOR_SAGA);

        if (initSagaLibrary) {
          setState(pageStates.STATE_REQUEST_EXAM_LIST);
        }
        break;

      case pageStates.STATE_REQUEST_EXAM_LIST:
        logHelper.log(LogLevelType.Info, pageStates.STATE_REQUEST_EXAM_LIST);

        if (!retrievedExamList) {
          if (isNullOrUndefined(examListError)) {
            getExamList(getUserId(), examType);
          }
        }
        setState(pageStates.STATE_WAITING_FOR_EXAM_LIST);
        break;

      case pageStates.STATE_WAITING_FOR_EXAM_LIST:
        logHelper.log(
          LogLevelType.Info,
          pageStates.STATE_WAITING_FOR_EXAM_LIST,
        );

        if (retrievedExamList) {
          setState(pageStates.STATE_RETRIEVED_EXAM_LIST);
        } else if (!isNullOrUndefined(examListError)) {
          setState(pageStates.STATE_EXAM_LIST_ERROR);
        }
        break;

      case pageStates.STATE_EXAM_LIST_ERROR:
        logHelper.log(LogLevelType.Info, pageStates.STATE_EXAM_LIST_ERROR);

        // eslint-disable-next-line no-case-declarations
        const result = window.confirm(
          'Error receiving data from the server. Select OK to retry.',
        );

        // if result is true, then retry otherwise send them packing
        if (result) {
          clearExamListError();
          setState(pageStates.STATE_REQUEST_EXAM_LIST);
        } else {
          console.error(
            'Get exam list failed and user did not retry so redirecting to online services',
          );
          window.location.href = 'https://os.lsac.org';
        }
        break;

      case pageStates.STATE_RETRIEVED_EXAM_LIST:
        logHelper.log(LogLevelType.Info, pageStates.STATE_RETRIEVED_EXAM_LIST);
        logHelper.log(LogLevelType.Info, 'modules', modules);

        setExam(getExam(modules));
        setState(pageStates.STATE_EXAM_SET);
        break;

      case pageStates.STATE_EXAM_SET:
        logHelper.log(LogLevelType.Info, pageStates.STATE_EXAM_SET);

        logHelper.log(LogLevelType.Info, 'exam', exam);

        if (!isNullOrUndefined(exam)) {
          if (exam.examStatus === 'Completed') {
            logHelper.log(LogLevelType.Info, 'EXAM_COMPLETED');
            setState(pageStates.STATE_RETURN_TO_ONLINE_SERVICES);
          } else if (exam.examStatus === 'Expired') {
            logHelper.log(LogLevelType.Info, 'EXAM_EXPIRED');
            setMessage('Your LSAT Writing has a status of Expired.');
            setState(pageStates.STATE_RETURN_TO_ONLINE_SERVICES);
          } else if (exam.examStatus === 'Cancelled') {
            logHelper.log(LogLevelType.Info, 'EXAM_CANCELLED');
            setMessage('Your LSAT Writing has a status of Cancelled.');
            setState(pageStates.STATE_RETURN_TO_ONLINE_SERVICES);
          } else if (exam.examStatus === 'Terminated') {
            logHelper.log(LogLevelType.Info, 'EXAM_TERMINATED');
            setMessage('Your LSAT Writing has a status of Terminated.');
            setState(pageStates.STATE_RETURN_TO_ONLINE_SERVICES);
          } else if (exam.examStatus === 'Pending') {
            logHelper.log(LogLevelType.Info, 'EXAM_PENDING');
            setMessage('Your LSAT Writing has a status of Pending.');
            setState(pageStates.STATE_RETURN_TO_ONLINE_SERVICES);
          } else if (
            exam.examStatus === 'In Progress' ||
            exam.examStatus === 'Not Started'
          ) {
            if (exam.examStatus === 'In Progress') {
              setIsCheckboxChecked(true);
            }

            if (!isNotificationsEnabled) {
              setState(pageStates.STATE_REQUEST_NOTIFICATIONS_BE_ENABLED);
            } else if (!isSubscribedToNotifications) {
              setState(pageStates.STATE_REQUEST_SUBSCRIBE_TO_NOTIFICATIONS);
            } else if (exam.isRoomScanRequired) {
              setState(pageStates.STATE_REQUEST_ROOM_SCAN);
            } else {
              setState(pageStates.STATE_READY_TO_START);
            }
          }
        } else {
          logHelper.log(LogLevelType.Info, 'EXAM_NOT_FOUND');
          setMessage('Your LSAT Writing exam was not found.');
          setState(pageStates.STATE_RETURN_TO_ONLINE_SERVICES);
        }
        break;

      case pageStates.STATE_RETURN_TO_ONLINE_SERVICES:
        logHelper.log(
          LogLevelType.Info,
          pageStates.STATE_RETURN_TO_ONLINE_SERVICES,
        );
        break;

      case pageStates.STATE_REQUEST_NOTIFICATIONS_BE_ENABLED:
        logHelper.log(
          LogLevelType.Info,
          pageStates.STATE_REQUEST_NOTIFICATIONS_BE_ENABLED,
        );

        // request permission and only move foward if granted
        Notification.requestPermission(permission => {
          if (permission === 'granted') {
            setState(pageStates.STATE_REQUEST_SUBSCRIBE_TO_NOTIFICATIONS);
          }
        });

        break;

      case pageStates.STATE_REQUEST_SUBSCRIBE_TO_NOTIFICATIONS:
        logHelper.log(
          LogLevelType.Info,
          pageStates.STATE_REQUEST_SUBSCRIBE_TO_NOTIFICATIONS,
        );

        subscribePush(subscribeUser, onFetchKeyAction, proctorDisconnect);
        setIsSubscribedToNotifications(true);
        setState(pageStates.STATE_SUBSCRIBED_TO_NOTIFICATIONS);

        break;

      case pageStates.STATE_SUBSCRIBED_TO_NOTIFICATIONS:
        logHelper.log(
          LogLevelType.Info,
          pageStates.STATE_SUBSCRIBED_TO_NOTIFICATIONS,
        );
        if (exam.isRoomScanRequired) {
          setState(pageStates.STATE_REQUEST_ROOM_SCAN);
        } else {
          setState(pageStates.STATE_READY_TO_START);
        }
        break;

      case pageStates.STATE_REQUEST_ROOM_SCAN:
        logHelper.log(LogLevelType.Info, pageStates.STATE_REQUEST_ROOM_SCAN);
        break;

      case pageStates.STATE_ROOM_SCAN_COMPLETE:
        logHelper.log(LogLevelType.Info, pageStates.STATE_ROOM_SCAN_COMPLETE);

        setState(pageStates.STATE_READY_TO_START);
        break;

      case pageStates.STATE_READY_TO_START:
        logHelper.log(LogLevelType.Info, pageStates.STATE_READY_TO_START);

        if (!isNullOrUndefined(instanceError)) {
          setState(pageStates.STATE_EXAM_ERROR);
        }

        break;

      case pageStates.STATE_EXAM_ERROR:
        logHelper.log(LogLevelType.Info, pageStates.STATE_EXAM_ERROR);
        setIsRetrievingExam(false);

        // eslint-disable-next-line no-alert
        console.error('There was a problem starting the test:', instanceError);
        alert(
          'Your session may be expired. Please call LSAC at 215.968.1001 (Monday through Friday, 8:30 a.m. to 8 p.m. ET, or Saturday and Sunday, 12 p.m. to 4 p.m. ET), or email us at LSATwriting@LSAC.org for your options.',
        );

        // clear the error
        clearInstanceError();

        // reset state for retry
        setState(pageStates.STATE_READY_TO_START);
        break;

      default:
        break;
    }
  }, [
    initSagaLibrary,
    state,
    retrievedExamList,
    isNotificationsEnabled,
    isSubscribedToNotifications,
    examListError,
    instanceError,
  ]);

  useEffect(() => {
    if (navigator.serviceWorker) {
      // subscribe to notification permission changes and if
      // they change then force a programmatical reload
      // for Firefox only as Chrome will display a reload button
      navigator.permissions
        .query({ name: 'notifications' })
        .then(permissionStatus => {
          permissionStatus.onchange = () => {
            logHelper.log(
              LogLevelType.Info,
              'notifications permission state has changed to ',
              permissionStatus,
            );
            if (typeof InstallTrigger !== 'undefined') {
              window.location.reload();
            }
          };
        });
    } else {
      // Show error message to user if navigator's service worker
      // is not available. Ideally happens when user is in
      // private mode
      setState(pageStates.STATE_PRIVATE_MODE);
    }
  }, []);

  // #endregion useEffects

  // #region main code

  // inject needed reducers
  useSessionReduxAndSaga();
  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectReducer({ key: 'directionsPage', reducer: directionsPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  // set the title
  document.title = 'LSAC - LSAT Writing';
  // #endregion main code

  // #region event handlers
  function onCheckChanged(checked) {
    setIsCheckboxChecked(checked);
  }

  function onRoomScanComplete() {
    setState(pageStates.STATE_ROOM_SCAN_COMPLETE);
  }
  // #endregion event handlers

  // #region functions
  function getExam(exams) {
    if (isNullOrUndefined(exams)) {
      return null;
    }

    let filteredExams = exams.filter(
      item => item.examStatus === 'In Progress' && item.isReadyToStart,
    );

    // return the single in progress exam
    if (filteredExams.length === 1) {
      return filteredExams[0];
    }

    filteredExams = exams.filter(
      item => item.examStatus === 'Not Started' && item.isReadyToStart,
    );

    // return the single not started exam
    if (filteredExams.length === 1) {
      return filteredExams[0];
    }

    filteredExams = exams.filter(
      item => item.examStatus === 'Not Started' && !item.isReadyToStart,
    );

    // return the single pending exam but update the status
    if (filteredExams.length === 1) {
      filteredExams[0].examStatus = 'Pending';
      return filteredExams[0];
    }

    filteredExams = exams.filter(
      item =>
        item.examStatus !== 'Not Started' && item.examStatus !== 'In Progress',
    );

    // return all other exam status states
    if (filteredExams.length === 1) {
      return filteredExams[0];
    }

    // nothing matches or multiple matches which
    // would not make sense so return null
    return null;
  }

  function startExam() {
    setIsRetrievingExam(true);

    const { testInstanceId } = exam;
    if (isNullOrUndefined(testInstanceId)) {
      logHelper.log(LogLevelType.Info, 'startExam() -> getNewInstance()');
      getNewInstance({
        userId: getUserId(),
        productId: exam.productId,
        examType,
        examPassword: '', // password no longer needed for writing
      });
    } else {
      sectionResume(true, testInstanceId);
      logHelper.log(LogLevelType.Info, 'startExam() -> getExistingInstance()');
      getExistingInstance({
        testInstanceId,
        userId: getUserId(),
        isResuming: true,
        productType: 'Exam',
        examType,
        examPassword: '', // password no longer needed for writing
      });
    }
  }
  // #endregion functions

  // #region JSX
  if (!isNullOrUndefined(exam)) {
    return (
      <div className="certifying-statement-page-container">
        <div className="certifying-statement-page-header">
          <div className="certifying-statement-page-header-div">
            <div
              className="certifying-statement-page-header-text"
              id="pageHeader"
            >
              <FormattedMessage {...messages.header} />
            </div>
          </div>
        </div>
        <div className="certifying-statement-page-top-decoration" />
        <div className="certifying-statement-page">
          <div id="heading1" className="heading-color">
            LSAT Writing
          </div>
          <hr
            className="certifying-statement-page-horizontal-rule"
            role="none"
          />
          <h1 id="certifying-statement-page-heading1">
            <FormattedMessage
              {...messages.heading1}
              values={{ full_name: getUserDetails().fullName }}
            />
          </h1>
          <CertifyingContent
            certContent={exam.certifyingStatements}
            checkboxDisabled={isRetrievingExam}
            onCheckboxChanged={onCheckChanged}
            checkboxChecked={isCheckboxChecked}
          />
          {isRetrievingExam && (
            <div className="text-center">
              <div className="spinner-border" role="status" />
            </div>
          )}
          <button
            disabled={
              !isCheckboxChecked ||
              state !== pageStates.STATE_READY_TO_START ||
              isRetrievingExam
            }
            className={
              !isCheckboxChecked || state !== pageStates.STATE_READY_TO_START
                ? 'certifying-statement-page-start-button-disabled'
                : 'certifying-statement-page-start-button-enabled'
            }
            type="button"
            onClick={() => startExam()}
          >
            <span className="certifying-statement-page-start-button-text">
              <FormattedMessage {...messages.buttonText} />
            </span>
            <img
              className="certifying-statement-page-start-button-image"
              src={arrowInCircle}
              alt=" "
            />
          </button>
        </div>
        <RedirectModal
          isOpen={state === pageStates.STATE_RETURN_TO_ONLINE_SERVICES}
          text={`${message} ${
            messages.returnToOnlineServicesText.defaultMessage
          }`}
          heading={`${message} ${
            messages.returnToOnlineServicesHeading.defaultMessage
          }`}
        />
        <NotificationsModal
          isOpen={state === pageStates.STATE_REQUEST_NOTIFICATIONS_BE_ENABLED}
        />
        <RoomScanModal
          isOpen={state === pageStates.STATE_REQUEST_ROOM_SCAN}
          onRoomScanComplete={onRoomScanComplete}
        />
        <RedirectModal
          isOpen={state === pageStates.STATE_PRIVATE_MODE}
          text={`${messages.privateModeText.defaultMessage}`}
          heading={`${messages.privateModeHeading.defaultMessage}`}
        />
      </div>
    );
  }

  // if we made it here then we have a null exam which means we can't
  // display any of the page as it depends on properties in the exam
  // so just show the return to os modal
  if (state === pageStates.STATE_RETURN_TO_ONLINE_SERVICES) {
    return (
      <div className="certifying-statement-page-container">
        <div className="certifying-statement-page-header">
          <div className="certifying-statement-page-header-div">
            <div
              className="certifying-statement-page-header-text"
              id="pageHeader"
            >
              <FormattedMessage {...messages.header} />
            </div>
          </div>
        </div>
        <RedirectModal
          isOpen={state === pageStates.STATE_RETURN_TO_ONLINE_SERVICES}
          text={`${message} ${
            messages.returnToOnlineServicesText.defaultMessage
          }`}
          heading={`${message} ${
            messages.returnToOnlineServicesHeading.defaultMessage
          }`}
        />
      </div>
    );
  }

  if (state === pageStates.STATE_PRIVATE_MODE) {
    return (
      <div className="certifying-statement-page-container">
        <div className="certifying-statement-page-header">
          <div className="certifying-statement-page-header-div">
            <div
              className="certifying-statement-page-header-text"
              id="pageHeader"
            >
              <FormattedMessage {...messages.header} />
            </div>
          </div>
        </div>
        <RedirectModal
          isOpen={state === pageStates.STATE_PRIVATE_MODE}
          text={`${messages.privateModeText.defaultMessage}`}
          heading={`${messages.privateModeHeading.defaultMessage}`}
        />
      </div>
    );
  }

  // exam is null and stuff is still initializing so put the hammer down
  return (
    <Container fluid style={{ width: '100vw' }}>
      <Row>
        <AriadneLoading text="exam" />
      </Row>
    </Container>
  );

  // #endregion JSX
}

ExamStartPage.propTypes = {
  instanceError: PropTypes.string,
  initSagaLibrary: PropTypes.bool.isRequired,
  modules: PropTypes.array,
  getExamList: PropTypes.func.isRequired,
  retrievedExamList: PropTypes.bool.isRequired,
  getNewInstance: PropTypes.func.isRequired,
  sectionResume: PropTypes.func.isRequired,
  getExistingInstance: PropTypes.func.isRequired,
  examListError: PropTypes.object,
  examType: PropTypes.string,
  intl: intlShape.isRequired,
  subscribeUser: PropTypes.bool,
  onFetchKeyAction: PropTypes.func.isRequired,
  proctorDisconnect: PropTypes.func.isRequired,
  clearInstanceError: PropTypes.func.isRequired,
  clearExamListError: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  testSelectPage: makeSelectTestSelectPage(),
  modules: flexExamsSelector(),
  initSagaLibrary: initSagaLibrarySelector(),
  retrievedExamList: retrievedFlexExamsSelector(),
  error: errorSelector(),
  instanceError: getInstanceErrorSelector(),
  examListError: flexExamErrorSelector(),
  subscribeUser: subscribeUserSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getExamList: (userId, examType) =>
      dispatch(getFlexExamListAction(userId, examType)),
    getNewInstance: userModule => dispatch(getFlexInstanceAction(userModule)),
    getExistingInstance: userDetails =>
      dispatch(getInstanceAction(userDetails)),
    sectionResume: (isSectionResumed, testInstanceId) =>
      dispatch(resumeSectionAction(isSectionResumed, testInstanceId)),
    onFetchKeyAction: () => dispatch(fetchKeyAction()),
    proctorDisconnect: () => dispatch(onProctorDisconnectAction()),
    clearInstanceError: () => dispatch(clearInstanceErrorAction()),
    clearExamListError: () => dispatch(clearExamListErrorAction()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(ExamStartPage));
