/*
 * ApplicationStatusTable Messages
 *
 * This contains all the text for the ApplicationStatusTable component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ApplicationStatusTable';

export default defineMessages({
  applicationDetails: {
    id: `${scope}.applicationDetails`,
    defaultMessage: 'application details',
  },
  viewDetails: {
    id: `${scope}.viewDetails`,
    defaultMessage: `View details`,
  },
  opensInNewWindow: {
    id: `${scope}.opensInNewWindow`,
    defaultMessage: 'Opens in a new window.',
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No data',
  },
  subscribeToUnlock: {
    id: `${scope}.subscribeToUnlock`,
    defaultMessage: 'Subscribe to unlock this info.',
  },
});
