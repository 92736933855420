/*
 *
 * DirectionsPage selector
 *
 */
import { createSelector } from 'reselect';
import { isNullOrUndefined } from 'util';
import * as moduleHelper from 'helpers/moduleHelper';
import { initialState } from './reducer';

/**
 * Direct selector to the directionsPage state domain
 */
const selectDirectionsPageDomain = state =>
  state.directionsPage || initialState;

const instancesSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.instances,
  );

/**
 * selected module selector
 */
const selectedInstanceSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => {
      if (!isNullOrUndefined(substate.selectedInstanceId)) {
        return moduleHelper.getInstance(
          substate.instances,
          substate.selectedInstanceId,
        );
      }
      return null;
    },
  );

/**
 * selected instance id selector
 */
const selectedInstanceIdSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.selectedInstanceId,
  );

/**
 * setting selector
 */
const settingSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.setting,
  );

/**
 * section selector
 */
const sectionSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => {
      if (!isNullOrUndefined(substate.selectedSectionId)) {
        const { section } = moduleHelper.getInstanceSectionByFormId(
          substate.instances,
          substate.selectedInstanceId,
          substate.selectedSectionId,
        );

        return section;
      }
      return null;
    },
  );

const getNewInstanceErrorSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.getInstanceError,
  );

/**
 * Show answer selector
 */
const showAnswerSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.showAnswer,
  );

/**
 * current item selector
 */
const currentItemSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.currentItem,
  );

/**
 * current item selector
 */
const currentItemShowAnswerSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => {
      if (!isNullOrUndefined(substate.currentItem)) {
        return substate.currentItem.showAnswerClicked;
      }
      return false;
    },
  );

/**
 * initialize saga selector
 */
const initSagaSectionSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.initSagaSection,
  );

/**
 * error selector
 */
const getInstanceErrorSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.error,
  );

/**
 * highlightStarted
 */
const highlightStartedSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.highlightStarted,
  );

/**
 * Section Paused selector
 */
const pauseSectionSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.isSectionPaused,
  );

/**
 * Section Resumed selector
 */
const resumeSectionSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.isSectionResumed,
  );

/**
 * Section Resumed selector
 */
const writeOnlySelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.isWritingOnlyMode,
  );

const hasDismissedOneHourWarningSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => substate.hasDismissedOneHourWarning,
  );

const showHintsSelector = () =>
  createSelector(
    selectDirectionsPageDomain,
    substate => {
      const item = substate.showHints.find(
        hint => hint.itemNumber === substate.currentItem?.itemNumber,
      );
      return item || {};
    },
  );
export {
  selectDirectionsPageDomain,
  settingSelector,
  sectionSelector,
  currentItemSelector,
  initSagaSectionSelector,
  getInstanceErrorSelector,
  highlightStartedSelector,
  instancesSelector,
  selectedInstanceSelector,
  showAnswerSelector,
  pauseSectionSelector,
  currentItemShowAnswerSelector,
  resumeSectionSelector,
  writeOnlySelector,
  getNewInstanceErrorSelector,
  hasDismissedOneHourWarningSelector,
  selectedInstanceIdSelector,
  showHintsSelector,
};
