/*
 * DuplicatePage Messages
 *
 * This contains all the text for the DuplicatePage container.
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DuplicatePage';

export default defineMessages({
  message: {
    id: `${scope}.message`,
    defaultMessage:
      'Multiple instances detected.  Please close this tab and continue on the previous tab.',
  },
});
