import React from 'react';

const RocketSVG = props => (
  <svg
    width={220}
    height={280}
    viewBox="0 0 280 280"
    {...props}
    aria-hidden="true"
  >
    <defs>
      <style>
        {
          '.f{fill:#ebebeb}.g{fill:#ff7d70}.j{fill:#59cff0}.l{fill:#a7a9ac}.m{fill:#97999c}.n{fill:#51bddb}'
        }
      </style>
      <clipPath id="a">
        <path
          d="M5 0h275v320H5a5 5 0 01-5-5V5a5 5 0 015-5z"
          transform="translate(331 631)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g transform="translate(-331 -631)" clipPath="url(#a)">
      <path
        d="M161.308 289.609a22.5 22.5 0 00-13.489-42.6 15.516 15.516 0 00-5.1-13.611 15.1 15.1 0 007.6-5.493 15.258 15.258 0 00-12.329-24.292l80.108-109.1a.446.446 0 00-.092-.641L194.907 76.9a.474.474 0 00-.641.092l-82.549 112.429a14.614 14.614 0 00-24.048-.641 14.781 14.781 0 00-2.441 5.31 17.8 17.8 0 00-11.6 1.953 12.665 12.665 0 00-5.218-10.5 12.8 12.8 0 00-16.418 1.1 30.3 30.3 0 00-48.034 1.1 31.382 31.382 0 00-3.937 7.233.48.48 0 00.153.519l6.378 4.669a27.44 27.44 0 0137.414-4.7 26.636 26.636 0 015.1 4.883 11.555 11.555 0 0114.831-.977 11.435 11.435 0 014.7 9.491 15.988 15.988 0 0110.467-1.77 13.558 13.558 0 012.2-4.822 13.25 13.25 0 0118.493-2.838 12.624 12.624 0 012.991 3.113c.092.122.153.214.244.336l.793-1.1a15.175 15.175 0 0123.62 13.031l-.641.854a3.679 3.679 0 01.58.031 13.394 13.394 0 017.6 2.655 13.776 13.776 0 01-3.906 24.231 13.991 13.991 0 014.608 12.3 20.33 20.33 0 0112.207 38.482 17.8 17.8 0 01.424 10.836l7.965 5.829a.47.47 0 00.61-.061 19.171 19.171 0 001.892-2.167 19.368 19.368 0 002.564-18.192z"
        transform="translate(326 646)"
        fill="#e6e7e8"
      />
      <path
        d="M147.849 293.424a20.327 20.327 0 00-12.207-38.482 13.991 13.991 0 00-4.608-12.3 13.776 13.776 0 003.906-24.231 14.024 14.024 0 00-7.6-2.655c-.183 0-.4-.031-.58-.031l.641-.854a15.175 15.175 0 00-23.62-13.031l-.793 1.1a2.329 2.329 0 00-.244-.336 13.784 13.784 0 00-2.991-3.113 13.246 13.246 0 00-18.493 2.838 12.616 12.616 0 00-2.2 4.822 15.988 15.988 0 00-10.467 1.77 11.435 11.435 0 00-4.7-9.491 11.59 11.59 0 00-14.831.977 28.59 28.59 0 00-5.1-4.883c-11.78-8.667-31.8-6.1-40.954 5.1v107.024h149.906c.978-3.513-3.783-10.684-5.065-14.224z"
        transform="translate(326 646)"
        fill="#eff0f0"
      />
      <path
        d="M263.387 35.033l-27.711-20.355-1.1-.793-.214.153c-8.24 5.493-16.266 13.4-23.254 22.919a113.359 113.359 0 00-7.355 11.413l-7.08 16.144a64.844 64.844 0 00-1.77 6.836l-.031.244 1.892 1.373 8.728 6.409c3.235-4.089 8.911-10.467 15.869-14.923a68.472 68.472 0 01-9.521 19.592l7.172 5.249c34.396-20.966 42.789-47.73 44.375-54.261z"
        transform="translate(326 646)"
        fill="#bedef5"
      />
      <path
        className="f"
        d="M266.165 37.383l.061-.244-1.1-.793-1.74-1.282c-1.587 6.531-9.979 33.294-44.372 54.26l2.2 1.617 1.892 1.373.214-.122a60.745 60.745 0 005.981-3.723v.061l13.275-11.658a107.226 107.226 0 008.7-10.437c6.955-9.521 12.112-19.561 14.889-29.052z"
        transform="translate(326 646)"
      />
      <path
        className="f"
        d="M74.415 3.236a16.755 16.755 0 103.57 23.407 16.763 16.763 0 00-3.57-23.407z"
        transform="translate(326 646) translate(170.297 30.027)"
      />
      <path
        className="g"
        d="M73.684 4.244a15.479 15.479 0 103.324 21.639 15.464 15.464 0 00-3.324-21.639z"
        transform="translate(326 646) translate(170.297 30.027)"
      />
      <path
        d="M75.63 13.552a11.459 11.459 0 01-2.228 7.141A11.58 11.58 0 0152.925 17a11.573 11.573 0 0020.9 6.561 11.426 11.426 0 001.805-10.009z"
        transform="translate(326 646) translate(170.297 30.027)"
        fill="#a6d9eb"
      />
      <path
        d="M73.407 20.69a8.536 8.536 0 00-2.045-13.305 11.625 11.625 0 00-16.2 2.472 8.536 8.536 0 002.045 13.306 11.6 11.6 0 0016.2-2.473z"
        transform="translate(326 646) translate(170.297 30.027)"
        fill="#b5ecff"
      />
      <path
        className="j"
        d="M18.964 19.564a6.036 6.036 0 00-3.448 1.739L.471 38.393a1.482 1.482 0 00-.458 1.221 1.408 1.408 0 001.312 1.16l8.118 1.4a6.237 6.237 0 003.784-.733l9.7-5.92a8.763 8.763 0 013.387-1.038h.061l7.08-16.144z"
        transform="translate(326 646) translate(170.297 30.027)"
      />
      <path
        className="g"
        d="M32.592 7.751l.854-7.6V0L2.96 13.4l24.566 18.036z"
        transform="translate(326 646) translate(235.678)"
      />
      <path
        d="M29.449 36.315l3.143-28.564-5.066 23.681L2.96 13.397 0 14.709z"
        transform="translate(326 646) translate(235.678)"
        fill="#eb7168"
      />
      <path
        className="l"
        d="M193.778 77.025l8.728 6.409s.671-1.007 1.892-2.625l-8.331-6.866z"
        transform="translate(326 646)"
      />
      <path
        className="m"
        d="M196.767 72.967l-.7.977 8.331 6.866c.336-.427.7-.916 1.1-1.434z"
        transform="translate(326 646)"
      />
      <path
        className="l"
        d="M210.531 85.876c-1.038 1.4-1.678 2.2-1.678 2.2l9.369 6.9 1.251-1.709z"
        transform="translate(326 646)"
      />
      <path
        className="m"
        d="M221.213 90.911l-9.369-6.866c-.488.7-.915 1.312-1.312 1.831l8.942 7.385z"
        transform="translate(326 646)"
      />
      <g>
        <path
          className="n"
          d="M16.118 0L2.843 11.658a8.94 8.94 0 01.122 3.021l13.794-11.81z"
          transform="translate(326 646) translate(226.457 76.843)"
        />
        <path
          className="j"
          d="M6.872 37.506l11.81-19.47a5.9 5.9 0 00.61-3.815L16.76 2.869 2.966 14.679c-.031.183-.061.366-.091.519L.128 26.215a6.4 6.4 0 00.427 3.845l3.754 7.355a1.417 1.417 0 001.5.916 1.65 1.65 0 001.063-.825z"
          transform="translate(326 646) translate(226.457 76.843)"
        />
      </g>
      <g>
        <path
          className="j"
          d="M19.806.458a70.673 70.673 0 00-15.2 14.465c-1.923 2.411-2.991 4.059-2.991 4.059A24.169 24.169 0 00.028 27.71C10.193 18.738 17.395 5.341 19.806.458z"
          transform="translate(326 646) translate(200.888 64.453)"
        />
        <path
          className="n"
          d="M10.956 19.592c2.96-4.3 7.355-11.6 9.521-19.592-.244.153-.458.305-.7.458C17.366 5.341 10.195 18.738.002 27.74v.763l.488-.183a25.227 25.227 0 007.416-4.669 38.07 38.07 0 003.05-4.059z"
          transform="translate(326 646) translate(200.888 64.453)"
        />
      </g>
    </g>
  </svg>
);

export default RocketSVG;
