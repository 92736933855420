/* eslint-disable indent */
import PropTypes from 'prop-types';
import UserProgramsSettingsModel from './userProgramsSettingsModel';

/**
 * User programs model
 * @class
 */
class UserProgramsModel {
  /**
   *
   * @param {string} programId The program id
   * @param {string} programName The program name
   * @param {array<UserProgramsSettingsModel>} userSettings An array of user program settings models
   */
  constructor(programId, programName, userSettings) {
    this.programId = programId;
    this.programName = programName;
    this.userSettings = userSettings
      ? userSettings.map(
          setting =>
            new UserProgramsSettingsModel(
              setting.settingName,
              setting.settingValue,
            ),
        )
      : [];
  }
}

UserProgramsModel.propTypes = {
  programId: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
  userSettings: PropTypes.arrayOf(UserProgramsSettingsModel),
};

export default UserProgramsModel;
