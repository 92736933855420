/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable global-require */
/**
 *
 * CallToAction
 *
 */

import React from 'react';
import { Media } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, intlShape } from 'react-intl';
import './index.css';
import ExternalLinkIcon from '../../images/external_link_outward_rebrand.svg';

function CallToAction({
  onClose,
  onAccept,
  headerMessage,
  headerValues,
  bodyMessage,
  bodyValues,
  buttonMessage,
  opensInNewTab,
  link,
  intl,
  descriptionIcon,
  hideCloseIcon,
  isWhiteBg,
  isSuccessBtn,
}) {
  const href = link || '';

  return (
    <div
      className={`upgradeNotification mainNotification ${
        isWhiteBg ? 'white-bg' : ''
      }`}
    >
      <div className="notificationHeader">
        <div className="upgradeFlex">
          <h2 className="bannerHeading">
            <FormattedHTMLMessage {...headerMessage} values={headerValues} />
          </h2>
        </div>
        {!hideCloseIcon && (
          <button
            type="button"
            className="closedUpdateNotification"
            aria-label="close promotion banner"
            onClick={() => {
              onClose();
            }}
            onKeyPress={() => {
              onClose();
            }}
          >
            <Media src={require('images/btn-close.png')} alt="close" />
          </button>
        )}
        <div className="notificationText">
          {descriptionIcon && (
            <img src={descriptionIcon} alt="" className="description-icon" />
          )}
          <FormattedHTMLMessage {...bodyMessage} values={bodyValues} />
        </div>
      </div>
      <div className="prepPlusSection">
        {link && opensInNewTab && (
          <a
            href={href}
            title="will open in a new browser window"
            target="_blank"
            className={`Upgrade-to-Prep-Plus ${
              isSuccessBtn ? 'success-btn' : ''
            }`}
          >
            {intl.formatMessage({
              ...buttonMessage,
            })}
            <img
              src={ExternalLinkIcon}
              alt="will open in a new browser window"
              className="external-link"
            />
          </a>
        )}
        {link && !opensInNewTab && (
          <a
            href={href}
            className={`Upgrade-to-Prep-Plus ${
              isSuccessBtn ? 'success-btn' : ''
            }`}
          >
            {intl.formatMessage({
              ...buttonMessage,
            })}
          </a>
        )}
        {!link && (
          <a
            href="#"
            className={`Upgrade-to-Prep-Plus ${
              isSuccessBtn ? 'success-btn' : ''
            }`}
            onClick={e => {
              onAccept(e);
            }}
            onKeyPress={e => {
              onAccept(e);
            }}
          >
            {intl.formatMessage({
              ...buttonMessage,
            })}
          </a>
        )}
      </div>
    </div>
  );
}

CallToAction.propTypes = {
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  headerMessage: PropTypes.object,
  headerValues: PropTypes.object,
  bodyMessage: PropTypes.object,
  bodyValues: PropTypes.object,
  buttonMessage: PropTypes.object,
  opensInNewTab: PropTypes.bool,
  link: PropTypes.string,
  intl: intlShape.isRequired,
  bannerIcon: PropTypes.string,
  descriptionIcon: PropTypes.string,
  hideCloseIcon: PropTypes.bool,
  isWhiteBg: PropTypes.bool,
  isSuccessBtn: PropTypes.bool,
};

export default CallToAction;
