/*
 *
 * MasterPage selector
 *
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the directionsPage state domain
 */
const selectMasterPageDomain = state => state.masterPage || initialState;

/**
 * setting selector
 */
const breadCrumbSectionSelector = () =>
  createSelector(
    selectMasterPageDomain,
    substate => substate.settings,
  );

/**
 * window size selector
 */
const windowSizeSelector = () =>
  createSelector(
    selectMasterPageDomain,
    substate => substate.windowSize,
  );

/**
 * displaySideNavBar selector
 */
const showSideNavSelector = () =>
  createSelector(
    selectMasterPageDomain,
    substate => substate.settings.displaySideNavBar,
  );

export { breadCrumbSectionSelector, showSideNavSelector, windowSizeSelector };
