import { createSelector } from 'reselect';

const selectRouter = state => state.router;

const routerSelector = () =>
  createSelector(
    selectRouter,
    routerState =>
      routerState !== undefined
        ? routerState.location
        : { pathname: 'null', search: {} },
  );

export { routerSelector };
