import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the historyPage state domain
 */

const selectHistoryPageDomain = state => state.historyPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by HistoryPage
 */

const makeSelectHistoryPage = () =>
  createSelector(
    selectHistoryPageDomain,
    substate => substate,
  );

/**
 * error selector
 */
const errorSelector = () =>
  createSelector(
    selectHistoryPageDomain,
    substate => substate.error,
  );

/**
 * selected user history selector
 */
const userHistorySelector = () =>
  createSelector(
    selectHistoryPageDomain,
    substate => ({
      initialized: substate.initialized,
      isLoading: substate.isLoading,
      isMoreToLoad: substate.isMoreToLoad,
      numberOfPagesRetrieved: substate.numberOfPagesRetrieved,
      numberOfNewItemsLoaded: substate.numberOfNewItemsLoaded,
      userHistory: substate.userHistory,
      firstItemIndex: substate.firstItemIndex,
    }),
  );

/**
 * selected module from user history
 */
const historyModuleSelector = () =>
  createSelector(
    selectHistoryPageDomain,
    substate => substate.module,
  );

export default makeSelectHistoryPage;
export { errorSelector, userHistorySelector, historyModuleSelector };
