/*
 *
 * DirectionsPage actions
 *
 */

import * as constants from './constants';

/**
 * Initialize saga section
 *
 */
export function initializeSagaSectionAction() {
  return {
    type: constants.INIT_SECTION_SAGA,
  };
}

/**
 * Get the test section
 *
 * @param  {string} sectionId The section id
 *
 */
export function setSectionAction(section, testInstanceId) {
  return {
    type: constants.SET_SECTION,
    section,
    testInstanceId,
  };
}

/**
 * Change the font size
 *
 * @param  {string} fontSize The font size
 *
 */
export function changeFontSizeAction(fontSizeType) {
  return {
    type: constants.CHANGE_FONTSIZE,
    fontSizeType,
  };
}

/**
 * Change the line height
 *
 * @param  {string} lineHeight The line height
 *
 */
export function changeLineHeightAction(lineHeightType) {
  return {
    type: constants.CHANGE_LINEHEIGHT,
    lineHeightType,
  };
}

/**
 * Change the font size
 *
 * @param  {number} index The question selection
 *
 */
export function changeSelectionAction(index) {
  return {
    type: constants.CHANGE_SELECTION,
    index,
  };
}

/**
 * Change the option state
 *
 * @param  {number} optionIndex The option index
 * @param  {string} optionType The option type (A or E)
 */
export function changeOptionStateAction(optionIndex, optionType) {
  return {
    type: constants.CHANGE_OPTION_STATE,
    optionIndex,
    optionType,
  };
}

export function setNotesIsExpandedAction(isExpanded) {
  return {
    type: constants.SET_NOTES_IS_EXPANDED,
    isExpanded,
  };
}

export function onProctorDisconnectAction() {
  return {
    type: constants.ON_PROCTOR_DISCONNECT,
  };
}

export function updateNotesTextAction(text) {
  return {
    type: constants.UPDATE_NOTES_TEXT,
    text,
  };
}

export function changeItemWritingTextAction(text) {
  return {
    type: constants.CHANGE_WRITING_TEXT,
    text,
  };
}

/**
 * Change the option mode
 *
 * @param  {number} optionIndex The option index
 *
 */
export function changeOptionModeAction(optionIndex) {
  return {
    type: constants.CHANGE_OPTION_MODE,
    optionIndex,
  };
}

/**
 * Mark item for review
 *
 * @param  {item} item The item to be marked
 *
 */
export function markForReviewAction(item) {
  return {
    type: constants.MARK_FOR_REVIEW,
    item,
  };
}

/**
 * Change the highlight
 *
 * @param  {number} highlightType The highlight type
 *
 */
export function changeHighlightAction(highlightType) {
  return {
    type: constants.CHANGE_HIGHLIGHT,
    highlightType,
  };
}

/**
 * Update Time Remaining
 *
 * @param {number} timeRemaining The current time remaining
 *
 */
export function changeTimeRemainingAction(timeRemaining, elapsed = false) {
  if (elapsed) {
    return {
      type: constants.CHANGE_ELAPSED_TIME,
      elapsedTime: timeRemaining,
    };
  }
  return {
    type: constants.CHANGE_TIME_REMAINING,
    timeRemaining,
  };
}

export function changeAnalysisTimeRemainingAction(analysisTimeRemaining) {
  return {
    type: constants.CHANGE_ANALYSIS_TIME_REMAINING,
    analysisTimeRemaining,
  };
}

export function showAnswer(showAnswerToggle) {
  return {
    type: constants.SHOW_ANSWER,
    showAnswerToggle,
  };
}

export function setTimeRemainingVisibility(isVisible) {
  return {
    type: constants.SET_TIME_VISIBILITY,
    isVisible,
  };
}

/**
 * Change the highlight state
 *
 * @param  {bool} highlightStarted The highlight started flag
 *
 */
export function changeHighlightStateAction(highlightStarted) {
  return {
    type: constants.CHANGE_HIGHLIGHT_STATE,
    highlightStarted,
  };
}

/**
 * Set section complete
 *
 */
export function setSectionComplete(allSectionsComplete) {
  return {
    type: constants.SET_SECTION_COMPLETE,
    allSectionsComplete,
  };
}

/**
 * Set section pause
 *
 */
export function onPauseSection(
  isSectionPaused,
  pagePaused,
  isNeverShowPausePopup,
) {
  return {
    type: constants.SET_PAUSE_SECTION,
    isSectionPaused,
    pagePaused,
    isNeverShowPausePopup,
  };
}
/**
 * Set the selected module
 *
 * @param  {ModuleModel} module The module object
 *
 */
export function selectInstanceAction(instance) {
  return {
    type: constants.SELECT_INSTANCE,
    instance,
  };
}

/**
 * Get a module action
 *
 * @param  {string} moduleId The module id
 *
 */
export function newInstanceAction(userModule) {
  return {
    type: constants.GET_NEW_INSTANCE,
    userModule,
  };
}

/**
 * Get a module action
 *
 * @param  {string} moduleId The module id
 *
 */
export function getFlexInstanceAction(userModule) {
  return {
    type: constants.GET_FLEX_INSTANCE,
    userModule,
  };
}

/**
 * Get a module action
 *
 * @param  {string} moduleId The module id
 *
 */
export function getInstanceAction(userInstance) {
  return {
    type: constants.GET_EXISTING_INSTANCE,
    userInstance,
  };
}

/**
 * Get a module action success
 *
 * @param  {ModuleModel} module The module data
 *
 */
export function getNewInstanceSuccessAction(data) {
  return {
    type: constants.GET_NEW_INSTANCE_SUCCESS,
    module: data.module,
    testInstanceId: data.testInstanceId,
  };
}

/**
 * Get a module action error
 *
 * @param  {object} err The error object
 *
 */
export function getNewInstanceFailureAction(err) {
  return {
    type: constants.GET_NEW_INSTANCE_FAILURE,
    err,
  };
}

/**
 * Get a module action success
 *
 * @param  {ModuleModel} module The module data
 *
 */
export function getExistingInstanceSuccessAction(data) {
  return {
    type: constants.GET_EXISTING_INSTANCE_SUCCESS,
    examType: data.examType,
    module: data.module,
    state: data.state,
    testInstanceId: data.testInstanceId,
    isExistingInsance: true,
    summary: data.summary,
    launchDetails: data.launchDetails,
    testInstanceRemoteStatus: data.testInstanceRemoteStatus,
  };
}

/**
 * Get a module action error
 *
 * @param  {object} err The error object
 *
 */
export function getExistingInstanceFailureAction(err) {
  return {
    type: constants.GET_EXISTING_INSTANCE_FAILURE,
    err,
  };
}

/**
 * Section load
 *
 * @param {object} section The section object
 */
export function sectionLoadAction(section) {
  return {
    type: constants.LOAD_SECTION,
    section,
  };
}

/**
 * Section unload
 *
 */
export function sectionUnLoadAction() {
  return {
    type: constants.UNLOAD_SECTION,
  };
}

/**
 * isPassageView mode
 *
 * @param {bool} isPassageView The Passage view mode
 */
export function setPassageViewAction(isPassageView) {
  return {
    type: constants.SET_PASSAGE_VIEW,
    isPassageView,
  };
}

/**
 * isSectionLoaded
 *
 * @param {bool} isSectionLoaded The section load completed
 *
 */
export function setSectionLoadComplete(isSectionLoaded) {
  return {
    type: constants.SET_SECTION_LOAD_COMPLETE,
    isSectionLoaded,
  };
}

/**
 * Undo section pause
 *
 * @param {bool} isSectionResumed The boolean object
 *
 */
export function resumeSectionAction(isSectionResumed, testInstanceId) {
  return {
    type: constants.RESUME_SECTION_ACTION,
    isSectionResumed,
    testInstanceId,
  };
}

/**
 * Undo section pause
 *
 * @param {object} module The module object
 *
 */
export function undoSectionPauseAction(module, testInstanceId) {
  return {
    type: constants.UNDO_SECTION_PAUSE_ACTION,
    module,
    testInstanceId,
  };
}

/**
 * Pause flex exam
 *
 * @param {bool} isFlexExamPaused The flex exam mode pause
 *
 */
export function pauseFlexExamAction(isFlexExamPaused, pausedOnPage) {
  return {
    type: constants.SET_FLEX_EXAM_PAUSE_ACTION,
    isFlexExamPaused,
    pausedOnPage,
  };
}
/**
 * Update section break Time Remaining
 *
 * @param {number} timeRemaining The current section break time remaining
 *
 */
export function breakTimeRemainingAction(timeRemaining, sectionId) {
  return {
    type: constants.CHANGE_BREAK_TIME_REMAINING,
    timeRemaining,
    sectionId,
  };
}

/**
 * Update section break Time Remaining
 *
 * @param {number} timeRemaining The current section break time remaining
 *
 */
export function intermissionTimeRemainingAction(timeRemaining, sectionId) {
  return {
    type: constants.CHANGE_INTERMISSION_TIME_REMAINING,
    timeRemaining,
    sectionId,
  };
}

/**
 * Ready to checkin status
 *
 * @param {boolean} isReadyToCheckin The current status of ready to checkin
 *
 */
export function readyToCheckinAction(isReadyToCheckin, sectionId) {
  return {
    type: constants.CHANGE_READY_TO_CHECKIN_STATUS,
    isReadyToCheckin,
    sectionId,
  };
}

/**
 *
 * @param {bool} isWritingOnlyMode The is writing section only
 */
export function setIsWritingSection(isWritingOnlyMode) {
  return {
    type: constants.SET_WRITING_ONLY_MODE,
    isWritingOnlyMode,
  };
}

export function clearInstanceErrorAction() {
  return {
    type: constants.CLEAR_INSTANCE_ERROR,
  };
}

export function setOneHourWarningAction() {
  return { type: constants.SET_ONE_HOUR_WARNING_DISMISSED };
}

/**
 * Update total hints viewed for item
 *
 * @param  {item} item The item to be viewed
 *
 */
export function setTotalHintsViewed(item, totalHintsViewed) {
  return {
    type: constants.ITEM_VIEW_HINT,
    item,
    totalHintsViewed,
  };
}

/**
 * Update hints viewed
 *
 * @param {object}
 *
 */
export function updateHintsViewed(itemNumber, showHints) {
  return {
    type: constants.SET_SHOW_HINTS,
    itemNumber,
    showHints,
  };
}
