import React from 'react';

const ConfettiSVG = props => (
  <svg
    viewBox="0 0 538.617 333.827"
    preserveAspectRatio="xMinYMin meet"
    {...props}
  >
    <defs>
      <style>
        {
          '.a{fill:#bf9769}.a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.m,.p,.r,.u{fill-rule:evenodd}.b{fill:#fcae41}.c{fill:#d6275b}.d{fill:#21944f}.e{fill:#39aade}.f{fill:#49de58}.g{fill:#eb2c2f}.h{fill:#8f318a}.i{fill:#f57627}.j{fill:#ba1a4a}.k{fill:#106933}.m{fill:#1773b5}.p{fill:#bd1b1d}.r{fill:#61185d}.u{fill:#fc0}'
        }
      </style>
    </defs>
    <path
      className="a"
      d="M538.617 12A1045.973 1045.973 0 010 .115V0h11.234a1046.813 1046.813 0 00527.383 8.426z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="b"
      d="M10.926 0c17.851 5 35.822 9.387 53.9 13.5q-21.064 25.8-43.32 50.672C16.466 42.859 11.888 21.545 7.848 0z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="c"
      d="M74.406 15.466q28.739 6.117 57.786 10.734-19.275 27.181-39.781 53.366-9.753-31.86-18.005-64.1z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="d"
      d="M141.849 27.662q29.028 4.155 58.248 6.848-17.37 28.393-36.087 55.862-11.831-31.105-22.161-62.71z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="b"
      d="M209.791 35.318q29.2 2.251 58.478 2.962-15.466 29.432-32.279 58.132-13.792-30.181-26.199-61.094z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="e"
      d="M278.042 38.473q29.259.346 58.517-.885-13.446 30.413-28.354 60.171-15.755-29.316-30.163-59.286z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="f"
      d="M346.292 37.088q29.258-1.616 58.363-4.771-11.426 31.221-24.315 61.9-17.659-28.159-34.048-57.129z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="g"
      d="M414.389 31.201q29.143-3.578 58.017-8.656-9.349 31.971-20.237 63.4-19.506-26.95-37.78-54.747z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="h"
      d="M482.101 20.775a1165.602 1165.602 0 0056.516-12.273v4.232c-4.732 20.16-9.618 40.2-15.081 60.171q-21.352-25.565-41.435-52.13z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="i"
      d="M21.506 63.942c-5-21.083-9.5-42.089-13.542-63.4A3.012 3.012 0 017.848 0h3.078C19.313 2.308 27.7 4.463 36.164 6.617c-4.886 19.16-9.772 38.165-14.658 57.325z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="j"
      d="M74.406 15.466c5.771 1.154 23.045 4.694 28.816 5.771Q97.855 50.38 92.411 79.523c-.923-2.962-13.388-44.821-18.005-64.057z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="k"
      d="M141.849 27.662q14.543 2.078 29.085 3.809c-1.847 15.774-5.078 43.128-6.925 58.9-6.925-18.621-20.506-57.555-22.16-62.71z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      className="i"
      d="M236.03 96.451c-11.042-24.815-24.853-57.517-26.2-61.095 9.734.769 19.506 1.385 29.2 1.885-1 19.7-2 39.473-3 59.209z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      d="M308.205 97.72q-14.6-27.065-27.97-54.708 13.389 27.643 27.97 54.708z"
      transform="translate(0 .1) translate(0 -.062)"
      fill="#09acc4"
      fillRule="evenodd"
    />
    <path
      className="m"
      d="M278.042 38.473c9.81.192 22.16.038 29.278-.077.231 14.773.692 44.551.885 59.325-8.5-14.966-27.7-54.169-30.163-59.248z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      d="M380.341 94.22q-16.794-26.719-32.356-54.131 15.582 27.412 32.356 54.131z"
      transform="translate(0 .1) translate(0 -.062)"
      fill="#8cc33e"
      fillRule="evenodd"
    />
    <path
      className="d"
      d="M346.292 37.088q14.6-.808 29.2-1.962l4.847 59.094c-16.428-25.623-29.432-48.937-34.048-57.132z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      d="M452.169 85.794c-12.35-16.928-24.276-34.241-35.856-51.784 11.58 17.544 23.545 34.856 35.856 51.784z"
      transform="translate(0 .1) translate(0 -.062)"
      fill="#d61e25"
      fillRule="evenodd"
    />
    <path
      className="p"
      d="M414.389 31.201q14.543-1.789 29.047-3.924 4.386 29.316 8.733 58.632c-14-19.621-30.97-42.166-37.78-54.708z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <path
      d="M523.494 72.906q-20.141-24.18-39.2-49.168 19.044 24.988 39.2 49.168z"
      transform="translate(0 .1) translate(0 -.062)"
      fill="#922675"
      fillRule="evenodd"
    />
    <path
      className="r"
      d="M482.063 20.775c9.618-1.847 19.236-3.77 28.816-5.848q6.29 28.97 12.619 57.94c-7.81-8.733-34.087-42.435-41.435-52.092z"
      transform="translate(0 .1) translate(0 -.062)"
    />
    <g fillRule="evenodd">
      <path
        d="M2.655 22.045C1.501 32.433-.23 46.398 2.847 54.554c2.5 6.617 5.5 10.811 12.311 14.466 6.271 3.386 14.2 4.347 15.2 8.579 0 0-2.424 2.693-12.5 7.5C12.549 87.638 4.085 90.947 1.7 97.141a26.974 26.974 0 00-.962 5.386c0 2 .269 7.271 6.925 12.2 6.848 5.078 11.157 7.464 12.581 9 0 0-14.581 2.27-19.659 9.849a6.544 6.544 0 00-.577 2.347c0 1.077.077 3.386.077 3.386s2.5-5.732 13.5-8.695a59.811 59.811 0 016.964-1.462c1.539-.192 3.039-1.077 3-2.424-.038-1.039-.077-5.54-.077-5.54-2-5.117-13.85-10.965-16.466-13.081-2.655-2.116-5.886-4.924-5.271-7.233 1.462-5.232 8.541-7.156 17.582-11.5 8.31-3.963 10.849-6.425 10.849-7.656v-8.043c0-2.154-2.732-4.194-8.618-6.463-6.579-2.5-14.889-5-16.351-10.2-.616-5.194.539-18.967 1.962-30.24C8.425 16.582 9 5 9.079 0h-5a205.876 205.876 0 01-1.423 22.045zm-2.539 117.8v3.732c0 1.885 2.616 4.848 7.541 7.156a68.5 68.5 0 0012 3.847s-10.811 4.5-15.889 9.31a5.259 5.259 0 00-1.539 4 29.114 29.114 0 00.923 5.425c.385 1.577 2.924 3.193 6.425 3.5s4.578.308 4.578.308a23.37 23.37 0 00-8.233 5.809 3.063 3.063 0 00-.423 3.232c.193.693 1.154 2.962 1.616 4 .5 1 2.308 1.693 6.54 1.577 4.232-.077 12.657-2.77 15.774-6.656l-.846-5.04s-8.272 9-20.544 7.656c0 0 4-5.309 18.89-10.618l-1-4.963c-12.234 1-35.7 2.885-13.388-7.5 4.424-2.078 8.195-3.463 10.042-4.463 1.885-1.077 2.116-1.423 2.077-2.655a51.814 51.814 0 00-.308-5.732c-.231-1.154-5.04-2.154-8.233-2.847s-11.772-3.039-16-9.08z"
        transform="translate(0 .1) translate(500.798 -.061)"
        fill="#65358f"
      />
      <path
        d="M8.04 187.669a4.21 4.21 0 00-.423 3.039c.846.693 2.693 1.077 6.04 1 4.232-.077 12.657-2.77 15.774-6.656l-.846-5.04s-8.272 9-20.544 7.656zm15.389-66.519c-2-5.117-13.85-10.965-16.466-13.081-2.308-1.847-5.117-4.27-5.348-6.386a10.174 10.174 0 01-.269-3.232 20.583 20.583 0 00-.615 4c0 2 .269 7.271 6.925 12.2 6.848 5.078 11.157 7.464 12.581 9 0 .038 3.193-.115 3.193-2.5zm-1.924-53.939c-6.579-2.5-14.889-5-16.351-10.2a20.372 20.372 0 001.577 5 23.9 23.9 0 008.272 6.925c6.271 3.386 14.35 4.347 15.12 8.579v-3.879c0-2.116-2.732-4.155-8.618-6.425zm-13.85 83.524a68.5 68.5 0 0012 3.847c1.27-.654 4.54-1.385 4.655-3.039-.77-1.077-5.155-2-8.156-2.616-3.232-.693-11.811-3.039-16.043-9.041v3.73c0 1.847 2.616 4.809 7.541 7.117z"
        transform="translate(0 .1) translate(500.798 -.061)"
        fill="#461b6b"
      />
    </g>
    <g>
      <path
        className="b"
        d="M42.887 149.274v-4.424c0-.693-3.693-1.27-7.695-1.27a51.443 51.443 0 00-10 .846c-3.963-1.116-10.426-3.578-10.426-5.04v-1.154c0-.693 1.347-3.232 3.809-4.655s16.659-8.233 20.814-10.657c1.885-1.154 3.463-1.924 3.463-4.155V116c0-1.116-.154-2.154-2.27-3.232s-16.274-5.155-21.429-7.848c-18.39-9.657 6.733-21.891 17.505-24.007 1.731-.346 1.962-.231 1.962-1.5s-.038-4.578-.038-5.309c0-.846-1-.846-3.039-1.693-2-.808-16.389-8.849-21.929-16.082A51.985 51.985 0 013.573 36.015C2.38 31.36.957 13.47 3.842.005H.149c-.269 10-.577 23.661 3.232 37.742 4.348 16.082 9.541 28.316 29.739 38.7-2.962.808-17.774 4.463-21.429 16.235a15.16 15.16 0 00-.385 4.924V99.8c0 1.423.693 5.309 6.233 8.849 5.54 3.578 19.775 8 21.7 8.579a84.479 84.479 0 01-10 5.309c-6.694 3.078-14.466 6.656-15.62 12.234V141c0 1.154 1.039 3.924 5.04 5.694a12.064 12.064 0 00-3.578 2.924 2.338 2.338 0 00-.308 1.385v3.809c0 .731 2.27 3.039 10.311 3.424 8.156.385 12.388-.577 12.388-.577v-4.924s-12.388 2.77-20.006-.654c.038 0 9.272-5.5 25.43-2.808z"
        transform="translate(0 .1) translate(468.029 -.062)"
      />
      <path
        className="i"
        d="M39.998 112.456c-3.462-1.385-16.043-5.078-20.814-7.579s-7.31-4.732-7.926-10c-.077 1.039.077 1.962.077 2.693v2.193c0 1.423.693 5.309 6.233 8.849 5.54 3.578 19.775 8 21.7 8.579a18.414 18.414 0 002.847-2.231c.616-1.077-.616-1.885-2.116-2.5zm-22.622 39.627a8.3 8.3 0 00-2.578 2.385v.308c0 .731 2.27 3.039 10.311 3.424 8.156.385 12.388-.577 12.388-.577v-4.886s-12.465 2.77-20.121-.654zm1.27-5.386a22.684 22.684 0 016.463-2.27c-2.347-.693-11.542-3.27-10.272-6.617a5.693 5.693 0 00-.923 4.617 9.021 9.021 0 004.732 4.27z"
        transform="translate(0 .1) translate(468.029 -.062)"
      />
    </g>
    <g>
      <path
        className="h"
        d="M27.739 22.045c1.154 10.388 2.885 24.353-.192 32.509-2.5 6.617-5.5 10.811-12.311 14.466-6.271 3.386-14.158 4.347-15.2 8.579.5.462 2.424 2.693 12.5 7.5 5.309 2.539 13.773 5.848 16.159 12.042a26.976 26.976 0 01.962 5.386c0 2-.269 7.271-6.925 12.2-6.848 5.078-11.157 7.464-12.581 9 0 0 14.581 2.27 19.66 9.849a6.544 6.544 0 01.577 2.347c0 1.077-.038 3.386-.038 3.386s-2.5-5.732-13.5-8.695a59.81 59.81 0 00-6.964-1.462c-1.539-.192-3.039-1.077-3-2.424 0-1.039.077-5.54.077-5.54 1.962-5.117 13.85-10.965 16.466-13.081s5.886-4.924 5.232-7.233c-1.462-5.232-8.656-7.156-17.7-11.5C2.655 85.371 0 82.909 0 81.716v-8.041c0-2.154 2.847-4.194 8.733-6.463 6.579-2.5 14.966-5 16.428-10.2.654-5.194-.5-18.967-1.924-30.239C21.968 16.582 21.429 5 21.314 0h5c.231 7.31.616 14.735 1.424 22.045zm2.655 117.8v3.732c0 1.885-2.655 4.848-7.579 7.156a68.453 68.453 0 01-12.042 3.847s10.811 4.5 15.851 9.31a5.258 5.258 0 011.539 4 29.118 29.118 0 01-.923 5.425c-.385 1.577-2.924 3.193-6.425 3.5s-4.578.308-4.578.308a23.407 23.407 0 018.195 5.809 3.178 3.178 0 01.462 3.232 40.865 40.865 0 01-1.654 4c-.5 1-2.308 1.693-6.54 1.577-4.232-.077-12.658-2.77-15.774-6.656l.846-5.04s8.272 9 20.544 7.656c0 0-4-5.309-18.89-10.618l1-4.963s20.852 1.654 22.353-1.039a6.32 6.32 0 00.385-1.731c.038-.846-4.578-2.5-9.349-4.732-4.463-2.078-8.233-3.463-10.041-4.463-1.885-1.077-2.116-1.423-2.078-2.655A51.865 51.865 0 016 151.775c.231-1.154 5.078-2.154 8.31-2.847 3.193-.693 11.465-3.039 16.082-9.08z"
        transform="translate(0 .1) translate(23.084 -.062)"
      />
      <path
        className="r"
        d="M6.964 121.15c1.962-5.117 13.85-10.965 16.466-13.081 2.308-1.847 5.078-4.27 5.309-6.386a10.174 10.174 0 00.269-3.232 20.572 20.572 0 01.616 4c0 2-.269 7.271-6.925 12.2-6.848 5.078-11.157 7.464-12.581 9 .038.038-3.155-.115-3.155-2.5zm1.77-53.939c6.579-2.5 14.966-5 16.428-10.2a19.074 19.074 0 01-1.539 5 24.38 24.38 0 01-8.387 6.925C8.926 72.322 1.155 73.322.001 77.554v-3.886c0-2.154 2.847-4.193 8.733-6.463zm13.58 120.458a4.011 4.011 0 01.423 3.039c-.846.693-2.693 1.077-6.04 1-4.232-.077-12.658-2.77-15.774-6.656l.846-5.04s8.31 9 20.544 7.656zm.5-36.934a68.455 68.455 0 01-12.042 3.847s-4.5-1.5-4.655-3.039c.769-1.077 5.194-2 8.195-2.616 3.232-.693 11.465-3.039 16.082-9.041v3.73c0 1.847-2.655 4.809-7.579 7.117z"
        transform="translate(0 .1) translate(23.084 -.062)"
      />
    </g>
    <g>
      <path
        className="g"
        d="M6.729 58.478c8.849 23.507 23.738 44.436 24.507 51.746 0 0-.269 4.848-3.078 8.041S8.691 128.383 6.113 130.038c-2.539 1.654-4.078 3.193-3.963 5.271.154 2.039 5.271 7.04 9.887 10.618 4.694 3.655 16.928 10.657 17.967 20.544 0 0-.154 3.732-.385 6.271-.269 2.578-4.194 6.848-11.272 10.5-7.425 3.847-14.735 7.695-16.659 9.734a21.371 21.371 0 008.349 4.771c4.655 1.577 18.659 6.617 18.659 14.312v4.655c0 1.27-1.462 5.309-10.2 7.695s-17.159 4.54-17.159 4.54l.115-5.117c13.042-2.732 41.9-7.656 16.12-18.005-7.233-2.885-16.582-6.233-16.966-9.811v-6.54c0-.962 2.77-4.309 9.734-7.5 6.925-3.193 16.428-8.233 19.121-12.85v-2.308c0-1.424-4.386-5.348-8.233-8.31S5.493 147.466 1.645 141.465c-.923-1.924-.539-2.578-.539-4.04 0-1.5.385-4.5.577-6.233s.962-5.463 8.272-8.849c6.54-3.039 18.467-6.425 21.352-12.273 0 0 2.193-5.771.385-10.5s-14.235-27.046-17.467-33.2C4.799 48.442-.775 20.01 1.183.005H.106c-.5 17.7.5 42.166 6.617 58.478z"
        transform="translate(0 .1) translate(67.946 -.062)"
      />
      <path
        className="p"
        d="M9.966 182.01c6.925-3.193 16.813-8.233 19.121-12.85.346-.154.616-.808.808-1.654-.077 1.308-.192 3.5-.346 5.232-.269 2.578-4.194 6.848-11.272 10.5-7.425 3.847-14.735 7.695-16.659 9.734a4.224 4.224 0 01-1.347-3.5c0-.923 2.77-4.27 9.7-7.464z"
        transform="translate(0 .1) translate(67.946 -.062)"
      />
    </g>
    <g>
      <path
        className="e"
        d="M2.198 112.763c2.116-1.077 16.235-5.155 21.391-7.848 18.428-9.657-6.733-21.891-17.544-24.007-1.731-.346-1.962-.231-1.962-1.5s.038-4.578.038-5.309c0-.846 1-.846 3.039-1.693 2-.808 16.389-8.849 21.929-16.082A51.985 51.985 0 0039.132 36.01c1.193-4.655 2.578-22.545-.269-36.01h3.693c-.462 13.465.539 23.622-3.232 37.742-4.347 16.082-9.5 28.316-29.7 38.7 2.116.539 17.89 4.463 21.545 16.235.654 2.116.462 4.886.462 7.156 0 1.423-.808 5.271-6.348 8.849s-19.813 7.964-21.737 8.579a87.81 87.81 0 0010.041 5.309c6.694 3.078 14.119 6.656 15.658 12.2V141c0 1.154-1.116 3.924-5.117 5.694a11.65 11.65 0 013.616 2.924 2.149 2.149 0 01.346 1.385v3.809c0 .731-2.462 3.039-10.5 3.424a53.9 53.9 0 01-12.581-.577v-4.886s12.581 2.77 20.2-.654c0 0-9.426-5.5-25.2-2.809v-4.424c0-.693 3.655-1.27 7.618-1.27a51.444 51.444 0 0110 .846c5.655-1.616 16.351-5.117 6.579-10.849-2.462-1.423-16.62-8.233-20.775-10.657-1.885-1.154-3.424-1.924-3.424-4.155v-2.77c-.038-1.154.077-2.193 2.193-3.27z"
        transform="translate(0 .1) translate(43.507 -.1)"
      />
      <path
        className="m"
        d="M2.737 112.494c3.463-1.385 16.043-5.078 20.814-7.579s7.387-4.694 7.964-10c.077 1.039 0 1.962 0 2.693v2.193c0 1.423-.769 5.309-6.31 8.849-5.54 3.578-19.775 8-21.7 8.579a18.439 18.439 0 01-2.885-2.231c-.616-1.077.769-1.962 2.116-2.5zm22.545 39.627a15.259 15.259 0 012.77 2.385v.308c0 .731-2.462 3.039-10.5 3.424a53.9 53.9 0 01-12.581-.577v-4.886s12.657 2.77 20.314-.654zm-1.231-5.386c-.577-.308-1.154-.577-1.731-.846a28.692 28.692 0 00-4.694-1.423c2.385-.692 10.965-3.039 10.5-6.656.923 2.385 1.039 3.578.693 4.655a8.983 8.983 0 01-4.771 4.27z"
        transform="translate(0 .1) translate(43.507 -.1)"
      />
    </g>
    <g>
      <path
        className="k"
        d="M123.42 91.334s7.618 1.077 8.733 6.54a19.626 19.626 0 01-1.077 12l6.54-6.54s3.27-10.926-6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="e"
        d="M29.585 41.05a30.086 30.086 0 0012 5.463l5.463-1.077a45.535 45.535 0 01-10.888-5.463z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="m"
        d="M62.98 79.254h2.193l7.618 5.771h-2.154z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="c"
        d="M224.988 162.432a145.249 145.249 0 0014.119-39.281v22.93s-9.811 13.081-13.081 25.084zM299.74 133.385s5 3.963-.269 10.5h3.347s4.617-5.963 1.616-10.311z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="g"
        d="M175.512 139.579l-.077 1.614s4.809-.731 4.809-5.617v-.231l-.808-.154a4.767 4.767 0 01-3.924 4.386z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="i"
        d="M295.508 107.914l7.81-3.539s7.887-.962 9.811-1.462l-2.424 2.962a87.7 87.7 0 01-15.2 2.039z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="e"
        d="M258.381 121.265l-2-2.77h-3.5l2.116-2.885-1.077-3.386 3.309 1.154 2.809-2.078-.077 3.463 2.808 2.039-3.309 1.077z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="f"
        d="M60.209 66.712s-7.887.731-15.274 14.581c-2.655 4.925-4.193 10.7-.846 20.544s4.117 17.505-1.962 20.314-20.657 1.578-25.93 12.311-2.77 14.7.5 22.391l3.386-7.348s-4.886-7.233-3.463-13.042c.808-3.386 16.12-2.231 24.7-9.233s11.426-19.352 5.5-33.2c-1.808-4.232-3.732-10.811.423-14.7a27.663 27.663 0 0110-5.54zM253.189 71.905s7.887.769 15.274 14.581c2.655 4.963 4.194 10.7.846 20.544s-4.117 17.505 1.962 20.314 20.66 1.577 25.931 12.311 2.77 14.7-.5 22.353l-3.386-7.31s4.886-7.233 3.463-13.042c-.808-3.386-16.12-2.231-24.7-9.233s-11.426-19.352-5.5-33.2c1.808-4.232 3.732-10.811-.423-14.7a27.663 27.663 0 00-10-5.54z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="b"
        d="M71.559 148.543s7.618 1.077 8.733 6.54a19.626 19.626 0 01-1.077 12l6.54-6.54s3.27-10.926-6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="a"
        d="M86.371 119.765a30.087 30.087 0 0012 5.463l5.463-1.077a45.537 45.537 0 01-10.888-5.463z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="g"
        d="M61.941 126.498l.462-2.154 7-6.233-.462 2.116zM219.564 70.289s-7.618 1.077-8.733 6.54a19.626 19.626 0 001.077 12l-6.54-6.54s-3.27-10.926 6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="c"
        d="M235.068 95.566a30.088 30.088 0 01-12 5.463l-5.463-1.077a45.537 45.537 0 0010.888-5.463zM119.957 68.097h-2.154l-7.656 5.771h2.193z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="m"
        d="M240.339 58.786s7.618 1.077 8.695 6.54a19.626 19.626 0 01-1.077 12l6.54-6.54s3.27-10.926-6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="k"
        d="M249.072 41.319a29.776 29.776 0 0011.965 5.463l5.463-1.077a45.533 45.533 0 01-10.888-5.463z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="a"
        d="M277.426 61.172h2.154l7.656 5.386h-2.193z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="f"
        d="M215.486 117.726s-7.618 1.077-8.733 6.54a19.626 19.626 0 001.077 12l-6.54-6.54s-3.27-10.926 6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="c"
        d="M129.114 129.537A30.1 30.1 0 00141.079 135l5.463-1.077a45.534 45.534 0 01-10.888-5.463z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="g"
        d="M157.469 149.659h2.154l7.656 5h-2.193z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="e"
        d="M278.657 95.066s2.847.423 3.232 2.424a7.553 7.553 0 01-.385 4.463l2.424-2.424s1.231-4.04-2.424-5.694z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="f"
        d="M286.16 73.637a17.89 17.89 0 007.31 3.309l3.309-.654a27.059 27.059 0 01-6.656-3.309z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="i"
        d="M311.09 92.719h2.193l7.656 5.386h-2.193z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="u"
        d="M291.546 159.738s-7.618 1.077-8.733 6.54a19.625 19.625 0 001.077 12l-6.54-6.54s-3.27-10.926 6.54-15.274zM282.813 142.272a30.086 30.086 0 01-12 5.463l-5.463-1.077a45.533 45.533 0 0010.884-5.463z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        d="M254.459 162.355h-2.193l-7.618 5h2.193z"
        transform="translate(0 .1) translate(111.763 124.205)"
        fill="#ed1f26"
        fillRule="evenodd"
      />
      <path
        className="g"
        d="M76.676 61.325s7.618 1.077 8.733 6.54a19.626 19.626 0 01-1.077 12l6.54-6.54s3.27-10.926-6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="c"
        d="M121.458 1.077a30.088 30.088 0 0012 5.463l5.463-1.077A45.534 45.534 0 01128.037 0z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="e"
        d="M89.334 28.085h2.154l7.656 5.771h-2.193z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="g"
        d="M3.424 138.155s7.618 1.077 8.733 6.54a19.626 19.626 0 01-1.077 12l6.54-6.54s3.27-10.926-6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="b"
        d="M0 108.57a30.087 30.087 0 0012 5.463l5.425-1.077a45.53 45.53 0 01-10.885-5.463z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="g"
        d="M30.086 132.731h2.193l7.618 5h-2.154zM196.673 188.4s-7.618 1.077-8.733 6.54a19.626 19.626 0 001.077 12l-6.54-6.54s-3.27-10.888 6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="i"
        d="M188.401 157.661a30.086 30.086 0 0012 5.463l5.463-1.077a45.535 45.535 0 01-10.884-5.463z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="g"
        d="M214.562 179.283h2.154l7.618 5.386h-2.154z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="e"
        d="M83.37 190.978s7.618 1.077 8.733 6.54a19.669 19.669 0 01-1.077 12l6.54-6.54s3.27-10.888-6.54-15.274z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="c"
        d="M47.283 176.282a30.086 30.086 0 0012 5.463l5.463-1.077a45.533 45.533 0 01-10.888-5.463z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
      <path
        className="b"
        d="M111.532 180.437h2.154l7.656 5.771h-2.193z"
        transform="translate(0 .1) translate(111.763 124.205)"
      />
    </g>
    <g>
      <path
        className="d"
        d="M26.458 231.105l-.077 4.5s-15.582-.846-20.391-5.579c0 0 16.428-2.231 25.046-1.039l-.269-5.117s-25.738-5.425-26.892-9.926v-.769c0-1.039 3.847-4.424 11.08-7.31 7.194-2.885 16.235-6.233 17.39-9.811v-6.54c0-.962-2.962-4.309-9.887-7.5s-17.044-8.233-19.352-12.85v-2.308c0-1.423 4.309-5.348 8.156-8.31s15.735-11.042 19.544-17.043c.923-1.924.5-2.578.5-4.04 0-1.5-.385-4.5-.577-6.233s-.962-5.463-8.272-8.849c-6.5-3.039-18.467-6.425-21.352-12.273 0 0 .269 4.886 3.078 8.079s19.467 10.157 22.045 11.811c2.539 1.654 4.117 3.193 3.963 5.271-.154 2.039-5.271 7.04-9.887 10.657-4.694 3.655-16.928 10.657-17.967 20.544 0 0 .115 3.732.385 6.31s4.193 6.848 11.273 10.5c7.425 3.847 14.735 7.695 16.658 9.734a22.993 22.993 0 01-8.5 4.771c-4.655 1.577-18.775 6.617-18.775 14.312v4.655c0 1.27 1.577 5.309 10.311 7.695l.462.115c-.577 0-6.54-.038-9.964 3.193-1.462 1.423-1.231 1.654-1.231 3.27v2.308c0 .808 3.463 5.425 17.12 7.04 3.539.385 8.656.115 10.311-1.077 1.654-1.231 1.308-5.386 1.308-5.386s-4.078-2.039-5.232-2.809zM32.224 0c.231 7.695.615 39.281-6.617 58.478C16.758 81.985 1.869 102.914 1.1 110.224c0 0-2.193-5.809-.385-10.542s14.235-27.046 17.428-33.2c9.464-18.2 15-45.282 12.965-66.442h1.116z"
        transform="translate(0 .1) translate(453.955 -.062)"
      />
      <path
        className="k"
        d="M26.26 129.999c2.539 1.654 4.116 3.193 3.963 5.271a2.289 2.289 0 01-.269.885 9.707 9.707 0 00.923-3.616c-.038-.5-.115-.923-.154-1.308-.192-1.731-.962-5.463-8.271-8.849-6.5-3.039-18.467-6.425-21.352-12.273 0 0 .269 4.886 3.078 8.079 2.847 3.232 19.544 10.157 22.083 11.811zm4.694 93.873c-3.693-.769-27.585-6-26.7-10.888a9.589 9.589 0 00-.693 3.732c0 4.155 6.925 6.771 10.734 7.81a144.453 144.453 0 0116.659-.654zm-4.54 11.734s4.039.038 5.463-1.654c0 0-4.232-2.078-5.386-2.847zm-4-53.592c-6.925-3.193-16.812-8.233-19.159-12.85-.346-.154-.615-.808-.808-1.654.077 1.308.193 3.5.346 5.232.269 2.578 4.194 6.848 11.273 10.5 7.425 3.847 14.735 7.695 16.659 9.734a4.224 4.224 0 001.347-3.5c.038-.923-2.77-4.27-9.657-7.464z"
        transform="translate(0 .1) translate(453.955 -.062)"
      />
    </g>
    <g>
      <path
        className="a"
        d="M294.316 0a475.022 475.022 0 01-104.761 72.252A472.684 472.684 0 010 122.074v-3.309a468.062 468.062 0 00186.323-48.591A459.983 459.983 0 00289.43 0z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="c"
        d="M45.321 114.687a600.391 600.391 0 01-26.354 59.363c-6.579-9-13.273-18.121-18.967-27.431v-27.546c15.62-.692 29.855-2.193 45.321-4.386z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="b"
        d="M55.401 113.033a515.043 515.043 0 0059.863-14.312 608.121 608.121 0 01-16.7 62.633 619.081 619.081 0 01-43.163-48.321z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="e"
        d="M125.036 95.604a513.078 513.078 0 0056.67-23.122 612.7 612.7 0 01-6.925 64.365 622.826 622.826 0 01-49.745-41.243z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="c"
        d="M190.824 67.904a516.836 516.836 0 0052.515-31.355 624.858 624.858 0 012.847 64.634 620.39 620.39 0 01-55.362-33.279z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="h"
        d="M251.688 30.624A482.2 482.2 0 00289.661 0h11.427c4.27 18.467 7.733 36.549 10.388 55.054a620.209 620.209 0 01-59.788-24.43z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="j"
        d="M18.929 174.05C12.35 165.047 5.694 155.926 0 146.619v-27.508c4.732-.231 9.657-.539 14.5-.885z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="i"
        d="M98.567 161.354q-13.735-13.85-26.546-28.47c-5.655-6.463-11.272-13.081-16.62-19.813 10.157-1.77 20.2-3.847 30.24-6.194z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="m"
        d="M125.036 95.605a582.955 582.955 0 0028.739-10.657l21.045 51.861a429.476 429.476 0 01-49.784-41.204z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="j"
        d="M246.186 101.145a531.048 531.048 0 01-9.233-5 10.414 10.414 0 01-.885-.5q-23.199-12.871-45.244-27.779c9.08-4.694 17.967-9.657 26.738-14.812 2.539 4.232 28.624 48.014 28.624 48.091z"
        transform="translate(0 .1) translate(0 -.062)"
      />
      <path
        className="r"
        d="M311.513 55.088q-15.235-5.309-30.2-11.426c-1.5-.616-2.962-1.231-4.463-1.847-.346-.154-.693-.269-1.039-.423a49.271 49.271 0 00-2.116-.885l-1.039-.462c-.693-.308-1.423-.616-2.116-.923l-1.039-.462q-8.945-3.924-17.774-8.041 12.407-9.06 24.276-18.736z"
        transform="translate(0 .1) translate(0 -.062)"
      />
    </g>
  </svg>
);

export default ConfettiSVG;
