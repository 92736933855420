/**
 *
 * PlusRegCompletePage
 *
 */
import './index.css';
import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { parse } from 'query-string';
import {
  userDetailsSelector,
  errorSelector,
  plusRegistrationCompleteSelector,
} from 'domains/session/selectors';
import { Container, Row } from 'reactstrap';
import messages from './messages';
import { verifyPlusProgramSurveyResponse } from '../../helpers/plusProgramHelper';
import { TITLE_PREP_PLUS_WELCOME } from '../MasterPage/constants';
import welcomeLogo from '../../images/plus-program-confirm.svg';
import { HOME_PATH } from '../App/constants';
import { setPlusRegisteredFlag } from '../../domains/session/actions';
import history from '../../utils/history';
import { isPLUSRegisteredUser, isPLUSUser } from '../../helpers/userHelper';
import { useSessionReduxAndSaga } from '../../domains/session/hooks';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import {
  setPlusJustRegistered,
  getPlusJustRegistered,
} from '../../helpers/sagaHelper';

export function PlusRegCompletePage({
  location,
  userDetails,
  setPlusRegistrationComplete,
}) {
  // required in order to get user details
  useSessionReduxAndSaga();

  const [success, setSuccess] = useState(null);

  // required in order to get user details
  useEffect(() => {
    document.title = TITLE_PREP_PLUS_WELCOME;
  }, []);

  useEffect(() => {
    if (!checkIfRegisteredAlready()) {
      const params = parse(location.search);
      const tid = params.id;
      const userId = params.userid;
      validateRedirect(userId, tid);
    }
  }, [userDetails]);

  useEffect(() => {
    if (success !== null) {
      setPlusJustRegistered();
      setPlusRegistrationComplete();
    }
  }, [success]);

  function checkIfRegisteredAlready() {
    const plusJustRegistered = getPlusJustRegistered();
    const isPLUS = isPLUSUser(userDetails);
    const isRegistered = isPLUSRegisteredUser(userDetails);
    if (!plusJustRegistered && (!isPLUS || isRegistered)) {
      navigateToLibrary();
      return true;
    }
    return plusJustRegistered;
  }

  function navigateToLibrary() {
    history.replace('/');
  }

  function validateRedirect(userId, tid) {
    const ret =
      verifyPlusProgramSurveyResponse(tid) && userId === userDetails.userId;
    setSuccess(ret);
  }

  if (isPLUSRegisteredUser(userDetails)) {
    return (
      <div className="plus-program-confirm-page-container">
        <div className="plus-program-confirm-page">
          <div className="plus-program-confirm-page-head">
            <img
              className="plus-program-confirm-page-confirm-image"
              id="pageHeader"
              src={welcomeLogo}
              alt=""
            />
          </div>
          <div>
            <hr
              className="plus-program-welcome-page-horizontal-rule"
              aria-hidden="true"
            />
            <h1 className="plus-program-confirm-page-heading1">
              <FormattedMessage {...messages.heading1} />
            </h1>

            <p className="plus-program-confirm-page-heading">
              <FormattedMessage {...messages.heading2} />
            </p>

            <h2 className="plus-program-confirm-page-heading3">
              <FormattedMessage {...messages.heading3} />
            </h2>
            <ul className="plus-program-confirm-page-text-content" type="1">
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint1} />
              </li>
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint2} />
              </li>
            </ul>

            <a
              data-testid="plus-confirm"
              className="plus-program-confirm-page-survey-button"
              href={HOME_PATH}
            >
              <span className="plus-program-confirm-page-survey-button-text">
                <FormattedMessage {...messages.buttonText} />
              </span>
            </a>
            <h3 className="plus-program-confirm-page-heading2">
              <FormattedMessage {...messages.heading4} />
            </h3>
            <ul className="plus-program-confirm-page-text-content" type="1">
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint3} />
              </li>
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint4} />
              </li>
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint5} />
              </li>
            </ul>
            <div style={{ clear: 'right' }}>
              <p className="plus-program-confirm-page-text-content plus-prog-content">
                <FormattedHTMLMessage {...messages.text1} />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container fluid style={{ width: '100vw' }}>
      <Row>
        <AriadneLoading text="session data" />
      </Row>
    </Container>
  );
}

PlusRegCompletePage.propTypes = {
  location: PropTypes.object,
  userDetails: PropTypes.object,
  plusRegistrationComplete: PropTypes.bool,
  setPlusRegistrationComplete: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  userDetails: userDetailsSelector(),
  error: errorSelector(),
  plusRegistrationComplete: plusRegistrationCompleteSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    setPlusRegistrationComplete: () => dispatch(setPlusRegisteredFlag()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(PlusRegCompletePage);
