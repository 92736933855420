import { createBrowserHistory } from 'history';
import {
  CERTIFYING_STATEMENT_PATH,
  EXAM_CONFIRM_COMPLETE_PATH,
  EXAM_COMPLETE_PATH,
  TEST_COMPLETE_FLEX_PATH,
  FLEX_CONGRATS_PATH,
  EXAM_START_PATH,
  MODULE_PATH,
  TEST_REVIEW_PATH,
  EXAM_TEST_REVIEW_PATH,
  DIRECTION_PATH,
  INTERMISSION_PATH,
  EXAM_MODULE_PATH,
  EXAM_DIRECTION_PATH,
  QUESTION_PATH,
  EXAM_PASSAGE_PATH,
  EXAM_QUESTION_PATH,
  PASSAGE_PATH,
} from 'containers/App/constants';
import { persistenceState } from '../helpers/persistence/common/persistenceState';

const history = createBrowserHistory();
let prevLocation = null;

function pathMatches(location, match) {
  return location?.toLowerCase().startsWith(match.toLowerCase());
}

let selectedInstance = null;
export function setCurrentInstanceForHistory(instance) {
  selectedInstance = instance;
}

const TEST_SCREENS = [
  CERTIFYING_STATEMENT_PATH,
  TEST_COMPLETE_FLEX_PATH,
  DIRECTION_PATH,
  EXAM_DIRECTION_PATH,
  QUESTION_PATH,
  EXAM_QUESTION_PATH,
  PASSAGE_PATH,
  EXAM_PASSAGE_PATH,
  MODULE_PATH,
  EXAM_MODULE_PATH,
  TEST_REVIEW_PATH,
  EXAM_TEST_REVIEW_PATH,
];

history.listen((location, action) => {
  const current = location.pathname;
  let preventBack = false;

  // do not reset retry flow if the user is still inside test screens
  const isPrevLocnFromExam = TEST_SCREENS.find(screen =>
    pathMatches(prevLocation, screen),
  );
  const isCurrentLocnFromExam = TEST_SCREENS.find(screen =>
    pathMatches(current, screen),
  );
  persistenceState.shouldStopRetry = !(
    isPrevLocnFromExam && isCurrentLocnFromExam
  );

  if (prevLocation && action === 'POP' && prevLocation !== current) {
    if (
      (pathMatches(prevLocation, DIRECTION_PATH) ||
        pathMatches(prevLocation, EXAM_DIRECTION_PATH)) &&
      selectedInstance.module.isFlexPaused
    ) {
      preventBack = true;
    }
    if (
      pathMatches(prevLocation, EXAM_CONFIRM_COMPLETE_PATH) ||
      pathMatches(prevLocation, EXAM_COMPLETE_PATH) ||
      pathMatches(prevLocation, TEST_COMPLETE_FLEX_PATH) ||
      pathMatches(prevLocation, FLEX_CONGRATS_PATH)
    ) {
      preventBack = true;
    }
    if (
      pathMatches(current, CERTIFYING_STATEMENT_PATH) ||
      pathMatches(current, EXAM_START_PATH)
    ) {
      preventBack = true;
    }
    if (selectedInstance?.module?.options?.disableBackButton) {
      if (
        pathMatches(current, MODULE_PATH) ||
        pathMatches(current, EXAM_MODULE_PATH) ||
        pathMatches(current, TEST_REVIEW_PATH) ||
        pathMatches(current, EXAM_TEST_REVIEW_PATH) ||
        pathMatches(current, INTERMISSION_PATH) ||
        pathMatches(prevLocation, MODULE_PATH) ||
        pathMatches(prevLocation, EXAM_MODULE_PATH) ||
        pathMatches(prevLocation, TEST_REVIEW_PATH) ||
        pathMatches(prevLocation, EXAM_TEST_REVIEW_PATH) ||
        pathMatches(prevLocation, INTERMISSION_PATH)
      ) {
        preventBack = true;
      }
    }
    if (preventBack) {
      history.goForward();
    }
  }

  if (!preventBack && prevLocation !== location.pathname) {
    prevLocation = location.pathname;
  }
});

export default history;
