import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DiagnosticPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Diagnostics',
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage: 'Something went wrong. But don’t worry…',
  },
  saveText: {
    id: `${scope}.saveText`,
    defaultMessage: 'SAVE diagnostics locally',
  },
  message1: {
    id: `${scope}.message1`,
    defaultMessage:
      'Something has gone wrong with saving data back to our servers but don’t worry, LawHub has built in redundancies and troubleshooting methods for just this type of occurrence.  We just need a little help from you.  Please click the button below to save the information we need to a file on your computer.  Once saved, please create a support ticket (<a href="https://lsac.zendesk.com/hc/en-us/requests/new" target="_blank">click here</a> or on Help at the bottom left) and attach the file (which should be located in your downloads directory).  Please specify your computer OS and browser and describe the issue you are experiencing including steps taken before experiencing the issue.',
  },
  message2: {
    id: `${scope}.message2`,
    defaultMessage:
      'We appreciate your help in our efforts to improve LawHub and ensure this issue is resolved.',
  },
});
