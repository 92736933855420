/*
 *
 * HistoryPage reducer
 *
 */
import produce from 'immer';
import * as historyHelper from 'helpers/historyHelper';
import {
  FETCH_USER_HISTORY_REQUEST,
  FETCH_USER_HISTORY_SUCCESS,
  FETCH_USER_HISTORY_ERROR,
  UPDATE_USER_HISTORY_COLLAPSED,
  ON_UNLOAD,
} from './constants';

export const initialState = {
  initialized: false,
  isLoading: false,
  isMoreToLoad: true,
  numberOfPagesRetrieved: 0,
  numberOfNewItemsLoaded: 0,
  firstItemIndex: 0,
  userHistory: [],
  module: {},
  error: {},
};

/* eslint-disable default-case, no-param-reassign */
const historyPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_USER_HISTORY_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_USER_HISTORY_SUCCESS:
        // set the index for newest item that has come down
        draft.firstItemIndex = draft.userHistory.length;

        // convert to models
        draft.userHistory.push(
          ...historyHelper.toHistoryModel(action.userHistoryData.items),
        );

        draft.numberOfNewItemsLoaded = action.userHistoryData.items.length;

        // update the page
        draft.numberOfPagesRetrieved = action.userHistoryData.pageNumber;

        if (
          action.userHistoryData.pageNumber >=
          Math.ceil(
            action.userHistoryData.recordCount /
              action.userHistoryData.pageSize,
          )
        ) {
          draft.isMoreToLoad = false;
        }

        draft.module = historyHelper.moduleFromHistory(
          action.userHistoryData.items,
        );

        draft.isLoading = false;

        break;

      case FETCH_USER_HISTORY_ERROR:
        draft.error = action.error;
        break;

      case UPDATE_USER_HISTORY_COLLAPSED:
        draft.userHistory[action.index].isCollapsed = action.collapsed;
        break;

      case ON_UNLOAD:
        draft.isLoading = false;
        draft.isMoreToLoad = true;
        draft.numberOfPagesRetrieved = 0;
        draft.numberOfNewItemsLoaded = 0;
        draft.userHistory = [];
        draft.module = {};
        draft.error = {};
        break;
    }
  });

export default historyPageReducer;
