/* eslint-disable no-param-reassign */
/*
 *
 * WritingExamPage saga
 *
 */
import { takeLatest, call, put } from 'redux-saga/effects';
import { getApi } from 'helpers/sagaHelper';
import { setTokenSuccessAction, setTokenErrorAction } from './actions';
import { config } from '../../config';
import { getAPIFullPath } from '../../helpers/configHelper';
import { SET_TOKEN_ACTION } from './constants';
import handleAPIErrorAndRetry from '../../helpers/apiResponseHelper';

const util = require('util');

export function* setToken(data) {
  function* successCallback(repoData) {
    yield put(setTokenSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(setTokenErrorAction(error));
  }

  let api;

  try {
    const { jwt, userId } = data.payload;
    const apiEndURL = `${util.format(config.REACT_APP_SET_TOKEN)}`;
    const options = {
      userId,
      jwt,
    };

    api = () => getApi('post', `${getAPIFullPath(apiEndURL)}`, options);
    const repos = yield call(api);

    yield put(setTokenSuccessAction(repos.data));
  } catch (error) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(error, api, successCallback, failureCallback);
  }
}

/**
 * Question saga manages watcher lifecycle
 */
export default function* questionData() {
  // Watches for GetSection action and call get when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SET_TOKEN_ACTION, setToken);
}
