/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import ExternalLink from 'images/external_link_outward_rebrand.svg';
import { config } from 'config';
import './index.css';

function LegalNotice() {
  return (
    <div id="legalNoticeDiv">
      <br />

      <h2 className="noticeHeader">
        <span>SELF-MANAGED ACCOUNT</span>
      </h2>

      <p>
        <span>
          Your LSAC/LawHub account provides a great deal of information about
          your activity with the Law School Admission Council, Inc. (“LSAC”). It
          is your responsibility to manage your account actively, ensuring that
          all information is true, current, and complete.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>DATA</span>
      </h2>

      <p>
        <span>
          LSAC collects and distributes data about you and transmits such data
          in accordance with its&nbsp;
          <span className="nowrap">
            <a
              href="https://www.lsac.org/about/lsac-policies/privacy-policy"
              target="_blank"
              title="Will open in a new browser window."
            >
              privacy&nbsp;policy
              <span className="hidden">Will open in a new browser window.</span>
              &nbsp;
              {config.REACT_APP_BRAND_UPDATE === 'true' ? (
                <img className="external-link-icon" src={ExternalLink} alt="" />
              ) : (
                <i className="fas fa-external-link-alt" alt="" />
              )}
            </a>
          </span>
          &nbsp;and all other published policies available on LSAC.org.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>NOTICES</span>
      </h2>

      <p>
        <span>
          By creating an account on LSAC and/or LawHub, you consent to the
          delivery of any legally required notices to the e-mail address
          associated with your account at the time a notice is sent.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>LIMITED WARRANTY</span>
      </h2>

      <p>
        <span>
          LSAC warrants that the services it provides over the web will
          substantially perform their stated functions, when used in accordance
          with all given instructions and in compliance with LSAC policies, for
          so long as your LSAC and/or LawHub account remains active. LSAC does
          not represent or warrant that these services will meet your
          requirements or operate free from error. LSAC disclaims all other
          warranties and conditions either express or implied, including the
          warranties of merchantability, fitness for a particular purpose, and
          noninfringement of third-party rights. No oral or written information
          or advice given by LSAC, its agents, or employees shall create a
          warranty or in any way increase the scope of this warranty, and you
          may not rely on any such information or advice. Some jurisdictions do
          not allow the exclusion of implied warranties, so the above exclusion
          may not apply to you. This warranty gives you specific legal rights,
          and you may also have other legal rights that vary from jurisdiction
          to jurisdiction.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>LIMITED REMEDIES</span>
      </h2>

      <p>
        <span>
          If any LSAC service fails to perform as warranted, it is your duty to
          notify LSAC of the problem immediately. Within a reasonable period of
          time, which shall be determined by LSAC based on the specific
          circumstances, LSAC shall, at its option, (I) correct any errors, or
          (II) refund some or all of any fees you have paid for the relevant
          service. This limited warranty is void if (a) the failure has resulted
          from accident, abuse, misapplication, or other user error; (b) you
          have used or modified the web services in any unauthorized manner; or
          (c) if more than 60 days have elapsed since the problem occurred. LSAC
          shall not be liable for any incidental or consequential damages
          (including any damages for lost opportunities, income, or profits;
          loss of information; business interruption; travel expenses;
          test-preparation courses fees; or any other types of damages) arising
          out of the use or inability to use any of its services even if LSAC or
          its representative has been advised of the possibility of such
          damages. Some jurisdictions do not allow the limitation or exclusion
          liability for incidental or consequential damages, so the above
          limitation or exclusion may not apply to you.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>LIMITED LIABILITY</span>
      </h2>

      <p>
        <span>
          The liability of LSAC for any claims arising out of your use of its
          services, regardless of the form of action, shall not exceed the
          amount of any monies you have paid to LSAC in the twelve (12) month
          period immediately prior to the event that caused such liability for
          the applicable services that are directly related to your claim
          against LSAC.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>COPYRIGHTS</span>
      </h2>

      <p>
        <span>
          The entire contents of the LSAC websites, including but not limited to
          LSAC.org; Law School Success; LawSchoolTransparency.com,
          LegalCareerCompass.org, LegalCareerCompass.com; the LawHub application
          and platform and all related applications and services, both the
          content that is visible to end-users and the software supporting it;
          and the Official LSAT Prep and Official LSAT Prep Plus, are
          copyrighted by the Law School Admission Council, Inc. Unauthorized
          reproduction of any such materials is strictly prohibited. Certain
          elements or applications on LawHub, Law School Success, or through an
          LSAC.org / LawHub account (e.g., Legal Career Compass) may be the
          property of a third-party. Users of LawHub must treat any third-party
          property in the same manner as LSAC&apos;s own copyrighted material,
          and LSAC reserves the right to prosecute copyright infringements of
          any content found in or associated with LawHub.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>TRADEMARKS, REGISTERED MARKS, AND SERVICE MARKS</span>
      </h2>

      <p>
        <span>
          LSAT, Law School Admission Test,&nbsp;
          <i>The Official LSAT PrepTest, The Official LSAT SuperPrep,</i>
          &nbsp; ItemWise, and LSAC are registered marks of the Law School
          Admission Council, Inc. Law School Forums, LLM Law School Forum,
          Credential Assembly Service, CAS, LLM Credential Assembly Service, and
          LLM CAS are service marks of the Law School Admission Council,
          Inc.&nbsp;
          <i>
            10 Actual, Official LSAT PrepTests; 10 More Actual, Official LSAT
            PrepTests; The Next 10 Actual, Official LSAT PrepTests; 10 New
            Actual, Official LSAT PrepTests with Comparative Reading; 10 Actual,
            Official LSAT Preptests, Volume V; 10 Actual, Official LSAT
            PrepTests 42—51; 10 Actual, Official LSAT PrepTests, Volume VI; LSAC
            Official Guide to ABA-Approved Law Schools; The Official LSAT
            SuperPrep II; The Official LSAT Handbook; The Official LSAT
            SuperPrep II; The Official LSAT Handbook;
          </i>
          &nbsp;LSAC Official Guide to LLM/Graduate Law Programs; ACES
          <sup>2</sup>; FlexApp; DiscoverLaw.org; LSAT Prep, Official LSAT Prep
          Plus, Law School Transparency, Legal Career Compass, LST Reports, I Am
          The Law, LST Wizard, Modern Legal Practice and Institute for the
          Future Practice of Law, Law School Unmasked, Law School Admission
          Unmasked and Law School Admission Council are trademarks of the Law
          School Admission Council, Inc.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>OFFICIAL LSAT PREP MATERIALS</span>
      </h2>
      <p>
        <span>
          By creating an account on LSAC.org and LawHub, you will be provided a
          free subscription to the Official LSAT Prep. The subscription
          materials may be used only for the purpose of preparing for the LSAT.
          LSAC reserves the right to terminate or suspend your subscription to
          the Official LSAT Prep immediately at any time if you fail to comply
          with the terms of these terms. LSAC reserves the right to change the
          length, features, content or other terms of the Official LSAT Prep
          subscription at any time without notice. We cannot guarantee the
          availability of the Official LSAT Prep subscription materials. Access
          to LawHub and products included on the LawHub platform and
          applications, or accessed through an LSAC.org / LawHub account, and
          any subscription materials may be unavailable for reasons including
          but not limited to maintenance, outages, and other matters beyond our
          control. Scores on Official LSAT Prep and/or Official LSAT Prep Plus
          materials do not reflect, and cannot be used as a substitute for,
          official LSAT test scores.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>LAW SCHOOL SUCCESS MATERIALS</span>
      </h2>
      <p>
        <span>
          By purchasing a subscription to Law School Success, you will be
          provided with a 1-year subscription to the Law School Success products
          that are available on LawHub. The subscription materials may be used
          by the user for the purpose of educating the user and may not be sold,
          displayed, transferred, or otherwise shared with any third party for
          any commercial or non-commercial purposes. LSAC reserves the right to
          terminate or suspend your subscription to Law School Success
          immediately at any time if you fail to comply with these terms. LSAC
          reserves the right to change the length, features, content or other
          terms of your Law School Success subscription at any time without
          notice to you. We cannot guarantee the availability of the Law School
          Success materials. Access to Law School Success products included on
          the LawHub platform and applications, or accessed through an LSAC.org
          / LawHub account, and any subscription materials may be unavailable
          for reasons including but not limited to maintenance, outages, and
          other matters beyond our control.
        </span>
      </p>

      <h2 className="noticeHeader">
        <span>GENERAL</span>
      </h2>

      <p>
        <span>
          These terms shall be governed by and interpreted in accordance with
          the laws of the Commonwealth of Pennsylvania, USA, without giving
          effect to any choice of law principles, and shall not be governed by
          the United Nations Convention for International Sales of Goods, the
          application of which is expressly excluded.
        </span>
      </p>

      <h3 className="noticeSubHeader">
        <span>
          Official LSAT Prep Plus and Law School Success Subscriber Agreement
        </span>
      </h3>
      <ol>
        <li>
          The subscriber is the individual who registered for the LSAC.org,
          LawHub account, and/or Law School Success products associated with the
          applicable subscription. The name provided on the payment information
          form, if different from the individual registering for the LSAC.org,
          LawHub account and/or Law School Success products, does not have any
          rights to use the subscription materials.
        </li>
        <li>
          You warrant that all information provided by you to register for your
          LSAC.org and/or LawHub account is accurate and current.
        </li>
        <li>
          The entire contents of the Official LSAT Prep, Prep Plus, and/or Law
          School Success subscription, both the content that is visible to you
          and the software supporting it, are copyrighted by the Law School
          Admission Council, Inc. Unauthorized reproduction or distribution of
          any contents accessible via you Official LSAT Prep, Prep Plus and/or
          Law School Success subscription are strictly prohibited. The
          subscription materials and contents may be used only for educating the
          user and may not be sold, displayed, transferred, or otherwise shared
          with any third party for any commercial or non-commercial purposes.
        </li>
        <li>
          By providing your payment details and personally identifiable
          information to LSAC, you are consenting to our&nbsp;
          <a
            href="https://www.lsac.org/about/lsac-policies/privacy-policy"
            target="_blank"
            title="Will open in a new browser window."
          >
            privacy policy
            <span className="hidden">Will open in a new browser window.</span>
            &nbsp;
            {config.REACT_APP_BRAND_UPDATE === 'true' ? (
              <img className="external-link-icon" src={ExternalLink} alt="" />
            ) : (
              <i className="fas fa-external-link-alt" alt="" />
            )}
          </a>
        </li>
        <li>
          By accessing the Legal Career Compass application, you understand and
          agree to comply with and adhere to the&nbsp;
          <a
            href="https://stepresearch.com/privacy/"
            target="_blank"
            title="Will open in a new browser window."
          >
            Privacy Policy and Terms of Use
            <span className="hidden">Will open in a new browser window.</span>
            &nbsp;
            {config.REACT_APP_BRAND_UPDATE === 'true' ? (
              <img className="external-link-icon" src={ExternalLink} alt="" />
            ) : (
              <i className="fas fa-external-link-alt" alt="" />
            )}
          </a>
          &nbsp; of Step Research Corporation. You acknowledge and agree that
          the Legal Career Compass application and model is subject to the&nbsp;
          <a
            href="https://stepresearch.com/privacy/"
            target="_blank"
            title="Will open in a new browser window."
          >
            Privacy Policy and Terms of Use
            <span className="hidden">Will open in a new browser window.</span>
            &nbsp;
            {config.REACT_APP_BRAND_UPDATE === 'true' ? (
              <img className="external-link-icon" src={ExternalLink} alt="" />
            ) : (
              <i className="fas fa-external-link-alt" alt="" />
            )}
          </a>
          &nbsp; of Step Research Corporation and that LSAC&apos;s privacy
          policy and terms of use are not applicable to your access and/or use
          of Legal Career Compass or any other products made available by Step
          Research Corporation on its website.
        </li>
        <li>
          We may ask you from time to time to verify your payment or profile
          information in order to confirm your eligibility for any subscription.
          We may use third-party software to determine your location for
          purposes of verifying eligibility for any subscription.
        </li>
        <li>
          LSAC reserves the right to terminate or suspend your subscription to
          Official LSAT Prep Plus and/or subscription to Law School Success,
          immediately and at any time, if you fail to comply with the terms of
          the Official LSAT Prep Plus and Law School Success Subscription
          Agreement.
        </li>
        <li>
          LSAC reserves the right to change the price, length or other terms of
          the Official LSAT Prep Plus and Law School Success subscription at any
          point. Any changes will not apply to an active subscription for
          Official LSAT Prep Plus and Law School Success.
        </li>
        <li>
          At its sole discretion and at any time, LSAC may elect to provide
          trial access, complimentary subscriptions, or any other type of free
          or complimentary access to the LawHub platform, Law School Success and
          applications (or any portions thereof) to any third party.
        </li>
        <li>
          Features and content delivered through your subscription to Official
          LSAT Prep Plus and/or your subscription to Law School Success are
          subject to change at any time and are not guaranteed. We are not
          obligated to provide notice of changes but will do our best to
          communicate changes through our help page.
        </li>
        <li>
          The LawHub Terms and Conditions shall incorporate by reference the
          LSAC Terms & Conditions of Use and the LSAC Terms & Conditions of Use
          shall be made a part of the LawHub Terms and Conditions. In the event
          of any conflict between the terms of these LawHub Terms and Conditions
          and the LSAC Terms & Conditions of Use, the LawHub Terms and
          Conditions shall apply.
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>
    </div>
  );
}

export default LegalNotice;
