/**
 *
 * ReloadingLink
 *
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Wrapper for Link component that will auto-reload when ServiceWorker has updated
 *
 * @param  {string} id Id to passthrough to Link
 * @param  {string} to Location descriptor
 * @param  {string} className Name of classes to be applied to component
 * @param  {string} dataTestId Passthrough prop for the data-testid attribute
 * @param  {object} history Object used to push routing
 * @param  {string, object} children Object or String to be displayed inside the Link
 * @param  {function} callFunctionBeforeReload Function to be called before the reload occurs
 *
 */
export function ReloadingLink({
  id,
  to,
  className,
  dataTestId,
  history,
  children,
  ariaCurrent,
  callFunctionBeforeReload,
}) {
  return (
    <Link
      id={id}
      key={id}
      to={to}
      className={className}
      data-testid={dataTestId}
      aria-current={ariaCurrent}
      onClick={e => {
        if (typeof callFunctionBeforeReload !== 'undefined') {
          callFunctionBeforeReload();
        }
        reloadFunction(e, to, history);
      }}
    >
      {children}
    </Link>
  );
}

// Function takes in onClick event, to prop, and history prop
// It will hard reload if there's a swUpdate, otherwise use conventional routing
function reloadFunction(e, to, history) {
  if (e.metaKey || e.ctrlKey) return;
  e.preventDefault();
  if (window.swUpdate) {
    window.location = to;
  } else {
    history.push(to);
  }
}

ReloadingLink.propTypes = {
  id: PropTypes.string,
  to: PropTypes.any,
  className: PropTypes.any,
  dataTestId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  history: PropTypes.object,
  callFunctionBeforeReload: PropTypes.func,
  ariaCurrent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default ReloadingLink;
