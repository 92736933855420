/**
 *
 * BrowserErrorPage
 *
 */
import './index.css';
import React from 'react';

export function PMCompletePage() {
  return (
    <div className="pm-complete-page-container">
      <div className="pm-complete-page-header">
        <div className="pm-complete-page-header-div">
          <div className="pm-complete-page-header-text">LSAT</div>
        </div>
      </div>
      <div className="pm-complete-page-top-decoration" />
      <div className="pm-complete-page">
        <div id="heading1" className="heading-color">
          LSAT
        </div>
        <hr className="pm-complete-page-horizontal-rule" role="none" />
        <div>
          <div className="pm-complete-page-text-content">
            Your testing session was completed
          </div>
        </div>
      </div>
    </div>
  );
}

export default PMCompletePage;
