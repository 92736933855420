import {
  SET_TOKEN_ACTION,
  SET_TOKEN_ACTION_SUCCESS,
  SET_TOKEN_ACTION_ERROR,
} from './constants';

export function setTokenAction(jwt, userId) {
  return {
    type: SET_TOKEN_ACTION,
    payload: { jwt, userId },
  };
}

export function setTokenSuccessAction(examdata) {
  return {
    type: SET_TOKEN_ACTION_SUCCESS,
    examdata,
  };
}

export function setTokenErrorAction(error) {
  return {
    type: SET_TOKEN_ACTION_ERROR,
    error,
  };
}
