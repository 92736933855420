export const STATE_WAITING_FOR_SAGA = 'STATE_WAITING_FOR_SAGA';
export const STATE_REQUEST_EXAM_LIST = 'STATE_REQUEST_EXAM_LIST';
export const STATE_WAITING_FOR_EXAM_LIST = 'STATE_WAITING_FOR_EXAM_LIST';
export const STATE_RETRIEVED_EXAM_LIST = 'STATE_RETRIEVED_EXAM_LIST';
export const STATE_EXAM_SET = 'STATE_EXAM_SET';
export const STATE_REQUEST_NOTIFICATIONS_BE_ENABLED =
  'STATE_REQUEST_NOTIFICATIONS_BE_ENABLED';
export const STATE_REQUEST_SUBSCRIBE_TO_NOTIFICATIONS =
  'STATE_REQUEST_SUBSCRIBE_TO_NOTIFICATIONS';
export const STATE_SUBSCRIBED_TO_NOTIFICATIONS =
  'STATE_SUBSCRIBED_TO_NOTIFICATIONS';
export const STATE_REQUEST_ROOM_SCAN = 'STATE_REQUEST_ROOM_SCAN';
export const STATE_ROOM_SCAN_COMPLETE = 'STATE_ROOM_SCAN_COMPLETE';
export const STATE_RETURN_TO_ONLINE_SERVICES =
  'STATE_RETURN_TO_ONLINE_SERVICES';
export const STATE_READY_TO_START = 'STATE_READY_TO_START';
export const STATE_WAITING_FOR_EXAM = 'STATE_WAITING_FOR_EXAM';
export const STATE_EXAM_LIST_ERROR = 'STATE_EXAM_LIST_ERROR';
export const STATE_EXAM_ERROR = 'STATE_EXAM_ERROR';
export const STATE_PRIVATE_MODE = 'STATE_PRIVATE_MODE';
