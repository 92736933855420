/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import './index.css';
import PropType from 'prop-types';

/**
 * Handle keyboard button press
 *
 * @param {object} params the parameters consisting of event, current selected list item, the list item references, and the props of the component.
 */

export function handleKeyPress(params) {
  const code = params.event.keyCode || params.event.which;

  if (params.event.stopPropagation) {
    params.event.stopPropagation();
  }

  if (code === 40) {
    params.event.preventDefault();
    let newSelectedItem = params.selectedItem + 1;

    if (newSelectedItem > params.liRefrences.length - 1) {
      newSelectedItem = 0;
    }

    params.setSelectedItem(newSelectedItem);
    setFocusForItem(params.liRefrences[newSelectedItem]);
  } else if (code === 38) {
    params.event.preventDefault();

    let newSelectedItem = params.selectedItem - 1;
    if (newSelectedItem < 0) {
      newSelectedItem = params.liRefrences.length - 1;
    }

    params.setSelectedItem(newSelectedItem);
    setFocusForItem(params.liRefrences[newSelectedItem]);
  }
}

/**
 * Sets focus on the given item
 *
 * @param {object} item list item to be set focus upon
 */
export function setFocusForItem(item) {
  if (item && item.focus) {
    item.setAttribute('tabindex', '0');
    item.focus();
  }
}

/**
 * Adds a React component to show a list of items, from which any item can be selected.
 *
 *  - Roles
 *     - Show current selected item with the title.
 *     - Open a list with items and highlight current selected item.
 *
 * @param name A unique name of the dropdown.
 * @param isOpen if true, the list is open by default.
 * @param children the text to show as the dropdown title.
 * @param itemList a set of items that is displayed when dropdown is open.
 * @param onBlurHandler function, called when the focus is shifted from the component.
 * @param onFocusHandler function, called when the focus is received by the component.
 */

const DropdownMenu = props => {
  const [selectedItem, setSelectedItem] = useState(0);
  const liRefrences = [];
  const childElements = [];

  if (props && props.children) {
    props.children.forEach(filterArray);
  }

  // To filter nested props
  function filterArray(item) {
    if (Array.isArray(item?.props?.children)) {
      item.props.children.forEach(
        child => child.props !== undefined && childElements.push(child),
      );
    } else if (item && item.props && typeof item.props !== 'undefined') {
      childElements.push(item);
    }
  }

  // /**
  //  * Set focus on current selected item
  //  * Update current selected item based on props value
  //  */
  useEffect(() => {
    const focusedElements = [...document.getElementsByClassName('li-focus')];

    if (focusedElements.length > 0) {
      const currentLiElement = focusedElements.find(li =>
        li.id.includes(props.accessibleName),
      );
      setFocusForItem(currentLiElement);
    }

    setSelectedItem(0);
  }, [props.isOpen]);

  return (
    <div
      id={`${props.accessibleName}-wrapper`}
      className="exp_wrapper"
      onBlur={() => props.onBlurHandler()}
      onFocus={() => props.onFocusHandler()}
    >
      <button
        type="button"
        id={props.accessibleName}
        aria-haspopup="true"
        aria-controls={`${props.accessibleName}-list`}
        aria-expanded={props.isOpen}
        className={`exp_button menu_button ${
          props.className ? props.className : ''
        }`}
        onClick={props.toggle}
        data-testid="userProfile.welcomeMessage"
        aria-label={props.label}
      >
        {props.title}
      </button>
      {props.isOpen && (
        <ul
          id={`${props.accessibleName}-list`}
          aria-labelledby={props.accessibleName}
          className="exp_elem_list"
          tabIndex={0}
          onKeyDown={event => {
            handleKeyPress({
              event,
              props,
              setSelectedItem,
              selectedItem,
              liRefrences,
            });
          }}
        >
          {childElements.map((child, index) => (
            <li
              id={`${props.accessibleName}-option-${index}`}
              // eslint-disable-next-line react/no-array-index-key
              key={`${props.accessibleName}-option-${index}`}
              className={`${selectedItem === index ? 'li-focus' : ''}`}
              ref={input => (liRefrences[index] = input)}
              onKeyPress={e => {
                if ((e.which === 13 || e.which === 32) && child.props.onClick) {
                  child.props.onClick();
                }
              }}
              tabIndex="0"
            >
              {child}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

DropdownMenu.propTypes = {
  accessibleName: PropType.string.isRequired,
  isOpen: PropType.bool.isRequired,
  title: PropType.oneOfType([PropType.string, PropType.object]).isRequired,
  label: PropType.string.isRequired,
  className: PropType.string,
  children: PropType.array.isRequired,
  toggle: PropType.func.isRequired,
  onBlurHandler: PropType.func.isRequired,
  onFocusHandler: PropType.func.isRequired,
};

export const DropdownItem = props => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    role={props.role || 'none'}
    onClick={props.onClick}
    onKeyPress={props.onClick}
    className={props.className ? props.className : ''}
    disabled={props.disabled}
  >
    <div title={props.tooltip}>
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {props.children}
      </span>
      {props.tooltip && <span className="hidden">{props.tooltip}</span>}
    </div>
  </div>
);

DropdownItem.propTypes = {
  onClick: PropType.func.isRequired,
  className: PropType.string,
  children: PropType.any.isRequired,
  disabled: PropType.bool,
  tooltip: PropType.string,
  role: PropType.string,
};

export default DropdownMenu;
