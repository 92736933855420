/*
 *
 * HistoryPage constants
 *
 */

export const FETCH_USER_HISTORY_REQUEST =
  'Athena/HistoryPage/FecthUserHistoryRequest';
export const FETCH_USER_HISTORY_SUCCESS =
  'Athena/HistoryPage/FecthUserHistorySuccess';
export const FETCH_USER_HISTORY_ERROR =
  'Athena/HistoryPage/FetchUserHistoryError';
export const UPDATE_USER_HISTORY_COLLAPSED =
  'Athena/HistoryPage/UpdateUserHistoryCollapsed';
export const ON_UNLOAD = 'Athena/HistoryPage/OnUnload';
export const SS_TEST_MODULEID = 'TestSelectedModuleId';
