import PropTypes from 'prop-types';

class telemetryItemModel {
  constructor() {
    this.eventType = null;
    this.eventTime = null;
    this.epochEventTime = null;
    this.isCurrent = false;
    this.details = {
      sectionNumber: null,
      itemNumber: null,
      elementId: null,
      endTime: null,
      epochEndTime: null,
      response: null,
    };
  }
}

telemetryItemModel.PropTypes = {
  eventType: PropTypes.string,
  eventTime: PropTypes.string,
  epochEventTime: PropTypes.number,
  isCurrent: PropTypes.bool,
  sectionNumber: PropTypes.number,
  itemNumber: PropTypes.number,
  elementId: PropTypes.string,
  endTime: PropTypes.string,
  epochEndTime: PropTypes.number,
  response: PropTypes.string,
};

export default telemetryItemModel;
