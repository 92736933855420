import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  LIBRARY_PATH,
  PROGRAM_DETAILS_PATH,
  PODCAST_PATH,
} from 'containers/App/constants';
import './ASOUnAuthView.css';
import {
  PersonOnTable,
  QuizIcon,
  AppsIcon,
  LocalLibrary,
  ExternalLinkIcon,
  PodcastIcon,
} from './commonImages';
import messages from './messages';
import { config } from '../../config';
import { signIn } from '../../authProvider';

const ASOSubscriptionPage = ({
  authView,
  subscribeToLawHub,
  hasPrepPlusSubscription,
}) => {
  const findtheBestFitUrl = 'https://www.lawschooltransparency.com/wizard';
  const iAmTheLawPath = `${PODCAST_PATH}/${
    config.REACT_APP_PODCAST_ID_FOR_SLIDER_ONE
  }`;

  return (
    <>
      <section className="layout-container">
        <div className="central-column">
          <div className="aso-helper-section">
            <img src={PersonOnTable} alt="" className="person-pic hide-sm" />
            <div>
              <h2 className="section-header">
                {messages.howAstHelpsYou.defaultMessage}
              </h2>
              <p>
                <FormattedHTMLMessage {...messages.keepTrackOfYourLsa} />
              </p>
              <ul>
                <li>{messages.keepTrackPoint1.defaultMessage}</li>
                <li>{messages.keepTrackPoint2.defaultMessage}</li>
                <li>{messages.keepTrackPoint3.defaultMessage}</li>
              </ul>
              <p>
                {messages.keepTrackPoint4.defaultMessage}
                {!hasPrepPlusSubscription &&
                  messages.keepTrackPoint5.defaultMessage}
              </p>
              <div className="btn-wrapper">
                {!hasPrepPlusSubscription && (
                  <button
                    type="button"
                    className="primary-btn"
                    onClick={e => subscribeToLawHub(e)}
                  >
                    {messages.subscribeToLawhubAdvantage.defaultMessage}
                  </button>
                )}
                {!hasPrepPlusSubscription && !authView && <span>OR</span>}
                {!authView && (
                  <button
                    type="button"
                    className="secondary-btn"
                    onClick={signIn}
                  >
                    {messages.accessYourAccount.defaultMessage}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className={`layout-container reasons-section bg-grey ${
          authView ? 'auth-view-subscription' : ''
        }`}
      >
        <div className="central-column">
          <h2 className="section-header">
            {authView
              ? messages.getReadyToApply.defaultMessage
              : messages.moreReasonsToSubscribe.defaultMessage}
          </h2>
          <div className="reasons-section-container">
            <div className="reasons-section-wrapper">
              <div className="img-holder">
                <img src={authView ? PodcastIcon : QuizIcon} alt="" />
              </div>
              <h3>
                {authView
                  ? messages.iAmTheLaw.defaultMessage
                  : messages.lsatPrepTest.defaultMessage}
              </h3>
              <p>
                {authView
                  ? messages.iAmTheLawDesc.defaultMessage
                  : messages.lsatPrepTestDescription.defaultMessage}
              </p>
              <a
                href={authView ? iAmTheLawPath : LIBRARY_PATH}
                className="secondary-btn"
              >
                {authView
                  ? messages.listenNow.defaultMessage
                  : messages.startPracticing.defaultMessage}
                <span className="sr-only">
                  {authView
                    ? messages.iAmTheLaw.defaultMessage
                    : messages.lsatPrepTest.defaultMessage}
                </span>
              </a>
            </div>
            <div className="reasons-section-wrapper">
              <div className="img-holder">
                <img src={AppsIcon} alt="" />
              </div>
              <h3>
                {authView
                  ? messages.findtheBestFit.defaultMessage
                  : messages.knowB4UGo.defaultMessage}
              </h3>
              <p>
                {authView ? (
                  messages.findtheBestFitDesc.defaultMessage
                ) : (
                  <FormattedHTMLMessage {...messages.knowB4UGoDescription} />
                )}
              </p>
              <a
                href={
                  authView ? findtheBestFitUrl : config.REACT_APP_KNOW_B4_U_GO
                }
                target="_blank"
                className="secondary-btn"
                title={messages.opensInNewWindow.defaultMessage}
              >
                {messages.getStarted.defaultMessage}
                <span className="sr-only">
                  {messages.knowB4UGo.defaultMessage}
                </span>
                <img
                  src={ExternalLinkIcon}
                  alt={messages.opensInNewWindow.defaultMessage}
                  className="external-link"
                />
              </a>
            </div>
            <div className="reasons-section-wrapper">
              <div className="img-holder">
                <img src={authView ? QuizIcon : LocalLibrary} alt="" />
              </div>
              <h3>
                {authView
                  ? messages.lsatPrepTest.defaultMessage
                  : messages.lawSchoolJumpstart.defaultMessage}
              </h3>
              <p>
                {authView ? (
                  messages.lsatPrepTestAuthDesc.defaultMessage
                ) : (
                  <FormattedHTMLMessage {...messages.lawSchoolJumpstartDesc} />
                )}
              </p>
              <a
                href={
                  authView
                    ? LIBRARY_PATH
                    : `${PROGRAM_DETAILS_PATH}?id=law-school-jumpstart`
                }
                className="secondary-btn"
              >
                {authView
                  ? messages.startPracticing.defaultMessage
                  : messages.learnMore.defaultMessage}
                <span className="sr-only">
                  {authView
                    ? messages.lsatPrepTest.defaultMessage
                    : messages.lawSchoolJumpstart.defaultMessage}
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ASOSubscriptionPage.propTypes = {
  authView: PropTypes.bool,
  hasPrepPlusSubscription: PropTypes.bool,
  subscribeToLawHub: PropTypes.func,
};

export default ASOSubscriptionPage;
