/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import './index.css';

function AboutLSAC() {
  return (
    <div id="aboutLSACDiv">
      <p>
        Our legal system can provide equal justice for all when it reflects the
        diversity of our society. LSAC seeks to advance law and justice by
        encouraging diverse, talented individuals to study law and by supporting
        their enrollment and learning journeys, from prelaw through practice.
      </p>
      <p>
        We created LawHub to be the place for anyone exploring or preparing for
        their own journey into the world of law. Our community is expanding to
        provide you with the information, products, and services you need to
        achieve your academic and professional goals.
      </p>
      <p>We’re happy to have you here!</p>
      <br />
      <p className="aboutLSACAllRights">
        All content &copy; {new Date().getFullYear()} Law School Admission
        Council, Inc. All rights reserved.
      </p>
      <p>
        <h2>Contact Us</h2>
        <span className="aboutLSACContact">
          <span>
            <i className="fas fa-envelope" /> <span>&nbsp;</span>
            <span>
              <a href="mailto:LSACinfo@LSAC.org">LSACInfo@LSAC.org</a>
            </span>
          </span>
          <br />
          <span>
            <i className="fas fa-phone" /> <span>&nbsp;</span>
            <span>
              <a href="tel:+18003363982">
                <span>1.800.336.3982</span>
              </a>
            </span>
          </span>
        </span>
      </p>
    </div>
  );
}

export default AboutLSAC;
