export const HighlightPadding = 'padding-top:0px;padding-bottom:0px';

/**
 * Highlight type enumeration that describes the color and properities of the highlight type
 * @category enumerations
 * @enum {number}
 */
const HighlightType = {
  None: 0,
  Erase: 1,
  Underline: 2,
  Orange: 3,
  Pink: 4,
  Yellow: 5,
  LineReference: 6,
  properties: {
    0: { name: 'None', value: 0, prefix: '', suffix: '' },
    1: { name: 'Erase', value: 1, prefix: '', suffix: '' },
    2: {
      name: 'Underline',
      value: 2,
      prefix: '<u>',
      suffix: '</u>',
    },
    3: {
      name: 'Orange',
      value: 3,
      prefix: `<span style="background-color:orange;display:inline;${HighlightPadding}">`,
      suffix: '</span>',
    },
    4: {
      name: 'Pink',
      value: 4,
      prefix: `<span style="background-color:pink;display:inline;${HighlightPadding}">`,
      suffix: '</span>',
    },
    5: {
      name: 'Yellow',
      value: 5,
      prefix: `<span style="background-color:yellow;display:inline;${HighlightPadding}">`,
      suffix: '</span>',
    },
    6: {
      name: 'LineReference',
      value: 6,
      prefix: `<span style="background-color:#6BADB9;display:inline;${HighlightPadding};text-decoration:underline">`,
      suffix: '</span>',
    },
  },
};

export { HighlightType };
