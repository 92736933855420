/**
 * Font size enumeration that describes the size of the fonts
 * @category enumerations
 * @enum {number}
 */
const FontSize = {
  /**
   * @type {number}
   * @description Small font size 16px
   */
  Small: 0,
  /**
   * @type {number}
   * @description Default font size 19px
   */
  Default: 1,
  /**
   * @type {number}
   * @description Large font size 22px
   */
  Large: 2,
  /**
   * @type {number}
   * @description Extra large font size 33px
   */
  ExtraLarge: 3,
  properties: {
    0: { name: 'Small', value: 0, px: 16, h: 16 },
    1: { name: 'Default', value: 1, px: 19, h: 19 },
    2: { name: 'Large', value: 2, px: 22, h: 22 },
    3: { name: 'Extra Large', value: 3, px: 33, h: 33 },
  },
};

export { FontSize };
