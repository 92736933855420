import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the proctorWritingPage state domain
 */

const selectProctorWritingPageDomain = state =>
  state.proctorWritingPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by proctorWritingPage
 */

const makeProctorWritingPage = () =>
  createSelector(
    selectProctorWritingPageDomain,
    substate => substate,
  );

/**
 * error selector
 */
const errorSelector = () =>
  createSelector(
    selectProctorWritingPageDomain,
    substate => substate.error,
  );

/**
 * initialize exam selector
 */
const initializeExamSelector = () =>
  createSelector(
    selectProctorWritingPageDomain,
    substate => ({
      reservationUrl: substate.reservationUrl,
    }),
  );

export default makeProctorWritingPage;
export { errorSelector, initializeExamSelector };
