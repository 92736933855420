import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WritingExamPage state domain
 */

const selectWritingExamPageDomain = state =>
  state.writingExamPage || initialState;
/**
 * Other specific selectors
 */

/**
 * Default selector used by WritingExamPage
 */

const makeWritingExamPage = () =>
  createSelector(
    selectWritingExamPageDomain,
    substate => substate,
  );

const isTokenSetSelector = () =>
  createSelector(
    selectWritingExamPageDomain,
    substate => substate.isTokenSet,
  );
const errorSelector = () =>
  createSelector(
    selectWritingExamPageDomain,
    substate => substate.error,
  );

export default makeWritingExamPage;
export { selectWritingExamPageDomain, isTokenSetSelector, errorSelector };
