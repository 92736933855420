import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import {
  HOME_PATH,
  UPGRADE_AST_PATH,
  LEARNING_COLLECTION_PATH,
  APPLICATION_ONLINE_STATUS_PAGE,
} from 'containers/App/constants';
import { userDetailsSelector } from 'domains/session/selectors';
import { AstAuthProfile } from './commonImages';
import ApplicationStatusTable from '../../components/ApplicationStatusTable';
import './index.css';
import { config } from '../../config';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import messages from './messages';
import { getUserId, extensionCandSeq } from '../../helpers/userHelper';
import {
  makeUserApplicationStatusOnlinePageSelector,
  applicationStatusOnlineErrorSelector,
  postNotificationErrorSelector,
  makeUserGetNotificationStatusSelector,
  getNotificationErrorSelector,
} from './selector';
import {
  getUserApplicationStatusAction,
  postApplicationNotificationStatusAction,
  getApplicationNotificationStatusAction,
} from './actions';
import saga from './saga';
import reducer from './reducer';
import CallToAction from '../../components/CallToAction';
import {
  getSortedApplicationList,
  getActiveSubscription,
} from '../../helpers/applicationStatusHelper';
import { pluralize } from '../../helpers/commonHelper';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import { reactPlugin } from '../TestSelectPage/testselect.util';
import ASOSubscriptionPage from './ASOSubscriptionPage';

const url = require('url');

const ASOAuthView = ({
  onGetUserApplicationStatusDetails,
  applicationStatusDetails,
  applicationStatusDetailsError,
  userDetails,
  onPostUserNotificationStatus,
  getNotificationStatusDetails,
  onGetUserNotificationStatus,
  intl,
}) => {
  useInjectReducer({ key: 'applicationDetails', reducer });
  useInjectSaga({ key: 'applicationDetails', saga });

  const location = useLocation();
  const history = useHistory();
  const {
    REACT_APP_IS_APPLICATION_SCHOOL_ONLINE_FEATURE_ENABLED,
    REACT_APP_IS_EMAIL_NOTIFICATION_FEATURE_ENABLED,
  } = config;
  const [isChecked, setIsChecked] = useState(
    getNotificationStatusDetails?.isChecked,
  );
  const [isUserHasApps, setIsUserHasApps] = useState(true);
  const listItems = !isNullOrUndefined(applicationStatusDetails?.items)
    ? getSortedApplicationList(applicationStatusDetails?.items)
    : {};
  const {
    hasPrepPlusSubscription,
    isExpiring,
    remainingDays,
  } = getActiveSubscription(userDetails?.subscriptionStatus);
  const remainingDaysAsText =
    remainingDays === 0 ? 'today' : `in ${pluralize(remainingDays, 'day')}`;

  useEffect(() => {
    let loadingTimer = null;
    if (REACT_APP_IS_APPLICATION_SCHOOL_ONLINE_FEATURE_ENABLED !== 'true') {
      history.push(HOME_PATH);
    }

    if (!isNullOrUndefined(extensionCandSeq?.value)) {
      onGetUserApplicationStatusDetails(extensionCandSeq?.value);
      onGetUserNotificationStatus(getUserId());
    } else {
      // if the user doesn't have extensionCandSeq then we are not hitting API but here we need to show the loader for some time for accessibility
      loadingTimer = setTimeout(() => setIsUserHasApps(false), 1000);
    }

    // In unAuth view by clicking on subscribe it will come here and check user has subscription or not, if user doesn't have then it will redirect to buy page
    const paramValues = url.parse(location.search, true).query;
    if (paramValues.subCheck) {
      // eslint-disable-next-line no-unused-expressions
      !hasPrepPlusSubscription
        ? history.push(UPGRADE_AST_PATH)
        : history.replace(APPLICATION_ONLINE_STATUS_PAGE);
    }

    return () => {
      clearTimeout(loadingTimer);
    };
  }, [getUserId()]);

  useEffect(() => {
    setIsChecked(getNotificationStatusDetails?.isChecked || false);
  }, [getNotificationStatusDetails]);

  useEffect(() => {
    if (
      applicationStatusDetails?.applicationLoaded &&
      getNotificationStatusDetails?.notificationLoaded
    ) {
      if (!listItems?.schools?.length > 0) {
        setIsUserHasApps(false);
      }
    }
  }, [applicationStatusDetails, getNotificationStatusDetails]);

  useEffect(() => {
    if (
      applicationStatusDetailsError &&
      Object.keys(applicationStatusDetailsError).length > 0
    ) {
      history.push(HOME_PATH);
    }
  }, [applicationStatusDetailsError]);

  const asoEmailNotificationButton = () => {
    const emailFeatureDisabled =
      REACT_APP_IS_EMAIL_NOTIFICATION_FEATURE_ENABLED === 'false';

    return (
      <>
        <input
          id="input"
          data-testid="emailNotification"
          type="checkbox"
          aria-checked={isChecked}
          checked={isChecked}
          onChange={() => handleOnChange()}
          disabled={emailFeatureDisabled}
        />
        <div className="toggle-text" data-testid="toggle-text">
          <FormattedMessage {...messages.emailNotifications} />
        </div>
        {emailFeatureDisabled && (
          <>
            <span className="sr-only">{messages.coming.defaultMessage}</span>
            <span className="soon-badge" data-testid="soon-badge">
              {messages.soon.defaultMessage}
            </span>
          </>
        )}
      </>
    );
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    onPostUserNotificationStatus(
      userDetails,
      extensionCandSeq?.value,
      !isChecked,
    );
  };

  const renewButtonClick = e => {
    e.preventDefault();
    history.push(UPGRADE_AST_PATH);
  };

  const learnMoreButtonClick = e => {
    e.preventDefault();
    history.push(`${LEARNING_COLLECTION_PATH}/ulsp23`);
  };

  const bannerMessages = () => {
    if (!isUserHasApps) {
      return {
        link: 'https://os.lsac.org/Logon/Access.aspx',
        headerMessage: messages.noAppsBannerHeader,
        bodyMessage: messages.noAppsBannerDesc,
        buttonMessage: messages.applyNow,
        opensInNewTab: true,
      };
    }

    if (!hasPrepPlusSubscription) {
      return {
        onAccept: renewButtonClick,
        headerMessage: messages.noSubscriptionBannerHeader,
        bodyMessage: messages.noSubscriptionBannerDesc,
        buttonMessage: messages.upgradeNow,
        isSuccessBtn: true,
      };
    }

    if (hasPrepPlusSubscription && isExpiring) {
      return {
        onAccept: renewButtonClick,
        headerMessage: messages.expiryWarningBannerHeading,
        headerValues: { remainingDaysAsText },
        bodyMessage: messages.expiryWarningHeaderText,
        buttonMessage: messages.renewNow,
      };
    }

    return {
      onAccept: learnMoreButtonClick,
      headerMessage: messages.promoBannerHeading,
      bodyMessage: messages.promoBannerBody,
      buttonMessage: messages.learnMore,
    };
  };

  if (!isUserHasApps || Object.keys(listItems).length > 0) {
    return (
      <div className="school-applications-container aso-unauth-container">
        <div className="layout-container header-profile-wrapper">
          <div className="central-column">
            <div className="header-wrapper">
              <div className="header-container">
                <h1 id="pageHeader">
                  <FormattedMessage {...messages.heading} />
                </h1>
                <p className="sub-title" data-testid="subtitle">
                  <FormattedMessage {...messages.headingHelperText} />
                </p>
                <p className="sub-title" data-testid="helperText">
                  <FormattedMessage {...messages.headingHelperText1} />
                </p>
                {isUserHasApps && hasPrepPlusSubscription && (
                  <div className="aso-email-notification">
                    <label>{asoEmailNotificationButton()}</label>
                  </div>
                )}
              </div>
              <div className="profile-container hide-sm">
                <img src={AstAuthProfile} alt="" className="profile-pic" />
              </div>
            </div>
          </div>
        </div>
        <div className="layout-container">
          <div className="central-column">
            <CallToAction
              hideCloseIcon
              intl={intl}
              isWhiteBg
              {...bannerMessages()}
            />
          </div>
        </div>
        {listItems?.schools ? (
          <div className="layout-container">
            <div className="central-column">
              <ApplicationStatusTable
                applicationList={listItems?.schools}
                hideContent={!hasPrepPlusSubscription}
              />
            </div>
          </div>
        ) : (
          <ASOSubscriptionPage
            subscribeToLawHub={renewButtonClick}
            hasPrepPlusSubscription={hasPrepPlusSubscription}
            authView
          />
        )}
      </div>
    );
  }

  return (
    <Container fluid style={{ width: '100vw' }}>
      <Row>
        <AriadneLoading text="Application Status Online Page" />
      </Row>
    </Container>
  );
};

ASOAuthView.propTypes = {
  applicationStatusDetails: PropTypes.object,
  applicationStatusDetailsError: PropTypes.object,
  onGetUserApplicationStatusDetails: PropTypes.func,
  userDetails: PropTypes.object,
  intl: intlShape.isRequired,
  postNotificationStatusError: PropTypes.object,
  getNotificationStatusDetails: PropTypes.object,
  onPostUserNotificationStatus: PropTypes.func,
  onGetUserNotificationStatus: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  applicationStatusDetails: makeUserApplicationStatusOnlinePageSelector(),
  applicationStatusDetailsError: applicationStatusOnlineErrorSelector(),
  userDetails: userDetailsSelector(),
  postNotificationStatusError: postNotificationErrorSelector(),
  getNotificationStatusDetails: makeUserGetNotificationStatusSelector(),
  getNotificationStatusError: getNotificationErrorSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onGetUserApplicationStatusDetails: candSeq =>
      dispatch(getUserApplicationStatusAction(candSeq)),

    onPostUserNotificationStatus: (userDetails, candSeq, isChecked) =>
      dispatch(
        postApplicationNotificationStatusAction(
          userDetails,
          candSeq,
          isChecked,
        ),
      ),
    onGetUserNotificationStatus: candSeq =>
      dispatch(getApplicationNotificationStatusAction(candSeq)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(
  withAITracking(
    reactPlugin,
    injectIntl(ASOAuthView),
    'ApplicationStatusOnlinePage',
  ),
);
