import { createSelector } from 'reselect';
import { SESSION_REDUX_NAME } from './hooks';
import { initialState } from './reducer';

/**
 * Direct selector to the appInitPage state domain
 */

const selectAthenaSessionDomain = state =>
  state[SESSION_REDUX_NAME] || initialState;

/**
 * initialize saga selector
 */
const initSagaLibrarySelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.initSagaLibrary,
  );

/**
 * retrieved user details selector
 */
const retrievedUserDetailsSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.retrievedUserDetails,
  );

/**
 *  user details selector
 */
const userDetailsSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.userDetails,
  );

/**
 * previous (stale) user details selector
 */
const prevUserDetailsSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.prevUserDetails,
  );

/**
 * error selector
 */
const errorSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.error,
  );

/**
 * isAppReady selector
 */
const isAppReadySelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.isAppReady,
  );

/**
 *  user settings selector
 */
const userSettingsSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate =>
      substate.userDetails && substate.userDetails.userSettings
        ? substate.userDetails.userSettings
        : null,
  );

/**
 * vendor data error selector
 */
const vendorDataErrorSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.vendorDataError,
  );

/**
 * Subscribe user selector
 */
const subscribeUserSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.subscribeUser,
  );

/**
 *  user vendor data selector
 */

const vendorDataSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.vendorData,
  );

/**
 * verification selector
 */
const verificationSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.verificationCode,
  );

/**
 *  user subscription selector
 */
const userSubscriptionSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate =>
      substate.userDetails && substate.userDetails.subscriptionStatus
        ? substate.userDetails.subscriptionStatus
        : null,
  );

/**
 *  email error selector
 */
const emailErrorSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.emailUpdateError,
  );

/**
 *  user updating email address selector
 */
const userNewEmailAddressSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.userNewEmailAddress,
  );

const taxInfoSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.taxInfo,
  );

/**
 * Returns an object with all fetched products.
 */
const productsSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.products,
  );

/**
 * Returns an object with the current login record
 */
const loginRecordSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.loginRecord,
  );

/**
 * Returns an object with the current login record error
 */
const loginRecordErrorSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.loginRecordError,
  );

/**
 * Returns an object with with the offer details.
 */
const offerSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.offerDetails,
  );

const offerErrorSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.offerError,
  );

const redeemedOfferSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.redeemedOffer,
  );

const paymentDetailsSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.paymentDetails,
  );

const paymentSuccessfulSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.paymentSuccessful,
  );

const paymentErrorSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.paymentError,
  );

const paymentInProgressSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.paymentInProgress,
  );

const showCallToActionSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.showCallToAction || {},
  );

const plusRegistrationCompleteSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.plusRegistrationComplete,
  );

const configDetailsSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.config,
  );

const configErrorSelector = () =>
  createSelector(
    selectAthenaSessionDomain,
    substate => substate.configError,
  );

export {
  selectAthenaSessionDomain,
  initSagaLibrarySelector,
  retrievedUserDetailsSelector,
  userDetailsSelector,
  prevUserDetailsSelector,
  errorSelector,
  isAppReadySelector,
  userSettingsSelector,
  vendorDataErrorSelector,
  subscribeUserSelector,
  vendorDataSelector,
  verificationSelector,
  userSubscriptionSelector,
  emailErrorSelector,
  userNewEmailAddressSelector,
  loginRecordSelector,
  loginRecordErrorSelector,
  offerSelector,
  offerErrorSelector,
  redeemedOfferSelector,
  taxInfoSelector,
  productsSelector,
  paymentDetailsSelector,
  paymentSuccessfulSelector,
  paymentErrorSelector,
  paymentInProgressSelector,
  showCallToActionSelector,
  plusRegistrationCompleteSelector,
  configDetailsSelector,
  configErrorSelector,
};
