import PropTypes from 'prop-types';

/**
 *
 * ProductModel
 *
 */

class ProductModel {
  constructor(props) {
    /**
     * @property {string} id Unique product id, required
     */
    this.id = props.id;

    /**
     * @property {string} description A description of the product
     */
    this.description = props.description;

    /**
     * @property {string} name The name of the product
     */
    this.name = props.name;

    /**
     * @property {string} owner The product owner
     * @example "LSAC"
     */
    this.owner = props.owner;

    /**
     * @property {string} price The product price
     */
    this.price = props.price.toString();

    /**
     * @property {string} productType The product type
     */
    this.productType = props.productType;

    /**
     * @property {string} activationDate The date string indicating when the product was activated
     */

    this.activationDate = props.activationDate;

    /**
     * @property {string} status The product status
     * @example "Inactive"
     */
    this.status = props.status;

    /**
     * @property {number} term
     */
    this.term = props.term;

    /**
     * @property {string} termFrequency How long the term is
     * @example "Yearly"
     */
    this.termFrequency = props.termFrequency;

    /**
     * @property {number} version The product version
     */
    this.version = props.version;

    /**
     * @property {number} content The content the product contains
     */
    this.content = props.content;
  }
}

ProductModel.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  activationDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  term: PropTypes.number.isRequired,
  termFrequency: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  content: PropTypes.array.isRequired,
};

export default ProductModel;
