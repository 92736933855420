import { defineMessages } from 'react-intl';
import { config } from '../../config';

export const scope = 'app.containers.TestCompleteFlexPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME} / Test Completion`,
  },
  writingHeader: {
    id: `${scope}.header`,
    defaultMessage: 'LSAT Writing / Test Completion',
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage: 'Congratulations! You have completed the test.',
  },
  heading2: {
    id: `${scope}.heading2`,
    defaultMessage: `Next steps regarding ${config.REACT_APP_LSAT_NAME}`,
  },
  heading3: {
    id: `${scope}.heading3`,
    defaultMessage: 'Acknowledgments',
  },
  bulletPoint1: {
    id: `${scope}.bulletPoint1`,
    defaultMessage:
      'Please <b>notify your proctor</b> that you have completed your test and rip up your scratch paper as directed.',
  },
  bulletPoint2: {
    id: `${scope}.bulletPoint2`,
    defaultMessage: 'You can expect to receive your score through email.',
  },
  bulletPoint3: {
    id: `${scope}.bulletPoint3`,
    defaultMessage:
      'Please remember to also complete your LSAT Writing as soon as possible, as most law schools will not consider your file complete until you have completed LSAT Writing.',
  },
  bulletPoint4: {
    id: `${scope}.bulletPoint4`,
    defaultMessage:
      'LSAT Writing was included with your test registration fee and is currently available in your online account.',
  },
  bulletPoint5: {
    id: `${scope}.bulletPoint5`,
    defaultMessage: `Should you decide to cancel your ${
      config.REACT_APP_LSAT_NAME
    } score, you must do so within six (6) calendar days of the date of the ${
      config.REACT_APP_LSAT_NAME
    }. For more information on the score cancellation process, visit the`,
  },
  link: {
    id: `${scope}.link`,
    defaultMessage:
      'https://www.lsac.org/lsat/taking-lsat/lsat-scoring/canceling-scores',
  },
  linkText: {
    id: `${scope}.linkText`,
    defaultMessage: 'Canceling Scores page of LSAC.org.',
  },
  buttonText: {
    id: `${scope}.buttonText`,
    defaultMessage: 'CLOSE the test',
  },
  saveText: {
    id: `${scope}.saveText`,
    defaultMessage: 'SAVE results locally',
  },
  finishTest: {
    id: `${scope}.finishTest`,
    defaultMessage: 'Finish',
  },
  logoText: {
    id: `${scope}.logoText`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME}`,
  },
  noAcks: {
    id: `${scope}.noAcks`,
    defaultMessage: 'No acknowledgements.',
  },
  openNewWindow: {
    id: `${scope}.openNewWindow`,
    defaultMessage: 'will open in a new browser window',
  },
  savingMessage: {
    id: `${scope}.savingMessage`,
    defaultMessage:
      'Please do not close the browser or navigate away from this screen.',
  },
  retryingMessage: {
    id: `${scope}.retryingMessage`,
    defaultMessage:
      'Oops! We seem to be experiencing a connectivity issue that is preventing us from closing out your test. Please do not close the browser or navigate away from this screen until the connection is restored and you see the Congratulations message indicating your test is complete.',
  },
  savingHeader: {
    id: `${scope}.savingHeader`,
    defaultMessage: 'Verifying test completion',
  },
  retryingHeader: {
    id: `${scope}.retryingHeader`,
    defaultMessage: "You're almost done!",
  },
  takeTooLongMessage: {
    id: `${scope}.takeTooLongMessage`,
    defaultMessage:
      "Your responses have been recorded, but a connection problem is preventing us from submitting them for you. Please click the button below to save your responses, then contact LSAC Test Security at <a href='mailto: TestSecurity@LSAC.org'>TestSecurity@LSAC.org</a> for help submitting them manually.",
  },
  takeTooLongMessagePM: {
    id: `${scope}.takeTooLongMessagePM`,
    defaultMessage:
      'A connection problem is preventing your responses from being submitted to LSAC.  You will receive final instructions on how to submit your answers and end your test session as soon as the connection problem has been resolved. If the connection is not re-established within 30 minutes, contact your proctor or test center administrator for further instructions.',
  },
});
