/**
 *
 * common helper
 *
 */

export function pluralize(count, noun, suffix = 's') {
  return `${count} ${noun}${parseInt(count, 10) !== 1 ? suffix : ''}`;
}

export function sortArrayOfObjectsByKey(inputObject, key, order = 'asc') {
  return inputObject.sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    // handling undefined key value pairs
    if (valueA === undefined && valueB === undefined) return 0;
    if (valueA === undefined) return order === 'asc' ? 1 : -1;
    if (valueB === undefined) return order === 'asc' ? -1 : 1;

    if (order === 'desc') {
      if (valueA > valueB) return -1;
      if (valueA < valueB) return 1;
      return 0;
    }
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });
}
