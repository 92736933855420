import { Modal } from 'reactstrap';
import { isNullOrUndefined } from 'util';

export class AccessibleModal extends Modal {
  onOpened() {
    super.onOpened();

    const modalRoot = document.getElementsByClassName(
      this.props.modalClassName,
    )[0];
    if (modalRoot) {
      modalRoot.setAttribute('aria-modal', 'true');
      if (this.props.describedBy) {
        modalRoot.setAttribute('aria-describedby', this.props.describedBy);
        modalRoot.children[0].removeAttribute('describedBy');
      }
    }

    const appDivElement = document.getElementById('app');
    const modalContentElements = document.getElementsByClassName(
      'modal-content',
    );
    if (!isNullOrUndefined(appDivElement)) {
      appDivElement.setAttribute('aria-hidden', 'true');
    }
    // After modal opened, finds first button element in modal and sets focus to it
    if (modalContentElements.length > 0) {
      const element = modalContentElements[0];
      const buttons = element.getElementsByTagName('button');
      if (!isNullOrUndefined(buttons)) {
        buttons[0].focus();
      }
    }
  }

  onClosed() {
    super.onClosed();
    const appDivElement = document.getElementById('app');
    if (!isNullOrUndefined(appDivElement)) {
      appDivElement.removeAttribute('aria-hidden');
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const appDivElement = document.getElementById('app');
    if (!isNullOrUndefined(appDivElement)) {
      appDivElement.removeAttribute('aria-hidden');
    }
  }
}
