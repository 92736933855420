/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import ExternalLinkIcon from 'images/external_link_outward_rebrand.svg';
import './index.css';
import history from '../../utils/history';
import messages from './messages';

const ToolCard = ({
  header,
  description,
  linkText,
  linkUrl,
  openInNewTab,
  unAuthViewFlag,
  screenReaderText,
  headerTag,
}) => {
  const Header = headerTag === 'h2' ? 'h2' : 'h3';
  const renderActionButton = () => {
    if (openInNewTab) {
      return (
        <a
          href={linkUrl}
          target="_blank"
          className="tool-link"
          title={messages.opensInNewWindow.defaultMessage}
        >
          {linkText}
          <img
            src={ExternalLinkIcon}
            alt={messages.opensInNewWindow.defaultMessage}
          />
        </a>
      );
    }

    return (
      <button
        type="button"
        onClick={() => history.push(linkUrl)}
        className="tool-link"
      >
        {linkText}
        {screenReaderText && (
          <span className="sr-only">{screenReaderText}</span>
        )}
      </button>
    );
  };

  return (
    <div className="tools-card-wrapper">
      <div className="tool-card-body">
        <Header className="tools-card-header">{header}</Header>
        <p className="tools-card-desc">{description}</p>
      </div>
      {!unAuthViewFlag && (
        <div className="tool-card-footer">{renderActionButton()}</div>
      )}
    </div>
  );
};

ToolCard.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.any,
  openInNewTab: PropTypes.bool,
  unAuthViewFlag: PropTypes.bool,
  screenReaderText: PropTypes.string,
  headerTag: PropTypes.string,
};

ToolCard.defaultProps = {
  openInNewTab: true,
  unAuthViewFlag: false,
};

export default ToolCard;
