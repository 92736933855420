/**
 *
 * Application online status page
 *
 */

import React from 'react';
import { intlShape } from 'react-intl';
import './index.css';
import { getUser } from '../../authProvider';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import ASOUnAuthView from './ASOUnAuthView';
import ASOAuthView from './ASOAuthView';
import { TITLE_APPLICATION_STATUS_ONLINE_PAGE } from '../MasterPage/constants';

export const ApplicationStatusOnlinePage = ({ intl }) => {
  const unAuthViewFlag = isNullOrUndefined(getUser());
  document.title = TITLE_APPLICATION_STATUS_ONLINE_PAGE;

  return unAuthViewFlag ? <ASOUnAuthView /> : <ASOAuthView intl={intl} />;
};

ApplicationStatusOnlinePage.propTypes = {
  intl: intlShape,
};

export default ApplicationStatusOnlinePage;
