import PropTypes from 'prop-types';
import { SectionType } from 'enumerations/SectionType';
import ItemModel from './itemModel';
import StemModel from './stemModel';
import StimulusModel from './stimulusModel';

/**
 * The section model
 * @class
 */

class SectionModel {
  constructor() {
    /**
     * @property {number} currentItemIndex The current item index
     */
    this.currentItemIndex = 0;

    /**
     * @property {SectionType} sectionType The section type
     */
    this.sectionType = SectionType.LogicalReasoning;

    /**
     * @property {number} sectionOrder The section order
     */
    this.sectionOrder = 0;

    /**
     * @property {string} sectionName The section name
     */
    this.sectionName = '';

    /**
     * @property {array<ItemModel>} items An array of items
     */
    this.items = null;

    /**
     * @property {StimulusModel} stimulus The stimulus
     */
    this.stimulus = null;

    /**
     * @property {array<StemModel>} stems An array of stems
     */
    this.stems = null;

    /**
     * @property {string} directions The directions text
     */
    this.directions = null;

    /**
     * @property {string} acknowledgements The acknowledgements text
     */
    this.acknowledgements = null;

    /**
     * @property {string} title The title
     */
    this.title = null;

    /**
     * @property {string} formId The form id
     */
    this.formId = null;

    /**
     * @property {number} totalSectionTime The total section time
     */
    this.totalSectionTime = 0;

    /**
     * @property {number} timeRemaining The time remaining
     */
    this.timeRemaining = 0;

    /**
     * @property {number} totalAnalysisSectionTime The total time of the writing analysis section
     */
    this.totalAnalysisSectionTime = 0;

    /**
     * @property {number} analysisTimeRemaining The remaining time of the writing analysis section
     */
    this.analysisTimeRemaining = 0;

    /**
     * @property {number} numberOfQuestions The number of questions
     */
    this.numberOfQuestions = 0;

    /**
     * @property {bool} passageSection Determines if passage section
     */
    this.passageSection = false;

    /**
     * @property {bool} hasGroupItems Determines if section has group items
     */
    this.hasGroupItems = false;

    /**
     * @property {bool} isTimeRemainingVisible Determines if the time remaining is visible
     */
    this.isTimeRemainingVisible = true;

    /**
     * @property {bool} isCompleted Determines if the section is completed
     */
    this.isCompleted = false;

    /**
     * @property {bool} isPaused Determines if the section is paused
     */
    this.isPaused = false;

    /**
     * @property {bool} pagePaused Determines if the page is paused
     */
    this.pagePaused = null;

    /**
     * @property {bool} isVariableSection Determines if the section is a variable section (also called the experimental section)
     */
    this.isVariableSection = false;

    /**
     * @property {number} elapsed The time elapsed
     */
    this.elapsedTime = 0;
  }
}

SectionModel.propTypes = {
  currentItemIndex: PropTypes.number,
  sectionType: PropTypes.objectOf(
    SectionType.LogicalReasoning,
    SectionType.AnalyticalReasoning,
    SectionType.ReadingComprehension,
    SectionType.WritingSample,
  ),
  sectionOrder: PropTypes.number,
  hasGroupItems: PropTypes.bool,
  sectionName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.instanceOf(ItemModel)).isRequired,
  stimulus: PropTypes.arrayOf(PropTypes.instanceOf(StimulusModel)).isRequired,
  directions: PropTypes.string,
  stems: PropTypes.arrayOf(PropTypes.instanceOf(StemModel)).isRequired,
  acknowledgements: PropTypes.string,
  title: PropTypes.string,
  formId: PropTypes.stirng,
  totalSectionTime: PropTypes.number,
  timeRemaining: PropTypes.number,
  numberOfQuestions: PropTypes.number.isRequired,
  passageSection: PropTypes.bool,
  isTimeRemainingVisible: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isPaused: PropTypes.bool,
  pagePaused: PropTypes.string,
  isVariableSection: PropTypes.bool,
  elapsedTime: PropTypes.number,
};

export default SectionModel;
