/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */

/**
 *
 * CardsSlider
 *
 */

import React, { useRef, useEffect, useLayoutEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, intlShape } from 'react-intl';
import { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import BackArrow from '../../images/left-chevron.svg';
import ForwardArrow from '../../images/right-chevron.svg';
import messages from './messages';
import { isXAxisMostlyVisible } from '../../utils/isElemInView';
import 'swiper/swiper.min.css';
import { validCardTypes } from './cardTypes';
import { slugify } from '../../utils/slugify';
import './index.css';

/**
 * Last card in the slider; links out to the specified page
 *
 * @param {number} slideHeight Height in pixels the card should be
 * @param {string} path location of page with more cards
 */

const ViewMoreCard = forwardRef(({ path }, ref) => (
  <Link to={path} className="view-more-card" ref={ref}>
    <div>
      <FormattedMessage {...messages.viewMoreLink} />
    </div>
  </Link>
));

ViewMoreCard.propTypes = {
  slideHeight: PropTypes.number,
  path: PropTypes.string,
};

/**
 * The CardsSlider
 *
 * @param {Object[]} cards Array of card objects
 * @param {React.FC} CardComponent The card component
 * @param {string} cardType Singluar type of card, e.g., "event"
 * @param {string} sliderHeading The heading for the slider
 * @param {string} viewMorePath Path to page with more cards
 * @param {bool} viewMoreFlag Controls whether to diplay the view more links
 * @param {string} cardFocusSelector CSS class indicating the focusable card element
 * @param {string} sliderContainerSelector CSS class indicating the slider container
 * @param {Object} windowSize Object containing the window height and width
 * @param {intlShape} intl The internalization object
 *
 */

const CardsSlider = ({
  cards,
  CardComponent,
  cardType,
  sliderHeading,
  viewMorePath,
  viewMoreLinkFlag,
  cardFocusSelector,
  sliderContainerSelector,
  windowSize,
  intl,
}) => {
  // Set the sliderContainerElRef on mount
  useEffect(() => {
    sliderContainerElRef.current = document.querySelector(
      sliderContainerSelector,
    );
  }, []);

  // The useLayoutEffect hook is used to manipulate the height of
  // cards in a slider by setting their minimum height to the maximum
  // height of the tallest card in the slider. It is also does this for the
  // "View More" link card.
  useLayoutEffect(() => {
    const cardArray = Array.from(document.querySelectorAll(cardFocusSelector));
    const cardHeights = cardArray.map(card => card.clientHeight);
    const maxCardHeight = Math.max(...cardHeights);
    cardArray.forEach(card => {
      // eslint-disable-next-line no-param-reassign
      card.style.minHeight = `${maxCardHeight}px`;
    });
    if (viewMoreCardRef.current?.style) {
      viewMoreCardRef.current.style.height = `${maxCardHeight}px`;
    }
  });

  // Booleans for event keyCodes used for navigation of the slider
  const isTabKey = event => event.keyCode === 9;
  const isShiftTabKeyCombo = event => event.shiftKey && event.keyCode === 9;

  // Number of milliseconds to delay slide focus
  const slideFocusDelay = 300;
  // Dynamically change for required card component prop
  // Detect an empty cards array
  const areNoCards = cards?.length === 0;

  // Refs to identify nav buttons and slides for programmatic focus
  const backArrowRef = useRef();
  const forwardArrowRef = useRef();
  const slideRefs = useRef([]);
  const viewMoreCardRef = useRef();
  const viewAllLinkRef = useRef();
  const sliderContainerElRef = useRef();
  const isDesktopPresentation = windowSize.width > 768;

  // Function to find the first whole slide in the slide array.
  // Async configurable because we need to wait for swiper to update after scroll
  // transition during page changes
  const focusFirstWholeSlideAsync = (delay = 0) => {
    if (slideRefs.current) {
      setTimeout(() => {
        const firstVisibleSlide = slideRefs.current
          .find(slide =>
            isXAxisMostlyVisible(sliderContainerElRef.current, slide),
          )
          ?.querySelector(cardFocusSelector);

        firstVisibleSlide?.focus();
      }, delay);
    }
  };

  // Function to find the last whole slide in the slide array.
  // Async for the same reasons
  const focusLastWholeSlideAsync = (delay = 0) => {
    if (slideRefs.current) {
      setTimeout(() => {
        let lastVisibleSlide;

        // eslint-disable-next-line no-plusplus
        for (let i = slideRefs.current.length - 1; i >= 0; i--) {
          if (
            slideRefs.current[i] &&
            isXAxisMostlyVisible(
              sliderContainerElRef.current,
              slideRefs.current[i],
            )
          ) {
            lastVisibleSlide = slideRefs.current[i]?.querySelector(
              cardFocusSelector,
            );
            break;
          }
        }

        lastVisibleSlide?.focus();
      }, delay);
    }
  };

  const focusViewMoreCard = (delay = 0) => {
    if (viewMoreCardRef?.current) {
      setTimeout(() => viewMoreCardRef.current.focus(), delay);
    }
  };

  // Event handlers for backward and forward nav buttons
  const handleForwardButtonClick = () => {
    focusFirstWholeSlideAsync(slideFocusDelay);
  };
  const handleBackwardButtonClick = () => {
    focusFirstWholeSlideAsync(slideFocusDelay);
  };

  const handleForwardViewAllLinkKeyDown = event => {
    if (
      isTabKey(event) &&
      !isShiftTabKeyCombo(event) &&
      !backArrowRef.current?.disabled &&
      !areNoCards &&
      isDesktopPresentation
    ) {
      event.preventDefault();
      focusFirstWholeSlideAsync();
    }
  };

  // Event hander for tabbing off of slides
  const handleSlideKeyDown = (event, index) => {
    const previousSlide = slideRefs?.current?.[index - 1];
    const nextSlide = slideRefs?.current?.[index + 1];

    // Only use for desktop presentation with backward and forward
    // nav buttons
    if (isDesktopPresentation) {
      // Back tabbing; if prev slide is partial, focus on backward
      // nav button
      if (
        isShiftTabKeyCombo(event) &&
        previousSlide &&
        !isXAxisMostlyVisible(sliderContainerElRef.current, previousSlide)
      ) {
        event.preventDefault();
        viewAllLinkRef?.current?.focus();
      }
      // Forward tabbing; if next slide is partial, focus on foward
      // nav button
      else if (
        isTabKey(event) &&
        !isShiftTabKeyCombo(event) &&
        nextSlide &&
        !isXAxisMostlyVisible(sliderContainerElRef.current, nextSlide)
      ) {
        event.preventDefault();
        if (!backArrowRef.current?.disabled) {
          backArrowRef.current?.focus();
        } else {
          forwardArrowRef?.current.focus();
        }
      }
    }
  };

  // Event handler for tabbing off of backward nav button
  const handleBackwardButtonKeyDown = event => {
    const isViewMoreCardVisible =
      viewMoreCardRef?.current &&
      isXAxisMostlyVisible(
        sliderContainerElRef.current,
        viewMoreCardRef.current,
      );
    if (isShiftTabKeyCombo(event)) {
      event.preventDefault();
      // if viewMoreCard is the last, whole, visible card before
      // the enabled forward nav button, then focus it
      if (isViewMoreCardVisible) {
        focusViewMoreCard();
      }
      // else focus on the last, whole, visible event card
      else {
        focusLastWholeSlideAsync();
      }
    }
  };

  // Event handler for tabbing off of forard nav button
  const handleForwardButtonKeyDown = event => {
    const isBackDisabled = backArrowRef.current.disabled;
    const isViewMoreCardVisible =
      viewMoreCardRef?.current &&
      isXAxisMostlyVisible(
        sliderContainerElRef.current,
        viewMoreCardRef.current,
      );
    if (isShiftTabKeyCombo(event) && isBackDisabled) {
      event.preventDefault();
      // if viewMoreCard is the last, whole, visible card before
      // the enabled forward nav button, then focus it
      if (isViewMoreCardVisible) {
        focusViewMoreCard();
      }
      // else focus on the last, whole, visible event card
      else {
        focusLastWholeSlideAsync();
      }
    }
  };

  const renderNoDataMsg = () => {
    let message = messages.noCardDataGeneric;
    if (cardType === validCardTypes.event) {
      message = messages.noEventsCard;
    }
    if (cardType === validCardTypes.course) {
      message = messages.noCoursesCard;
    }
    if (cardType === validCardTypes.podcast) {
      message = messages.noPodcastsCard;
    }
    if (cardType === validCardTypes.toolsExplore) {
      message = messages.noPodcastsCard;
    }
    return (
      <div data-testid="no-data-msg" className="no-data">
        {intl.formatMessage({ ...message })}
      </div>
    );
  };
  const cardIdTypes = {
    event: 'eventId',
    course: 'courseId',
    podcast: 'episodeId',
  };
  const cardIdType = cardIdTypes[cardType];

  const getCardId = (card, index) =>
    card?.[cardIdType]
      ? card?.[cardIdType]
      : JSON.stringify(`${cardType}${index}`);

  const labelForNavButton =
    cardType === validCardTypes.podcast ? slugify(sliderHeading) : cardType;

  // Render the Swiper slider element
  const renderSlider = () => (
    // Use provided id in format of 'eventId' or use fallback
    <div className="slider-layout">
      <Swiper
        modules={[A11y, Navigation]}
        a11y={{
          prevSlideMessage: intl.formatMessage(
            {
              ...messages.previousNavButtonScrollBackText,
            },
            { sliderHeading },
          ),
          nextSlideMessage: intl.formatMessage(
            {
              ...messages.previousNavButtonScrollForwardText,
            },
            { sliderHeading },
          ),
          slideLabelMessage: `${sliderHeading} Card {{index}} of {{slidesLength}}`,
        }}
        navigation={{
          nextEl: `.swiper-button-next-${labelForNavButton}-slider`,
          prevEl: `.swiper-button-prev-${labelForNavButton}-slider`,
        }}
        onAfterInit={swiper => {
          swiper.wrapperEl.removeAttribute('aria-live');
        }}
        slidesPerView="auto"
        slidesPerGroupAuto
        wrapperTag="ul"
        breakpoints={{
          0: {
            spaceBetween: 16,
          },
          451: {
            spaceBetween: 24,
          },
          769: {
            spaceBetween: 20,
          },
        }}
      >
        {cards.map((card, index) => (
          <SwiperSlide
            tabIndex="-1"
            key={getCardId(card, index)}
            data-testid="swiper-slide"
            tag="li"
          >
            <div
              ref={el => {
                slideRefs.current[index] = el;
              }}
              onKeyDown={e => handleSlideKeyDown(e, index)}
            >
              <CardComponent {...card} cardId={getCardId(card, index)} />
            </div>
          </SwiperSlide>
        ))}
        {viewMoreLinkFlag && (
          <SwiperSlide tabIndex="-1" data-testid="swiper-slide" tag="li">
            <div
              ref={el => {
                slideRefs.current[cards.length] = el;
              }}
              onKeyDown={e => handleSlideKeyDown(e, slideRefs.current.length)}
            >
              <ViewMoreCard path={viewMorePath} ref={viewMoreCardRef} />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );

  return (
    <div className="cards-slider-layout">
      <div className="cards-slider-container">
        <div className="heading-bar">
          <div className="group-one">
            <h2>
              <FormattedMessage
                {...messages.header}
                values={{ heading: sliderHeading }}
              />
            </h2>
            {viewMorePath && (
              <Link
                to={viewMorePath}
                onKeyDown={handleForwardViewAllLinkKeyDown}
                className="view-all-link"
                ref={viewAllLinkRef}
                title={intl.formatMessage(
                  { ...messages.viewAllLinkTitleText },
                  { sliderHeading },
                )}
              >
                <FormattedMessage {...messages.viewAllLink} />
              </Link>
            )}
          </div>
        </div>
        <div>{areNoCards ? renderNoDataMsg() : renderSlider()}</div>
        {!areNoCards && (
          <div className="group-two">
            <button
              type="button"
              className={`swiper-button-prev-${labelForNavButton}-slider prev-button`}
              title={intl.formatMessage(
                {
                  ...messages.previousNavButtonScrollBackTextTwoLines,
                },
                { sliderHeading },
              )}
              ref={backArrowRef}
              onKeyDown={handleBackwardButtonKeyDown}
              onClick={handleBackwardButtonClick}
            >
              <img
                src={BackArrow}
                alt={intl.formatMessage(
                  {
                    ...messages.previousNavButtonScrollBackText,
                  },
                  { sliderHeading },
                )}
              />
            </button>
            <button
              type="button"
              className={`swiper-button-next-${labelForNavButton}-slider next-button`}
              title={intl.formatMessage(
                {
                  ...messages.previousNavButtonScrollForwardTextTwoLines,
                },
                { sliderHeading },
              )}
              ref={forwardArrowRef}
              onKeyDown={handleForwardButtonKeyDown}
              onClick={handleForwardButtonClick}
            >
              <img
                src={ForwardArrow}
                alt={intl.formatMessage(
                  {
                    ...messages.previousNavButtonScrollForwardText,
                  },
                  { sliderHeading },
                )}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

CardsSlider.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  CardComponent: PropTypes.func,
  cardType: PropTypes.string,
  sliderHeading: PropTypes.string,
  cardFocusSelector: PropTypes.string,
  sliderContainerSelector: PropTypes.string,
  viewMorePath: PropTypes.string,
  viewMoreLinkFlag: PropTypes.bool,
  windowSize: PropTypes.object,
  intl: intlShape,
};

CardsSlider.defaultProps = {
  cards: [],
  CardComponent: () => <React.Fragment />,
  cardType: '',
  sliderHeading: 'Heading',
  cardFocusSelector: '.swiper-slide div[tabindex="0"]',
  sliderContainerSelector: '.swiper',
  viewMoreLinkFlag: false,
  windowSize: {
    width: 600,
    height: 1000,
  },
};

export default CardsSlider;
