/* eslint-disable indent */
/**
 *
 * SectionSelect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { withRouter } from 'react-router';
import { isNullOrUndefined } from 'util';
import * as moduleHelper from 'helpers/moduleHelper';
import SectionDetails from '../SectionDetails';
import { ExamMode } from '../../enumerations/ExamMode';
import './index.css';
import {
  getTotalAnsweredInSection,
  isRealExam,
} from '../../helpers/moduleHelper';

function SectionSelect({ module, testInstanceId, examMode }) {
  const { sections } = module;
  const allowSectionSelect =
    isNullOrUndefined(module?.options?.allowSectionSelect) ||
    module?.options?.allowSectionSelect;
  const showCorrectAnswers =
    isNullOrUndefined(module?.options?.showCorrectAnswers) ||
    module?.options?.showCorrectAnswers;

  return (
    <ul className="sectionContainer">
      {sections !== undefined &&
        sections.map((section, secIndex) => {
          const isWritingSection =
            moduleHelper.isWritingSection(section) ||
            moduleHelper.isScoredWritingSection(section);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li className="sectionItem" key={`${section.title}`}>
              {!isNullOrUndefined(section.items) &&
                Array.isArray(section.items) && (
                  <SectionDetails
                    sectionName={section.title}
                    isWritingSection={isWritingSection}
                    moduleId={testInstanceId}
                    totalAnswered={getTotalAnsweredInSection(section)}
                    totalQuestions={section.numberOfQuestions}
                    isCompleted={section.isCompleted}
                    totalCorrect={0}
                    index={secIndex}
                    showCorrectAns={false}
                    isExamMode={examMode === ExamMode.ExamTime}
                    isRealExam={isRealExam(module)}
                    currentActive={
                      examMode === ExamMode.ExamTime
                        ? secIndex ===
                          sections.findIndex(s => s.isCompleted === false)
                        : true
                    }
                    allowSectionSelect={allowSectionSelect}
                    showTotalCorrect={showCorrectAnswers}
                    isPaused={section.isPaused}
                    pagePaused={section.pagePaused}
                  />
                )}
            </li>
          );
        })}
    </ul>
  );
}

SectionSelect.propTypes = {
  module: PropTypes.object,
  testInstanceId: PropTypes.string,
  examMode: PropTypes.number,
};

export default withRouter(SectionSelect);
