/**
 *
 * ApolloContainer
 *
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './index.css';
import { persistenceState } from '../../helpers/persistence/common/persistenceState';
import { isRealExam } from '../../helpers/moduleHelper';
import {
  getInstanceById,
  removeTestFromLocal,
  saveTestToLocal,
} from '../../helpers/persistence/common/repoHelper';
import { flexExamsSelector } from '../../containers/TestSelectPage/selectors';
import { ModuleType } from '../../enumerations/ModuleType';
import useThrottledResizeObserver from '../../utils/useThrottledResizeObserver';
function ApolloContainer({
  children,
  setApolloContainerLoaded,
  onHeightChange,
  childClassName,
}) {
  const containerRef = useRef(null);
  const containerRefDrillSet = useRef(null);
  const apolloContainerId = 'ApolloContainer';
  const apolloInnerContainerId = 'ApolloContainerInner';
  const isDrillSet =
    persistenceState?.module?.moduleType === ModuleType.DrillSet;
  const isAdditionalPracticeSet =
    persistenceState?.module?.moduleType === ModuleType.DrillSetAdditional;

  useThrottledResizeObserver(
    isDrillSet || isAdditionalPracticeSet ? containerRefDrillSet : containerRef,
    newHeight => {
      onHeightChange?.(newHeight);
    },
  );

  useEffect(() => {
    const flexBoxContainer = document.getElementById(
      'parent-flexbox-container',
    );

    // Save current test instance to local storage if this is prep exam
    const instance = getInstanceById(persistenceState.testInstanceId);
    if (instance) {
      if (!isRealExam(instance.module)) {
        saveTestToLocal(persistenceState.testInstanceId);
        flexBoxContainer?.classList.add('exam-flexbox-container');
      }
    }
    if (setApolloContainerLoaded) {
      setApolloContainerLoaded(true);
    }

    // The beforeunload event is triggered when the tab/window is about to be unloaded/closed.
    const handleTabClose = () => {
      removeTestFromLocal(persistenceState.testInstanceId);
    };
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      // Remove current test instance from local storage once exam is not
      // being undertaken (pause or completed)
      removeTestFromLocal(persistenceState.testInstanceId);
      window.removeEventListener('beforeunload', handleTabClose);
      if (flexBoxContainer) {
        flexBoxContainer.classList.remove('exam-flexbox-container');
      }
    };
  }, []);

  return isDrillSet || isAdditionalPracticeSet ? (
    <div
      id={apolloContainerId}
      className={`DrillSetApolloContainer ${childClassName}`}
    >
      <div
        id={apolloInnerContainerId}
        className="DrillSetApolloContainerInner"
        ref={containerRefDrillSet}
      >
        {children}
      </div>
    </div>
  ) : (
    <div id={apolloContainerId} className="ApolloContainer">
      <div
        id={apolloInnerContainerId}
        className="ApolloContainerInner"
        ref={containerRef}
      >
        {children}
      </div>
    </div>
  );
}

/**
 *
 * The zoom box prop type validation
 *
 */
ApolloContainer.propTypes = {
  children: PropTypes.element,
  setApolloContainerLoaded: PropTypes.func,
  onHeightChange: PropTypes.func,
  childClassName: PropTypes.string,
};

ApolloContainer.defaultProps = {
  childClassName: '',
};

const mapStateToProps = createStructuredSelector({
  exams: flexExamsSelector(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(injectIntl(ApolloContainer));
