/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/*
 *
 * TestSelectPage saga
 *
 */
import { push } from 'react-router-redux';
import { takeLatest, call, put } from 'redux-saga/effects';
import { getNonAuthApi, getApi, verifyExistingUser } from 'helpers/sagaHelper';
import { ExamMode } from 'enumerations/ExamMode';
import { replace } from 'connected-react-router';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import { LogLevelType } from '../../enumerations/LogLevelType';
import {
  BROWSER_ERROR_PATH,
  DIRECTION_PATH,
  EXAM_MODULE_PATH,
  MODULE_PATH,
} from '../App/constants';
import {
  GET_LIBRARY,
  GET_FLEX_EXAM_LIST,
  PUT_INSTANCE_STATE,
  GET_TEST_INSTANCE_STATUS,
  POST_EXAM_LAUNCH_DETAILS,
} from './constants';
import {
  GET_NEW_INSTANCE,
  GET_EXISTING_INSTANCE,
  GET_FLEX_INSTANCE,
} from '../DirectionsPage/constants';
import {
  getLibrarySuccessAction,
  getLibraryErrorAction,
  initializeSagaLibraryAction,
  putInstanceStateErrorAction,
  putInstanceStateSuccessAction,
  getFlexExamListSuccessAction,
  getFlexExamListErrorAction,
  storeExamPasswordHashAction,
  getTestInstanceStatusSuccessAction,
  getTestInstanceStatusErrorAction,
  updateLaunchDetailsSuccessAction,
  updateLaunchDetailsErrorAction,
} from './actions';
import {
  getNewInstanceSuccessAction,
  getNewInstanceFailureAction,
  getExistingInstanceSuccessAction,
  getExistingInstanceFailureAction,
} from '../DirectionsPage/actions';
import { config } from '../../config';
import { setUpExamOptions } from '../../helpers/examHelper';
import { persistenceGet } from '../../helpers/persistence/persistenceManagementHelper';
import { mergeModuleState } from '../../helpers/persistence/examManagementHelper';
import { getInitialState } from '../../helpers/persistence/instanceStateManagementHelper';
import {
  getNonAuthAPIFullPath,
  getAPIFullPath,
} from '../../helpers/configHelper';
import * as logHelper from '../../helpers/logHelper';
import { getBrowserName } from '../../helpers/browserHelper';
import handleAPIErrorAndRetry from '../../helpers/apiResponseHelper';
import { ModuleType } from '../../enumerations/ModuleType';

const util = require('util');

export function* getTestInstanceStatus(data) {
  const { userId, testInstanceId } = data;
  const apiEndURL = `${util.format(
    config.REACT_APP_GET_TEST_INSTANCE_STATUS_URL,
    userId,
    testInstanceId,
  )}`;
  const requestURL = `${getAPIFullPath(apiEndURL)}`;
  const api = () => getApi('get', requestURL);

  function* successCallback(repoData) {
    yield put(getTestInstanceStatusSuccessAction(repoData));
  }
  function* failureCallback(error) {
    yield put(getTestInstanceStatusErrorAction(error));
  }

  try {
    yield call(verifyExistingUser);
    const repos = yield call(api);
    // send the result back to the reducer
    yield put(getTestInstanceStatusSuccessAction(repos.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * getLibraryAPI data function to retrieve data
 */
export function getLibraryAPI(data) {
  const { userId } = data;
  const apiEndURL = `${util.format(config.REACT_APP_GET_LIBRARY_URL, userId)}`;

  const unAuthapiEndURL = `${util.format(
    config.REACT_APP_GET_UNAUTH_LIBRARY_URL,
  )}`;
  const userFlag = isNullOrUndefined(userId);

  return userFlag
    ? getNonAuthApi('get', getNonAuthAPIFullPath(unAuthapiEndURL))
    : getApi('get', getAPIFullPath(apiEndURL));
}

/**
 * getSection data function to retrieve data
 */
export function* getLibraryData(data) {
  function* successCallback(repoData) {
    yield put(getLibrarySuccessAction(repoData));
  }
  function* failureCallback(error) {
    yield put(getLibraryErrorAction(error));
  }

  // Perform the http call
  try {
    yield call(verifyExistingUser);
    const repos = yield call(getLibraryAPI, data);
    // send the result back to the reducer
    yield put(getLibrarySuccessAction(repos.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(
      err,
      getLibraryAPI,
      successCallback,
      failureCallback,
      { apiData: data },
    );
  }
}

/**
 * data function to retrieve list of flex exams
 */
export function* getFlexExamList(data) {
  function* successCallback(repoData) {
    yield put(getFlexExamListSuccessAction(repoData));
  }
  function* failureCallback(error) {
    yield put(getFlexExamListErrorAction(error));
  }

  const { userId, examType } = data;
  let apiEndURL = `${util.format(config.REACT_APP_GET_EXAMS_URL, userId)}`;
  if (!isNullOrUndefined(examType)) {
    apiEndURL = `${apiEndURL}?examType=${examType}`;
  }
  const requestURL = `${getAPIFullPath(apiEndURL)}`;
  const api = () => getApi('get', requestURL);

  // Perform the http call
  try {
    yield call(verifyExistingUser);
    const repos = yield call(api);
    // send the result back to the reducer
    yield put(getFlexExamListSuccessAction(repos.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * get module data function
 */
export function* getFlexInstance(data) {
  function* successCallback(repoData, callbackData) {
    const { examPassword, examType } = callbackData.userModule;
    yield put(storeExamPasswordHashAction(examPassword));
    mergeModuleState(repoData.module, callbackData.payload.state);

    setUpExamOptions(
      repoData.module,
      callbackData.userDetails,
      examType || 'flex',
      repoData.testInstanceId,
      undefined,
      repoData.defaultExamSettings,
    );
    // send the result back to the reducer
    yield put(getNewInstanceSuccessAction(repoData));
    // navigate to the start exam page
    yield put(push(`${EXAM_MODULE_PATH}/${repoData.testInstanceId}`));
  }
  function* failureCallback(error) {
    // send the error back to the reducer
    yield put(getNewInstanceFailureAction(error));
  }

  const callbackData = {};
  let api;
  const { userId, productId, examPassword, examType } = data.userModule;

  // Perform the http call
  try {
    const userDetails = yield call(verifyExistingUser);
    let customTime;
    if (
      examType === ModuleType.Writing ||
      examType === ModuleType.WritingWithPerspectives
    ) {
      if (
        !isNullOrUndefined(userDetails.examSettings) &&
        !isNullOrUndefined(userDetails.examSettings.accomSectionTimeWriting)
      ) {
        customTime = userDetails.examSettings.accomSectionTimeWriting;
      }
    } else if (
      !isNullOrUndefined(userDetails.examSettings) &&
      !isNullOrUndefined(userDetails.examSettings.accomSectionTime)
    ) {
      customTime = userDetails.examSettings.accomSectionTime;
    }
    const payload = {
      userId,
      productType: 'Exam',
      //  examType: examType !== undefined ? examType : 'Flex',
      productId,
      examPassword,
      state: getInitialState(ExamMode.ExamTime, customTime),
    };

    callbackData.userModule = data.userModule;
    callbackData.payload = payload;
    callbackData.userDetails = userDetails;

    const apiEndURL = `${util.format(
      config.REACT_APP_NEW_TEST_INSTANCE_URL,
      userId,
    )}`;

    const url = `${getAPIFullPath(apiEndURL)}`;
    api = () => getApi('post', url, payload);

    const repos = yield call(api);

    yield* successCallback(repos.data, callbackData);
  } catch (err) {
    // send the error back to the reducer if password attempt exceeded, else handle the retry flow
    const errorCode = err?.response?.status;
    if (errorCode === 429 && examPassword.length > 0) {
      yield* failureCallback(err);
    } else {
      yield* handleAPIErrorAndRetry(
        err,
        api,
        successCallback,
        failureCallback,
        {
          callbackData,
        },
      );
    }
  }
}

/**
 * get module data function
 */
export function* getNewInstance(data) {
  function* successCallback(repoData, callbackData) {
    mergeModuleState(repoData.module, callbackData.payload.state);
    if (!isNullOrUndefined(callbackData.userModule.examMode)) {
      repoData.module.examMode = callbackData.userModule.examMode;
    }
    if (!isNullOrUndefined(callbackData.userModule.customTime)) {
      repoData.module.customTime = callbackData.userModule.customTime;
    }

    if (callbackData.userModule.moduleType) {
      setUpExamOptions(
        repoData.module,
        callbackData.userDetails,
        undefined,
        repoData.testInstanceId,
        callbackData.userModule.moduleType,
        repoData.defaultExamSettings,
      );
    }

    // if a vendor id is specified, link this instance to the vendor
    if (!isNullOrUndefined(callbackData.userModule.vendorId)) {
      try {
        const { testInstanceId } = repoData;
        const payload2 = { testInstanceId };
        const vendorAPI = `${util.format(
          config.REACT_APP_ADD_VENDOR_TO_INSTANCE_URL,
          callbackData.userModule.vendorId,
          callbackData.userModule.userId,
        )}`;
        yield call(getApi, 'post', `${getAPIFullPath(vendorAPI)}`, payload2);
      } catch (ex) {
        // if a 403, this user probably used a deep link given to them
        // by another user, and they don't belong to this vendor, we
        // will ignore this and let them take the test anyway.
        if (ex.response && ex.response.status === 403) {
          logHelper.log(
            LogLevelType.Info,
            'User is not associated with this vendor.',
          );
        } else if (ex.response && ex.response.status === 404) {
          logHelper.log(
            LogLevelType.Info,
            'Vendor ID does not match an existing vendor',
          );
        } else {
          throw ex;
        }
      }
    }
    // send the result back to the reducer
    yield put(getNewInstanceSuccessAction(repoData));

    // redirection to drillset questions page
    if (
      repoData?.module?.moduleType === ModuleType.DrillSet ||
      repoData?.module?.moduleType === ModuleType.DrillSetAdditional
    ) {
      yield put(
        push(
          `${DIRECTION_PATH}/${repoData.testInstanceId}/Section ${
            repoData.module.sections[0].sectionOrder
          }`,
        ),
      );
    } else {
      yield put(push(`${MODULE_PATH}/${repoData.testInstanceId}`));
    }
  }
  function* failureCallback(error) {
    // send the error back to the reducer
    yield put(getNewInstanceFailureAction(error));
  }
  // Perform the http call
  const callbackData = {};
  let api;

  try {
    const userDetails = yield call(verifyExistingUser);
    const {
      userId,
      moduleId,
      examMode,
      customTime,
      moduleType,
    } = data.userModule;

    callbackData.userDetails = userDetails;
    callbackData.userModule = data.userModule;

    const payload = {
      module: { id: moduleId },
      userId,
      state: getInitialState(examMode, customTime, moduleType),
    };

    callbackData.payload = payload;

    const apiEndURL = `${util.format(
      config.REACT_APP_NEW_TEST_INSTANCE_URL,
      userId,
    )}`;
    const url = `${getAPIFullPath(apiEndURL)}`;
    api = () => getApi('post', url, payload);

    const repos = yield call(api);
    yield* successCallback(repos.data, callbackData);
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback, {
      callbackData,
    });
  }
}

/**
 * get module data function
 */
/** GET INSTANCE */
export function* getExistingInstance(data) {
  function* successCallback(repoData, callbackData) {
    let isExam = false;
    const {
      testInstanceId,
      isResuming,
      examPassword,
      examType,
    } = callbackData.userInstance;

    if (repoData.productType === 'Exam') {
      setUpExamOptions(
        repoData.module,
        callbackData.userDetails,
        examType || repoData.examType || 'flex', // we can't assume flex - the userInstance for writingWithPerspectives comes back null
        repoData.testInstanceId,
        undefined,
        repoData.defaultExamSettings,
      );
      yield put(storeExamPasswordHashAction(examPassword));
      isExam = true;
    }
    if (isExam && isResuming) {
      const browserName = getBrowserName();
      const originalBrowserName = repoData.state.browser;
      if (!originalBrowserName || originalBrowserName === '') {
        repoData.state.browser = browserName;
      } else if (originalBrowserName !== browserName) {
        yield put(
          replace(
            `${BROWSER_ERROR_PATH}/${examType || 'Flex'}/${encodeURIComponent(
              originalBrowserName,
            )}`,
          ),
        );
        return;
      }
    }
    if (isExam && !isResuming) {
      yield put(replace(repoData.module.options.reconnectedPath));
      return;
    }
    yield put(getExistingInstanceSuccessAction(repoData));
    if (isResuming) {
      // only for real test!!
      yield put(push(`${EXAM_MODULE_PATH}/${testInstanceId}`));
    }
  }

  function* failureCallback(error, callbackData) {
    if (isFlexExamRefreshError(error, callbackData.userInstance.examPassword)) {
      const options = persistenceGet(
        `${callbackData.userInstance.testInstanceId}_options`,
      );
      if (!isNullOrUndefined(options)) {
        yield put(push(options.startPath));
      } else {
        yield put(push(`/exam/certifyingStatement`));
      }
      return;
    }
    yield put(getExistingInstanceFailureAction(error));
  }

  const { userId, testInstanceId, examPassword } = data.userInstance;

  const examPasswordQuery = !isNullOrUndefined(examPassword)
    ? `?examPassword=${examPassword}`
    : '';

  const apiEndURL = `${util.format(
    config.REACT_APP_EXISTING_TEST_INSTANCE_URL,
    userId,
    testInstanceId,
  )}${examPasswordQuery}`;
  const url = `${getAPIFullPath(apiEndURL)}`;
  const api = () => getApi('get', url);

  const callbackData = { userInstance: data.userInstance };

  // Perform the http call
  try {
    const userDetails = yield call(verifyExistingUser);
    callbackData.userDetails = userDetails;

    const repos = yield call(api);

    yield* successCallback(repos.data, callbackData);
  } catch (err) {
    // send the error back to the reducer if password attempt exceeded, else handle the retry flow
    const errorCode = err?.response?.status;
    if (errorCode === 429 && examPasswordQuery.length > 0) {
      yield* failureCallback(err, callbackData);
    } else {
      yield* handleAPIErrorAndRetry(
        err,
        api,
        successCallback,
        failureCallback,
        {
          callbackData,
        },
      );
    }
  }
}

function isFlexExamRefreshError(err, pwd) {
  if (
    (err.message.indexOf('403') >= 0 || err.message.indexOf('429') >= 0) &&
    isNullOrUndefined(pwd)
  ) {
    return true;
  }
  if (err.message.indexOf('400') >= 0) {
    if (
      !isNullOrUndefined(err.response) &&
      !isNullOrUndefined(err.response.data)
    ) {
      if (err.response.data.error.indexOf('completed') >= 0) {
        return true;
      }
    }
  }
  return false;
}

export function* putInstanceState(data) {
  const { userId, testInstanceId, state, summary, launchDetails } = data.data;
  const payload = { testInstanceId, state, summary, launchDetails };
  // for some reason the API changed summary.examMode to summary.isExamMode
  // so in order for it to update correctly, the property is set here based
  // on the original name. trying to refactor the code throughout this code
  // was likely riskly and not worth the time
  summary.isExamMode = state?.examMode;
  let api;

  function* successCallback(repoData) {
    yield put(putInstanceStateSuccessAction(repoData));
  }
  function* failureCallback(error, callbackData) {
    yield put(putInstanceStateErrorAction(callbackData, error));
  }

  try {
    yield call(verifyExistingUser);

    const apiEndURL = `${util.format(
      config.REACT_APP_PUT_INSTANCE_STATE_URL,
      userId,
      testInstanceId,
    )}`;

    const url = `${getAPIFullPath(apiEndURL)}`;
    api = () => getApi('put', url, payload, true);

    const repos = yield call(api);
    // send the result back to the reducer
    yield put(putInstanceStateSuccessAction(repos.data));
  } catch (err) {
    logHelper.log(LogLevelType.Error, err);
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback, {
      callbackData: testInstanceId,
    });
  }
}

export function* postLaunchDetails(data) {
  function* successCallback(repoData) {
    yield put(updateLaunchDetailsSuccessAction(repoData));
  }
  function* failureCallback(error) {
    yield put(updateLaunchDetailsErrorAction(error));
  }

  let api;

  try {
    yield call(verifyExistingUser);

    const apiEndURL = `${util.format(
      config.REACT_APP_POST_LAUNCH_DETAILS,
      data.userId,
    )}`;

    const url = `${getAPIFullPath(apiEndURL)}`;
    api = () => getApi('post', url, data.details, true);

    const repos = yield call(api);
    // send the result back to the reducer
    yield put(updateLaunchDetailsSuccessAction(repos.data));
  } catch (err) {
    logHelper.log(LogLevelType.Error, err);
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * Question saga manages watcher lifecycle
 */
export default function* questionData() {
  // Watches for GetSection action and call get when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_LIBRARY, getLibraryData);
  yield takeLatest(GET_FLEX_EXAM_LIST, getFlexExamList);
  yield takeLatest(GET_FLEX_INSTANCE, getFlexInstance);
  yield takeLatest(GET_NEW_INSTANCE, getNewInstance);
  yield takeLatest(GET_EXISTING_INSTANCE, getExistingInstance);
  yield takeLatest(PUT_INSTANCE_STATE, putInstanceState);
  yield takeLatest(GET_TEST_INSTANCE_STATUS, getTestInstanceStatus);
  yield takeLatest(POST_EXAM_LAUNCH_DETAILS, postLaunchDetails);
  yield put(initializeSagaLibraryAction());
}
