/**
 *
 * ArticlePage
 *
 */
import React, { lazy, Suspense, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useSessionReduxAndSaga } from 'domains/session/hooks';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import history from 'utils/history';

import { HOME_PATH } from 'containers/App/constants';
import { TITLE_TEST_PREP_HOME } from 'containers/MasterPage/constants';
import { reactPlugin } from 'containers/TestSelectPage/testselect.util';
import AriadneLoading from 'components/ariadne/AriadneLoading/AriadneLoading';
import { config } from 'config';

import ArticleContentModel from 'models/articleContentModel';
import { getArticleContentAction } from './actions';
import articlePageReducer from './reducer';
import articlePageSaga from './saga';
import { errorSelector, makeArticleContent } from './selectors';

import './index.css';
const ArticleContent = lazy(() => import('./ArticleContent/'));

export function ArticlePage({ onGetArticleContent, articleContent, error }) {
  const { articleId } = useParams();

  useSessionReduxAndSaga();
  useInjectReducer({
    key: 'articlePage',
    reducer: articlePageReducer,
  });
  useInjectSaga({
    key: 'articlePage',
    saga: articlePageSaga,
  });

  // Fetch the article data or route home if feature  is off
  useEffect(() => {
    if (config.REACT_APP_ARTICLE_FEATURE_ENABLED === 'true') {
      onGetArticleContent(articleId);
    } else {
      history.replace(HOME_PATH);
    }
  }, [articleId]);

  // If there is an error getting content, bounce to main
  useEffect(() => {
    if (!(error === undefined || error === null)) {
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        history.push(HOME_PATH);
      }
    }
    document.title = TITLE_TEST_PREP_HOME;
  }, [error]);

  if (articleContent?.id) {
    return (
      <div className="article-page-container">
        <div className="header">
          <div>
            {articleContent?.title && (
              <h1 data-testid="pageHeader" id="pageHeader">
                {articleContent.title}
              </h1>
            )}
          </div>
        </div>
        <Suspense fallback={<></>}>
          <ArticleContent articleContent={articleContent} />
        </Suspense>
      </div>
    );
  }

  return (
    <Container fluid style={{ width: '100vw' }}>
      <Row>
        <AriadneLoading text="article" />
      </Row>
    </Container>
  );
}

ArticlePage.propTypes = {
  onGetArticleContent: PropTypes.func,
  articleContent: PropTypes.instanceOf(ArticleContentModel),
  error: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  articleContent: makeArticleContent(),
  error: errorSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onGetArticleContent: articleId =>
      dispatch(getArticleContentAction(articleId)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(
  withAITracking(reactPlugin, injectIntl(ArticlePage), 'ArticlePage'),
);
