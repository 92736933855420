import { defineMessages } from 'react-intl';
import { config } from '../../config';

export const scope = 'app.containers.ExamCompletePage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'LSAT Writing / Test Completion',
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage: 'Your writing sample has been submitted.',
  },
  heading2: {
    id: `${scope}.heading2`,
    defaultMessage: 'Please follow these steps to close out your session.',
  },
  heading3: {
    id: `${scope}.heading3`,
    defaultMessage: 'Acknowledgments',
  },
  bulletPoint1: {
    id: `${scope}.bulletPoint1`,
    defaultMessage:
      'Please end your ProctorU session and stop screen sharing if this did not occur automatically.',
  },
  bulletPoint2: {
    id: `${scope}.bulletPoint2`,
    defaultMessage:
      'The proctor will direct you to rip up all scratch paper regardless of whether they were used. You must <b>rip up all scratch paper in view of the camera</b>.',
  },
  bulletPoint3: {
    id: `${scope}.bulletPoint3`,
    defaultMessage:
      'The proctor will notify you, verbally or through the chat box, when the check out process is complete. Once that happens, you are free to navigate away from this screen and close the browser window.',
  },
  bulletPoint5: {
    id: `${scope}.bulletPoint5`,
    defaultMessage:
      'Please allow up to three weeks of processing for your writing sample to become available in your LSAC account.',
  },
  bulletPoint4: {
    id: `${scope}.bulletPoint4`,
    defaultMessage:
      'Please do not share the writing prompt or your response to it with anyone.',
  },
  heading4: {
    id: `${scope}.heading4`,
    defaultMessage: 'We are here to help.',
  },
  text1: {
    id: `${scope}.text1`,
    defaultMessage:
      'If you have any questions or concerns, please reach out to LSAC at <a href="mailto: LSATWRITING@lsac.org">LSATWRITING@lsac.org</a>.',
  },
  heading5: {
    id: `${scope}.heading5`,
    defaultMessage: 'Review Submission',
  },
  buttonText1: {
    id: `${scope}.buttonText1`,
    defaultMessage: 'Return to LawHub',
  },
  buttonText2: {
    id: `${scope}.buttonText2`,
    defaultMessage: 'Complete LSAT Writing Session',
  },
  saveText: {
    id: `${scope}.saveText`,
    defaultMessage: 'SAVE results locally',
  },
  logoText: {
    id: `${scope}.logoText`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME}`,
  },
  noAcks: {
    id: `${scope}.noAcks`,
    defaultMessage: 'No acknowledgements.',
  },
});
