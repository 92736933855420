/*
 * Accordion Messages
 *
 * This contains all the text for the Accordion component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Accordion';

export default defineMessages({
  transcript: {
    id: `${scope}.transcript`,
    defaultMessage: 'Transcript',
  },
  expandIcon: {
    id: `${scope}.expandIcon`,
    defaultMessage: 'Expand icon',
  },
  collapseIcon: {
    id: `${scope}.collapseIcon`,
    defaultMessage: 'Collapse icon',
  },
  transcriptRenderFailed: {
    id: `${scope}.transcriptRenderFailed`,
    defaultMessage: 'Transcript render failed',
  },
  scrollableRegionTranscript: {
    id: `${scope}.scrollableRegionTranscript`,
    defaultMessage: 'Scrollable region, transcript',
  },
});
