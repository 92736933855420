/*
 *
 * MetisProgress constants
 *
 */

export const SECTION_TIMER_NAME = 'METIS_SECTION_TIMER';
export const WRITING_ANALYSIS_TIMER_NAME = 'WRITING_ANALYSIS_TIMER_NAME';
export const UNLIMITED_TIME_NAME = 'unlimitedTime';
export const ADDITIONAL_PRACTICE_TIMER_NAME = 'ADDITIONAL_PRACTICE_TIMER_NAME';
