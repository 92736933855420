/* eslint-disable no-console */
import { isNullOrUndefined } from 'util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LogLevelType } from '../enumerations/LogLevelType';
import { config } from '../config';
import { storeValueInLocal } from './localStorageHelper';
let appInsights = null;

/**
 *
 * @module logHelper
 * @description Helper for logging exceptions using the
 * {@link https://www.npmjs.com/package/@microsoft/applicationinsights-web} library
 */

/**
 *
 * @param {object} appInsightsInstance The application insights instance for the app
 */
export function initialize(appInsightsInstance) {
  appInsights = appInsightsInstance;
}

/**
 *
 * @param {object} ex The exception to log
 * @param {object} source The source of the exception
 */
export function logException(ex, source) {
  const ErrorEvents = ['resizeobserver', 'syntaxerror'];

  if (!isNullOrUndefined(appInsights)) {
    // Bug For Exceptions to be logged only once per 5 minutes
    // this is to avoid multiple logging of similar bugs
    if (
      ErrorEvents.filter(error => ex.message?.toLowerCase().includes(error))
        .length > 0
    ) {
      const lastTimeStampForException = localStorage.getItem(
        'timestampForExceptionBug',
      );
      if (lastTimeStampForException) {
        if (new Date() - new Date(lastTimeStampForException) > 300000) {
          storeValueInLocal('timestampForExceptionBug', new Date());
          appInsights.trackException({ error: ex }, { source });
        }
      } else {
        storeValueInLocal('timestampForExceptionBug', new Date());
        appInsights.trackException({ error: ex }, { source });
      }
    }
    // do not log script errors
    else if (!ex.message?.toLowerCase().includes('script error')) {
      appInsights.trackException({ error: ex }, { source });
    }
  }
  if (source) {
    console.log(`${source}: ${ex}`);
  } else {
    console.log(ex);
  }
}

/**
 *
 * @param {LogLevelType} logLevel The log level type
 * @param {object} message The message or any object
 * @param  {any[]} restMessages The rest messages
 */
export function log(logLevel, message, ...restMessages) {
  if (
    !isNullOrUndefined(message) &&
    config.REACT_APP_SHOW_CONSOLE_LOG === 'true'
  ) {
    console.log(message, ...restMessages);
  }
  if (logLevel === LogLevelType.Error && !isNullOrUndefined(appInsights)) {
    appInsights.trackTrace(message, SeverityLevel.Error);
  }
}
