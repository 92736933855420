/* eslint-disable no-param-reassign */
/*
 *
 * DirectionsPage reducer
 *
 */
import produce from 'immer';
import { FontSize } from 'enumerations/FontSize';
import { LineHeight } from 'enumerations/LineHeight';
import SettingModel from 'models/settingModel';
import ItemModel from 'models/itemModel';
import { OptionMode } from 'enumerations/OptionMode';
import * as moduleHelper from 'helpers/moduleHelper';
import { isNullOrUndefined } from 'util';
import * as constants from './constants';
import * as testSelectConstants from '../TestSelectPage/constants';
import { setOptionState } from '../../helpers/optionStateHelper';
import {
  logChange,
  populateStateFromServer,
  sendStateNow,
} from '../../helpers/persistence/instanceStateManagementHelper';
import {
  logFlexExamPause,
  logUndoSectionPause,
  logSectionPause,
  logSectionComplete,
} from '../../helpers/persistence/examManagementHelper';
import * as telemetryHelper from '../../helpers/telemetryHelper';
import { config } from '../../config';
import { appendModuleToInstance } from '../../helpers/persistence/common/repoHelper';
import { ModuleType } from '../../enumerations/ModuleType';

/**
 *
 * Directions page default sate
 *
 */
export const initialState = {
  instances: [],
  currentItem: null,
  highlightStarted: false,
  setting: {
    fontSize: FontSize.properties[1].px,
    lineHeight: LineHeight.properties[1].h,
    highlightType: 0,
    fontSizeType: FontSize.Default,
    lineHeightType: LineHeight.Default,
  },
  initSagaSection: false,
  selectedInstanceId: null,
  selectedSectionId: null,
  showAnswer: false,
  isSectionLoadCompleted: false,
  isSectionPaused: false,
  isPassageView: false,
  pausedPage: null,
  isSectionResumed: false,
  isWritingOnlyMode: false,
  error: null,
  hasDismissedOneHourWarning: false,
  showHints: [],
};

/**
 *
 * Directions page reducer for each action
 *
 */
const directionsPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    const sectionId =
      action.type === constants.CHANGE_BREAK_TIME_REMAINING
        ? action.sectionId
        : state.selectedSectionId;

    const {
      section,
      selectedInstance,
    } = moduleHelper.getInstanceSectionByFormId(
      state.instances,
      state.selectedInstanceId,
      sectionId,
    );
    let pausedModule = null;

    switch (action.type) {
      case constants.INIT_SECTION_SAGA:
        draft.initSagaSection = true;
        break;
      case constants.SET_SECTION:
        if (!isNullOrUndefined(action.testInstanceId)) {
          draft.selectedInstanceId = action.testInstanceId;
        }
        draft.currentItem =
          action.section.items[action.section.currentItemIndex];
        draft.selectedSectionId = action.section.formId;
        draft.showAnswer = false;
        draft.isSectionPaused = false;
        break;
      case constants.CHANGE_FONTSIZE:
        draft.setting = Object.assign(new SettingModel(), state.setting, {
          fontSize: FontSize.properties[action.fontSizeType].px,
          fontSizeType: action.fontSizeType,
        });
        logChange(action.type, null, action.fontSizeType);
        break;
      case constants.CHANGE_LINEHEIGHT:
        draft.setting = Object.assign(new SettingModel(), state.setting, {
          lineHeight: LineHeight.properties[action.lineHeightType].h,
          lineHeightType: action.lineHeightType,
        });
        logChange(action.type, null, action.lineHeightType);
        break;
      case constants.CHANGE_SELECTION:
        section.currentItemIndex = action.index;
        draft.currentItem = section.items[section.currentItemIndex];
        draft.showAnswer = false;
        logChange(action.type, section.formId, action.index);
        telemetryHelper.saveItemInfo(
          action.type,
          section,
          state,
          selectedInstance?.module,
        );
        break;
      case constants.CHANGE_OPTION_MODE:
        draft.currentItem = Object.assign(
          new ItemModel(),
          section.items[section.currentItemIndex],
        );
        draft.currentItem.answerOptions[action.optionIndex].optionMode =
          draft.currentItem.answerOptions[action.optionIndex].optionMode ===
          OptionMode.Expanded
            ? OptionMode.Collapsed
            : OptionMode.Expanded;
        section.items[section.currentItemIndex] = draft.currentItem;
        logChange(action.type, section.formId, {
          itemNumber: draft.currentItem.itemNumber,
          optionLetter:
            draft.currentItem.answerOptions[action.optionIndex].letter,
          optionMode:
            draft.currentItem.answerOptions[action.optionIndex].optionMode,
        });
        break;
      case constants.CHANGE_OPTION_STATE:
        draft.currentItem = Object.assign(
          new ItemModel(),
          setOptionState(section, action.optionIndex, action.optionType),
        );
        section.items[section.currentItemIndex] = draft.currentItem;
        logChange(action.type, section.formId, {
          itemNumber: draft.currentItem.itemNumber,
          answerOptions: draft.currentItem.answerOptions,
        });
        telemetryHelper.saveItemInfo(
          action.type,
          section,
          state,
          selectedInstance?.module,
        );
        break;
      case constants.CHANGE_WRITING_TEXT:
        draft.currentItem.writingText = action.text;
        section.items[section.currentItemIndex] = draft.currentItem;
        logChange(action.type, section.formId, {
          itemNumber: draft.currentItem.itemNumber,
          writingText: draft.currentItem.writingText,
        });
        break;
      case constants.UPDATE_NOTES_TEXT:
        section.notes = action.text;
        logChange(action.type, section.formId, action.text);
        break;
      case constants.SET_NOTES_IS_EXPANDED:
        section.isNotesExpanded = action.isExpanded;
        break;
      case constants.MARK_FOR_REVIEW:
        draft.currentItem = Object.assign(new ItemModel(), action.item);
        draft.currentItem.markForReview = !draft.currentItem.markForReview;
        section.items[section.currentItemIndex] = draft.currentItem;
        logChange(action.type, section.formId, {
          itemNumber: draft.currentItem.itemNumber,
          markForReview: draft.currentItem.markForReview,
        });
        break;
      case constants.ITEM_VIEW_HINT:
        draft.currentItem = Object.assign(new ItemModel(), action.item);
        draft.currentItem.totalHintsViewed = action.totalHintsViewed;
        section.items[section.currentItemIndex] = draft.currentItem;
        logChange(action.type, section.formId, {
          itemNumber: draft.currentItem.itemNumber,
          totalHintsViewed: draft.currentItem.totalHintsViewed,
        });
        break;
      case constants.CHANGE_HIGHLIGHT:
        draft.setting = Object.assign(new SettingModel(), state.setting, {
          highlightType: action.highlightType,
        });
        logChange(action.type, null, action.highlightType);
        break;
      case constants.ON_PROCTOR_DISCONNECT:
        section.timeRemaining += 20000;
        section.disconnected = true;
        logChange(
          constants.CHANGE_TIME_REMAINING,
          section.formId,
          section.timeRemaining,
        );
        sendStateNow();
        break;
      case constants.CHANGE_TIME_REMAINING:
        if (!section.disconnected) {
          section.timeRemaining = action.timeRemaining;
          logChange(action.type, section.formId, action.timeRemaining);
        }
        break;
      case constants.CHANGE_ANALYSIS_TIME_REMAINING:
        if (!section.disconnected) {
          section.analysisTimeRemaining = action.analysisTimeRemaining;
          logChange(action.type, section.formId, action.analysisTimeRemaining);
        }
        break;
      case constants.CHANGE_ELAPSED_TIME:
        if (!section.disconnected) {
          section.elapsedTime = action.elapsedTime;
          logChange(action.type, section.formId, action.elapsedTime);
        }
        break;
      case constants.SHOW_ANSWER:
        draft.showAnswer = action.showAnswerToggle;
        // draft.currentItem = Object.assign(new ItemModel(), action.item);
        draft.currentItem.showAnswerClicked = true;
        // section.items[section.currentItemIndex] = draft.currentItem;
        logChange(action.type, section.formId, {
          itemIndex: section.currentItemIndex,
          showAnswerClicked: draft.currentItem.showAnswerClicked,
        });
        break;
      case constants.SET_TIME_VISIBILITY:
        section.isTimeRemainingVisible = action.isVisible;
        logChange(action.type, section.formId, action.isVisible);
        break;
      case constants.SET_SECTION_COMPLETE:
        section.isCompleted = true;
        section.isPaused = false;
        draft.isSectionPaused = false;
        selectedInstance.module.currentPage = null;
        logSectionComplete(section.formId, action.allSectionsComplete);
        telemetryHelper.saveItemInfo(
          action.type,
          section,
          state,
          selectedInstance?.module,
        );
        break;
      case constants.SET_PAUSE_SECTION:
        section.isPaused = action.isSectionPaused;
        draft.isSectionPaused = action.isSectionPaused;
        section.pagePaused = action.pagePaused;
        draft.pausedPage = action.pagePaused;

        if (action.isSectionPaused) {
          logSectionPause(
            true,
            action.pagePaused,
            section.formId,
            section.currentItemIndex,
            action.isNeverShowPausePopup,
          );
        }

        if (
          !isNullOrUndefined(action.isNeverShowPausePopup) &&
          !isNullOrUndefined(selectedInstance) &&
          action.isNeverShowPausePopup
        ) {
          selectedInstance.module.isNeverShowPauseMessage = true;
        }
        telemetryHelper.saveItemInfo(
          action.type,
          section,
          state,
          selectedInstance?.module,
        );
        break;
      case constants.CHANGE_HIGHLIGHT_STATE:
        draft.highlightStarted = action.highlightStarted;
        break;
      case constants.SELECT_INSTANCE:
        draft.selectedInstance = action.instance;
        break;
      case constants.GET_NEW_INSTANCE_SUCCESS:
        draft.error = null;
        draft.getInstanceError = null;
        draft.instances = Object.assign(
          [],
          moduleHelper.insertInstance(
            action.testInstanceId,
            action.examType,
            action.module,
            state.instances,
            action.launchDetails,
            action.testInstanceRemoteStatus,
          ),
        );
        draft.selectedInstanceId = action.testInstanceId;
        appendModuleToInstance(action.testInstanceId, action.module);

        break;
      case constants.GET_NEW_INSTANCE_FAILURE:
        draft.error = action.err;
        draft.getInstanceError = action.err;
        break;
      case constants.CLEAR_INSTANCE_ERROR:
        draft.error = undefined;
        draft.getInstanceError = undefined;
        break;
      case constants.GET_EXISTING_INSTANCE_SUCCESS:
        populateStateFromServer(
          action.testInstanceId,
          action.state,
          action.module,
          action.summary,
          action.launchDetails,
          action.testInstanceRemoteStatus,
        );
        draft.error = null;
        draft.getInstanceError = null;
        draft.instances = Object.assign(
          [],
          moduleHelper.insertInstance(
            action.testInstanceId,
            action.examType,
            action.module,
            state.instances,
            action.state,
            action.summary,
            action.isExistingInsance,
            action.launchDetails,
            action.testInstanceRemoteStatus,
          ),
        );
        draft.selectedInstanceId = action.testInstanceId;
        appendModuleToInstance(action.testInstanceId, action.module);
        break;
      case constants.LOAD_SECTION:
        if (
          action.section.isCompleted === true &&
          draft.isSectionLoadCompleted === false &&
          draft.isPassageView === false
        ) {
          if (section.moduleType !== ModuleType.DrillSet) {
            section.currentItemIndex = 0;
          }
          section.items.forEach(item => {
            if (
              !isNullOrUndefined(item) &&
              !isNullOrUndefined(item.answerOptions) &&
              item.answerOptions.length >= 0
            ) {
              item.answerOptions.forEach(option => {
                if (
                  !isNullOrUndefined(option) &&
                  !isNullOrUndefined(option.optionMode) &&
                  option.optionMode === OptionMode.Collapsed
                ) {
                  option.optionMode = OptionMode.Expanded;
                }
              });
            }
          });
          draft.currentItem = section.items[section.currentItemIndex];
          draft.isSectionLoadCompleted = true;
        }

        if (draft.isPassageView === false) {
          telemetryHelper.saveItemInfo(
            action.type,
            section,
            state,
            selectedInstance?.module,
          );
        }
        draft.isSectionPaused = false;
        draft.isPassageView = false;
        break;
      case constants.UNLOAD_SECTION:
        if (draft.isPassageView === false) {
          draft.isSectionLoadCompleted = false;
          telemetryHelper.saveItemInfo(
            action.type,
            section,
            state,
            selectedInstance?.module,
          );
        }
        break;
      case constants.SET_PASSAGE_VIEW:
        draft.isPassageView = action.isPassageView;
        if (draft.isPassageView === false) {
          draft.isSectionLoadCompleted = false;
        }
        break;
      case constants.SET_SECTION_LOAD_COMPLETE:
        draft.isSectionLoadCompleted = action.isSectionLoaded;
        break;
      case constants.UNDO_SECTION_PAUSE_ACTION:
        draft.selectedSectionId = action.module.pausedSectionId;
        draft.selectedInstanceId = action.testInstanceId;
        pausedModule = moduleHelper.getInstanceSectionByFormId(
          state.instances,
          action.testInstanceId,
          action.module.pausedSectionId,
        );

        if (!isNullOrUndefined(pausedModule)) {
          draft.currentItem =
            pausedModule.section.items[action.module.pausedQuestionIndex];
          logUndoSectionPause(action.module);
        }
        telemetryHelper.saveItemInfo(
          action.type,
          section,
          state,
          selectedInstance?.module,
        );
        break;
      case constants.RESUME_SECTION_ACTION:
        draft.isSectionResumed = action.isSectionResumed;
        draft.selectedInstanceId = action.testInstanceId;
        break;
      case constants.SET_FLEX_EXAM_PAUSE_ACTION: {
        if (
          !isNullOrUndefined(selectedInstance) &&
          !isNullOrUndefined(selectedInstance.module)
        ) {
          const wentFromPausedToUnpaused =
            !action.isFlexExamPaused && selectedInstance.module.isFlexPaused;
          selectedInstance.module.isFlexPaused = action.isFlexExamPaused;
          selectedInstance.module.flexPausedOn = action.isFlexExamPaused
            ? action.pausedOnPage
            : null;
          if (
            wentFromPausedToUnpaused &&
            config.REACT_APP_ADD_EXTRA_MINUTE_ON_PAUSE_ENABLED === 'true'
          ) {
            section.timeRemaining += 60 * 1000;
          }
          logFlexExamPause(
            action.isFlexExamPaused,
            selectedInstance.module.flexPausedOn,
          );
        }
        break;
      }
      case constants.CHANGE_BREAK_TIME_REMAINING:
        if (!isNullOrUndefined(section)) {
          section.breakTimeRemaining = action.timeRemaining;
        }
        logChange(action.type, action.sectionId, action.timeRemaining);
        break;
      case constants.CHANGE_INTERMISSION_TIME_REMAINING:
        if (!isNullOrUndefined(section)) {
          section.intermissionTimeRemaining = action.timeRemaining;
        }
        logChange(action.type, action.sectionId, action.timeRemaining);
        break;
      case constants.CHANGE_READY_TO_CHECKIN_STATUS:
        if (!isNullOrUndefined(section)) {
          section.isReadyToCheckin = action.isReadyToCheckin;
        }
        logChange(action.type, action.sectionId, action.isReadyToCheckin);
        break;
      case constants.GET_EXISTING_INSTANCE_FAILURE:
        draft.getInstanceError = action.err;
        draft.error = action.err;
        break;
      case constants.SET_WRITING_ONLY_MODE:
        draft.isWritingOnlyMode = action.isWritingOnlyMode;
        break;
      case testSelectConstants.GET_LIBRARY:
        telemetryHelper.saveItemInfo(
          action.type,
          section,
          state,
          selectedInstance?.module,
        );
        break;
      case constants.SET_ONE_HOUR_WARNING_DISMISSED:
        draft.hasDismissedOneHourWarning = true;
        break;
      case constants.SET_SHOW_HINTS:
        if (
          !draft.showHints.some(item => item.itemNumber === action.itemNumber)
        ) {
          draft.showHints.push({
            itemNumber: action.itemNumber,
            ...action.showHints,
          });
        } else {
          draft.showHints = draft.showHints.map(showHint => {
            if (showHint.itemNumber === action.itemNumber) {
              return {
                ...showHint,
                ...action.showHints,
              };
            }
            return showHint;
          });
        }
        break;
      default:
        break;
    }
  });

export default directionsPageReducer;
