import { getApi, verifyExistingUser } from 'helpers/sagaHelper';
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_USER_APPLICATION_STATUS,
  POST_APPLICATION_NOTIFICATION_STATUS,
  GET_APPLICATION_NOTIFICATION_STATUS,
} from './constants';
import {
  getUserApplicationStatusSuccessAction,
  getUserApplicationStatusErrorAction,
  postApplicationNotificationStatusSuccessAction,
  postApplicationNotificationStatusErrorAction,
  getApplicationNotificationStatusSuccessAction,
  getApplicationNotificationStatusErrorAction,
} from './actions';
import { getAPIFullPath } from '../../helpers/configHelper';
import { config } from '../../config';
import handleAPIErrorAndRetry from '../../helpers/apiResponseHelper';

const util = require('util');

export function* getUserApplicationDetails(data) {
  function* successCallback(repoData) {
    yield put(getUserApplicationStatusSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(getUserApplicationStatusErrorAction(error));
  }

  let api;

  // Perform the http call
  try {
    yield call(verifyExistingUser);
    const { candSeq } = data;
    const apiEndURL = `${util.format(
      config.REACT_APP_GET_USER_APPLICATION_STATUS_URL,
      candSeq,
    )}`;

    api = () => getApi('get', `${getAPIFullPath(apiEndURL)}`);
    const repos = yield call(api);
    // send the result back to the reducer
    yield put(getUserApplicationStatusSuccessAction(repos.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

export function* postNotificationDetails(data) {
  function* successCallback(repoData) {
    yield put(postApplicationNotificationStatusSuccessAction(repoData.data));
    yield put(getApplicationNotificationStatusSuccessAction(data.isChecked));
  }
  function* failureCallback(error) {
    yield put(postApplicationNotificationStatusErrorAction(error));
  }

  let api;

  // Perform the http call
  try {
    yield call(verifyExistingUser);

    const { userDetails, candSeq, isChecked } = data;
    const apiEndURL = `${util.format(
      config.REACT_APP_POST_USER_APPLICATION_NOTIFICATION_STATUS_URL,
      userDetails.userId,
    )}`;
    const payload = {
      notificationId: 'ASOUpdate',
      enable: isChecked,
      userDetails: {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.emailAddress,
        organizationIdentifiers: [
          {
            organization: 'LSAC',
            organizationIdentifier: candSeq,
          },
        ],
        userProducts: [
          {
            name: userDetails.activeProducts[1].productId,
            expirationDate: userDetails.activeProducts[1].expirationDate,
          },
        ],
      },
    };
    api = () => getApi('post', `${getAPIFullPath(apiEndURL)}`, payload);
    const repos = yield call(api);

    // send the result back to the reducer
    yield put(postApplicationNotificationStatusSuccessAction(repos.data));
    yield put(getApplicationNotificationStatusSuccessAction(isChecked));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

export function* getNotificationDetails(data) {
  function* successCallback(repoData) {
    yield put(getApplicationNotificationStatusSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(getApplicationNotificationStatusErrorAction(error));
  }

  let api;

  // Perform the http call
  try {
    yield call(verifyExistingUser);
    const { userId } = data;
    const apiEndURL = `${util.format(
      config.REACT_APP_GET_USER_APPLICATION_NOTIFICATION_STATUS_URL,
      userId,
    )}`;

    api = () => getApi('get', `${getAPIFullPath(apiEndURL)}`);
    const repos = yield call(api);

    // send the result back to the reducer
    yield put(getApplicationNotificationStatusSuccessAction(repos.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

export default function* applicationdetails() {
  // Watches for events action and call get when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_USER_APPLICATION_STATUS, getUserApplicationDetails);
  yield takeLatest(
    POST_APPLICATION_NOTIFICATION_STATUS,
    postNotificationDetails,
  );
  yield takeLatest(GET_APPLICATION_NOTIFICATION_STATUS, getNotificationDetails);
}
