/* eslint-disable global-require */
/*
 *
 * ToolsPage constants
 *
 */

export const exploreCardList = [
  {
    id: 'TE1',
    topic: 'Law School Scholarships',
    imageUrl: {
      url: require('../../images/law_school_scholarships.png'),
      altText: 'Woman calculating figures at computer',
    },
    url: 'REACT_APP_LEGAL_SCHOOL_SCHOLARSHIPS',
  },
  {
    id: 'TE2',
    topic: 'Types of Law Programs',
    imageUrl: {
      url: require('../../images/types_law_programs.png'),
      altText: 'collage of diverse law school applicants',
    },
    url: 'REACT_APP_TYPES_OF_LAW_PROGRAMS',
  },
  {
    id: 'TE3',
    topic: 'Fields of Law',
    imageUrl: {
      url: require('../../images/fields_of_law.png'),
      altText: 'Person taking notes at desk with laptop',
    },
    url: 'REACT_APP_FIELDS_OF_LAW',
  },
];
