/**
 *
 * ToolsExploreSlider
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ExploreCard } from './exploreCard';
import { validCardTypes } from '../CardsSlider/cardTypes';
import CardsSlider from '../CardsSlider';
import './index.css';

const ToolsExploreSlider = props => {
  // Selector used for programmatic focus in the CardsSlider
  const focusHandle = '.tools-explore-card-wrapper';
  const sliderContainerSelector = '.all-tools-explore-slider';

  // The wrapped component
  const WrappedComponent = withHandler(ExploreCard);

  return (
    <div className="all-tools-explore-slider cards-slider">
      <CardsSlider
        {...props}
        cardType={validCardTypes.toolsExplore}
        sliderHeading={props.sliderHeading || 'Explore'}
        CardComponent={WrappedComponent}
        cardFocusSelector={focusHandle}
        sliderContainerSelector={sliderContainerSelector}
        viewAllLinkFlag={false}
      />
    </div>
  );
};

ToolsExploreSlider.propTypes = {
  sliderHeading: PropTypes.string.isRequired,
};

// HOC that passes the click handlers and props specific to that card type
const withHandler = BaseComponent => cardProps => (
  <BaseComponent {...cardProps} />
);

export default ToolsExploreSlider;
