/**
 *
 * Hambuger Button
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

function HamburgerButton({
  id,
  style,
  onClick,
  onKeyPress,
  ariaLabel,
  tabIndex,
}) {
  return (
    <button
      type="button"
      data-testid="hamButton"
      id={id}
      style={style}
      className={
        id === 'Homehamburger'
          ? 'hamburgerButton homePage'
          : 'hamburgerButton hamburgerImgButton'
      }
      onClick={onClick}
      onKeyPress={onKeyPress}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
    />
  );
}

HamburgerButton.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  tabIndex: PropTypes.string,
};

HamburgerButton.defaultProps = {
  id: 'hamburger',
  ariaLabel: 'Main Navigation',
};

export default HamburgerButton;
