/**
 *
 * Accordion
 *
 * Used to display html content within accordion
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ExpandIcon from 'images/expand_icon.svg';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import './index.css';
import messages from './messages';

const Accordion = props => {
  const {
    URL,
    accordionTitle,
    staticDescription,
    staticDescJSXElement,
    ariaLabel,
    collapsed,
    accordionId,
    tabIndexValue,
    setFocus,
    onSetCollapsible,
  } = props;
  const [accordionCollapsible, setAccordionCollapsible] = useState(collapsed);
  const [accordionContent, setAccordionContent] = useState('');
  const accordionContentRef = useRef(null);
  const accordionButtonRef = useRef(null);

  useEffect(() => {
    if (!accordionCollapsible && accordionContentRef?.current) {
      accordionContentRef.current.focus();
    }
  }, [accordionCollapsible]);

  useEffect(() => {
    if (accordionButtonRef?.current && setFocus) {
      accordionButtonRef.current.focus();
    }
  }, [accordionId, setFocus]);

  useEffect(() => {
    setAccordionCollapsible(collapsed);
  }, [collapsed]);

  const isScrollable =
    document?.getElementById('custom-accordion')?.scrollHeight > 360;

  const getAccordionContent = () => {
    fetch(URL)
      .then(response => {
        if (!response.ok) {
          throw messages.transcriptRenderFailed.defaultMessage;
        } else {
          return response.text();
        }
      })
      .then(htmlContent => {
        setAccordionContent(htmlContent);
      })
      .catch(() => {
        setAccordionContent(messages.transcriptRenderFailed.defaultMessage);
      });
  };

  useEffect(() => {
    if (!isNullOrUndefined(URL)) {
      getAccordionContent();
    }
  }, [URL]);

  const onCollapsibleHandler = () => {
    setAccordionCollapsible(!accordionCollapsible);
    onSetCollapsible(accordionCollapsible);
  };

  return (
    <div
      className={`accordion-container ${
        accordionCollapsible ? 'acc-closed' : 'acc-opened'
      }`}
    >
      <h2 className="accordion-header">
        <button
          type="button"
          aria-controls="custom-accordion"
          onClick={onCollapsibleHandler}
          aria-expanded={!accordionCollapsible}
          className="accordion-btn"
          id={`accordionButton-${accordionId}`}
          ref={accordionButtonRef}
        >
          {accordionTitle || <FormattedMessage {...messages.transcript} />}
          <img
            src={ExpandIcon}
            className={accordionCollapsible ? 'expand-icon' : 'collapse-icon '}
            alt=""
          />
        </button>
      </h2>
      <div
        className={`${accordionCollapsible ? '' : 'accordion-content open'}`}
        id="custom-accordion"
        role="region"
        aria-label={
          ariaLabel ||
          (isScrollable
            ? messages.scrollableRegionTranscript.defaultMessage
            : messages.transcript.defaultMessage)
        }
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={tabIndexValue}
        ref={accordionContentRef}
      >
        {staticDescJSXElement || (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: accordionContent || staticDescription,
            }}
            data-testid="accordionInnerHtml"
          />
        )}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  URL: PropTypes.string,
  accordionTitle: PropTypes.string,
  staticDescription: PropTypes.string,
  staticDescJSXElement: PropTypes.element,
  ariaLabel: PropTypes.string,
  collapsed: PropTypes.bool,
  tabIndexValue: PropTypes.string,
  accordionId: PropTypes.string,
  setFocus: PropTypes.bool,
  onSetCollapsible: PropTypes.func,
};

Accordion.defaultProps = {
  collapsed: true,
  tabIndexValue: '0',
  setFocus: false,
};

export default Accordion;
