/**
 * Log level type enumeration used with logging
 * @category enumerations
 * @enum {number}
 */
const LogLevelType = {
  None: 0,
  Error: 1,
  Warning: 2,
  Info: 3,
  Debug: 4,
  Trace: 5,
};

export { LogLevelType };
