/**
 *
 * WritingResponsePanel
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import WritingResponse from '../WritingResponse';

function WritingResponsePanel({ writingSectionResponse }) {
  return (
    <div className="writing-response-panel-container">
      <h2 className="writing-response-panel-header">Review Submission</h2>
      <WritingResponse writingSectionResponse={writingSectionResponse} />
    </div>
  );
}

WritingResponsePanel.propTypes = {
  /** 
    The text to be seen in the writing section
  */
  writingSectionResponse: PropTypes.string.isRequired,
};

export default WritingResponsePanel;
