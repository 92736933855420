import {
  FULFILL_PROCTORING_TEST,
  FULFILL_PROCTORING_TEST_ERROR,
  FULFILL_PROCTORING_TEST_SUCCESS,
  INITIALIZE_PROCTORING_TEST,
  INITIALIZE_PROCTORING_TEST_ERROR,
  INITIALIZE_PROCTORING_TEST_SUCCESS,
} from './constants';

export function initializeProctoringTestAction(examId, userId) {
  return {
    type: INITIALIZE_PROCTORING_TEST,
    payload: { examId, userId },
  };
}

export function initializeProctoringTestSuccessAction(testInfo) {
  return {
    type: INITIALIZE_PROCTORING_TEST_SUCCESS,
    testInfo,
  };
}

export function initializeProctoringTestErrorAction(error) {
  return {
    type: INITIALIZE_PROCTORING_TEST_ERROR,
    error,
  };
}

export function fulfillProctoringTestAction(examId, userId) {
  return {
    type: FULFILL_PROCTORING_TEST,
    payload: { examId, userId },
  };
}

export function fulfillProctoringTestSuccessAction(testInfo) {
  return {
    type: FULFILL_PROCTORING_TEST_SUCCESS,
    testInfo,
  };
}

export function fulfillProctoringTestErrorAction(error) {
  return {
    type: FULFILL_PROCTORING_TEST_ERROR,
    error,
  };
}
