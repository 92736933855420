import {
  GET_USER_APPLICATION_STATUS,
  GET_USER_APPLICATION_STATUS_SUCCESS,
  GET_USER_APPLICATION_STATUS_ERROR,
  POST_APPLICATION_NOTIFICATION_STATUS,
  POST_APPLICATION_NOTIFICATION_STATUS_SUCCESS,
  POST_APPLICATION_NOTIFICATION_STATUS_ERROR,
  GET_APPLICATION_NOTIFICATION_STATUS,
  GET_APPLICATION_NOTIFICATION_STATUS_SUCCESS,
  GET_APPLICATION_NOTIFICATION_STATUS_ERROR,
} from './constants';

/**
 * Get the User Application Status Online Page details
 *
 * @param {string} candSeq
 *
 */
export function getUserApplicationStatusAction(candSeq) {
  return {
    type: GET_USER_APPLICATION_STATUS,
    candSeq,
  };
}

/**
 * Notify when the user getApplication Status is retrieved
 *
 * @param application status
 *
 */
export function getUserApplicationStatusSuccessAction(applicationData) {
  return {
    type: GET_USER_APPLICATION_STATUS_SUCCESS,
    applicationData,
  };
}

/**
 * Notify when the user application status retrieval encounters an error
 *
 * @param {object} error the error object
 *
 */
export function getUserApplicationStatusErrorAction(error) {
  return {
    type: GET_USER_APPLICATION_STATUS_ERROR,
    error,
  };
}

/**
 * post the notification for application status tracker
 *
 * @param {string} candSeq
 *
 */
export function postApplicationNotificationStatusAction(
  userDetails,
  candSeq,
  isChecked,
) {
  return {
    type: POST_APPLICATION_NOTIFICATION_STATUS,
    userDetails,
    candSeq,
    isChecked,
  };
}

/**
 * Notify when the user toggles the notification button
 *
 * @param application status
 *
 */
export function postApplicationNotificationStatusSuccessAction(
  notificationData,
) {
  return {
    type: POST_APPLICATION_NOTIFICATION_STATUS_SUCCESS,
    notificationData,
  };
}

/**
 * Notify when the user toggle notification status retrieval encounters an error
 *
 * @param {object} error the error object
 *
 */
export function postApplicationNotificationStatusErrorAction(error) {
  return {
    type: POST_APPLICATION_NOTIFICATION_STATUS_ERROR,
    error,
  };
}

/**
 * get the notification for application status
 *
 * @param {string} candSeq
 *
 */
export function getApplicationNotificationStatusAction(userId) {
  return {
    type: GET_APPLICATION_NOTIFICATION_STATUS,
    userId,
  };
}

/**
 * Notify when the user toggles the notification button
 *
 * @param application status
 *
 */
export function getApplicationNotificationStatusSuccessAction(
  notificationInfo,
) {
  return {
    type: GET_APPLICATION_NOTIFICATION_STATUS_SUCCESS,
    notificationInfo,
  };
}

/**
 * Notify when the user toggle notification status retrieval encounters an error
 *
 * @param {object} error the error object
 *
 */
export function getApplicationNotificationStatusErrorAction(error) {
  return {
    type: GET_APPLICATION_NOTIFICATION_STATUS_ERROR,
    error,
  };
}
