/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-constructor */
/* eslint-disable global-require */
// #region Imports
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import 'styles/common.css';
import './modulepage.css';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import * as moduleHelper from 'helpers/moduleHelper';
import { isNullOrUndefined } from 'util';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { SESSION_REDUX_NAME } from 'domains/session/hooks';
import { subscribeUserSelector } from 'domains/session/selectors';
import appInitPageReducer from 'domains/session/reducer';
import saga from '../TestSelectPage/saga';
import reducer from '../TestSelectPage/reducer';
import directionsPageReducer from '../DirectionsPage/reducer';
import history from '../../utils/history';
import { CERTIFYING_STATEMENT_PATH } from '../App/constants';
import RocketSVG from '../../svg/rocket.svg';
import 'linq';
import {
  instancesSelector,
  sectionSelector,
  resumeSectionSelector,
  selectedInstanceSelector,
} from '../DirectionsPage/selectors';
import { routerSelector } from '../App/selectors';
import { BREADCRUMB } from '../MasterPage/constants';
import { updateBreadCrumb } from '../MasterPage/actions';
import SectionSelect from '../../components/SectionSelect';
import ApolloContainer from '../../components/ApolloContainer';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import {
  getInstanceAction,
  undoSectionPauseAction,
  resumeSectionAction,
  breakTimeRemainingAction,
} from '../DirectionsPage/actions';
import DirectionsPanel from '../../components/DirectionsPanel';
import { getUserId } from '../../helpers/userHelper';
import { ExamMode } from '../../enumerations/ExamMode';
import { LogLevelType } from '../../enumerations/LogLevelType';
import * as sectionHelper from '../../helpers/sectionHelper';
import {
  logModuleComplete,
  mergePauseModuleState,
} from '../../helpers/persistence/examManagementHelper';
import * as logHelper from '../../helpers/logHelper';
import { fetchKeyAction } from '../../domains/session/actions';
import { initSagaLibrarySelector } from '../TestSelectPage/selectors';
import { unsubscribePush, subscribePush } from '../../helpers/pushHelper';
import { reactPlugin } from '../TestSelectPage/testselect.util';
import DuplicatePage from '../DuplicatePage';
import { isTestAlreadyInLocal } from '../../helpers/persistence/common/repoHelper';

/**
 * @class ModulePage
 * @description This is the section selection page.
 * @author Chad Adams (Microsoft)
 * @param {*} props
 * @example <ModulePage />
 */
// eslint-disable-next-line react/prefer-stateless-function
export class ModulePage extends React.Component {
  fContainer = 'flexbox-container';

  sectionAndRole = "section[role='main']";

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    // useInjectReducer({ key: 'testReview', reducer });
    this.module = null;
    this.instance = null;
    this.testInstanceId = null;
    this.selectedSection = null;
    this.breakInfo = null;

    this.state = {
      selectedTab: 0,
      shouldRenderDuplicatePage: false,
    };
  }

  goToCertPage() {
    history.replace(CERTIFYING_STATEMENT_PATH);
  }

  getModule() {
    logHelper.log(LogLevelType.Trace, 'getmodule', this.instance);
    const { testInstanceId } = this.props.match.params;
    this.instance = moduleHelper.getInstance(
      this.props.instances,
      testInstanceId,
    );

    if (!isNullOrUndefined(this.instance)) {
      this.module = this.instance.module;
    }
    this.testInstanceId = testInstanceId;
    if (
      (isNullOrUndefined(this.instance) ||
        isNullOrUndefined(this.instance.module.sections) ||
        this.instance.module.sections.length === 0) &&
      this.props.initSagaLibrary
    ) {
      this.props.onGetInstance({ testInstanceId, userId: getUserId() });
    }

    if (!isNullOrUndefined(this.instance) && this.props.isSectionResumed) {
      mergePauseModuleState(this.module, testInstanceId);
      sectionHelper.verifySectionPause(
        this.instance,
        this.props.onUndoSectionPause,
      );
      this.props.onSectionResume(false, testInstanceId);
    }
  }

  updateBreadcrumb() {
    // update breadcrumb
    const breadCrumbSettings = {
      ...BREADCRUMB.settings,
      isVisible: true,
    };
    this.props.onBreadCrumbLoading(breadCrumbSettings);
  }

  checkForCompletedModule() {
    if (moduleHelper.hasAllSectionsCompleted(this.module)) {
      // do not add to history if LSAT flex
      if (!moduleHelper.isRealExam(this.module)) {
        logModuleComplete();
        history.push(moduleHelper.getTestCompletePath(this.instance));
      }
    }
  }

  componentDidMount() {
    if (isNullOrUndefined(this.module)) {
      this.getModule();
    }
    if (
      this.props.instances &&
      this.props.instances.length === 0 &&
      moduleHelper.isRealExam(this.module)
    ) {
      this.goToCertPage();
    }
    const appDivElement = document.getElementById('app');
    if (appDivElement && appDivElement.focus) {
      appDivElement.focus();
    }

    const skipLink = document.getElementById('skipToMainLink');
    if (skipLink && skipLink.remove) {
      skipLink.remove();
    }
    this.checkForCompletedModule();
    this.updateBreadcrumb();
    // update the title accordingly
    if (!isNullOrUndefined(this.module)) {
      document.title = moduleHelper.getTabTitle(this.instance);
    }
    subscribePush(this.props.subscribeUser, this.props.onFetchKeyAction);

    const testId =
      this.props.selectedInstance?.testInstanceId ??
      this.testInstanceId ??
      undefined;
    if (isTestAlreadyInLocal(testId) && moduleHelper.isRealExam(this.module)) {
      this.setState({ shouldRenderDuplicatePage: true });
    }
  }

  componentDidUpdate() {
    this.checkForCompletedModule();
    this.updateBreadcrumb();
    subscribePush(this.props.subscribeUser, this.props.onFetchKeyAction);
  }

  componentWillUnmount() {
    unsubscribePush();
    document.getElementsByClassName(
      this.fContainer,
    )[0].style.backgroundColor = null;
    document.querySelector(this.sectionAndRole).style.margin = null;
    this.props.onBreadCrumbLoading({});
  }

  titleTabClick = () => {
    if (this.state.selectedTab !== 0) {
      this.setState({ selectedTab: 0 });
    }
  };

  sectionTabClick = () => {
    if (this.state.selectedTab !== 1) {
      this.setState({ selectedTab: 1 });
    }
  };

  getSelectedSection = () => {
    if (!isNullOrUndefined(this.module)) {
      let pausedSection = null;
      if (!isNullOrUndefined(this.props.section)) {
        pausedSection = this.module.sections.find(
          section =>
            section.isPaused && section.formId === this.props.section.formId,
        );
      }
      const inCompleteSection = this.module.sections.find(
        s => s.isCompleted === false,
      );
      if (!isNullOrUndefined(pausedSection)) {
        this.selectedSection = pausedSection;
      } else if (!isNullOrUndefined(inCompleteSection)) {
        this.selectedSection = inCompleteSection;
      } else {
        this.selectedSection = this.module.sections[0];
      }
    }
  };

  render() {
    if (this.state.shouldRenderDuplicatePage) {
      return <DuplicatePage />;
    }

    this.getModule();
    this.getSelectedSection();
    if (
      !isNullOrUndefined(this.module) &&
      !isNullOrUndefined(this.module.sections) &&
      this.module.sections.length > 0
    ) {
      const sectionSelection = this.module?.options?.allowSectionSelect
        ? 'Section Selection'
        : 'Sections';

      return (
        <ApolloContainer>
          <div className="mainContent">
            <Container className="rowContainer">
              <Row className="sectionSelection">
                <Col xl="6" lg="6" md="12" xs="12" className="answers_panel">
                  <Row>
                    <Col xs={{ size: 12 }} className="text-center">
                      <h1 id="pageHeader" className="moduleHeader">
                        {this.module.moduleName}
                      </h1>
                      <h2 className="sectionSelection sectionSelHeader">
                        {sectionSelection}
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="sectionSelectionContainer" xs="12">
                      <SectionSelect
                        module={this.module}
                        testInstanceId={this.instance.testInstanceId}
                        examMode={
                          !isNullOrUndefined(this.module.examMode)
                            ? this.module.examMode
                            : ExamMode.SelfPaced
                        }
                      />
                    </Col>
                  </Row>
                  <div className="rocketContainer">
                    <RocketSVG />
                  </div>
                </Col>
                <Col xl="6" lg="6" md="12" xs="12" className="directions_panel">
                  <DirectionsPanel
                    section={this.selectedSection}
                    testInstanceId={this.testInstanceId}
                    selectedInstance={this.props.selectedInstance}
                    onChangeBreakTime={this.props.onChangeBreakTime}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </ApolloContainer>
      );
    }
    const moduleName = isNullOrUndefined(this.module)
      ? 'module'
      : this.module.moduleName;
    return (
      <Container fluid style={{ width: '100vw' }}>
        <Row>
          <AriadneLoading text={moduleName} />
        </Row>
      </Container>
    );
  }
}

ModulePage.propTypes = {
  instances: PropTypes.array,
  initSagaLibrary: PropTypes.bool,
  isSectionResumed: PropTypes.bool,
  match: PropTypes.object,
  section: PropTypes.object,
  selectedInstance: PropTypes.object,
  subscribeUser: PropTypes.bool,
  onBreadCrumbLoading: PropTypes.func.isRequired,
  onGetInstance: PropTypes.func,
  onUndoSectionPause: PropTypes.func,
  onSectionResume: PropTypes.func,
  onChangeBreakTime: PropTypes.func,
  onFetchKeyAction: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  instances: instancesSelector(),
  router: routerSelector(),
  initSagaLibrary: initSagaLibrarySelector(),
  isSectionResumed: resumeSectionSelector(),
  section: sectionSelector(),
  selectedInstance: selectedInstanceSelector(),
  subscribeUser: subscribeUserSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onBreadCrumbLoading: breadCrumbSection =>
      dispatch(updateBreadCrumb(breadCrumbSection)),
    onGetInstance: testInstanceId =>
      dispatch(getInstanceAction(testInstanceId)),
    onUndoSectionPause: (module, testInstanceId) =>
      dispatch(undoSectionPauseAction(module, testInstanceId)),
    onSectionResume: (isSectionResumed, testInstanceId) =>
      dispatch(resumeSectionAction(isSectionResumed, testInstanceId)),
    onChangeBreakTime: (timeRemaining, sectionId) =>
      dispatch(breakTimeRemainingAction(timeRemaining, sectionId)),
    onFetchKeyAction: () => dispatch(fetchKeyAction()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'testSelectPage', saga });
const withReducer = injectReducer({ key: 'testSelectPage', reducer });
const withSessionReducer = injectReducer({
  key: SESSION_REDUX_NAME,
  reducer: appInitPageReducer,
});
const withDirectionsReducer = injectReducer({
  key: 'directionsPage',
  reducer: directionsPageReducer,
});

export default compose(
  withReducer,
  withDirectionsReducer,
  withSessionReducer,
  withSaga,
  withConnect,
)(withAITracking(reactPlugin, injectIntl(ModulePage), 'ModulePage'));
