import { defineMessages } from 'react-intl';

export const scope = 'app.containers.TestCompleteFlexPage';

export default defineMessages({
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage:
      'Congratulations, your enrollment in the LSAC PLUS Program is now complete.',
  },
  heading2: {
    id: `${scope}.heading2`,
    defaultMessage:
      'You are now part of the LSAC PLUS Program with nearly 250 other undergraduate students at 8 different law schools around the country. We look forward to meeting you (virtually) soon!',
  },
  heading3: {
    id: `${scope}.heading3`,
    defaultMessage: 'More about your LawHub account:',
  },
  heading4: {
    id: `${scope}.heading4`,
    defaultMessage: 'Reminders:',
  },
  bulletPoint1: {
    id: `${scope}.bulletPoint1`,
    defaultMessage:
      'You have immediate access to free Official LSAT Prep<span class="sup">SM</span>, including two Official LSAT PrepTests and one Official LSAT-Flex Sample test.',
  },
  bulletPoint2: {
    id: `${scope}.bulletPoint2`,
    defaultMessage:
      'Upon successful completion of the program, you will be eligible to receive an <strong>LSAC Fee Waiver</strong> which includes two free LSAT registrations and upgraded access to Official LSAT Prep Plus<span class="sup">SM</span> in addition to invitations to exclusive educational events. <strong>Details will be provided at the conclusion of your program.</strong>',
  },
  bulletPoint3: {
    id: `${scope}.bulletPoint3`,
    defaultMessage:
      '<strong>Look for a pre-survey</strong> that will be sent to you <strong>before your program begins.</strong>',
  },
  bulletPoint4: {
    id: `${scope}.bulletPoint5`,
    defaultMessage:
      'Visit <strong><a href="https://www.lsac.org/" target="_blank"><i class="fas fa-external-link-alt"></i> LSAC.org<span class="hidden">Will open in a new browser window.</span></a></strong> for more information and resources about researching and applying to law school.',
  },
  bulletPoint5: {
    id: `${scope}.bulletPoint6`,
    defaultMessage:
      'Connect with us on <strong>social media</strong> to keep up with the latest news and events.',
  },
  text1: {
    id: `${scope}.text1`,
    defaultMessage:
      '<strong>Questions?</strong> please contact us at <strong><a href="mailto:diversityoffice@LSAC.org">diversityoffice@LSAC.org</a></strong>.',
  },
  buttonText: {
    id: `${scope}.buttonText`,
    defaultMessage: 'Explore LawHub Now',
  },
});
