import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, Button } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { AccessibleModal } from '../../../components/AccessibleModal';
import './index.css';
import messages from './messages';
import viewSiteIcon from '../../../images/view-site-setting-icon-chrome.png';

function NotificationsModal({ isOpen }) {
  return (
    <AccessibleModal isOpen={isOpen} modalClassName="notifications-modal">
      <h2 className="notifications-modal-header">
        <FormattedMessage {...messages.heading} />
      </h2>
      <hr aria-hidden="true" className="room-scan-modal-hr" />
      <ModalBody className="notifications-modal-body">
        <span>
          <p>
            <FormattedHTMLMessage {...messages.firstPart} />
          </p>
          <ul>
            <li>
              <FormattedHTMLMessage {...messages.secondPart} />
              <img src={viewSiteIcon} alt="" width={40} height={31} />
              <FormattedHTMLMessage {...messages.thirdPart} />
            </li>
            <li>
              <FormattedHTMLMessage {...messages.fourthPart} />
            </li>
          </ul>
        </span>
        <Button style={{ display: 'none' }} />
      </ModalBody>
    </AccessibleModal>
  );
}

NotificationsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default NotificationsModal;
