/*
 *
 * ArticlePage constants
 *
 */
export const GET_ARTICLE_CONTENT = 'Athena/ArticlePage/GetArticleContent';
export const GET_ARTICLE_CONTENT_SUCCESS =
  'Athena/ArticlePage/GetArticleContentSuccess';
export const GET_ARTICLE_CONTENT_ERROR =
  'Athena/ArticlePage/GetArticleContentError';
