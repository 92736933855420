import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';

export const SESSION_REDUX_NAME = 'athenaSession';

export const useSessionReduxAndSaga = () => {
  useInjectReducer({ key: SESSION_REDUX_NAME, reducer });
  useInjectSaga({ key: SESSION_REDUX_NAME, saga });
};

export const useSessionRedux = () => {
  useInjectReducer({ key: SESSION_REDUX_NAME, reducer });
};
