/*
 * PageHeader Messages
 *
 * This contains all the text for the PageHeader component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.PageHeader';

export default defineMessages({
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  logoHome: {
    id: `${scope}.logoHome`,
    defaultMessage: 'LawHub Home',
  },
  helpLinkText: {
    id: `${scope}.helpLinkText`,
    defaultMessage: 'Need Help?',
  },
});
