/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/**
 *
 * persistence management helper
 *
 */
import { isNullOrUndefined } from 'util';
import { config } from 'config';
import { encrypt } from '../encryptHelper';
import { logException } from '../logHelper';
import { currentTimeAsISO } from '../timeHelper';
import { getUserId } from '../userHelper';
import { sendState } from './instanceStateManagementHelper';
import { initializeRepo } from './repoManagementHelper';
import { persistenceState, getInitialRepo } from './common/persistenceState';
import { getInstanceById, getInstanceRepo } from './common/repoHelper';
import { persistNow } from './common/storageHelper';
import { refreshSummary } from './common/summaryHelper';

import { getArrayItemByKey } from './common/arrayHelper';
export function clearRepo() {
  persistenceState.testInstanceId = null;
  persistenceState.repo = getInitialRepo();
  persistenceState.requestQueue = [];
}

export function pausePersistence() {
  persistenceState.isPaused = true;
}

export function resumePersistence() {
  persistenceState.isPaused = false;
}

/**
 * Called once on startup, initializes the timer and restores any previous state from local storage
 */
export async function initializePersistence() {
  try {
    persistenceState.refreshSummary = refreshSummary;
    if (isNullOrUndefined(window.document.documentMode)) {
      // we are not in IE, so check see see if we should be using indexedDB
      persistenceState.useIndexedDB =
        config.REACT_APP_USE_INDEXED_DB.toLowerCase() === 'true';
    } else {
      persistenceState.isIE = true;
    }
    await initializeRepo();
    window.setInterval(
      sendState,
      1000 / parseInt(config.REACT_APP_PERSIST_ITERATIONS_PER_SECOND, 10),
    );
  } catch (ex) {
    logException(ex, 'initializePersistence');
  }
}

export function persistenceSet(key, value) {
  if (isNullOrUndefined(persistenceState.repo.keyvals)) {
    persistenceState.repo.keyvals = {};
  }
  persistenceState.repo.keyvals[key] = value;
  persistNow();
}

export function persistenceGet(key) {
  if (!isNullOrUndefined(persistenceState.repo.keyvals)) {
    return persistenceState.repo.keyvals[key];
  }
  return undefined;
}

/**
 * Save the Flex results locally
 *
 * @param {object} testInstanceId the test instance
 * @param {string} passPhrase the pass phrase
 */
export function persistLocally(
  testInstanceId,
  passPhrase,
  examType = 'flexExam',
) {
  const instance = getInstanceById(testInstanceId);
  const userId = getUserId();
  const data = {
    userId,
    testInstanceId,
    state: instance.state,
    summary: instance.summary,
  };
  const encryptedText = encrypt(JSON.stringify(data), passPhrase);
  const a = document.createElement('a');
  a.href = URL.createObjectURL(
    new Blob([JSON.stringify(encryptedText, null, 2)], {
      type: 'application/octet-stream',
    }),
  );
  a.setAttribute('download', `${userId}-${testInstanceId}.${examType}`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * Save the entire persistence state locally
 */
export function persistAllLocally() {
  const userId = getUserId();
  const data = {
    userId,
    state: persistenceState,
  };
  const a = document.createElement('a');
  a.href = URL.createObjectURL(
    new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/octet-stream',
    }),
  );
  const dateTime = currentTimeAsISO()
    .replace(':', '')
    .split('.')[0];
  a.setAttribute('download', `${userId}-${dateTime}.json`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function addReadyToCheckIn(sectionId) {
  if (
    persistenceState?.module?.sections &&
    persistenceState.module.sections.length > 0
  ) {
    const section = getArrayItemByKey(
      persistenceState.module.sections,
      'title',
      sectionId,
    );
    const checkInData = {
      sectionLocation: section.sectionOrder,
      checkInReady: currentTimeAsISO(),
    };

    const instanceRepo = getInstanceRepo();
    if (!isNullOrUndefined(instanceRepo)) {
      const { state, summary } = instanceRepo;

      if (!isNullOrUndefined(summary)) {
        if (!summary.intermissionCheckInReady) {
          summary.intermissionCheckInReady = [];
        }
        summary.intermissionCheckInReady.push(checkInData);
      }
      if (!isNullOrUndefined(state)) {
        if (!state.intermissionCheckInReady) {
          state.intermissionCheckInReady = [];
        }
        state.intermissionCheckInReady.push(checkInData);
      }
    }
  }
}
