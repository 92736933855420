import { isNullOrUndefined } from 'util';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { config } from '../../config';

/**
 * Function to get PrepPlus status of user.
 *
 * @param subscriptionDetails the subscription information of user
 */
export function getPrepPlusStatus(subscriptionDetails) {
  const hasPrepPlusSubscription =
    !isNullOrUndefined(subscriptionDetails) &&
    subscriptionDetails.subscriptionType ===
      config.REACT_APP_USER_SUBSCRIPTION_PREP_PLUS &&
    config.REACT_APP_ALLOW_BUY_WHEN_ALREADY_PURCHASED.toLowerCase() !== 'true';
  if (subscriptionDetails && subscriptionDetails.expirationDate) {
    const remainingDays = getNumberOfDaysBetweenDates(
      new Date(subscriptionDetails.expirationDate),
      new Date(),
    );
    const daysLeftThreshold = parseInt(
      config.REACT_APP_DAYS_UNTIL_EXPIRATION,
      10,
    );
    return {
      hasPrepPlusSubscription,
      isExpiring: hasPrepPlusSubscription && remainingDays <= daysLeftThreshold,
      remainingDays,
    };
  }
  return { hasPrepPlusSubscription, isExpiring: false };
}

/**
 * Function to get number of days between two dates.
 *
 * @param date1 the start date
 * @param date2 the end date
 */
export function getNumberOfDaysBetweenDates(date1, date2) {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export const reactPlugin = new ReactPlugin();
