/* eslint-disable no-param-reassign */
/**
 *
 * progress timer helper
 *
 */
import { isNullOrUndefined } from 'util';

let instances = {};
let timer = null;

/**
 * hook into an existing timer instance or create a new one if necessary
 * @param {string} name the name of the timer
 * @param {number} timeRemaining the time remaining to initialize with if timer doesn't exist
 * @param {function} callback the function to execute when the timer fires
 */
export function hookTimer(
  name,
  timeRemaining,
  callback,
  unlimited = false,
  elapsedTime = 0,
) {
  let instance = instances[name];
  if (isNullOrUndefined(instance)) {
    instance = {
      startTime: new Date(),
      timeRemaining,
      callback,
      unlimited,
      elapsedTime,
    };
  } else {
    instance = {
      startTime: instance.startTime,
      timeRemaining: instance.timeRemaining,
      callback,
      unlimited,
      elapsedTime: instance.elapsedTime,
    };
  }
  instances[name] = instance;
  if (isNullOrUndefined(timer)) {
    timer = window.setInterval(tick, 100);
  }
  if (!isNullOrUndefined(callback)) {
    callback(getTimeRemainingFromState(instance));
  }
}

/**
 * unhook from a timer instance, for example if component is unloading. The timer instance will
 * still exist, so when another component hooks to it, it will preserve the previous values
 * @param {string} name the name of the timer
 */
export function unhookTimer(name) {
  const instance = instances[name];
  if (!isNullOrUndefined(instance)) {
    instance.callback = null;
  }
}

/**
 * Removes the timer instance specified. The next time hookTimer is called, it will be initialized
 * with the time remaining and start time. Call this when you don't want the start time to be
 * remembered, for example if we pause the test or complete a section.
 * @param {string} name the name of the timer
 */
export function resetTimer(name) {
  delete instances[name];
}

/**
 * Removes all timer instances
 */
export function resetAll() {
  instances = {};
}

/**
 * Loop through the timer instances, calculate the time remaining,
 * and call any active callbacks.
 */
function tick() {
  Object.entries(instances).forEach(entry => {
    const value = entry[1];
    if (!isNullOrUndefined(value.callback)) {
      const timeRemaining = getTimeRemainingFromState(value);
      value.callback(timeRemaining);
    }
  });
}

/**
 * Get the time remaining for a timer instance
 * @param {object} state the timer instance state to calculate reaining time for
 */
function getTimeRemainingFromState(state) {
  if (state.unlimited) {
    return state.elapsedTime + (new Date() - state.startTime);
  }
  return state.timeRemaining - (new Date() - state.startTime);
}

/**
 * Gets the time remaining for a named timer instance
 * @param {string} name the timer instance name
 * @param {number} defaultTimeRemaining the time to return if the timer instance doesn't exist
 */
export function getTimeRemaining(name, defaultTimeRemaining) {
  const instance = instances[name];
  if (isNullOrUndefined(instance)) {
    return defaultTimeRemaining;
  }
  return getTimeRemainingFromState(instance);
}
