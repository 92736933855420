/**
 *
 * persistence array helper
 *
 */

import { isNullOrUndefined } from 'util';
/**
 * find a matching object in an array, or create if if it doesn't exist
 * @param {array} array the array to search
 * @param {string} key the key for the array items
 * @param value the value to match
 */
export function getArrayItemByKey(array, key, value, createIfNotFound = true) {
  if (isNullOrUndefined(array)) {
    return null;
  }
  let foundItem = array.find(item => item[key] === value);
  if (isNullOrUndefined(foundItem) && createIfNotFound) {
    foundItem = {};
    foundItem[key] = value;
    array.push(foundItem);
  }
  return foundItem;
}

/**
 * check if array exists in parent, and create it on fly if it doesn't
 * @param {object} parent the parent object
 * @param {string} arrayName the array name to check for
 */
export function createArrayIfNecessary(parent, arrayName) {
  let array;
  if (!isNullOrUndefined(arrayName) && !isNullOrUndefined(parent)) {
    array = parent[arrayName];
    if (isNullOrUndefined(array)) {
      // eslint-disable-next-line no-param-reassign
      parent[arrayName] = [];
    }
  }
}
export function arrayUnique(array) {
  const a = array.concat();
  for (let i = 0; i < a.length; i += 1) {
    for (let j = i + 1; j < a.length; j += 1) {
      if (a[i] === a[j]) {
        a.splice(j, 1);
        j -= 1;
      }
    }
  }
  return a;
}
