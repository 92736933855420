/**
 *
 * Application online status table
 *
 * This contains the list of application which are active and current status
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ExternalLinkIconRebrand from 'images/external_link_outward_rebrand.svg';
import LockIcon from '../../images/lock_icon.svg';
import { formatTimeAgo } from '../../helpers/timeHelper';
import { getCapitalizedString } from '../../helpers/applicationStatusHelper';
import dashIcon from '../../images/dash_icon.svg';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import messages from './messages';
import './index.css';

const ApplicationStatusTable = props => {
  const { applicationList, hideContent } = props;
  const tableHeading = [
    'Year',
    'Term',
    'Degree',
    'Program',
    'Status',
    'Last Updated',
  ];

  const validateData = tData => {
    if (!isNullOrUndefined(tData) && tData !== '') {
      return tData;
    }
    return (
      <img
        src={dashIcon}
        alt={messages.noData.defaultMessage}
        className="dash-icon"
      />
    );
  };

  const getApplicationStatus = appDetail => (
    <tr className="app-table-header" key={appDetail.applicationId}>
      <td>{validateData(appDetail?.year)}</td>
      <td>{validateData(appDetail?.term)}</td>
      <td>{validateData(appDetail?.degree)}</td>
      <td>{validateData(appDetail?.programTitle)}</td>
      <td>
        {hideContent ? (
          <img src={LockIcon} alt={messages.subscribeToUnlock.defaultMessage} />
        ) : (
          validateData(appDetail?.status)
        )}
      </td>
      <td>{getLastUpdatedData(appDetail)}</td>
    </tr>
  );

  const getLastUpdatedData = appDetail => {
    if (hideContent) {
      return (
        <img src={LockIcon} alt={messages.subscribeToUnlock.defaultMessage} />
      );
    }

    return appDetail?.lastUpdated ? (
      getCapitalizedString(formatTimeAgo(appDetail?.lastUpdated))
    ) : (
      <img
        src={dashIcon}
        alt={messages.noData.defaultMessage}
        className="dash-icon"
      />
    );
  };

  return (
    <div>
      {applicationList &&
        applicationList.map(universityData => (
          <div key={universityData?.schoolCode}>
            {universityData?.applications &&
              universityData?.applications?.length > 0 && (
                <div className="aso-table-wrapper">
                  <h2 className="university-name">
                    {universityData?.schoolName}
                  </h2>
                  <table className="aso-table">
                    <caption className="sr-only">
                      {universityData?.schoolName}
                      <FormattedMessage {...messages.applicationDetails} />
                    </caption>
                    <thead>
                      <tr>
                        {tableHeading?.map(appHeading => (
                          <th key={appHeading}>{appHeading}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {universityData?.applications?.map(appDetail =>
                        getApplicationStatus(appDetail),
                      )}
                    </tbody>
                  </table>
                  {universityData?.asoUrl && (
                    <a
                      href={universityData?.asoUrl}
                      target="_blank"
                      title={messages.opensInNewWindow.defaultMessage}
                      className="view-details"
                    >
                      <span className="sr-only">
                        {universityData.schoolName}
                      </span>
                      <FormattedMessage {...messages.viewDetails} />
                      <img
                        src={ExternalLinkIconRebrand}
                        alt={messages.opensInNewWindow.defaultMessage}
                        className="external-link-icon"
                      />
                    </a>
                  )}
                </div>
              )}
          </div>
        ))}
    </div>
  );
};

ApplicationStatusTable.propTypes = {
  applicationList: PropTypes.arrayOf(PropTypes.object),
  hideContent: PropTypes.bool,
};

export default ApplicationStatusTable;
