/**
 *
 * Prometrics Test Duration Ended
 *
 */
import './index.css';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { displaySideNavBar } from '../MasterPage/actions';
import { flexExamsSelector } from '../TestSelectPage/selectors';
import { HOME_PATH } from '../App/constants';
import lsatLogo from '../../images/lsat-logo.png';
import history from '../../utils/history';

export function PMTestDurationEndedPage() {
  function closeTheTestHandler() {
    history.push(HOME_PATH);
  }

  return (
    <div className="pm-test-duration-ended-page-container">
      <div className="pm-test-duration-ended-page-header">
        <div className="pm-test-duration-ended-page-header-div">
          <div
            className="pm-test-duration-ended-page-header-text"
            id="pageHeader"
          >
            LSAT Session Duration
          </div>
        </div>
      </div>
      <div className="pm-test-duration-ended-page-top-decoration" />
      <div className="pm-test-duration-ended-page">
        <img
          className="pm-test-duration-ended-page-lsat-flex-logo"
          src={lsatLogo}
          alt="LSAT"
        />
        <hr
          className="pm-test-duration-ended-page-horizontal-rule"
          role="none"
        />
        <div>
          <div className="pm-test-duration-ended-page-text-content">
            <h1>LSAT Test Duration Error</h1>
            <p>
              Your testing session has ended because the maximum testing time of
              8 hours has been reached. Please refer to the LSAT FAQs for more
              information.
            </p>
          </div>
          <button
            className="browser-error-page-close-button"
            type="button"
            onClick={closeTheTestHandler}
          >
            <span className="browser-error-page-close-button-text">
              Return to LawHub
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  modules: flexExamsSelector(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onShowSideBar: hasToShowSideBar =>
      dispatch(displaySideNavBar(hasToShowSideBar)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(PMTestDurationEndedPage);
