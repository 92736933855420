import { defineMessages } from 'react-intl';

export const scope = 'app.container.ExamStartPage.RoomScanModal';

export default defineMessages({
  warning: {
    id: `${scope.warning}`,
    defaultMessage: 'Reminder: Your camera is recording your test session',
  },
  heading: {
    id: `${scope}.heading`,
    defaultMessage: 'Self Check-In Step 1 of 2',
  },
  heading2: {
    id: `${scope}.heading`,
    defaultMessage: 'Self Check-In Step 2 of 2',
  },
  instructions: {
    id: `${scope}.instructions`,
    heading:
      'Failure to perform a room scan will result in a security cancellation of your LSAT Writing and may delay the release of your LSAT score.',
    defaultMessage:
      '<ol><li>Remove all prohibited items, including watches, cellphones, scratch paper and non-religious head coverings (i.e., hats, hoods, beanies, bonnets, durags, scarves, etc.).</li><li>When you are ready, select the “Begin Room Scan” button for additional instructions. Perform all tasks as you check off the boxes. Do not rush. The room scan should take between 20 and 30 seconds.</li></ol>',
  },
  instructions2: {
    id: `${scope}.instructions`,
    heading:
      'Perform all tasks before checking each box. Failure to complete these tasks will result in a security cancellation of your LSAT writing and may delay the release of your LSAT score.',
    defaultMessage:
      '<p>Slowly pan your device&#39;s camera around your room, being sure to aim it at every wall, your workspace, and the floor around your workspace. Check the boxes below when you have completed these steps, then click the &#34Self Check-In Completed&#34 button. The &#34Self Check-In Completed&#34 button will not be enabled until all boxes are checked and 30 seconds have passed for the room scan.</p>',
  },
  checkboxText1: {
    id: `${scope}.checkboxText1`,
    defaultMessage:
      'I removed all prohibited items. <span class="indent"> Prohibited items include scratch paper and writing utensils, unless specifically pre-approved as a testing accommodation. </span>',
  },
  checkboxText2: {
    id: `${scope}.checkboxText2`,
    defaultMessage: 'I showed every wall to the camera.',
  },
  checkboxText3: {
    id: `${scope}.checkboxText3`,
    defaultMessage: 'I showed my workspace to the camera.',
  },
  checkboxText4: {
    id: `${scope}.checkboxText4`,
    defaultMessage: 'I showed the floor around my workspace to the camera.',
  },
  checkboxText5: {
    id: `${scope}.checkboxText5`,
    defaultMessage: 'I showed my ID to the camera.',
  },
  checkboxText6: {
    id: `${scope}.checkboxText6`,
    defaultMessage:
      'I disabled all third-party applications (e.g., Grammarly, MS Editor, Predictive Text).',
  },
  buttonText: {
    id: `${scope}.buttonText`,
    defaultMessage: 'Begin Room Scan',
  },
  buttonText2: {
    id: `${scope}.buttonText`,
    defaultMessage: 'Self Check-In Completed',
  },
});
