/* eslint-disable react/no-danger */
/* eslint-disable prettier/prettier */
import './index.css';
import history from 'utils/history';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isNullOrUndefined } from 'util';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { connect } from 'react-redux';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { userDetailsSelector } from 'domains/session/selectors';
import { useSessionRedux } from 'domains/session/hooks';
import messages from './messages';
import { HOME_PATH, WRITING_EXAM_COMPLETE_FINAL_PATH } from '../App/constants';
import {
  TITLE_LSAT_WRITING_EXAM,
  COMPLETE_PAGE_REDIRECTION_TIME,
} from '../MasterPage/constants';
import WritingResponse from '../../components/WritingResponse';

// suports user detail information
import {
  setRetrieveFlexExams,
  getFlexExamListAction,
} from '../TestSelectPage/actions';
import {
  flexExamsSelector,
  retrievedFlexExamsSelector,
  flexExamErrorSelector,
  passwordHashSelector,
} from '../TestSelectPage/selectors';
import { instancesSelector } from '../DirectionsPage/selectors';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';

// support for getting state that contains the acknowledgements
import directionsPageReducer from '../DirectionsPage/reducer';

// support for proctor writing exam
import proctorWritingSaga from '../ProctorWritingPage/saga';
import proctorWritingReducer from '../ProctorWritingPage/reducer';
import { errorSelector } from '../ProctorWritingPage/selector';
import { fulfillProctoringTestAction } from '../ProctorWritingPage/actions';
import { config } from '../../config';

let fulfillAPIAttempts = 0;
export function ExamCompletePage({
  userDetails,
  testInstanceId,
  instances,
  fulfillProctorExam,
  fulfillError,
}) {
  // required in order to get user details
  useSessionRedux();
  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  // required in order to get test instances
  useInjectReducer({ key: 'directionsPage', reducer: directionsPageReducer });

  // required in order to fulfill proctor writing exam
  useInjectSaga({ key: 'proctorWritingPage', saga: proctorWritingSaga });
  useInjectReducer({
    key: 'proctorWritingPage',
    reducer: proctorWritingReducer,
  });

  const location = useLocation();

  // required in order to get user details
  useEffect(() => {
    document.title = TITLE_LSAT_WRITING_EXAM;

    fulfillProctorWritingExam();

    const appDivElement = document.getElementById('app');
    if (appDivElement && appDivElement.focus) {
      appDivElement.focus();
    }
  }, []);

  useEffect(() => {
    // bypass error http code 400 for fulfill API
    if (fulfillError && fulfillError.response.status !== 400) {
      fulfillAPIAttempts += 1;
      fulfillProctorWritingExam();
    }
  }, [fulfillError]);

  useEffect(() => {
    if (config.WRITING_EXAM_COMPLETE_PAGE_FOR_GUARDIAN === 'true') {
      // Navigate to WritingExamCompletePage after 30 seconds
      setTimeout(() => {
        history.push(WRITING_EXAM_COMPLETE_FINAL_PATH);
      }, COMPLETE_PAGE_REDIRECTION_TIME);
    }
  }, []);

  const completeButtonClass =
    config.WRITING_EXAM_COMPLETE_PAGE_FOR_GUARDIAN === 'true'
      ? 'wr-test-complete-flex-page-close-button'
      : 'test-complete-flex-page-close-button';

  function fulfillProctorWritingExam() {
    // fulfill proctor exam if examType is Writing or Writing with perspectives
    const examTypeIsWriting = (
      location?.pathname?.split('examComplete/')[1].split('/')[0] === 'Writing' || 
    location?.pathname?.split('examComplete/')[1].split('/')[0] ==='WritingWithPerspectives');

    if (
      examTypeIsWriting &&
      userDetails &&
      fulfillAPIAttempts < 3
    ) {
      fulfillProctorExam(
        config.REACT_APP_LSAC_WRITING_EXAM_ID,
        userDetails.userId,
      );
    } else if (fulfillAPIAttempts > 2) {
      /* eslint-disable-next-line no-alert */
      window.alert('Please close the window to disconnect Proctor');
    }
  }

  if (isNullOrUndefined(testInstanceId)) {
    // this page was navigated to directly without any parameter (testInstanceId) or it was passed
    // a parameter but it doesn't not match any of the test instances in the state so simply redirect
    // to the home page
    history.push(HOME_PATH);

    // we are done here so return null to get out
    return null;
  }

  // accessibility support for keyboard navigation
  document.body.addEventListener('mousedown', () => {
    document.body.classList.remove('keyboard-focus');
    document.body.classList.add('mouse-focus');
  });

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('mouse-focus');
    document.body.classList.add('keyboard-focus');
  });

  function closeTheTestHandler() {
    if (config.WRITING_EXAM_COMPLETE_PAGE_FOR_GUARDIAN === 'true') {
      history.push(WRITING_EXAM_COMPLETE_FINAL_PATH);
    } else {
      history.push(HOME_PATH);
    }
  }

  function getTestInstance() {
    if (instances == null || testInstanceId == null) {
      return null;
    }

    return instances.find(
      instance => instance.testInstanceId === testInstanceId,
    );
  }

  const instance = getTestInstance();
  const writingSectionResponse = isNullOrUndefined(instance)
    ? null
    : instance?.module?.sections[0]?.items[0]?.writingText;

  return (
    <div className="test-complete-flex-page-container">
      <div className="test-complete-flex-page-header">
        <div className="test-complete-flex-page-header-div">
          <div className="test-complete-flex-page-header-text">
            <FormattedMessage {...messages.header} />
          </div>
        </div>
      </div>
      <div className="test-complete-flex-page-top-decoration" />
      <div className="test-complete-flex-page">
        <div id="heading1" className="heading-color">
          LSAT Writing
        </div>
        <hr className="test-complete-flex-page-horizontal-rule" role="none" />
        <div>
          <h1 className="test-complete-flex-page-heading1">
            <FormattedMessage {...messages.heading1} />
          </h1>
          <ol className="test-complete-flex-page-text-content" type="1">
            <li>
              <FormattedHTMLMessage {...messages.bulletPoint1} />
            </li>
            <li>
              <FormattedHTMLMessage {...messages.bulletPoint4} />
            </li>
            <li>
              <FormattedHTMLMessage {...messages.bulletPoint5} />
            </li>
          </ol>
          <button
            className={completeButtonClass}
            type="button"
            onClick={closeTheTestHandler}
          >
            <span className="test-complete-flex-page-close-button-text">
              {config.WRITING_EXAM_COMPLETE_PAGE_FOR_GUARDIAN === 'true' ? (
                <FormattedMessage {...messages.buttonText2} />
              ) : (
                <FormattedMessage {...messages.buttonText1} />
              )}
            </span>
          </button>
          <div style={{ clear: 'right' }}>
            <h2 id="pageHeader" className="test-complete-flex-page-heading2">
              <FormattedMessage {...messages.heading4} />
            </h2>
            <p className="test-complete-flex-page-text-content">
              <FormattedHTMLMessage {...messages.text1} />
            </p>
          </div>
          {!isNullOrUndefined(writingSectionResponse) && (
            <div>
              <h2 className="test-complete-flex-page-heading2">
                <FormattedMessage {...messages.heading5} />
              </h2>
              <WritingResponse
                writingSectionResponse={writingSectionResponse}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ExamCompletePage.propTypes = {
  userDetails: PropTypes.object,
  intl: intlShape.isRequired,
  testInstanceId: PropTypes.string,
  clearExamList: PropTypes.func,
  onGetFlexExams: PropTypes.func,
  retrievedFlexExams: PropTypes.bool,
  modules: PropTypes.array,
  error: PropTypes.object,
  fulfillError: PropTypes.object,
  passwordHash: PropTypes.string,
  instances: PropTypes.array,
  fulfillProctorExam: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  userDetails: userDetailsSelector(),
  retrievedFlexExams: retrievedFlexExamsSelector(),
  modules: flexExamsSelector(),
  error: flexExamErrorSelector(),
  passwordHash: passwordHashSelector(),
  instances: instancesSelector(),
  fulfillError: errorSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    clearExamList: () => dispatch(setRetrieveFlexExams(false)),
    onGetFlexExams: userId => dispatch(getFlexExamListAction(userId)),
    fulfillProctorExam: (examId, userId) =>
      dispatch(fulfillProctoringTestAction(examId, userId)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(ExamCompletePage));
