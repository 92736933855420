/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *
 * Checkbox
 *
 */

import './index.css';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

function Checkbox({
  onCheckChanged,
  disabled,
  checked,
  ariaDescribedBy,
  id,
  labelClassName,
  labelTextContent,
}) {
  const checkboxRef = useRef();

  function onClick() {
    onCheckChanged(checkboxRef.current.checked);
  }

  return (
    <React.Fragment>
      <input
        id={id}
        ref={checkboxRef}
        type="checkbox"
        defaultChecked={checked ? 'checked' : undefined}
        className="checkbox-container"
        onClick={onClick}
        disabled={disabled}
        // eslint-disable-next-line jsx-a11y/aria-props
        aria-describedby={ariaDescribedBy}
      />
      <label
        htmlFor={id}
        className={labelClassName}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: labelTextContent }}
      />
    </React.Fragment>
  );
}

Checkbox.propTypes = {
  onCheckChanged: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  ariaDescribedBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  labelTextContent: PropTypes.string.isRequired,
};

export default Checkbox;
