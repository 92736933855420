// eslint-disable consistent-return
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import './index.css';
import history from 'utils/history';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isNullOrUndefined } from 'util';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { connect } from 'react-redux';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import { createStructuredSelector } from 'reselect';
import * as examHelper from 'helpers/examHelper';
import * as browserHelper from 'helpers/browserHelper';
import { useLocation } from 'react-router-dom';
import messages from './messages';
import {
  HOME_PATH,
  PROMETRICS_COMPLETE_PATH,
  CONNECTIVITY_ERROR_PATH,
} from '../App/constants';
import { TITLE_TEST_FLEX_EXAM } from '../MasterPage/constants';
import { restrictBack } from '../../helpers/restrictBackHelper';
import { getUserId } from '../../helpers/userHelper';

// images
import lsatLogo from '../../images/lsat-logo.png';

// suports user detail information
import {
  setRetrieveFlexExams,
  getFlexExamListAction,
} from '../TestSelectPage/actions';
import {
  flexExamsSelector,
  retrievedFlexExamsSelector,
  flexExamErrorSelector,
  passwordHashSelector,
  initSagaLibrarySelector,
} from '../TestSelectPage/selectors';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';

// support for getting state that contains the acknowledgements
import directionsPageReducer from '../DirectionsPage/reducer';
import { instancesSelector } from '../DirectionsPage/selectors';

const url = require('url');
export function FlexCongratsPage({
  initSagaLibrary,
  intl,
  instances,
  modules,
  retrievedFlexExams,
  onGetFlexExams,
  error,
}) {
  // required in order to get user details
  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  // required in order to get test instances
  useInjectReducer({ key: 'directionsPage', reducer: directionsPageReducer });

  const [isPrometricsTest, setIsPrometricsTest] = useState(false);

  // required in order to set skipToMainLink post writing exam completion
  useEffect(() => {
    restrictBack();
    const skipId = document.getElementById('skipToMainLink');
    const rootDiv = document.getElementById('app');
    if (!skipId) {
      const element = document.createElement('a');
      element.setAttribute('id', 'skipToMainLink');
      element.setAttribute('aria-live', 'off');
      element.setAttribute('href', '#masterContentPane');
      element.text = 'Skip to main content';
      element.className = 'skipToMainLink';

      if (rootDiv) {
        rootDiv.insertBefore(element, rootDiv.children[0]);
      }
    }

    if (rootDiv && rootDiv.focus) {
      rootDiv.focus();
    }
  }, []);

  // required in order to get user details
  useEffect(() => {
    document.title = TITLE_TEST_FLEX_EXAM;
  }, []);

  useEffect(() => {
    // return to home screen if the testinstanceid is not a completed flex exam
    if (modules && modules.length > 0) {
      const currentExam = examHelper.getCurrentExam(modules);
      if (!currentExam || currentExam.examStatus !== 'Completed') {
        history.push(HOME_PATH);
      }
    }

    const hasFlexExams = !isNullOrUndefined(modules) && modules.length > 0;
    const isPrometrics = hasFlexExams
      ? examHelper.isPrometrics(modules[0])
      : false;
    setIsPrometricsTest(isPrometrics);
    if (isPrometrics && browserHelper.isAcceptableUserAgent()) {
      const timer = setTimeout(() => {
        redirectToPrometricsEndScreen();
        return undefined; // the linter complains about no return in a arrow function
      }, 180000); // 180 seconds

      return () => clearTimeout(timer); // clean up
    }
    return undefined; // the linter complains about no return in a arrow function
  }, [modules]);

  useEffect(() => {
    restrictBack();

    if (initSagaLibrary && !retrievedFlexExams) {
      if (isNullOrUndefined(error)) {
        onGetFlexExams(getUserId());
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          !isNullOrUndefined(error.message) &&
          !isNullOrUndefined(modules) &&
          error.message === 'Network Error'
        ) {
          history.push(`${CONNECTIVITY_ERROR_PATH}?type=flexStart`);
        } else {
          // eslint-disable-next-line no-alert
          const ret = window.confirm(
            'Error receiving data from the server. Select OK to retry.',
          );
          if (ret) {
            onGetFlexExams(getUserId());
          }
        }
      }
    }
    document.title = TITLE_TEST_FLEX_EXAM;
  }, [initSagaLibrary, retrievedFlexExams, error]);

  // fetching testInstanceId from query since it is no longer passed as a prop
  const location = useLocation();
  const testInstanceId = url.parse(location.search, true).query.id;

  function getTextFromHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';
    return text.replace('"', '').replace("'", '');
  }

  // gets test instance based on instances and testInstanceId
  const testInstance = getTestInstance();

  if (isNullOrUndefined(testInstanceId)) {
    // this page was navigated to directly without any parameter (testInstanceId) or it was passed
    // a parameter but it doesn't not match any of the test instances in the state so simply redirect
    // to the home page
    history.push(HOME_PATH);

    // we are done here so return null to get out
    return null;
  }

  let acksHtml = '';

  if (
    !isNullOrUndefined(testInstance) &&
    testInstance.module &&
    testInstance.module.sections
  ) {
    const mapping = [];
    // get all the acks from each module if they exist into a list of strings
    Array.prototype.forEach.call(testInstance.module.sections, section => {
      if (section.acknowledgements) {
        Array.prototype.forEach.call(
          section.acknowledgements,
          acknowledgement => {
            if (acknowledgement.content) {
              Array.prototype.forEach.call(acknowledgement.content, entry => {
                mapping.push({
                  text: getTextFromHtml(entry).toLowerCase(),
                  html: entry,
                });
              });
            }
          },
        );
      }
    });
    const sorted = mapping.sort((a, b) => {
      if (a.text > b.text) {
        return 1;
      }
      if (b.text > a.text) {
        return -1;
      }
      return 0;
    });
    sorted.forEach(item => {
      acksHtml += item.html;
    });
    // if there are no acks then handle it
    if (acksHtml === '') {
      acksHtml = intl.formatMessage({
        ...messages.noAcks,
      });
    }
  }

  // accessibility support for keyboard navigation
  document.body.addEventListener('mousedown', () => {
    document.body.classList.remove('keyboard-focus');
    document.body.classList.add('mouse-focus');
  });

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('mouse-focus');
    document.body.classList.add('keyboard-focus');
  });

  function closeTheTestHandler() {
    history.push(HOME_PATH);
  }

  function redirectToPrometricsEndScreen() {
    history.push(PROMETRICS_COMPLETE_PATH);
  }

  function getTestInstance() {
    if (instances == null || testInstanceId == null) {
      return null;
    }

    return instances.find(
      instance => instance.testInstanceId === testInstanceId,
    );
  }

  return (
    <div className="test-complete-flex-page-container">
      <div className="test-complete-flex-page-header">
        <div className="test-complete-flex-page-header-div">
          <div className="test-complete-flex-page-header-text">
            <FormattedMessage {...messages.header} />
          </div>
        </div>
      </div>
      <div className="test-complete-flex-page-top-decoration" />
      <div className="test-complete-flex-page">
        <img
          className="test-complete-flex-page-lsat-flex-logo"
          src={lsatLogo}
          alt={`${intl.formatMessage({ ...messages.logoText })}`}
        />
        <hr className="test-complete-flex-page-horizontal-rule" role="none" />
        <div>
          <h1 id="test-complete-flex-page-heading1">
            <FormattedMessage {...messages.heading1} />
          </h1>
          <h2 id="pageHeader" className="test-complete-flex-page-heading2">
            <FormattedMessage {...messages.heading2} />
          </h2>

          {isPrometricsTest ? (
            <div className="prometrics-complete-section">
              <h3 className="test-complete-flex-page-text-page-location-instruction-header">
                <strong>If you are testing remotely:</strong>
              </h3>
              <ol className="test-complete-flex-page-text-content" type="1">
                <li>
                  <FormattedHTMLMessage {...messages.pmRemoteBulletPoint1} />
                </li>
                <li>
                  <FormattedHTMLMessage {...messages.pmRemoteBulletPoint2} />
                </li>
              </ol>

              <h3 className="test-complete-flex-page-text-page-location-instruction-header">
                <strong>If you are testing in a center:</strong>
              </h3>
              <ol className="test-complete-flex-page-text-content" type="1">
                <li>
                  <FormattedHTMLMessage {...messages.pmInCenterBulletPoint1} />
                </li>
                <li>
                  <FormattedHTMLMessage {...messages.pmInCenterBulletPoint2} />
                </li>
              </ol>
            </div>
          ) : (
            <ol className="test-complete-flex-page-text-content" type="1">
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint1} />
              </li>
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint2} />
              </li>
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint3} />
              </li>
              <li>
                <FormattedHTMLMessage {...messages.bulletPoint4} />
              </li>
            </ol>
          )}
          {isPrometricsTest ? (
            browserHelper.isAcceptableUserAgent() ? (
              // PM user in PM browser
              <button
                className="test-complete-flex-page-close-button"
                type="button"
                onClick={redirectToPrometricsEndScreen}
              >
                <span className="test-complete-flex-page-close-button-text">
                  <FormattedMessage {...messages.proMetricsButtonText} />
                </span>
              </button>
            ) : (
              // PM User NOT in PM browser
              <button
                className="test-complete-flex-page-close-button"
                type="button"
                onClick={closeTheTestHandler}
              >
                <span className="test-complete-flex-page-close-button-text">
                  <FormattedMessage {...messages.buttonText} />
                </span>
              </button>
            )
          ) : (
            <button
              className="test-complete-flex-page-close-button"
              type="button"
              onClick={closeTheTestHandler}
            >
              <span className="test-complete-flex-page-close-button-text">
                <FormattedMessage {...messages.buttonText} />
              </span>
            </button>
          )}
          <div style={{ clear: 'right' }}>
            <h2 className="test-complete-flex-page-heading2">
              <FormattedMessage {...messages.heading4} />
            </h2>
            <p className="test-complete-flex-page-text-content">
              <FormattedHTMLMessage {...messages.text1} />
            </p>
          </div>

          {acksHtml !== '' && (
            <h2 className="test-complete-flex-page-heading3">
              <FormattedMessage {...messages.heading3} />
            </h2>
          )}
          {acksHtml !== '' && (
            <div
              className="test-complete-flex-page-text-acknowledgements"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: acksHtml }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

FlexCongratsPage.propTypes = {
  initSagaLibrary: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  instances: PropTypes.array,
  clearExamList: PropTypes.func,
  onGetFlexExams: PropTypes.func,
  retrievedFlexExams: PropTypes.bool,
  modules: PropTypes.array,
  error: PropTypes.object,
  passwordHash: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  instances: instancesSelector(),
  retrievedFlexExams: retrievedFlexExamsSelector(),
  modules: flexExamsSelector(),
  error: flexExamErrorSelector(),
  passwordHash: passwordHashSelector(),
  initSagaLibrary: initSagaLibrarySelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    clearExamList: () => dispatch(setRetrieveFlexExams(false)),
    onGetFlexExams: userId => dispatch(getFlexExamListAction(userId)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(FlexCongratsPage));
