/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable func-names */
/**
 *
 * config helper
 *
 */
import { config } from '../config';
import { HashSha512 } from './encryptHelper';
import * as logHelper from './logHelper';
import { LogLevelType } from '../enumerations/LogLevelType';
import { storeValueInLocal } from './localStorageHelper';

export const CONFIG_DETAILS_KEY = 'configDetails';
export const LOCAL_CONFIG_HASH_KEY = 'localConfigHash';

let localConfig;

try {
  localConfig = require('../localConfig').localConfig;
} catch (error) {
  // this just means the file doesn't exist and it's okay
}

/**
 * To get complete non-authentiacted API path
 *
 * @param {string} configKey The config Key
 */
export function getNonAuthAPIFullPath(configValue) {
  return `${config.REACT_APP_ENDPOINT}${
    config.REACT_APP_UNAUTHED_API_SUFFIX
  }${configValue}`;
}

/**
 * To get complete API path
 *
 * @param {string} configKey The config Key
 */
export function getAPIFullPath(configValue) {
  return `${config.REACT_APP_ENDPOINT}${
    config.REACT_APP_API_SUFFIX
  }${configValue}`;
}

export function getServerConfigHashFromLocal() {
  // get the local config if exists and verified. If the config doesn't exist or
  // is corrupted, return null for hash so that we retrieve latest from server
  const storedConfig = getServerConfigObjectFromLocal();
  return storedConfig?.configurationHash;
}

export function getServerConfigObjectFromLocal() {
  const storedConfig = localStorage.getItem(CONFIG_DETAILS_KEY);
  if (!storedConfig) return null;
  const validateHash = localStorage.getItem(LOCAL_CONFIG_HASH_KEY);
  if (!validateHash) return null;
  if (validateHash !== HashSha512(storedConfig)) return null;
  return JSON.parse(storedConfig);
}

export function saveServerConfigObjectToLocal(configObject) {
  const configJson = JSON.stringify(configObject);
  storeValueInLocal(CONFIG_DETAILS_KEY, configJson);
  storeValueInLocal(LOCAL_CONFIG_HASH_KEY, HashSha512(configJson));
}

let initialConfig = null;

function convertToKeyValues(obj) {
  const kv = [];
  Object.keys(obj).forEach(k => {
    kv.push({ Key: k, Value: obj[k] });
  });
  return kv;
}

export function mergeServerConfigIntoLocalConfig(configObject) {
  if (configObject && configObject.configurationNameValues) {
    if (!initialConfig) {
      initialConfig = convertToKeyValues(config);
    }
    const mergedValues = [];
    initialConfig.forEach(kv => {
      mergedValues.push({ Key: kv.Key, Value: kv.Value });
    });
    const serverConfigValues = JSON.parse(configObject.configurationNameValues);
    serverConfigValues.forEach(kv => {
      mergedValues.push({ Key: kv.Key, Value: kv.Value });
    });
    mergedValues.forEach(kv => {
      if (kv.Key !== 'REACT_APP_ENDPOINT') {
        config[kv.Key] = kv.Value;
      }
    });

    // only merge in the localConfig if it exists
    if (localConfig !== undefined && localConfig !== null) {
      const localConfigValues = convertToKeyValues(localConfig);
      localConfigValues.forEach(kv => {
        config[kv.Key] = kv.Value;
      });
    }

    logHelper.log(LogLevelType.Info, 'Configuration: ', config);
  }

  return config;
}
