/*
 *
 * ArticlePage actions
 *
 */

import {
  GET_ARTICLE_CONTENT,
  GET_ARTICLE_CONTENT_SUCCESS,
  GET_ARTICLE_CONTENT_ERROR,
} from './constants';

/**
 * Get article content action
 *
 * * @param {object} userId
 */
export function getArticleContentAction(articleId) {
  return {
    type: GET_ARTICLE_CONTENT,
    articleId,
  };
}

/**
 * Get article content success action
 *
 * @param {object} articleDto
 */
export function getArticleContentSuccessAction(articleDto) {
  return {
    type: GET_ARTICLE_CONTENT_SUCCESS,
    articleDto,
  };
}

/**
 * Notify when the article content retrieval encounters an error
 *
 * @param  {object} error The error object
 *
 */
export function getArticleContentErrorAction(error) {
  return {
    type: GET_ARTICLE_CONTENT_ERROR,
    error,
  };
}
