import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import AriadneLoading from '../ariadne/AriadneLoading/AriadneLoading';
import { LIBRARY_PATH, FULLTEST_PATH } from '../../containers/App/constants';

const NextRedirect = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);

  useEffect(() => {
    if (!searchParams.has('tonext') && !searchParams.has('url')) {
      const pathname =
        location.pathname === LIBRARY_PATH ? FULLTEST_PATH : location.pathname;
      window.location = `${window.location.origin}${pathname}?tonext=true`;
    }
  }, []);

  return (
    <Container
      fluid
      style={{
        width: '100vw',
      }}
    >
      <Row>
        <AriadneLoading text="" />
      </Row>
    </Container>
  );
};

export default NextRedirect;
