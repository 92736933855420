/*
 *
 * TestSelectPage reducer
 *
 */
import produce from 'immer';
import * as moduleHelper from 'helpers/moduleHelper';

import {
  GET_LIBRARY_SUCCESS,
  GET_LIBRARY_ERROR,
  GET_FLEX_EXAM_LIST_SUCCESS,
  GET_FLEX_EXAM_LIST_ERROR,
  INIT_LIBRARY_SAGA,
  PUT_INSTANCE_STATE_SUCCESS,
  SET_RETRIEVE_LIBRARY,
  SET_RETRIEVE_FLEX_EXAMS,
  PUT_INSTANCE_STATE_ERROR,
  SET_ERROR,
  STORE_EXAMPASSWORD_HASH,
  GET_TEST_INSTANCE_STATUS_SUCCESS,
  GET_TEST_INSTANCE_STATUS_ERROR,
  CLEAR_TEST_INSTANCE_STATUS,
  CLEAR_EXAM_LIST_ERROR,
} from './constants';
import {
  sendStateToServerSuccess,
  sendStateToServerError,
} from '../../helpers/persistence/instanceStateManagementHelper';

export const initialState = {
  items: [],
  flexExams: [],
  retrievedLibrary: false,
  retrievedFlexExams: false,
  initSagaLibrary: false,
  selectedModule: null,
  passwordHash: null,
};

/* eslint-disable default-case, no-param-reassign */
const testSelectPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_LIBRARY_SUCCESS:
        draft.items = moduleHelper.convertModules(
          action.libraryData,
          state.items,
        );
        draft.retrievedLibrary = true;
        draft.error = undefined;
        break;
      case GET_LIBRARY_ERROR:
        draft.error = action.error;
        break;
      case GET_FLEX_EXAM_LIST_SUCCESS:
        draft.flexExams = action.libraryData;
        draft.retrievedFlexExams = true;
        draft.flexExamError = undefined;
        break;
      case GET_FLEX_EXAM_LIST_ERROR:
        draft.flexExamError = action.error;
        break;
      case CLEAR_EXAM_LIST_ERROR:
        draft.flexExamError = undefined;
        break;
      case INIT_LIBRARY_SAGA:
        draft.initSagaLibrary = true;
        break;
      case PUT_INSTANCE_STATE_SUCCESS:
        sendStateToServerSuccess(action.data.testInstanceId);
        draft.testSummary = action.data.summary;
        draft.testInstanceRemoteStatus = action.data.testInstanceRemoteStatus;
        break;
      case SET_RETRIEVE_LIBRARY:
        draft.retrievedLibrary = action.isLibraryRetrieved;
        break;
      case SET_RETRIEVE_FLEX_EXAMS:
        draft.retrievedFlexExams = action.isFlexExamsRetrieved;
        break;
      case PUT_INSTANCE_STATE_ERROR:
        sendStateToServerError(action.testInstanceId, action.error);
        break;
      case SET_ERROR:
        draft.error = action.error;
        break;
      case STORE_EXAMPASSWORD_HASH:
        {
          const parts = action.passwordHash
            ? action.passwordHash.split('|')
            : ['', ''];
          // eslint-disable-next-line prefer-destructuring
          draft.passwordHash = parts[0];
          // eslint-disable-next-line prefer-destructuring
          draft.sequenceHash = parts[1];
        }
        break;
      case GET_TEST_INSTANCE_STATUS_SUCCESS:
        draft.testInstanceStatus = action.data;
        break;
      case CLEAR_TEST_INSTANCE_STATUS:
        draft.testInstanceStatus = null;
        draft.testInstanceStatusError = null;
        break;
      case GET_TEST_INSTANCE_STATUS_ERROR:
        draft.testInstanceStatusError = action.error;
        break;
    }
  });

export default testSelectPageReducer;
