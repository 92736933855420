/*
 * ToolsExploreSlider Messages
 *
 * This contains all the text for the ToolsExploreSlider component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ToolsExploreSlider';

export default defineMessages({
  opensInNewBrowserWindow: {
    id: `${scope}.opensInNewBrowserWindow`,
    defaultMessage: 'Opens in a new browser window.',
  },
});
