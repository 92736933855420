import { defineMessages } from 'react-intl';
import { config } from '../../config';

export const scope = 'app.container.FlexConnectivityErrorPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME} / Registration Notification`,
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  logoText: {
    id: `${scope}.logoText`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME}`,
  },
  retryFirstParagraph: {
    id: `${scope}.retryFirstParagraph`,
    defaultMessage:
      'We seem to be experiencing a connection issue. Your test has been paused. Please do not close the browser or navigate from this screen.',
  },
  attemptingToRetry: {
    id: `${scope}.attemptingToRetry`,
    defaultMessage: 'Attempting to reconnect',
  },
  retrySecondParagraph: {
    id: `${scope}.retrySecondParagraph`,
    defaultMessage:
      'Please check your system settings and try to reestablish a connection.When you have a connection select Retry to return to the Start screen.',
  },
  retryTimeCompleted: {
    id: `${scope}.retryTimeCompleted`,
    defaultMessage:
      'We are unable to reconnect. Please check your WIFI connection and click Retry.',
  },
});
