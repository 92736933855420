/*
 *
 * DirectionsPage constants
 *
 */

export const INIT_SECTION_SAGA = 'Aetha/SectionPage/InitSectionSaga';
export const SET_SECTION = 'Aetha/SectionPage/SetSection';
export const CHANGE_FONTSIZE = 'Aetha/SectionPage/ChangeFontSize';
export const CHANGE_LINEHEIGHT = 'Aetha/SectionPage/ChangeLineHeight';
export const CHANGE_SELECTION = 'Aetha/SectionPage/ChangeSelection';
export const CHANGE_OPTION_STATE = 'Aetha/SectionPage/ChangeOptionState';
export const CHANGE_OPTION_MODE = 'Aetha/SectionPage/ChangeOptionMode';
export const CHANGE_WRITING_TEXT = 'Aetha/SectionPage/ChangeWritingText';
export const MARK_FOR_REVIEW = 'Aetha/SectionPage/MarkForReview';
export const CHANGE_HIGHLIGHT = 'Aetha/SectionPage/ChangeHighlight';
export const CHANGE_TIME_REMAINING = 'Aetha/SectionPage/ChangeTimeRemaining';
export const CHANGE_ANALYSIS_TIME_REMAINING =
  'Athena/SectionPage/ChangeAnalysisTimeRemaining';
export const CHANGE_ELAPSED_TIME = 'Aetha/SectionPage/ChangeElapsedTime';
export const SHOW_ANSWER = 'Aetha/SectionPage/ShowAnswer';
export const SHOW_ANSWER_CLICKED = 'Aetha/SectionPage/ShowAnswerClicked';
export const SET_TIME_VISIBILITY = 'Aetha/SectionPage/SetTimeVisiblity';
export const SET_SECTION_COMPLETE = 'Aetha/SectionPage/SectionComplete';
export const SET_PAUSE_SECTION = 'Aetha/SectionPage/PauseSection';
export const CHANGE_HIGHLIGHT_STATE = 'Aetha/SectionPage/ChangeHighlightState';
export const SELECT_INSTANCE = 'Aetha/TestSelectPage/SelectInstance';

export const GET_FLEX_INSTANCE = 'Aetha/DirectionsPage/GetFlexInstance';
export const GET_FLEX_INSTANCE_SUCCESS =
  'Aetha/DirectionsPage/GetFlexInstanceSuccess';
export const GET_FLEX_INSTANCE_FAILURE =
  'Aetha/DirectionsPage/GetFlexInstanceFailure';

export const GET_NEW_INSTANCE = 'Aetha/DirectionsPage/GetNewInstance';
export const GET_NEW_INSTANCE_SUCCESS =
  'Aetha/DirectionsPage/GetNewInstanceSuccess';
export const GET_NEW_INSTANCE_FAILURE =
  'Aetha/DirectionsPage/GetNewInstanceFailure';
export const GET_EXISTING_INSTANCE = 'Aetha/DirectionsPage/GetExistingInstance';
export const GET_EXISTING_INSTANCE_SUCCESS =
  'Aetha/DirectionsPage/GetExistingInstanceSuccess';
export const GET_EXISTING_INSTANCE_FAILURE =
  'Aetha/DirectionsPage/GetEXISTINGInstanceFailure';
export const LOAD_SECTION = 'Aetha/DirectionsPage/LoadSection';
export const UNLOAD_SECTION = 'Aetha/DirectionsPage/UnLoadSection';
export const SET_PASSAGE_VIEW = 'Aetha/DirectionsPage/SetPassageViewAction';
export const SET_SECTION_LOAD_COMPLETE =
  'Aetha/DirectionsPage/SetSectionLoadComplete';
export const UNDO_SECTION_PAUSE_ACTION =
  'Aetha/SectionPage/UndoSectionPauseAction';
export const RESUME_SECTION_ACTION = 'Aetha/SectionPage/ResumeSectionAction';
export const SET_FLEX_EXAM_PAUSE_ACTION =
  'Aetha/SectionPage/FlexExamPauseAction';
export const CHANGE_BREAK_TIME_REMAINING =
  'Aetha/SectionPage/ChangeBreakTimeRemaining';
export const CHANGE_INTERMISSION_TIME_REMAINING =
  'Aetha/SectionPage/ChangeIntermissionTimeRemaining';
export const CHANGE_READY_TO_CHECKIN_STATUS =
  'Aetha/SectionPage/ChangeReadyToCheckinStatus';
export const SET_WRITING_ONLY_MODE = 'Aetha/SectionPage/SetWritingOnlyMode';
export const UPDATE_NOTES_TEXT = 'Aetha/SectionPage/UpdateNotesText';
export const SET_NOTES_IS_EXPANDED = 'Aetha/SectionPage/SetNotesIsExpanded';
export const ON_PROCTOR_DISCONNECT = 'Aetha/SectionPage/ProctorDisconnected';
export const CLEAR_INSTANCE_ERROR = 'Aetha/DirectionsPage/ClearInstanceError';
export const SET_ONE_HOUR_WARNING_DISMISSED =
  'Aetha/Test/SetOneHourWarningDismissed';
export const TEST_TYPE_DRILL_SET = 'DrillSet';
export const ITEM_VIEW_HINT = 'Athena/SectionPage/ItemViewHint';
export const SET_SHOW_HINTS = 'Athena/SectionPage/ShowHints';
