/**
 *
 * persistence state helper
 *
 */
import { config } from '../../../config';
export const CURRENT_REPO_VERSION = '2.0.1';

export function getInitialRepo() {
  return { version: CURRENT_REPO_VERSION, instances: [] };
}

export const persistenceState = {
  initialized: false,
  testInstanceId: null,
  testInstanceStateCall: null,
  repo: getInitialRepo(),
  requestQueue: [],
  lastPersist: Date.now(),
  requestTime: Date.now(),
  requestInProgress: false,
  useIndexedDB: false,
  isIE: false,
  lastSuccessfulSend: Date.now(),
  secondsBetweenRequests: parseInt(
    config.REACT_APP_PERSIST_SECONDS_BETWEEN_REQUESTS,
    10,
  ),
  isPaused: false,
  changedValues: {},
  shouldStopRetry: false,
  launchDetails: [],
  isCurrentTestCompleted: false,
  completedTestIds: [],
  testInstanceRemoteStatus: [],
};
