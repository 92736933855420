import { useRef, useCallback } from 'react';

const useAnimationFrameThrottle = (callback, delay) => {
  const lastCall = useRef(0);
  const rafId = useRef(null);

  const throttledCallback = useCallback(
    (...args) => {
      const now = Date.now();
      const timeSinceLastCall = now - lastCall.current;

      if (timeSinceLastCall >= delay) {
        lastCall.current = now;

        if (rafId.current) {
          cancelAnimationFrame(rafId.current);
        }

        rafId.current = requestAnimationFrame(() => {
          callback(...args);
        });
      }
    },
    [callback, delay],
  );

  return throttledCallback;
};

export default useAnimationFrameThrottle;
