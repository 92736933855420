/**
 *
 * @module timeHelper
 * @description Helper for calculating and formatting time
 */
import isNullOrUndefined from '../utils/isNullOrUndefined';

/**
 * @example
 * // returns "1h 40m"
 * millisToHoursMinsTextShortVersion(6000000);
 * @param {number} millis The number of milliseconds elapsed since January 1, 1970
 * @returns {string}
 */
export function millisToHoursMinsTextShortVersion(millis) {
  return millisToHoursMinsText(millis, false);
}

/**
 * Converts milliseconds to hours and minutes (long version)
 * @example
 * // returns "1 hour and 40 minutes"
 * millisToHoursMinsTextLongVersion(6000000);
 * @param  {number} millis The number of milliseconds to convert
 * @returns {string} The hours and minutes as a string
 */
export function millisToHoursMinsTextLongVersion(millis) {
  return millisToHoursMinsText(millis, true);
}

/**
 * Converts milliseconds to hours and minutes (short version), for example "3h 5m" or
 * converts milliseconds to hours and minutes (long version), for example "3 hours and 5 seconds"
 * depending on the boolean parameter passed. The two versions are needed as the short version
 * is used for display on the screen whereas the long version is used for accessibility and screen
 * readers.
 *
 * @param  {number} millis The number of milliseconds to convert
 * @param  {boolean} longVersion Flag to determine whether to return the short or long version
 *
 */
function millisToHoursMinsText(millis, longVersion) {
  // Helper internal function for converting to user friendly time.
  let minutes = Math.floor(millis / 60000);
  const hours = Math.floor(minutes / 60);
  minutes -= hours * 60;
  let totalTime = '';
  if (longVersion) {
    // Default to plural and adjust below for singularity
    let hoursText = 'hours';
    let minutesText = 'minutes';

    if (hours === 1) {
      hoursText = 'hour';
    }

    if (minutes === 1) {
      minutesText = 'minute';
    }

    if (hours >= 1) {
      totalTime += `${hours} ${hoursText} and `;
    }
    totalTime += `${minutes} ${minutesText}`;

    return totalTime;
  }

  if (hours >= 1) {
    totalTime += `${hours}h `;
  }
  totalTime += `${minutes}m`;

  return totalTime;
}

/**
 * Get total time
 * @param {array<SectionModel>} sections - The array of sections
 * @returns The total time in milliseconds of all sections combined
 */
export function getTotalTime(sections) {
  let totalTime = 0;
  let i = 0;
  for (i = 0; i < sections.length; i += 1) {
    const section = sections[i];
    totalTime += section.totalSectionTime - section.timeRemaining;
  }
  return totalTime;
}

/**
 * @example
 * // returns "September 2021"
 * dateToMonthYear(new Date());
 * @param {Date} The date object
 * @returns The date as a string
 */
export function dateToMonthYear(date) {
  if (date !== null && date !== undefined) {
    return `${getMonthAsString(date.getMonth())} ${date.getFullYear()}`;
  }
  return date;
}

/**
 * @example
 * // returns "09/27/21"
 * dateToMonthDateYear(new Date());
 * @param {Date} date The date object
 * @returns The date as a string
 */
export function dateToMonthDateYear(date) {
  if (date !== null && date !== undefined) {
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);

    return `${month}/${day}/${date
      .getFullYear()
      .toString()
      .substr(2, 2)}`;
  }
  return date;
}

/**
 * @example
 * // returns "24"
 * dateToTime(new Date());
 * @param {Date} date The date object
 * @returns The day as a string
 */
export function dateToDayOnly(date) {
  if (date !== null && date !== undefined) {
    const day = `0${date.getDate()}`.slice(-2);
    return day;
  }
  return date;
}

/**
 * @example
 * // returns "Feb"
 * dateToTime(new Date());
 * @param {Date} date The date object
 * @returns The month as a string
 */

export function dateToMonthOnly(date) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (date !== null && date !== undefined) {
    const month = `${date.getMonth() + 1}`.slice(-2);
    return months[month - 1];
  }
  return date;
}

/**
 * @example
 * // returns "10:58 AM"
 * dateToTime(new Date());
 * @param {Date} date The date object
 * @returns The time as a string
 */
export function dateToTime(date) {
  if (date !== null && date !== undefined) {
    return `${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }
  return date;
}

/**
 * @example
 * // returns "September 27 2021, 10:57 AM"
 * dateToDayMonthYearTimeLong(new Date());
 * @param {Date} date The date object
 * @returns The date as a string
 */
export function dateToDayMonthYearTimeLong(date) {
  return `${dateToDayMonthYearLong(date)}, ${dateToTime(date)}`;
}

/**
 * Takes a date string and parses it into a date object
 * @param {string} dateString The date represented as a string
 * @returns {Date|null} The date object
 */
export function parseDateString(dateString) {
  const timestamp = Date.parse(dateString);
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(timestamp)) {
    return new Date(timestamp);
  }
  return null;
}

/**
 * @example
 * // returns "September 24 2021"
 * dateToDayMonthYearLong(new Date());
 * @param {Date} date The date object
 * @returns {string} The date in a long text format
 */
export function dateToDayMonthYearLong(date) {
  if (date !== null && date !== undefined) {
    return `${getMonthAsString(
      date.getMonth(),
    )} ${date.getDate()} ${date.getFullYear()}`;
  }
  return date;
}

/**
 * @example
 * // returns "2021-09-24T13:52:51.791Z"
 * currentTimeAsISO();
 * @returns {string} The date in ISO string format
 */
export function currentTimeAsISO() {
  return new Date().toISOString();
}

/**
 * Adds a year to the current date
 * @example
 * // Given the current date is "September 24, 2021"
 * // returns "September 24, 2022"
 * addYearToDate();
 * @returns {string} Returns the date as a string
 */
export function addYearToDate() {
  const date = new Date();
  if (date !== null && date !== undefined) {
    return `${getMonthAsString(
      date.getMonth(),
    )} ${date.getDate()}, ${date.getFullYear() + 1}`;
  }
  return date;
}

/**
 * Gets the month as a string based on the passed index
 * @example
 * // returns "January"
 * getMonthAsString(0);
 * @param {number} month Index [0, 11] with 0 being January and 11 being December
 * @returns {string|null} Returns the month as a string
 */
export function getMonthAsString(month) {
  if (month !== null && month !== undefined) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return `${months[month]}`;
  }
  return null;
}
/**
 * Gets the short form month as a string based on the passed index
 * @example
 * // returns "Jan"
 * getMonthAsStringShortForm(0);
 * @param {number} month Index [0, 11] with 0 being Jan and 11 being Dec
 * @returns {string|null} Returns the month as a string
 */
export function getMonthAsStringShortForm(month) {
  if (month !== null && month !== undefined) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    return `${months[month]}`;
  }
  return null;
}
/**
 * Gets the day as a string based on the passed index
 * @example
 * // returns "Sun"
 * getDayAsStringShortForm(0);
 * @param {number} day Index [0, 6] with 0 being Sun and 6 being Sat
 * @returns {string|null} Returns the day as a string
 */
export function getDayAsStringShortForm(day) {
  if (day !== null && day !== undefined) {
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
    return `${days[day]}`;
  }
  return null;
}
/**
 * Gets the day as a string based on the passed index
 * @example
 * // returns "Sunday"
 * getDayAsStringForm(0);
 * @param {number} day Index [0, 6] with 0 being Sunday and 6 being Saturday
 * @returns {string|null} Returns the day as a string
 */
export function getDayAsString(day) {
  if (day !== null && day !== undefined) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return `${days[day]}`;
  }
  return null;
}

/**
 * @example
 * // returns " Mon Sept 24, 2021"
 * dateToDayStringMonthYear(new Date());
 * @param {Date} date The date object
 * @returns {string} The date in a long text format
 */
export function dateToDayStringMonthYear(date) {
  if (date !== null && date !== undefined) {
    return `${getDayAsString(date.getDay())}, ${getMonthAsStringShortForm(
      date.getMonth(),
    )} ${date.getDate()}, ${date.getFullYear()}`;
  }
  return date;
}
/**
 * @example
 * // returns " Monday September 24, 2021"
 * dateToDayStringMonthYearFull(new Date());
 * @param {Date} date The date object
 * @returns {string} The date in a long text format
 */
export function dateToDayStringMonthYearForm(date) {
  if (date !== null && date !== undefined) {
    return `${getDayAsString(date.getDay())} ${getMonthAsString(
      date.getMonth(),
    )} ${date.getDate()}, ${date.getFullYear()}`;
  }
  return date;
}

/**
 * @example
 * // returns "{
 * days: 14
  hours: 12
  minutes: 51
  seconds: 47
  total: 1255907000
 * }"
 * CountDownTimer(new Date());
 * @param {Date} date The date object
 * @returns {Object} The time remaining in days, hours, minutes,seconds,total 
 */

export const CountDownTimer = countDown => {
  // calculate time left
  if (countDown !== null && countDown !== undefined && countDown > 0) {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }
  return '';
};

/* getTimeRemaining(new Date());
 * @param {Date} date The date object
 * @returns {Object} The time remaining in days, hours, minutes,seconds,total
 */
export function getTimeRemaining(date) {
  if (date !== null && date !== undefined) {
    const total = Date.parse(date) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }
  return date;
}

/**
 * @example
 *  returns "IST"
 *  getTimezoneNameAbbr()
 * @returns {string} abbreviation of local time zone
 */
export function getTimezoneNameAbbr(eventDate) {
  let timeZoneName = String(String(eventDate).split('(')[1]).split(')')[0];
  if (timeZoneName.includes(' ')) {
    const timeZoneNameArr = timeZoneName.split(' ');
    let abbr = '';
    timeZoneNameArr.forEach(value => {
      abbr += value.charAt(0);
    });
    timeZoneName = abbr;
  }

  const timeZoneMappings = {
    EDT: 'ET',
    EST: 'ET',
    PDT: 'PT',
    PST: 'PT',
    ADT: 'AT',
    AST: 'AT',
    CDT: 'CT',
    CST: 'CT',
    MDT: 'MT',
    MST: 'MT',
    HDT: 'HT',
    HST: 'HT',
  };

  return timeZoneMappings[timeZoneName] || timeZoneName;
}

/**
 * @example
 * // returns "September 24, 2022"
 * dateToDayMonthYearLongWith(new Date());
 * @param {Date} date The date object
 * @returns {string} The date in a long text format
 */
export function dateToDayMonthYearLongWithComma(date) {
  if (date !== null && date !== undefined) {
    return `${getMonthAsString(
      date.getMonth(),
    )} ${date.getDate()}, ${date.getFullYear()}`;
  }
  return date;
}

/**
 * @example
 * // returns "10:58 a.m."
 * formatAmPm(new Date());
 * @param {Date} date The date object
 * @returns The time as a string
 */
export function formatAmPm(date) {
  const eventDate = new Date(date);
  let hours = eventDate.getHours();
  let minutes = eventDate.getMinutes();
  const ampm = hours >= 12 ? 'p.m.' : 'a.m.';
  hours %= 12;
  hours = hours || 12;
  minutes = minutes.toString().padStart(2, '0');
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

/**
 * @example
 * // returns true or false
 * isDatesEqual(new Date(), new Date());
 * @returns The time as a string
 */
export function isDatesEqual(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

/**
 * @example
 * // returns "10:06"
 * secondsToHHMMSS(seconds);
 * @param seconds
 * @returns The time as a string
 */
export function secondsToHHMMSS(secs) {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map(v => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
}

const getRelativeFormat = options => new Intl.RelativeTimeFormat('en', options);

/**
 * @example
 * // returns today, 3 days ago, 1 year ago
 * formatTimeAgo(new Date());
 * @returns Formatted time in string in difference with current time
 */
export function formatTimeAgo(date) {
  if (!isNullOrUndefined(date)) {
    // converting UTC to current timezone
    const localeDate = new Date(date);
    let duration =
      (localeDate.setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) /
      1000;
    let options = {
      numeric: 'always',
    };
    const DIVISIONS = [
      { amount: 7, name: 'days' },
      { amount: 4.34524, name: 'weeks' },
      { amount: 12, name: 'months' },
      { amount: Number.POSITIVE_INFINITY, name: 'years' },
    ];

    // for within 1 day calculation
    duration /= 60 * 60 * 24;

    for (let i = 0; i <= DIVISIONS.length; i += 1) {
      const division = DIVISIONS[i];
      if (Math.abs(duration) < division.amount) {
        if (
          division.name === 'days' &&
          [0, 1].includes(Math.abs(Math.round(duration)))
        ) {
          options = {
            numeric: 'auto',
          };
        }
        return getRelativeFormat(options).format(
          Math.round(duration),
          division.name,
        );
      }
      duration /= division.amount;
    }
  }
  return '';
}

/**
 * Calculate the duration in seconds between a specified date and
 * the current date in the user's local timezone.
 *
 * @param {Date | string | null | undefined} [date] - The date or date
 * string to calculate the duration from.
 * @returns {number | undefined} - The duration in seconds or undefined.
 */
export const calculateDurationInSeconds = date => {
  if (
    isNullOrUndefined(date) ||
    // eslint-disable-next-line no-restricted-globals
    (typeof date === 'string' && isNaN(new Date(date)))
  ) {
    return undefined;
  }

  const localeDate = new Date(date);
  const currentDate = new Date();

  // Set the time of day to midnight for each date
  localeDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  // Calculate and return the duration in seconds
  return (localeDate - currentDate) / 1000;
};
