/*
 *
 * AthenaSession constants
 *
 */

export const INIT_LIBRARY_SAGA = 'Athena/Session/InitLibrarySaga';
export const GET_USER_DETAILS = 'Athena/Session/GetUserDetails';
export const GET_USER_DETAILS_SUCCESS = 'Athena/Session/GetUserDetailsSuccess';
export const GET_USER_DETAILS_ERROR = 'Athena/Session/GetUserDetailsError';
export const PUT_IS_APP_READY = 'Athena/Session/PutIsAppReady';
export const PUT_USER_SETTINGS = 'Athena/Session/PutUserSettings';
export const PUT_USER_SETTINGS_SUCCESS =
  'Athena/Session/PutUserSettingsSuccess';
export const PUT_USER_SETTINGS_ERROR = 'Athena/Session/PutUserSettingsError';
export const PUT_CONFIRM_USER = 'Athena/Session/PutConfirmUser';
export const PUT_CONFIRM_USER_ERROR = 'Athena/Session/PutConfirmUserError';
export const PUT_CONFIRM_USER_SUCCESS = 'Athena/Session/PutConfirmUserSuccess';
export const PUT_USER_EMAIL = 'Athena/Session/PutUserEmail';
export const SET_USER_EMAIL = 'Athena/Session/SetUserEmail';
export const SET_USER_EMAIL_SUCCESS = 'Athena/Session/SetUserEmailSuccess';
export const SET_USER_EMAIL_ERROR = 'Athena/Session/SetUserEmailError';
export const SET_USER_EMAIL_VERIFICATION = 'Athena/Session/PutUserEmailSuccess';
export const PUT_USER_EMAIL_ERROR = 'Athena/Session/PutUserEmailError';
export const SET_COMPLETE_EMAIL_UPDATE =
  'Athena/Session/SetCompleteEmailUpdate';
export const SET_PLUS_REGISTERED_FLAG = 'Athena/Session/SetPlusRegistered';
export const SET_PLUS_REGISTERED_FLAG_SUCCESS =
  'Athena/Session/SetPlusRegisteredSuccess';
export const SET_PLUS_REGISTERED_FLAG_ERROR =
  'Athena/Session/SetPlusRegisteredError';
// Session var for recording only one login per session
export const RECORDED_LOGIN = 'recordedLogin';
export const AUTH_STATE = 'auth-state';
export const POST_LOGIN_COUNT = 'Athena/Session/PostLoginCount';
export const POST_LOGIN_COUNT_SUCCESS = 'Athena/Session/PostLoginCountSuccess';
export const POST_LOGIN_COUNT_ERROR = 'Athena/Session/PostLoginCountError';
export const GET_OFFER_CODE = 'Athena/Session/GetOfferCode';
export const GET_OFFER_CODE_SUCCESS = 'Athena/Session/GetOfferCodeSuccess';
export const GET_OFFER_CODE_ERROR = 'Athena/Session/GetOfferCodeError';
export const CLEAR_OFFER_CODE_ERROR = 'Athena/Session/ClearOfferCodeError';
export const CLEAR_OFFER_DETAILS = 'Athena/Session/ClearOfferDetails';
export const POST_USER_OFFER = 'Athena/Session/PostUserOffer';
export const POST_USER_OFFER_SUCCESS = 'Athena/Session/PostUserOfferSuccess';
export const POST_USER_OFFER_ERROR = 'Athena/Session/PostUserOfferError';
export const CLEAR_USER_OFFER = 'Athena/Session/ClearUserOffer';
export const FETCH_KEY = 'Athena/Session/FetchKey';
export const FETCH_KEY_SUCCESS = 'Athena/Session/FetchKeySuccess';
export const FETCH_KEY_ERROR = 'Athena/Session/FetchKeyError';
export const GET_TAX_INFO = 'Athena/Session/GetTaxInfo';
export const GET_TAX_INFO_SUCCESS = 'Athena/Session/GetTaxInfoSuccess';
export const GET_TAX_INFO_ERROR = 'Athena/Session/GetTaxInfoError';
export const GET_CONFIG_DETAILS = 'Athena/Session/GetConfigDetails';
export const GET_CONFIG_DETAILS_SUCCESS =
  'Athena/Session/GetConfigDetailsSuccess';
export const GET_CONFIG_DETAILS_ERROR = 'Athena/Session/GetConfigDetailsError';
export const GET_CONFIG_HASH = 'Athena/Session/GetConfigHash';
export const GET_PRODUCT_INFO = 'Athena/Session/GetProductInfo';
export const GET_PRODUCT_INFO_SUCCESS = 'Athena/Session/GetProductInfoSuccess';
export const GET_PRODUCT_INFO_ERROR = 'Athena/Session/GetProductInfoError';
export const CLEAR_PAYMENT_INFO = 'Athena/Session/ClearPaymentInfo';
export const POST_PREP_PLUS_PAYMENT = 'Athena/Session/PostPrepPlusPayment';
export const POST_PREP_PLUS_PAYMENT_SUCCESS =
  'Athena/Session/PostPrepPlusPaymentSuccess';
export const POST_PREP_PLUS_PAYMENT_ERROR =
  'Athena/Session/PostPrepPlusPaymentError';
export const POST_PRODUCT_PAYMENT = 'Athena/Session/PostProductPayment';
export const POST_PRODUCT_PAYMENT_SUCCESS =
  'Athena/Session/PostProductPaymentSuccess';
export const POST_PRODUCT_PAYMENT_ERROR =
  'Athena/Session/PostProductPaymentError';
export const SET_PAYMENT_IN_PROGRESS = 'Athena/Session/SetPaymentInProgress';
export const SHOW_CALL_TO_ACTION = 'Athena/Session/ShowCallToAction';
export const RESET_VENDOR_INFORMATION = 'Athena/Session/ResetVendorInformation';
export const SET_ERROR = 'Athena/Session/SetError';
export const EMAIL_CONFLICT_HTTP_CODE = 409;
