import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, Button } from 'reactstrap';
import { AccessibleModal } from '../../../components/AccessibleModal';
import './index.css';

function RedirectModal({ isOpen, text, heading = '' }) {
  return (
    <AccessibleModal isOpen={isOpen} modalClassName="redirect-modal">
      <h2 className="redirect-modal-header">
        <p>{heading}</p>
      </h2>
      {heading.length > 0 && (
        <hr aria-hidden="true" className="redirect-modal-hr" />
      )}
      <ModalBody className="redirect-modal-body">
        <span>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </span>
        <Button style={{ display: 'none' }} />
      </ModalBody>
    </AccessibleModal>
  );
}

RedirectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  heading: PropTypes.string,
};

export default RedirectModal;
