import { defineMessages } from 'react-intl';
import { config } from '../../config';

export const scope = 'app.containers.TestCompleteFlexPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME} / Test Completion`,
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  heading1: {
    id: `${scope}.heading2`,
    defaultMessage: 'Your responses have been submitted.',
  },
  heading2: {
    id: `${scope}.heading1`,
    defaultMessage:
      'If you have not already done so, please follow these steps to close out your testing session.',
  },
  heading3: {
    id: `${scope}.heading3`,
    defaultMessage: 'Acknowledgments',
  },
  bulletPoint1: {
    id: `${scope}.bulletPoint1`,
    defaultMessage: `Notify your proctor that you have completed the ${
      config.REACT_APP_LSAT_NAME
    }. You can do this verbally, or by typing <b>“FINISHED”</b> in the chat box. This will start the checkout process.`,
  },
  bulletPoint2: {
    id: `${scope}.bulletPoint2`,
    defaultMessage:
      'The proctor will direct you to rip up all scratch paper regardless of whether they were used. You must <b>rip up all scratch paper in view of the camera</b>.',
  },
  bulletPoint3: {
    id: `${scope}.bulletPoint3`,
    defaultMessage:
      'The proctor will notify you, verbally or through the chat box, when the check out process is complete. Once that happens, you are free to navigate away from this screen and close the browser window.',
  },
  bulletPoint4: {
    id: `${scope}.bulletPoint4`,
    defaultMessage:
      'If you have not already completed an LSAT writing sample, you should complete the LSAT Writing portion of this test as soon as possible. Be sure to exit out of this proctoring session before starting <a href="https://www.lsac.org/lsat/taking-lsat/about-lsat-writing" target="_blank" title="will open in a new browser window">LSAT Writing</a>. <b>You will not be able to access your score for this test until you have a writing sample in your file.</b>',
  },
  heading4: {
    id: `${scope}.heading4`,
    defaultMessage: 'We are here to help.',
  },
  text1: {
    id: `${scope}.text1`,
    defaultMessage:
      '<b>If you have any questions or concerns, please reach out to LSAC at <a href="mailto: LSACinfo@LSAC.org">LSACinfo@LSAC.org</a></b>.',
  },
  buttonText: {
    id: `${scope}.buttonText`,
    defaultMessage: 'Return to LawHub',
  },
  proMetricsButtonText: {
    id: `${scope}.proMetricsButtonText`,
    defaultMessage: 'Submit',
  },
  saveText: {
    id: `${scope}.saveText`,
    defaultMessage: 'SAVE results locally',
  },
  logoText: {
    id: `${scope}.logoText`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME}`,
  },
  noAcks: {
    id: `${scope}.noAcks`,
    defaultMessage: 'No acknowledgements.',
  },
  pmRemoteBulletPoint1: {
    id: `${scope}.pmRemoteBulletPoint1`,
    defaultMessage:
      'Destroy all scratch paper, including those you have not used, by ripping it up in view of the camera.',
  },
  pmRemoteBulletPoint2: {
    id: `${scope}.pmRemoteBulletPoint2`,
    defaultMessage:
      'Click the blue “SUBMIT” button below to end your test. There is no further action needed.',
  },
  pmInCenterBulletPoint1: {
    id: `${scope}.pmInCenterBulletPoint1`,
    defaultMessage: 'Click the blue “SUBMIT” button below to end your test.',
  },
  pmInCenterBulletPoint2: {
    id: `${scope}.pmInCenterBulletPoint2`,
    defaultMessage:
      'Return to the reception/admin area with all scratch paper to complete your sign out process.',
  },
});
