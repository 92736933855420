/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import ExternalLinkIconRebrand from 'images/external_link_outward_rebrand.svg';
import messages from './messages';
import './index.css';
import { config } from '../../config';

const ExploreCard = ({ cardId, imageUrl, topic, url }) => {
  const exploreCardClickHandler = () => {
    window.open(config[url], '_blank');
  };

  const handleKeyPress = event => {
    const enterKey = event.keyCode === 13;
    if (enterKey) {
      window.open(config[url], '_blank');
    }
  };

  const renderExploreCard = () => (
    <div
      className="tools-explore-card-wrapper"
      id={cardId}
      key={cardId}
      data-testid="tools_card_wrapper_click"
      tabIndex="0"
      onClick={exploreCardClickHandler}
      onKeyDown={e => handleKeyPress(e)}
    >
      <a className="sr-only" href={config[url]} tabIndex="-1" target="_blank">
        {topic}
        {messages.opensInNewBrowserWindow.defaultMessage}
      </a>
      <div className="explore-card-img-holder">
        <img src={imageUrl?.url} alt="" className="explore-card-img" />
      </div>
      <div>
        <h3 className="ellipsis explore-card-header">
          {topic}
          <img
            src={ExternalLinkIconRebrand}
            className="external-link"
            alt={messages.opensInNewBrowserWindow.defaultMessage}
            title={messages.opensInNewBrowserWindow.defaultMessage}
          />
        </h3>
      </div>
    </div>
  );

  return <>{renderExploreCard()}</>;
};

ExploreCard.propTypes = {
  cardId: PropTypes.string,
  imageUrl: PropTypes.object,
  url: PropTypes.string,
  topic: PropTypes.string,
};

export { ExploreCard };
