/* eslint-disable prettier/prettier */
import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ExamCompletePage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'LSAT',
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage: 'Your testing session was completed.',
  },
});
