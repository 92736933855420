/**
 *
 * persistence summary helper
 *
 */

import { isNullOrUndefined } from 'util';
import { getArrayItemByKey } from './arrayHelper';
import { setAttributesIfExist } from './attributeHelper';
import { getInstanceRepo } from './repoHelper';
import { persistenceState } from './persistenceState';
import { ModuleType } from '../../../enumerations/ModuleType';

export function calculateInstanceMetrics(module) {
  if (isNullOrUndefined(module)) return null;
  const isRealExam = module?.options?.isRealExamMode;
  const instanceRepo = getInstanceRepo();
  const state =
    !isNullOrUndefined(instanceRepo) && instanceRepo.state
      ? instanceRepo.state
      : { sections: [] };
  const summary = {
    totalQuestions: 0,
    sections: [],
  };

  if (!isRealExam) summary.totalCorrect = 0;

  setAttributesIfExist(
    summary,
    state,
    'startTime',
    'examMode',
    'customTime',
    'isSectionPaused',
    'pausedOnPage',
    'pausedSectionId',
    'pausedQuestionIndex',
    'totalAnswered',
  );
  if (state.isTerminated) {
    summary.isTerminated = true;
    summary.terminatedTime = state.terminatedTime;
  }
  if (state.isContentExposed) {
    summary.isContentExposed = true;
  }
  if (!isNullOrUndefined(state.intermissionCheckInReady)) {
    summary.intermissionCheckInReady = state.intermissionCheckInReady;
  }
  if (!isNullOrUndefined(state.technicalPauseInitiated)) {
    summary.technicalPauseInitiated = state.technicalPauseInitiated;
  }
  if (!isNullOrUndefined(state.submitEarlyInitiated)) {
    summary.submitEarlyInitiated = state.submitEarlyInitiated;
  }
  if (isNullOrUndefined(module.sections)) return summary;
  let sectionIndex = 0;
  for (
    sectionIndex = 0;
    sectionIndex < module.sections.length;
    sectionIndex += 1
  ) {
    const section = module.sections[sectionIndex];
    let sectionState = getArrayItemByKey(
      state.sections,
      'formId',
      section.formId,
    );
    // Check to avoid the intermission section junk value to be added in the summary object
    const IntermissionFlag =
      !isNullOrUndefined(state.sections) &&
      state.sections[sectionIndex]?.intermissionTimeRemaining;
    if (!IntermissionFlag) {
      if (sectionState === null) sectionState = {};
      let totalCorrect = 0;
      let i = 0;
      const itemCount = !isNullOrUndefined(section.items)
        ? section.items.length
        : 0;
      const isWritingSection =
        section?.options?.sectionType === ModuleType.Writing ||
        section?.options?.sectionType === ModuleType.WritingWithPerspectives;
      for (i = 0; i < itemCount; i += 1) {
        const item = section.items[i];
        if (item.answer === item.correctAnswer && !isWritingSection) {
          totalCorrect += 1;
        }
      }
      const sectionSummary = {
        formId: section.formId,
        totalQuestions: itemCount,
      };
      if (!isRealExam) sectionSummary.totalCorrect = totalCorrect;

      setAttributesIfExist(
        sectionSummary,
        sectionState,
        'startTime',
        'endTime',
        'isSectionExposed',
        'totalAnswered',
      );
      summary.sections.push(sectionSummary);
      summary.totalQuestions += itemCount;
      if (!isRealExam) summary.totalCorrect += totalCorrect;
    }
  }
  return summary;
}

export function refreshSummary() {
  getInstanceRepo().summary = calculateInstanceMetrics(persistenceState.module);
}
