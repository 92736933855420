import {
  QUESTION_PATH,
  DIRECTION_PATH,
  PASSAGE_PATH,
  INTERMISSION_PATH,
  MODULE_PATH,
  EXAM_PAUSE_PATH,
} from '../../containers/App/constants';

export const PATHS_NOT_ALLOWED_FOR_HEADER = [
  MODULE_PATH,
  QUESTION_PATH,
  DIRECTION_PATH,
  PASSAGE_PATH,
  INTERMISSION_PATH,
  EXAM_PAUSE_PATH,
];
