/**
 *
 * google analytics helper
 *
 */

import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { config } from '../config';

// EVENT CONSTANTS
export const PAGE_VIEW = 'pageview';
export const SIGN_UP = 'sign_up';
export const LOGIN = 'login';

/**
 * * initialize google analytics and google tag manager
 */
export function initializeGoogleAnalytics() {
  ReactGA.initialize(config.REACT_APP_GOOGLE_ID);
  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    wait_for_update: 500,
  });
  ReactGA.gtag('set', 'ads_data_redaction', true);
  ReactGA.gtag('set', 'url_passthrough', true);

  const tagManagerArgs = {
    gtmId: config.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

/**
 * Log page navigation when react router location changes to GA.
 * Not needed as long as Enhanced measurement option is turned on
 * for the GA4 property, as page views and some events are tracked
 * automatically.
 * @param {object} location the location object
 */
export function logPageNavigation(location) {
  const pageViewObject = {
    hitType: PAGE_VIEW,
    page: location.pathname,
    title: document.title,
  };
  ReactGA.send(pageViewObject);
}

/**
 * Helper function for logging events to GTM.
 * @param {string} eventName
 */
export const pushGtmEvent = (eventName = '') => {
  if (eventName) {
    window.dataLayer.push({
      event: eventName,
    });
  }
};
