/* eslint-disable no-console */
import { isNullOrUndefined } from 'util';
import history from '../utils/history';
import { config } from '../config';
import * as logHelper from './logHelper';
import { LogLevelType } from '../enumerations/LogLevelType';

let disconnectAction = null;

/* eslint-disable default-case */
export function messageHandler(event) {
  if (config.REACT_APP_WEB_PUSH === 'true') {
    switch (event.data) {
      case 'stop':
        logHelper.log(LogLevelType.Info, 'got stop message');
        if (!isNullOrUndefined(disconnectAction)) {
          logHelper.log(LogLevelType.Info, 'invoking disconnect action');
          disconnectAction();
        }
        history.push('/library');
        break;
    }
  }
}

export function subscribePush(
  subscribeUser,
  onFetchKeyAction,
  onProctorDisconnectAction,
) {
  if (config.REACT_APP_WEB_PUSH === 'true') {
    disconnectAction = onProctorDisconnectAction;
    if (!isNullOrUndefined(disconnectAction)) {
      logHelper.log(
        LogLevelType.Info,
        'subscribed to proctoru disconnect action',
      );
    }
    if (!subscribeUser) {
      onFetchKeyAction();
    } else {
      navigator?.serviceWorker?.addEventListener('message', messageHandler);
    }
  }
}

export function unsubscribePush() {
  if (config.REACT_APP_WEB_PUSH === 'true') {
    navigator?.serviceWorker?.removeEventListener('message', messageHandler);
  }
}
