/**
 *
 * PausePage
 *
 * Prometrics Pause Screen
 *
 */
import './index.css';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { createStructuredSelector } from 'reselect';
import lsatLogo from '../../images/lsat-logo.png';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
import { config } from '../../config';
import { sendStateNow } from '../../helpers/persistence/instanceStateManagementHelper';
import { selectedInstanceSelector } from '../DirectionsPage/selectors';
export function PausePage({ selectedInstance }) {
  // required in order to get user details
  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  // const isFlex = examType && examType.toLowerCase() !== 'writing';
  // const examTypeName = isFlex ? config.REACT_APP_LSAT_NAME : 'LSAT Writing';

  /* temporary settings while testing 7913: */
  const isFlex = true;
  const examTypeName = config.REACT_APP_LSAT_NAME;

  useEffect(() => {
    let timeoutId;

    if (selectedInstance) {
      // check for the remote resume
      timeoutId = window.setInterval(() => {
        sendStateNow(selectedInstance?.testInstanceId);
      }, 60000); // every 60 seconds we check for the resume command.
    }

    return () => window.clearTimeout(timeoutId);
  }, []);

  return (
    <div className="pause-page-container">
      <div className="pause-page-header">
        <div className="pause-page-header-div">
          <div className="pause-page-header-text">{examTypeName}</div>
        </div>
      </div>
      <div className="pause-page-top-decoration" />
      <div className="pause-page">
        {isFlex && (
          <img className="pause-page-lsat-flex-logo" src={lsatLogo} alt="" />
        )}
        {!isFlex && (
          <div id="heading1" className="heading-color">
            LSAT Writing
          </div>
        )}
        <hr className="pause-page-horizontal-rule" role="none" />
        <div>
          <div className="pause-page-text-content">
            <h1>{examTypeName} Pause</h1>
            <p>
              The Proctor has paused your exam, and your test time has been
              stopped. The Proctor will contact you to let you know what needs
              to be addressed so that you may continue your exam. When the
              Proctor resumes your exam, you will be returned to your exam and
              can continue where you left off.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

PausePage.propTypes = {
  selectedInstance: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  selectedInstance: selectedInstanceSelector(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(withConnect)(PausePage);
