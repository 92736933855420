/**
 * Raw score calculation
 *
 * @param  {array} items Section items
 *
 */
export function calculateRawScore(items) {
  let correctAnswerCount = 0;
  if (items != null) {
    for (let i = 0; i < items.length; i += 1) {
      const selectedAnswer = items[i].answer ? items[i].answer : '--';
      if (selectedAnswer === items[i].correctAnswer) {
        correctAnswerCount += 1;
      }
    }
  }
  return correctAnswerCount;
}

/**
 * get raw score text for all sections, i.e. "85/101"
 *
 * @param  {array} sections
 *
 */
export function getRawScoreText(sections) {
  let totalQuestions = 0;
  let correctQuestions = 0;
  let sectionIndex = 0;
  for (sectionIndex = 0; sectionIndex < sections.length; sectionIndex += 1) {
    const section = sections[sectionIndex];

    // don't score the variable section in the total raw score
    if (
      section.isVariableSection === undefined ||
      section.isVariableSection === null ||
      (section.isVariableSection !== undefined &&
        section.isVariableSection !== null &&
        section.isVariableSection === false)
    ) {
      totalQuestions += section.items.length;
      totalQuestions -= calculatePinnedQuestions(section.items);
      correctQuestions += calculateRawScore(section.items);
    }
  }
  return `${correctQuestions}/${totalQuestions}`;
}

/**
 * Pinned Question calculation
 *
 * @param  {array} items Section items
 *
 */
export function calculatePinnedQuestions(items) {
  let pinnedQuestionsCount = 0;
  if (items != null) {
    for (let i = 0; i < items.length; i += 1) {
      if (!items[i].correctAnswer || items[i].correctAnswer === '') {
        pinnedQuestionsCount += 1;
      }
    }
  }
  return pinnedQuestionsCount;
}
