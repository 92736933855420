/**
 *
 * browser helper
 *
 */
import Bowser from 'bowser';

import { config } from '../config';

export function getBrowserName() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const name = browser.getBrowserName();
  return name;
}

export function getFullUserAgent() {
  return navigator.userAgent;
}

/**
 * Check if the current user agent is in an allowable list (from the config).
 * Note: the config list is comma separated
 * @returns boolean
 */

export const isAcceptableUserAgent = () => {
  // Split config into an array of acceptable keywords
  const acceptableAgents = config.REACT_APP_EXAM_ALLOWABLE_USER_AGENTS.split(
    ',',
  ).map(agent => agent.toLowerCase().trim());
  // Check if "ALL" is in the config
  const hasAllInConfig = acceptableAgents.includes('all');
  if (hasAllInConfig) {
    return true;
  }
  // Check if any of the acceptable agents are in the user agent string
  const lowerCaseUserAgent = getFullUserAgent().toLowerCase();
  const matches = acceptableAgents.some(agent =>
    lowerCaseUserAgent.includes(agent),
  );

  // THE SDK does not allow access to the console so we need to do this with an alert
  if (config.REACT_APP_DEBUG_USER_AGENT === 'true' && !matches) {
    // eslint-disable-next-line no-alert
    alert(`User Agent:  ${navigator.userAgent}`);
  }

  return matches;
};

export function getUrlParams(url) {
  const searchParams = new URLSearchParams(new URL(url).search);
  return Object.fromEntries(searchParams.entries());
}

export function searchUsingWindowFind(e, searchRef) {
  const searchValue = e.target.value;

  if (searchValue.length >= 3) {
    searchForText(searchValue, searchRef);
  } else {
    searchForText('', searchRef);
  }
}

export function searchForText(text, searchRef = undefined, color = 'orange') {
  if (color !== 'transparent') {
    searchForText(
      document.getElementById('hid_search').value,
      searchRef,
      'transparent',
    );
    document.getElementById('hid_search').value = text;
  }
  if (window.find && window.getSelection) {
    document.designMode = 'on';
    const sel = window.getSelection();
    sel?.collapse(document.body, 0);

    while (window.find(text)) {
      document.execCommand('HiliteColor', false, color);
      sel?.collapseToEnd();
    }
    document.designMode = 'off';
  } else if (document.body.createTextRange) {
    const textRange = document.body.createTextRange();
    while (textRange.findText(text)) {
      textRange.execCommand('BackColor', false, color);
      textRange.collapse(false);
    }
  }

  searchRef?.current?.focus();
}

export function isPageReloaded() {
  const pageAccessedByReload =
    (window.performance.navigation &&
      window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType('navigation')
      .map(nav => nav.type)
      .includes('reload');

  return pageAccessedByReload;
}

export function searchOptimization(fnc, timeout = 300) {
  // set the focus to input element
  const searchInput = document.getElementById('search-input-field');
  if (searchInput?.focus) {
    searchInput.focus();
  }
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fnc.apply(this, args);
    }, timeout);
  };
}
