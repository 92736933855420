import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { userDetailsSelector } from 'domains/session/selectors';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import { getUser, signIn } from '../../authProvider';
import ToolCard from './ToolsCard';
import messages from './messages';
import './index.css';
import { config } from '../../config';
import { extensionCandSeq } from '../../helpers/userHelper';
import {
  APPLICATION_ONLINE_STATUS_PAGE,
  UPGRADE_TOOLS_PAGE_PATH,
  HOME_PATH,
} from '../App/constants';
import tipsAndUpdatesIcon from '../../images/tips_and_updates_icon.svg';
import ASTsaga from '../ApplicationStatusOnlinePage/saga';
import ASTreducer from '../ApplicationStatusOnlinePage/reducer';
import { getUserApplicationStatusAction } from '../ApplicationStatusOnlinePage/actions';
import { makeUserApplicationStatusOnlinePageSelector } from '../ApplicationStatusOnlinePage/selector';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import { reactPlugin } from '../TestSelectPage/testselect.util';
import ToolsExploreSlider from '../../components/ToolsExploreSlider';
import history from '../../utils/history';
import { windowSizeSelector } from '../MasterPage/selectors';
import { exploreCardList } from './constants';
import { getActiveSubscription } from '../../helpers/applicationStatusHelper';

const ToolsPage = ({
  onGetUserApplicationStatusDetails,
  applicationStatusDetails,
  userDetails,
  windowSize,
  intl,
}) => {
  useInjectReducer({ key: 'applicationDetails', reducer: ASTreducer });
  useInjectSaga({ key: 'applicationDetails', saga: ASTsaga });

  const { hasPrepPlusSubscription } = getActiveSubscription(
    userDetails?.subscriptionStatus,
  );

  const unAuthViewFlag = isNullOrUndefined(getUser());

  useEffect(() => {
    if (config.REACT_APP_IS_TOOLS_LANDING_ENABLED === 'false') {
      history.push(HOME_PATH);
    }

    if (
      config.REACT_APP_IS_APPLICATION_SCHOOL_ONLINE_FEATURE_ENABLED ===
        'true' &&
      !isNullOrUndefined(extensionCandSeq?.value)
    ) {
      onGetUserApplicationStatusDetails(extensionCandSeq?.value);
    }
  }, []);

  const getASTCardProps = () => {
    const props = {};

    props.linkUrl = APPLICATION_ONLINE_STATUS_PAGE;
    if (applicationStatusDetails?.items?.schools?.length > 0) {
      props.linkText = messages.viewApps.defaultMessage;
    } else {
      props.linkText = messages.learnMore.defaultMessage;
    }

    return props;
  };

  const getKnowB4UGoCardProps = () => {
    const props = {};
    if (!hasPrepPlusSubscription) {
      props.linkText = messages.upgradeNow.defaultMessage;
      props.linkUrl = UPGRADE_TOOLS_PAGE_PATH;
      props.screenReaderText = messages.knowB4UGo.defaultMessage;
      props.openInNewTab = false;
    }
    return props;
  };

  if (extensionCandSeq?.value && applicationStatusDetails?.isLoading) {
    return (
      <Container fluid style={{ width: '100vw' }}>
        <Row>
          <AriadneLoading text="Tools Page" />
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{messages.docTitle.defaultMessage}</title>
        <meta
          name="description"
          content={messages.metaDescription.defaultMessage}
        />
      </Helmet>
      <div className="tools-page-container">
        <div className="layout-container header-wrapper">
          <div className="central-column">
            <div
              className={`header-wrapper ${
                unAuthViewFlag ? 'unauth-header' : ''
              }`}
            >
              <div className="header-section">
                <h1 className="header" id="pageHeader">
                  {messages.tools.defaultMessage}
                </h1>
                <p className="sub-header">
                  {messages.subHeader.defaultMessage}
                </p>
              </div>
              {unAuthViewFlag && (
                <div className="button-section">
                  <button
                    type="button"
                    className="sign-in-lawhub"
                    onClick={signIn}
                  >
                    {messages.signInToLawHub.defaultMessage}
                  </button>
                </div>
              )}
            </div>

            {unAuthViewFlag && (
              <div className="banner-section">
                <img src={tipsAndUpdatesIcon} alt="" />
                {messages.signInBannerMessage.defaultMessage}
              </div>
            )}
          </div>
        </div>
        <div className="layout-container bg-grey">
          <div className="central-column">
            <div className="tool-card-container">
              <ToolCard
                unAuthViewFlag={unAuthViewFlag}
                header={messages.findYourLawSchool.defaultMessage}
                description={
                  messages.findYourLawSchoolDescription.defaultMessage
                }
                linkText={messages.goToPersonalReport.defaultMessage}
                linkUrl={config.REACT_APP_FIND_YOUR_LAW_SCHOOL_URL}
                headerTag="h2"
              />
              <ToolCard
                header={messages.applicationStatusTracker.defaultMessage}
                description={
                  messages.applicationStatusTrackerDescription.defaultMessage
                }
                openInNewTab={false}
                {...getASTCardProps()}
                headerTag="h2"
              />
              <ToolCard
                unAuthViewFlag={unAuthViewFlag}
                header={messages.knowB4UGo.defaultMessage}
                description={messages.knowB4UGoDesc.defaultMessage}
                linkText={messages.tryItNow.defaultMessage}
                linkUrl={config.REACT_APP_KNOW_B4_U_GO}
                {...getKnowB4UGoCardProps()}
                headerTag="h2"
              />
            </div>
          </div>
        </div>
        <div className="layout-container">
          <div className="central-column">
            <div className="other-tools">
              <h2>{messages.otherTools.defaultMessage}</h2>
              <div className="tool-card-container">
                <ToolCard
                  unAuthViewFlag={unAuthViewFlag}
                  header={messages.legalCareerCompass.defaultMessage}
                  description={
                    messages.legalCareerCompassDescription.defaultMessage
                  }
                  linkText={messages.seeWhereYouFit.defaultMessage}
                  linkUrl={config.REACT_APP_LEGAL_CAREER_COMPASS_URL}
                />
                <ToolCard
                  unAuthViewFlag={unAuthViewFlag}
                  header={messages.serious.defaultMessage}
                  description={
                    unAuthViewFlag
                      ? messages.seriousDescUnAuth.defaultMessage
                      : messages.seriousDesc.defaultMessage
                  }
                  linkText={messages.takeAdvToday.defaultMessage}
                  linkUrl={config.REACT_APP_LAW_EXAM_SRS_URL}
                />
              </div>
            </div>
            <div className="explore-block">
              <div className="tool-card-container">
                <ToolsExploreSlider
                  cards={exploreCardList}
                  sliderHeading={messages.explore.defaultMessage}
                  windowSize={windowSize}
                  intl={intl}
                  viewMoreLinkFlag={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ToolsPage.propTypes = {
  applicationStatusDetails: PropTypes.object,
  onGetUserApplicationStatusDetails: PropTypes.func,
  windowSize: PropTypes.object,
  userDetails: PropTypes.object,
  intl: intlShape,
};

const mapStateToProps = createStructuredSelector({
  applicationStatusDetails: makeUserApplicationStatusOnlinePageSelector(),
  windowSize: windowSizeSelector(),
  userDetails: userDetailsSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onGetUserApplicationStatusDetails: candSeq =>
      dispatch(getUserApplicationStatusAction(candSeq)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(
  withAITracking(reactPlugin, injectIntl(ToolsPage), 'ToolsPage'),
);
