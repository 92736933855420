/* eslint-disable no-param-reassign */
/**
 *
 * PLUS program helper
 *
 */
import uuid from 'uuid';
import { config } from '../config';
import { PLUS_PROGRAM_REGISTRATION_COMPLETE } from '../containers/App/constants';
import { storeValueInLocal } from './localStorageHelper';
let tid = null;

function initTid() {
  if (!tid) {
    tid = uuid();
    storeValueInLocal('plusSurveyKey', tid);
  }
  return tid;
}

function getHost() {
  let host = window.location.hostname;
  if (host === 'localhost') {
    host += ':3000';
  }
  return host;
}

function getSkipSurveyUri(userDetails) {
  initTid();
  let uri = PLUS_PROGRAM_REGISTRATION_COMPLETE;
  const host = getHost();
  const query = new URLSearchParams({
    userid: userDetails.userId,
    firstname: userDetails.firstName,
    lastname: userDetails.lastName,
    email: userDetails.emailAddress,
    referrer: host,
    id: tid,
  });
  uri += '?';
  uri += query.toString();
  return uri;
}

export function getPlusProgramRedirectUri(userDetails) {
  if (!userDetails) return null;
  if (config.REACT_APP_SKIP_PLUS_PROGRAM_SURVEY === 'true') {
    return getSkipSurveyUri(userDetails);
  }
  let uri = config.REACT_APP_PLUS_SURVEY_URL;
  const host = getHost();
  initTid();
  const query = new URLSearchParams({
    userid: userDetails.userId,
    firstname: userDetails.firstName,
    lastname: userDetails.lastName,
    email: userDetails.emailAddress,
    referrer: host,
    tid,
  });
  uri += query.toString();
  return uri;
}

export function verifyPlusProgramSurveyResponse(id) {
  const originalRequestId = localStorage.getItem('plusSurveyKey');
  return originalRequestId === id;
}
