/*
 *
 * AthenaSession reducer
 *
 */

import produce from 'immer';
import UserModel from 'models/userModel';
import ProductModel from 'models/productModel';
import {
  mergeServerConfigIntoLocalConfig,
  saveServerConfigObjectToLocal,
} from '../../helpers/configHelper';

import {
  INIT_LIBRARY_SAGA,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  PUT_IS_APP_READY,
  PUT_USER_SETTINGS_SUCCESS,
  PUT_USER_SETTINGS_ERROR,
  PUT_CONFIRM_USER_SUCCESS,
  PUT_CONFIRM_USER_ERROR,
  RESET_VENDOR_INFORMATION,
  SET_USER_EMAIL_VERIFICATION,
  PUT_USER_EMAIL_ERROR,
  SET_USER_EMAIL_SUCCESS,
  SET_COMPLETE_EMAIL_UPDATE,
  SET_USER_EMAIL_ERROR,
  GET_TAX_INFO_SUCCESS,
  GET_TAX_INFO_ERROR,
  POST_LOGIN_COUNT_SUCCESS,
  POST_LOGIN_COUNT_ERROR,
  GET_OFFER_CODE_SUCCESS,
  GET_OFFER_CODE_ERROR,
  CLEAR_OFFER_CODE_ERROR,
  CLEAR_OFFER_DETAILS,
  POST_USER_OFFER_SUCCESS,
  POST_USER_OFFER_ERROR,
  CLEAR_USER_OFFER,
  GET_PRODUCT_INFO_SUCCESS,
  GET_PRODUCT_INFO_ERROR,
  CLEAR_PAYMENT_INFO,
  POST_PRODUCT_PAYMENT_SUCCESS,
  POST_PRODUCT_PAYMENT_ERROR,
  POST_PREP_PLUS_PAYMENT_SUCCESS,
  POST_PREP_PLUS_PAYMENT_ERROR,
  SET_PLUS_REGISTERED_FLAG_SUCCESS,
  SET_PLUS_REGISTERED_FLAG_ERROR,
  SET_PAYMENT_IN_PROGRESS,
  SHOW_CALL_TO_ACTION,
  FETCH_KEY_SUCCESS,
  SET_ERROR,
  GET_CONFIG_DETAILS_SUCCESS,
  GET_CONFIG_DETAILS_ERROR,
} from './constants';

export const initialState = {
  initSagaLibrary: false,
  retrievedUserDetails: false,
  userDetails: null,
  error: null,
  isAppReady: false,
  vendorData: null,
  vendorDataError: null,
  verificationCode: null,
  userNewEmailAddress: null,
  emailUpdateError: null,
  taxInfo: null,
  paymentDetails: null,
  taxInfoError: null,
  paymentError: null,
  plusRegistrationComplete: false,
  plusRegistrationError: null,
  subscribeUser: false,
  paymentInProgress: false,
  products: null,
};

/* eslint-disable default-case, no-param-reassign */
const sessionStateReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case INIT_LIBRARY_SAGA:
        draft.initSagaLibrary = true;
        break;
      case GET_USER_DETAILS_SUCCESS:
        draft.prevUserDetails = { ...draft.userDetails };
        draft.userDetails = UserModel.fromDto(action.userModel);
        draft.retrievedUserDetails = true;
        draft.error = null;
        break;
      case GET_USER_DETAILS_ERROR:
        draft.error = action.error;
        break;
      case PUT_IS_APP_READY:
        draft.isAppReady = action.isAppReady;
        break;
      case PUT_USER_SETTINGS_SUCCESS:
        draft.userDetails = {
          ...state.userDetails,
          userSettings: action.userSettings,
        };
        break;
      case PUT_USER_SETTINGS_ERROR:
        draft.error = action.error;
        break;
      case PUT_CONFIRM_USER_SUCCESS:
        draft.vendorData = action.vendorData;
        break;
      case PUT_CONFIRM_USER_ERROR:
        draft.vendorDataError = action.error;
        break;
      case RESET_VENDOR_INFORMATION:
        draft.vendorData = null;
        break;
      case SET_USER_EMAIL_VERIFICATION:
        draft.verificationCode = action.userNewEmailDetails.code;
        draft.userNewEmailAddress =
          action.userNewEmailDetails.userNewEmailAddress;
        draft.emailUpdateError = null;
        break;
      case PUT_USER_EMAIL_ERROR:
        draft.error = action.error;
        draft.emailUpdateError = null;
        break;
      case SET_USER_EMAIL_SUCCESS:
        draft.verificationCode = null;
        draft.userNewEmailAddress = null;
        draft.emailUpdateError = null;
        draft.error = null;
        draft.userDetails.emailAddress = action.userEmailDetails.newEmail;
        break;
      case SET_COMPLETE_EMAIL_UPDATE:
        draft.verificationCode = null;
        draft.userNewEmailAddress = null;
        draft.emailUpdateError = null;
        break;
      case SET_USER_EMAIL_ERROR:
        draft.emailUpdateError = action.error;
        break;
      case GET_CONFIG_DETAILS_SUCCESS:
        mergeServerConfigIntoLocalConfig(action.configDetails);
        if (!action.isFromCache) {
          saveServerConfigObjectToLocal(action.configDetails);
        }
        draft.config = action.configDetails;
        draft.configError = null;
        break;
      case GET_CONFIG_DETAILS_ERROR:
        draft.configError = action.error;
        break;
      case GET_TAX_INFO_SUCCESS:
        draft.taxInfo = action.taxInfo;
        draft.taxInfoError = null;
        break;
      case GET_TAX_INFO_ERROR:
        draft.taxInfoError = action.error;
        break;
      case POST_LOGIN_COUNT_SUCCESS:
        draft.loginRecord = action.loginRecord;
        break;
      case POST_LOGIN_COUNT_ERROR:
        draft.loginRecordError = action.error;
        break;
      case GET_OFFER_CODE_SUCCESS:
        draft.offerDetails = action.offer;
        break;
      case GET_OFFER_CODE_ERROR:
        draft.offerError = action.error;
        break;
      case CLEAR_OFFER_CODE_ERROR:
        draft.offerError = null;
        break;
      case CLEAR_OFFER_DETAILS:
        draft.offerDetails = null;
        break;
      case POST_USER_OFFER_SUCCESS:
        draft.redeemedOffer = action.data;
        break;
      case POST_USER_OFFER_ERROR:
        draft.offerError = action.error;
        break;
      case CLEAR_USER_OFFER:
        draft.redeemedOffer = null;
        break;
      case GET_PRODUCT_INFO_SUCCESS:
        draft.products = {
          ...state.products,
          [action?.productData?.id]: new ProductModel(action?.productData),
        };
        break;
      case GET_PRODUCT_INFO_ERROR:
        draft.productsError = action.error;
        break;
      case CLEAR_PAYMENT_INFO:
        draft.taxInfo = null;
        draft.paymentDetails = null;
        draft.taxInfoError = null;
        draft.paymentError = null;
        break;
      case POST_PRODUCT_PAYMENT_SUCCESS:
        draft.paymentDetails = action.paymentDetails;
        draft.paymentSuccessful = true;
        draft.paymentError = null;
        break;
      case POST_PRODUCT_PAYMENT_ERROR:
        draft.paymentError = action.error.response.data;
        draft.paymentSuccessful = false;
        break;
      case POST_PREP_PLUS_PAYMENT_SUCCESS:
        draft.paymentDetails = action.paymentDetails;
        draft.paymentSuccessful = true;
        draft.paymentError = null;
        break;
      case POST_PREP_PLUS_PAYMENT_ERROR:
        draft.paymentError = action.error.response.data;
        draft.paymentSuccessful = false;
        break;
      case SET_PLUS_REGISTERED_FLAG_SUCCESS:
        draft.plusRegistrationComplete = true;
        draft.plusRegistrationError = null;
        draft.isAppReady = false;
        draft.userDetails = null;
        draft.retrievedUserDetails = false;
        break;
      case SET_PLUS_REGISTERED_FLAG_ERROR:
        draft.plusRegistrationComplete = false;
        draft.plusRegistrationError = action.error.response.data;
        break;
      case SET_PAYMENT_IN_PROGRESS:
        draft.paymentInProgress = action.isPaymentInProgress;
        break;
      case SHOW_CALL_TO_ACTION:
        if (!draft.showCallToAction) {
          draft.showCallToAction = {};
        }
        draft.showCallToAction[action.key] = action.isVisible;
        break;
      case FETCH_KEY_SUCCESS:
        draft.subscribeUser = true;
        break;
      case SET_ERROR:
        draft.error = action.error;
        break;
    }
  });

export default sessionStateReducer;
