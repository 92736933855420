/* eslint-disable func-names */
/**
 *
 * webCrypto helper to encrypt/decrypt locally only
 *
 * Original source: https://gist.github.com/saulshanabrook/b74984677bccd08b028b30d9968623f5
 */

import { config } from 'config';
import { isNullOrUndefined } from 'util';
// import * as logHelper from './logHelper';
// import { LogLevelType } from '../enumerations/LogLevelType';
const idb = require('idb-keyval/dist/idb-keyval-cjs-compat.min.js');
let cryptoKeys = null;
let iv = null;

// async function encryptDataSaveKey() {
//   const data = await makeData();
//   logHelper.log(LogLevelType.Info, 'generated data', data);
//   const keys = await makeKeys();
//   const encrypted = await encrypt(data, keys);
//   callOnStore(function(store) {
//     store.put({ id: 1, keys, encrypted });
//   });
// }
//
// function loadKeyDecryptData() {
//   callOnStore(function(store) {
//     const getData = store.get(1);
//     getData.onsuccess = async function() {
//       const { keys, encrypted } = getData.result;
//       const data = await decrypt(encrypted, keys);
//       logHelper.log(LogLevelType.Info, 'decrypted data', data);
//     };
//   });
// }
//
// async function encryptDecrypt() {
//   const data = await makeData();
//   logHelper.log(LogLevelType.Info, 'generated data', data);
//   const keys = await makeKeys();
//   const encrypted = await encrypt(data, keys);
//   logHelper.log(LogLevelType.Info, 'encrypted', encrypted);
//   const finalData = await decrypt(encrypted, keys);
//   logHelper.log(LogLevelType.Info, 'decrypted data', data);
// }
//
// function makeData() {
//   return window.crypto.getRandomValues(new Uint8Array(16));
// }

async function makeKeys() {
  const keys = window.crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256, // can be  128, 192, or 256
    },
    false, // whether the key is extractable (i.e. can be used in exportKey)
    ['encrypt', 'decrypt'], // can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
  );
  return keys;
}

async function getKeys() {
  if (!isNullOrUndefined(cryptoKeys)) {
    return cryptoKeys;
  }
  let keys = await idb.get(config.REACT_APP_CRYPTO_KEY);
  if (!isNullOrUndefined(keys)) {
    cryptoKeys = keys;
    return cryptoKeys;
  }
  keys = await makeKeys();
  await idb.set(config.REACT_APP_CRYPTO_KEY, keys);
  cryptoKeys = keys;
  return cryptoKeys;
}

async function getIV() {
  if (iv !== null) {
    return iv;
  }
  const ret = await idb.get('session');
  if (!isNullOrUndefined(ret)) {
    iv = ret;
    return iv;
  }
  iv = window.crypto.getRandomValues(new Uint8Array(12));
  await idb.set('session', iv);
  return iv;
}

async function encrypt(data, keys) {
  const encData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: await getIV(),
    },
    keys, // from generateKey or importKey above
    data, // ArrayBuffer of data you want to encrypt
  );
  return encData;
}

async function decrypt(data, keys) {
  return new Uint8Array(
    await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: await getIV(),
      },
      keys, // from generateKey or importKey above
      data, // ArrayBuffer of the data
    ),
  );
}

export async function encryptObject(obj) {
  const json = JSON.stringify(obj);
  const keys = await getKeys();
  const data = convertStringToArrayBuffer(json);
  const encData = await encrypt(data, keys);
  return encData;
}

export async function decryptObject(data) {
  const keys = await getKeys();
  const buf = await decrypt(data, keys);
  const json = convertArrayBuffertoString(buf);
  const obj = JSON.parse(json);
  return obj;
}

function convertStringToArrayBuffer(str) {
  const encoder = new TextEncoder('utf-8');
  return encoder.encode(str).buffer;
}

function convertArrayBuffertoString(buffer) {
  const decoder = new TextDecoder('utf-8');
  return decoder.decode(buffer);
}

export async function resetCrypto() {
  cryptoKeys = null;
  iv = null;
  await idb.clear();
}
