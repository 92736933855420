/* eslint-disable */
/**
 *
 * PageHeader
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import LawHubLogoVertical from 'images/LawHubLogo-Blue.svg';
import HelpIcon from 'images/help-icon-material-flame.svg';
import VerticalSeparator from 'images/vertical-separator.svg';
import { displaySideNavBar } from '../../containers/MasterPage/actions';
import {
  windowSizeSelector,
  showSideNavSelector,
} from '../../containers/MasterPage/selectors';
import messages from './messages';
import { CERTIFYING_STATEMENT_PATH, FLEX_LANDING_PATH, HOME_PATH } from '../../containers/App/constants';
import UserProfile from '../UserProfile';
import SignInButton from './SignInButton';
import HamburgerButton from '../HamburgerButton';
import { config } from '../../config';
import './index.css';

/**
 * Inject handler into PageHeader component to make it possible
 * to mock its implemenation in tests
 */
export const showSidebar = (e, onShowSideBar) => {
  e.preventDefault();
  // Using both .which and .key for maximum browser compatability
  const enterKeyPressed = e.which === 13 || e.key === 'Enter';
  const spaceKeyPressed = e.which === 32 || e.key === ' ';
  if (enterKeyPressed || spaceKeyPressed || e.type === 'click') {
    onShowSideBar(true);
  }
};

const PageHeader = ({
  windowSize,
  intl,
  userDetails,
  onShowSideBar,
  showSidebarHandler = showSidebar,
  isSideNavBarVisible,
}) => {
  const isMobileWidth = windowSize.width <= 675;
  const examPaths = [FLEX_LANDING_PATH, CERTIFYING_STATEMENT_PATH];
  const isExamPath = examPaths.includes(window.location.pathname);
  const isLogoVisible  = windowSize.width <= 1170 && !isExamPath;

  // Adding ternarary wrappers in advance so that we can add the
  // layout-container and central-column classes when ready
  return (
    <header className="right-side-nav" role="banner">
      <div className="pageHeaderdiv">
        <div className="leftHeaderSection">
          {isLogoVisible && (
            <a href={HOME_PATH} tabIndex={isSideNavBarVisible ? '-1' : '0'}>
              <img
                className="logo"
                src={LawHubLogoVertical}
                alt={`${intl.formatMessage({
                  ...messages.logoHome,
                })}`}
              />
            </a>
          )}
        </div>

        <div className="rightUserSection">
          {!userDetails && !isMobileWidth && (
            <span
              className="welcome-message"
              style={{ marginRight: userDetails ? '1rem' : '0.4rem' }}
            >
              Welcome to LawHub
            </span>
          )}
          <div className="userWelcomeAndHelp">
            {!isMobileWidth && (
              <>
                {userDetails && (
                  <>
                    <a
                      href="https://lsac.zendesk.com/hc/en-us"
                      target="_blank"
                      title="Help, will open in a new browser window."
                      rel="noopener noreferrer"
                      className="pageHeaderHelpLink"
                      data-testid="header-help-link"
                      tabIndex={isSideNavBarVisible ? '-1' : '0'}
                    >
                      <img className="helpIcon" src={HelpIcon} alt="Help" />
                    </a>

                    <span className="hidden">
                      will open in a new browser window
                    </span>
                  </>
                )}
                <img src={VerticalSeparator} alt="" />
              </>
            )}
            {userDetails ? <UserProfile /> : <SignInButton />}
          </div>
          <HamburgerButton
            id="Homehamburger"
            onClick={e => showSidebarHandler(e, onShowSideBar)}
            onKeyPress={e => showSidebarHandler(e, onShowSideBar)}
            ariaLabel={`${intl.formatMessage({
              ...messages.mainNavigation,
            })}`}
            tabIndex={isSideNavBarVisible ? '-1' : '0'}
          />
        </div>
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  windowSize: PropTypes.object,
  intl: intlShape,
  showSidebar: PropTypes.func,
  userDetails: PropTypes.object,
  onShowSideBar: PropTypes.func,
  showSidebarHandler: PropTypes.func,
  isSideNavBarVisible: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  windowSize: windowSizeSelector(),
  isSideNavBarVisible: showSideNavSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onShowSideBar: hasToShowSideBar =>
      dispatch(displaySideNavBar(hasToShowSideBar)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(PageHeader));
