import { defineMessages } from 'react-intl';
import { config } from '../../config';

export const scope = 'app.container.PasswordPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME} / Registration Notification`,
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage:
      'Welcome, {full_name}! You are scheduled for the {admin_date} LSAT.',
  },
  heading2: {
    id: `${scope}.heading2`,
    defaultMessage: 'CERTIFYING STATEMENT',
  },
  heading3: {
    id: `${scope}.heading3`,
    defaultMessage:
      'You must <u>read aloud this statement</u> and agree to it in order to proceed to the test',
  },
  heading4: {
    id: `${scope}.heading4`,
    defaultMessage: 'Proctor enters password',
  },
  certifyingStatementFirstParagraph: {
    id: `${scope}.certifyingStatementFirstParagraph`,
    defaultMessage: `I certify that I am the candidate whose name appears on the ${
      config.REACT_APP_LSAT_NAME
    } and that I am here to take the ${
      config.REACT_APP_LSAT_NAME
    } for the sole purpose of being considered for admission to law school. I further certify that I will neither assist nor receive assistance from any other candidate during the ${
      config.REACT_APP_LSAT_NAME
    } and I agree that after I take the ${
      config.REACT_APP_LSAT_NAME
    }, I will not discuss, share, disclose, copy, or otherwise misappropriate the ${
      config.REACT_APP_LSAT_NAME
    } or any intellectual property related to the ${
      config.REACT_APP_LSAT_NAME
    }, whether orally, in writing, on the Internet or via any other means or media, nor will I assist any person or entity in doing so. Any such discussion, sharing, disclosure, copying, or misappropriation of the ${
      config.REACT_APP_LSAT_NAME
    } could constitute a crime and may result in enforcement actions against you, to the fullest extent permitted by law, including but not limited to, civil or criminal penalties.`,
  },
  certifyingStatementSecondParagraph: {
    id: `${scope}.certifyingStatementSecondParagraph`,
    defaultMessage: `By having the proctor enter the password that authorizes my taking of the ${
      config.REACT_APP_LSAT_NAME
    }, I certify that I have read and understood this Certifying Statement, agree to the statement in the paragraph above and agree to be bound by all policies of the Law School Admission Council, Inc. relating to the ${
      config.REACT_APP_LSAT_NAME
    }.`,
  },
  logoText: {
    id: `${scope}.logoText`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME}`,
  },
  buttonText: {
    id: `${scope}.buttonText`,
    defaultMessage: 'START the test',
  },
  invalidPasswordText: {
    id: `${scope}.invalidPasswordText`,
    defaultMessage: 'Invalid password. Please try again.',
  },
  lockDownText: {
    id: `${scope}.lockDownText`,
    defaultMessage:
      'Password attempts exceeded. Please wait a few minutes and try again.',
  },
});
