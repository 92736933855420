import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import { getUser, signIn } from '../../authProvider';
import AriadneLoading from '../ariadne/AriadneLoading/AriadneLoading';

/**
 * A memoized component that checks if a user is logged in. Triggers auth redirect
 * if not, or loads the component normally otherwise
 * @returns {JSX.Element} Either the children (if signed in) or a loading modal.
 */
const ProtectedRoute = React.memo(({ children }) => {
  // Memoize the signIn function
  const handleSignIn = useCallback(() => signIn(), []);
  // If not logged in, trigger auth.
  useEffect(() => {
    if (!getUser()) {
      handleSignIn();
    }
  }, []);

  // Render children if logged in
  if (getUser()) {
    return children;
  }

  return (
    <Container fluid style={{ width: '100vw' }}>
      <Row>
        <AriadneLoading text="session data" />
      </Row>
    </Container>
  );
});

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: PropTypes.object,
};
