/**
 *
 * ConfirmExamSaved
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNullOrUndefined } from 'util';
import history from 'utils/history';
import { config } from '../../config';
import { sendStateNow } from '../../helpers/persistence/instanceStateManagementHelper';
import { getUserId } from '../../helpers/userHelper';
import { RETRIEVING, TAKING_TOO_LONG, TITLE } from './constants';
import { persistenceState } from '../../helpers/persistence/common/persistenceState';
import { getInstanceById } from '../../helpers/persistence/common/repoHelper';
import { removeInstanceFromRepo } from '../../helpers/persistence/common/instanceHelper';

function ConfirmExamSaved({
  getTestInstanceStatus,
  testInstanceId,
  testInstanceStatus,
  redirect,
  timeoutCallback,
  clearTestInstanceStatus,
  verificationCallback,
  isPrepTest,
}) {
  let timer = null;
  let startTime = null;
  let lastCheck = null;
  let timeUntilRetry = parseInt(config.REACT_APP_FLEX_SAVE_INITIAL_DELAY, 10);

  const [isTakingTooLong, setIsTakingTooLong] = useState(false);

  useEffect(() => {
    startTime = Date.now();
    lastCheck = Date.now();
    sendStateNow(testInstanceId);
    if (!verifyTestIsComplete() && !isNullOrUndefined(testInstanceStatus)) {
      clearTestInstanceStatus();
    }
    timer = window.setInterval(onTick, 100);
    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if (!isNullOrUndefined(testInstanceStatus)) {
      if (!verifyTestIsComplete()) {
        clearTestInstanceStatus();
        sendStateNow(testInstanceId);
      }
    }
  }, [testInstanceStatus]);

  function clearTimer() {
    if (timer != null) {
      window.clearInterval(timer);
      timer = null;
    }
  }

  function verifyTestIsComplete() {
    if (isNullOrUndefined(testInstanceStatus)) {
      return false;
    }
    if (!testInstanceStatus.isCompleted) {
      // The server might not have the updated data, try and resave it
      const instance = getInstanceById(testInstanceId, false);
      if (instance) {
        persistenceState.putInstanceStateCall({
          userId: getUserId(),
          testInstanceId: instance.testInstanceId,
          state: instance.state,
          summary: instance.summary,
          launchDetails: instance.launchDetails,
        });
      }
    }
    const isComplete =
      testInstanceStatus.endDate && testInstanceStatus.isCompleted;
    if (isComplete && testInstanceId === testInstanceStatus.testInstanceId) {
      clearTimer();
      if (redirect) {
        history.replace(redirect);
      } else if (verificationCallback) {
        verificationCallback();
      }

      // Update the below persistenceState values to avoid PUT calls after the instance is completed
      // and updated on server.
      const instance = getInstanceById(testInstanceId);
      if (instance.state?.isCompleted) {
        persistenceState.completedTestIds?.push(testInstanceId);
        persistenceState.isCurrentTestCompleted = true;
        removeInstanceFromRepo(testInstanceId);
      }

      return true;
    }

    return false;
  }

  function onTick() {
    const now = Date.now();
    if (now - lastCheck > timeUntilRetry) {
      timeUntilRetry = parseInt(
        config.REACT_APP_FLEX_SAVE_RETRY_DELAY_MIL_SECS,
        10,
      );
      lastCheck = now;
      getTestInstanceStatus(getUserId(), testInstanceId);
    }
    if (
      now - startTime >
      parseInt(config.REACT_APP_FLEX_SAVE_TAKING_TOO_LONG_MILLI_SECS, 10)
    ) {
      setIsTakingTooLong(true);
      if (timeoutCallback) timeoutCallback();
    }
  }

  if (isPrepTest) {
    return (
      <div className="api_error_page">
        <h1 className="heading-color">{TITLE}</h1>
        <hr className="api_error_page-horizontal-rule" role="none" />
        <div>
          <p className="api_error_page-heading1">
            {isTakingTooLong ? `${TAKING_TOO_LONG}` : `${RETRIEVING}`}
          </p>
        </div>
      </div>
    );
  }
  return <div id="confirm_save" style={{ display: 'none' }} />;
}

ConfirmExamSaved.propTypes = {
  getTestInstanceStatus: PropTypes.func,
  testInstanceStatus: PropTypes.object,
  testInstanceId: PropTypes.string,
  redirect: PropTypes.string,
  timeoutCallback: PropTypes.func,
  clearTestInstanceStatus: PropTypes.func,
  verificationCallback: PropTypes.func,
  isPrepTest: PropTypes.bool,
};

export default ConfirmExamSaved;
