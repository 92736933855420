/* eslint-disable no-param-reassign */
/*
 *
 * SessionState saga
 *
 */
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  verifyExistingUser,
  getApi,
  getNonAuthApi,
  createUser,
  getUser,
  PLUSRedirectIfNeeded,
} from 'helpers/sagaHelper';
import { getUser as getAuthStatus } from '../../authProvider';
import {
  pushGtmEvent,
  SIGN_UP,
  LOGIN,
} from '../../helpers/googleAnalyticsHelper';
import { LogLevelType } from '../../enumerations/LogLevelType';
import { config } from '../../config';
import {
  GET_USER_DETAILS,
  PUT_CONFIRM_USER,
  PUT_USER_SETTINGS,
  PUT_USER_EMAIL,
  SET_USER_EMAIL,
  EMAIL_CONFLICT_HTTP_CODE,
  GET_TAX_INFO,
  GET_PRODUCT_INFO,
  POST_PRODUCT_PAYMENT,
  POST_LOGIN_COUNT,
  RECORDED_LOGIN,
  GET_OFFER_CODE,
  POST_USER_OFFER,
  POST_PREP_PLUS_PAYMENT,
  FETCH_KEY,
  SET_PLUS_REGISTERED_FLAG,
  GET_CONFIG_DETAILS,
} from './constants';
import {
  initializeSagaLibraryAction,
  getUserDetailsErrorAction,
  putUserSettingsSuccessAction,
  putUserSettingsErrorAction,
  putConfirmUserSuccessAction,
  putConfirmUserErrorAction,
  setEmailVerificationAction,
  putEmailErrorAction,
  setUserEmailSuccessAction,
  setUserEmailErrorAction,
  setPlusRegisteredFlagSuccessAction,
  setPlusRegisteredFlagErrorAction,
  getTaxInfoSuccessAction,
  getTaxInfoErrorAction,
  getProductInfoSucessAction,
  getProductInfoErrorAction,
  setPaymentInProgressAction,
  postPrepPlusPaymentSuccess,
  postPrepPlusPaymentError,
  postProductPaymentSuccess,
  postProductPaymentError,
  postLoginCountSuccessAction,
  postLoginCountErrorAction,
  getOfferCodeSuccessAction,
  getOfferCodeErrorAction,
  postUserOfferSuccessAction,
  postUserOfferErrorAction,
  fetchKeySuccessAction,
  fetchKeyErrorAction,
  getUserDetailsSuccessAction,
  getConfigDetailsSuccessAction,
  getConfigDetailsErrorAction,
} from './actions';
import {
  getAPIFullPath,
  getNonAuthAPIFullPath,
  getServerConfigHashFromLocal,
  getServerConfigObjectFromLocal,
} from '../../helpers/configHelper';
import handleAPIErrorAndRetry from '../../helpers/apiResponseHelper';
import { getUserId, setUserDetails } from '../../helpers/userHelper';
import * as logHelper from '../../helpers/logHelper';
import { urlBase64ToUint8Array } from '../../subscription';

const util = require('util');

export function* initUser() {
  function* getUserSuccessCallback(repoData) {
    details = repoData.data;

    yield put(getUserDetailsSuccessAction(details));
    setUserDetails(details);
    PLUSRedirectIfNeeded(details);
  }
  function* getUserFailureCallback(error) {
    yield put(getUserDetailsErrorAction(error));
  }
  function* createUserSuccessCallback() {
    yield call(initUser);
  }
  function* createUserFailureCallback(error) {
    yield put(getUserDetailsErrorAction(error));
  }

  let details = null;
  try {
    // Perform the http call, verify user, set CandSeq
    const repos = yield call(getUser);
    // Send the user data back to the reducer
    yield* getUserSuccessCallback(repos);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      try {
        yield call(createUser);
      } catch (error) {
        yield* handleAPIErrorAndRetry(
          error,
          createUser,
          createUserSuccessCallback,
          createUserFailureCallback,
        );
      }
      yield call(initUser);
    } else {
      // Retry. If API failure persists, send error to the reducer
      // and redirect to API Error Page.
      yield* handleAPIErrorAndRetry(
        err,
        getUser,
        getUserSuccessCallback,
        getUserFailureCallback,
      );
    }
  }

  return details;
}

/**
 * Get user details
 *
 */
export function* getUserDetails() {
  try {
    yield call(initUser);
  } catch (err) {
    yield put(getUserDetailsErrorAction(err));
  }
}

/**
 * Save user settings
 *
 * @param {object} data The user settings data
 */
export function* putUserSettings(data) {
  function* successCallback(_, callbackData) {
    yield put(putUserSettingsSuccessAction(callbackData.userSettings));
  }
  function* failureCallback(error) {
    yield put(putUserSettingsErrorAction(error));
  }

  const { userSettings } = data.data;
  let api;
  const callbackData = { userSettings };

  try {
    const userId = getUserId();
    yield call(verifyExistingUser);

    const payload = userSettings;
    const apiEndURL = `${util.format(
      config.REACT_APP_SAVE_USER_SETTINGS_URL,
      userId,
    )}`;
    const url = `${getAPIFullPath(apiEndURL)}`;
    api = () => getApi('put', url, payload);

    yield call(api);
    // send the result back to the reducer
    yield put(putUserSettingsSuccessAction(userSettings));
  } catch (err) {
    logHelper.log(LogLevelType.Error, err);
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback, {
      callbackData,
    });
  }
}

/**
 * Confirm user
 *
 * @param {object} data The user data
 */
export function* putConfirmUser(data) {
  function* successCallback(repoData) {
    yield put(putConfirmUserSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(putConfirmUserErrorAction(error));
  }

  const userInfo = data.data;
  const { vendorId } = userInfo;
  const confirmUserAPI = `${util.format(
    config.REACT_APP_CONFIRM_USER_URL,
    vendorId,
  )}`;
  let api;

  try {
    yield call(verifyExistingUser);
    const payload = userInfo;
    const url = `${getAPIFullPath(confirmUserAPI)}`;
    api = () => getApi('post', url, payload);

    const vendorInfo = yield call(api);

    // send the result back to the reducer
    yield put(putConfirmUserSuccessAction(vendorInfo.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * Confirm user
 *
 * @param {object} data The user data
 */
export function* patchUserEmailVerification(data) {
  function* successCallback(repoData, callbackData) {
    const userNewEmailDetails = {
      code: repoData.data.code,
      userNewEmailAddress: callbackData.userEmailDetails.newEmail,
    };
    yield put(setEmailVerificationAction(userNewEmailDetails));
  }
  function* failureCallback(error) {
    yield put(putEmailErrorAction(error));
  }

  const userId = getUserId();
  const { userEmailDetails } = data;
  userEmailDetails.userId = userId;

  let api;
  const callbackData = { userEmailDetails };

  const userVerifyAPI = `${util.format(
    config.REACT_APP_USER_VERIFY_EMAIL,
    userId,
  )}`;
  try {
    yield call(verifyExistingUser);
    const payload = userEmailDetails;

    const url = `${getAPIFullPath(userVerifyAPI)}`;
    api = () => getApi('patch', url, payload);

    const emailInfo = yield call(api);
    yield successCallback(emailInfo, callbackData);
  } catch (err) {
    if (err.response && err.response.status === EMAIL_CONFLICT_HTTP_CODE) {
      const conflictErrorMessage = `Email ${
        userEmailDetails.newEmail
      } is already taken`;
      yield put(setUserEmailErrorAction(conflictErrorMessage));
    } else {
      // send the error back to the reducer
      yield* handleAPIErrorAndRetry(
        err,
        api,
        successCallback,
        failureCallback,
        { callbackData },
      );
    }
  }
}

/**
 * Confirm user
 *
 * @param {object} data The user data
 */
export function* setUserEmail(data) {
  function* successCallback(_, callbackData) {
    yield put(setUserEmailSuccessAction(callbackData.userEmailDetails));
  }
  function* failureCallback(error) {
    yield put(putEmailErrorAction(error));
  }

  const { userEmailDetails } = data;
  let api;
  const callbackData = { userEmailDetails };

  try {
    const userId = getUserId();
    userEmailDetails.userId = userId;
    yield call(verifyExistingUser);
    const payload = userEmailDetails;
    const userVerifyAPI = `${util.format(
      config.REACT_APP_USER_VERIFY_EMAIL,
      userId,
    )}`;

    const url = `${getAPIFullPath(userVerifyAPI)}`;
    api = () => getApi('put', url, payload);

    yield call(api);
    // send the result back to the reducer
    yield put(setUserEmailSuccessAction(userEmailDetails));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback, {
      callbackData,
    });
  }
}

/**
 * Set PLUS registered flag
 *
 */
export function* setPlusRegisteredFlag() {
  function* successCallback() {
    yield put(setPlusRegisteredFlagSuccessAction());
  }
  function* failureCallback(error) {
    yield put(setPlusRegisteredFlagErrorAction(error));
  }

  let api;

  try {
    const userId = getUserId();
    yield call(verifyExistingUser);
    const setRegisteredAPI = `${util.format(
      config.REACT_APP_SET_PLUS_REGISTRATION_COMPLETED,
      userId,
    )}`;
    const url = `${getAPIFullPath(setRegisteredAPI)}`;
    api = () => getApi('post', url);
    yield call(api);
    // send the result back to the reducer
    yield put(setPlusRegisteredFlagSuccessAction());
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * Post login count
 *
 */
export function* postLoginCountSaga() {
  function* successCallback(repoData) {
    yield put(postLoginCountSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(postLoginCountErrorAction(error));
  }

  let api;

  try {
    // If we have already recorded the login this session, return early
    if (sessionStorage.getItem(RECORDED_LOGIN) === 'true') {
      return;
    }

    // If we do not have a record of the login, create the record and POST
    if (sessionStorage.getItem(RECORDED_LOGIN) !== 'true') {
      sessionStorage.setItem(RECORDED_LOGIN, 'true');

      const userId = getUserId();
      const postLoginCountApiPath = `${util.format(
        config.REACT_APP_POST_LOGIN_COUNT_URL,
        userId,
      )}`;
      const url = `${getAPIFullPath(postLoginCountApiPath)}`;

      api = () => getApi('post', url);

      // Make the POST http call
      const response = yield call(api);

      // send the retrieved data to the reducer
      yield put(postLoginCountSuccessAction(response.data));

      // Push sign_up or login events to GTM
      const { loginCount } = response.data;
      if (!!loginCount && loginCount > 1) {
        pushGtmEvent(LOGIN);
      } else {
        pushGtmEvent(SIGN_UP);
      }
    }
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * Validate offer code
 *
 * @param {string} data
 */
export function* getOfferCode(data) {
  function* successCallback(repoData) {
    yield put(getOfferCodeSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(getOfferCodeErrorAction(error));
  }

  const { offerCode } = data;
  let api;

  try {
    const getOfferApiPath = `${util.format(
      config.REACT_APP_GET_VALIDATE_OFFER_URL,
      offerCode,
    )}`;
    const url = new URL(getOfferApiPath, config.REACT_APP_ENDPOINT);
    api = () => getApi('get', url);

    // Make the GET http call
    const response = yield call(api);

    // send the retrieved data to the reducer
    yield put(getOfferCodeSuccessAction(response.data));
  } catch (err) {
    if (err?.response?.status === 400) {
      // /offers route handles 400 error code itself
      yield* failureCallback(err);
    } else {
      // send the error back to the reducer
      yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
    }
  }
}

/**
 * Redeem valid offer code
 *
 * @param {Object} offerData offer code to redeem
 */
export function* postUserOfferSaga({ offerCode }) {
  function* successCallback(repoData) {
    yield put(postUserOfferSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(postUserOfferErrorAction(error));
  }

  let api;
  try {
    yield call(verifyExistingUser);
    const userId = getUserId();
    const redeemOfferAPI = `${util.format(
      config.REACT_APP_POST_REDEEM_OFFER_URL,
      userId,
      offerCode,
    )}`;

    const url = new URL(redeemOfferAPI, config.REACT_APP_ENDPOINT);

    api = () => getApi('post', url);

    const response = yield call(api);

    // send the result back to the reducer
    yield put(postUserOfferSuccessAction(response.data));
  } catch (err) {
    if (err?.response?.status === 400) {
      // /offers route handles 400 error code itself
      yield* failureCallback(err);
    } else {
      // send the error back to the reducer
      yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
    }
  }
}

export function* getTaxInfo(data) {
  function* successCallback(repoData) {
    yield put(getTaxInfoSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(getTaxInfoErrorAction(error));
  }

  const { taxRequest } = data;
  let api;

  try {
    if (taxRequest.country === 'US' || taxRequest.country === 'CA') {
      yield call(verifyExistingUser);
      const payload = taxRequest;
      const getTaxAPI = `${getAPIFullPath(config.REACT_APP_GET_TAX_URL)}`;

      const url = `${getAPIFullPath(getTaxAPI)}`;
      api = () => getApi('post', url, payload);

      const response = yield call(api);
      yield put(getTaxInfoSuccessAction(response.data));
    } else {
      // other countries don't need tax calculated
      const taxInfo = { decision: 'ACCEPT', totalTax: 0 };
      yield put(getTaxInfoSuccessAction(taxInfo));
    }
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * @param {Object} data data about the product
 * @param {string} data.productId The product's id
 */
export function* getProductInfo(data) {
  function* successCallback(repoData) {
    yield put(getProductInfoSucessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(getProductInfoErrorAction(error));
  }

  let api;

  try {
    const { productId } = data;
    yield call(verifyExistingUser);

    // Format URL with productId
    const getProductInfoApi = `${util.format(
      config.REACT_APP_GET_PRODUCT_INFO,
      productId,
    )}`;

    const url = `${getAPIFullPath(getProductInfoApi)}`;
    api = () => getApi('get', url);

    // Make the api call
    const response = yield call(api);

    // Send product data to the reducer
    yield put(getProductInfoSucessAction(response.data));
  } catch (err) {
    // Send the error to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * @param {Object} data data about the product
 * @param {Object} data.paymentInfo The submitted payment info
 */
export function* postProductPaymentSaga(data) {
  yield put(setPaymentInProgressAction(true));

  function* successCallback(repoData) {
    yield put(postProductPaymentSuccess(repoData.data));
  }
  function* failureCallback(error) {
    yield put(postProductPaymentError(error));
  }

  let api;

  try {
    yield call(verifyExistingUser);

    const userId = getUserId();
    const { paymentInfo } = data;
    const payload = paymentInfo;

    // Format URL with userId
    const postProductPaymentApi = `${util.format(
      config.REACT_APP_USER_PURCHASE_URL,
      userId,
    )}`;

    const url = `${getAPIFullPath(postProductPaymentApi)}`;
    api = () => getApi('post', url, payload);

    // Make the api call
    const response = yield call(api);

    // Send product data to the reducer
    yield put(postProductPaymentSuccess(response.data));
  } catch (err) {
    // Send the error to the reducer
    if (err?.response?.status === 400) {
      yield* failureCallback(err);
    } else {
      yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
    }
  }
  yield put(setPaymentInProgressAction(false));
}

/**
 * Get client config from server
 *
 */
export function* getConfigDetails() {
  const isUnauthed = getAuthStatus() === null;
  const fullPathGetter = isUnauthed ? getNonAuthAPIFullPath : getAPIFullPath;
  const apiGetter = isUnauthed ? getNonAuthApi : getApi;

  function* successCallback(repoData) {
    yield put(getConfigDetailsSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(getConfigDetailsErrorAction(error));
  }

  // This will either be the fetch_url or hash_url.
  // Update to either depending on the conditions
  let requestURL = `${fullPathGetter(config.REACT_APP_CONFIG_API_HASH_URL)}`;
  const api = () => apiGetter('get', requestURL);

  try {
    const storedServerHash = getServerConfigHashFromLocal();
    if (!storedServerHash) {
      // we don't have any config locally stored, so let's retrieve it
      const getConfigDetailsURL = `${fullPathGetter(
        config.REACT_APP_CONFIG_API_FETCH_URL,
      )}`;

      requestURL = getConfigDetailsURL;
      const response = yield call(apiGetter, 'get', getConfigDetailsURL);
      yield put(getConfigDetailsSuccessAction(response.data));
    } else {
      // compare hash to current server hash
      const getConfigHashURL = `${fullPathGetter(
        config.REACT_APP_CONFIG_API_HASH_URL,
      )}`;
      requestURL = getConfigHashURL;
      const response = yield call(apiGetter, 'get', getConfigHashURL);

      if (response?.data !== storedServerHash) {
        const getConfigDetailsURL = `${fullPathGetter(
          config.REACT_APP_CONFIG_API_FETCH_URL,
        )}`;
        requestURL = getConfigDetailsURL;
        const resp = yield call(apiGetter, 'get', getConfigDetailsURL);
        yield put(getConfigDetailsSuccessAction(resp.data));
      } else {
        yield put(
          getConfigDetailsSuccessAction(getServerConfigObjectFromLocal(), true),
        );
      }
    }
  } catch (err) {
    // Retry. If API failure persists, send error to the reducer
    // and redirect to API Error Page.
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

/**
 * Buy prep plus subscription
 *
 * @param {object} data The payment info
 */
export function* postPrepPlusPayment(data) {
  function* successCallback(repoData) {
    yield put(postPrepPlusPaymentSuccess(repoData.data));
  }
  function* failureCallback(error) {
    yield put(postPrepPlusPaymentError(error));
  }

  yield put(setPaymentInProgressAction(true));
  const { paymentInfo } = data;
  const userId = getUserId();
  let api;

  try {
    yield call(verifyExistingUser);
    const payload = paymentInfo;
    const buyPrepPlusAPI = `${util.format(
      config.REACT_APP_BUY_PREP_PLUS,
      userId,
    )}`;

    const url = `${getAPIFullPath(buyPrepPlusAPI)}`;
    api = () => getApi('post', url, payload);

    const response = yield call(api);

    yield put(postPrepPlusPaymentSuccess(response.data));
  } catch (err) {
    // send the error back to the reducer
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
  yield put(setPaymentInProgressAction(false));
}

/**
 * Get user details
 *
 */
export function* fetchKey() {
  function* successCallback(repoData) {
    const key = repoData.data;
    const registration = yield navigator.serviceWorker.ready;
    if (!registration.pushManager) {
      logHelper.log(LogLevelType.Info, 'Push manager unavailable.');
      return;
    }
    const existedSubscription = yield registration.pushManager.getSubscription();
    if (existedSubscription === null) {
      logHelper.log(
        LogLevelType.Info,
        'No subscription detected, make a request.',
      );
      const newSubscription = yield registration.pushManager.subscribe({
        applicationServerKey: urlBase64ToUint8Array(key),
        userVisibleOnly: true,
      });
      yield call(sendSubscription, newSubscription);
    } else {
      yield call(sendSubscription, existedSubscription);
    }
    yield put(fetchKeySuccessAction());
  }

  function* failureCallback(error) {
    yield put(fetchKeyErrorAction(error));
  }

  let api;

  try {
    if (config.REACT_APP_WEB_PUSH === 'true') {
      yield call(verifyExistingUser);

      const url = `${getAPIFullPath(config.REACT_APP_PUSH_KEY)}`;
      api = () => getApi('get', url);

      const response = yield call(api);
      yield successCallback(response);
    }
  } catch (err) {
    yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
  }
}

function* sendSubscription(subscription) {
  const userId = getUserId();
  const url = `${config.REACT_APP_PUSH_SUBSCRIBE}/${userId}`;
  yield call(getApi, 'post', getAPIFullPath(url), subscription);
}

/**
 * Athena session saga manages watcher lifecycle
 */
export default function* sessionStateData() {
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_USER_DETAILS, getUserDetails);
  yield takeLatest(PUT_USER_SETTINGS, putUserSettings);
  yield takeLatest(PUT_CONFIRM_USER, putConfirmUser);
  yield takeLatest(PUT_USER_EMAIL, patchUserEmailVerification);
  yield takeLatest(SET_USER_EMAIL, setUserEmail);
  yield takeLatest(GET_TAX_INFO, getTaxInfo);
  yield takeLatest(GET_CONFIG_DETAILS, getConfigDetails);
  yield takeLatest(GET_PRODUCT_INFO, getProductInfo);
  yield takeLatest(POST_LOGIN_COUNT, postLoginCountSaga);
  yield takeLatest(GET_OFFER_CODE, getOfferCode);
  yield takeLatest(POST_USER_OFFER, postUserOfferSaga);
  yield takeLatest(POST_PRODUCT_PAYMENT, postProductPaymentSaga);
  yield takeLatest(POST_PREP_PLUS_PAYMENT, postPrepPlusPayment);
  yield takeLatest(FETCH_KEY, fetchKey);
  yield takeLatest(SET_PLUS_REGISTERED_FLAG, setPlusRegisteredFlag);
  yield put(initializeSagaLibraryAction());
}
