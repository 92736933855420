/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import './index.css';
import history from 'utils/history';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isNullOrUndefined } from 'util';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { connect } from 'react-redux';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { useSessionRedux } from 'domains/session/hooks';
import { useLocation } from 'react-router-dom';
import messages from './messages';
import { HOME_PATH } from '../App/constants';
import { TITLE_TEST_FLEX_EXAM } from '../MasterPage/constants';
import {
  isPrometrics,
  getExamCompletePath,
  getCurrentExam,
} from '../../helpers/examHelper';
import ConfirmExamSaved from '../../components/ConfirmExamSaved';
// suports user detail information
import {
  getTestInstanceStatusAction,
  clearTestInstanceStatusAction,
} from '../TestSelectPage/actions';
import {
  passwordHashSelector,
  testInstanceStatusSelector,
  flexExamsSelector,
} from '../TestSelectPage/selectors';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';

// support for getting state that contains the acknowledgements
import directionsPageReducer from '../DirectionsPage/reducer';
import { instancesSelector } from '../DirectionsPage/selectors';
import { persistLocally } from '../../helpers/persistence/persistenceManagementHelper';

const url = require('url');

export function TestCompleteFlexPage({
  intl,
  onClearTestInstanceStatus,
  onGetTestInstanceStatus,
  testInstanceStatus,
  passwordHash,
  examType,
  modules,
}) {
  // required in order to get user details
  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useSessionRedux();
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  // required in order to get test instances
  useInjectReducer({ key: 'directionsPage', reducer: directionsPageReducer });

  const [takingTooLong, setTakingTooLong] = useState(false);
  const [isPrometricsUser, setIsPrometricsUser] = useState(false);

  // required to toggle saveTest and Finish button
  const [testFinished, setTestFinished] = useState(false);

  // fetching testInstanceId from query since it is no longer passed as a prop
  const location = useLocation();
  const testInstanceId = url.parse(location.search, true).query.id;

  const redirect = getExamCompletePath(examType, testInstanceId);
  const currentExam = getCurrentExam(modules);

  if (currentExam) {
    currentExam.examStatus = 'Completed';
  }
  // required in order to get user details
  useEffect(() => {
    document.title = TITLE_TEST_FLEX_EXAM;
  }, []);

  useEffect(() => {
    setIsPrometricsUser(isPrometrics(modules ? modules[0] : undefined));
  }, [modules]);

  if (isNullOrUndefined(testInstanceId)) {
    // this page was navigated to directly without any parameter (testInstanceId) or it was passed
    // a parameter but it doesn't not match any of the test instances in the state so simply redirect
    // to the home page
    history.push(HOME_PATH);

    // we are done here so return null to get out
    return null;
  }

  // accessibility support for keyboard navigation
  document.body.addEventListener('mousedown', () => {
    document.body.classList.remove('keyboard-focus');
    document.body.classList.add('mouse-focus');
  });

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('mouse-focus');
    document.body.classList.add('keyboard-focus');
  });

  function saveLocally() {
    persistLocally(
      testInstanceId,
      isNullOrUndefined(passwordHash) ? '' : passwordHash,
      examType,
    );
    // Display Finish button after saving test locally
    setTestFinished(true);
  }

  // Redirect to the Test Complete screen
  function finishTest() {
    history.replace(redirect);
  }

  const onTimeOut = () => {
    setTakingTooLong(true);
  };

  const typeOfExam = !isNullOrUndefined(examType)
    ? examType.toLowerCase()
    : 'flex';
  const savingMsgObject = !takingTooLong
    ? { ...messages.savingMessage }
    : { ...messages.retryingMessage };
  const savingMsg = intl.formatMessage(savingMsgObject);
  const savingHdrObject = !takingTooLong
    ? { ...messages.savingHeader }
    : { ...messages.retryingHeader };
  const savingHdr = intl.formatMessage(savingHdrObject);
  const takingTooLongMessage = (
    <FormattedHTMLMessage {...messages.takeTooLongMessage} />
  );
  const takingTooLongMessagePM = (
    <FormattedHTMLMessage {...messages.takeTooLongMessagePM} />
  );
  const header =
    typeOfExam === 'writing'
      ? intl.formatMessage({
          ...messages.writingHeader,
        })
      : intl.formatMessage({
          ...messages.header,
        });
  return (
    <div className="test-complete-flex-page-container">
      <div className="test-complete-flex-page-header">
        <div className="test-complete-flex-page-header-div">
          <div className="test-complete-flex-page-header-text">{header}</div>
        </div>
      </div>
      <div className="test-complete-flex-page-top-decoration" />
      <div className="test-complete-flex-page">
        {takingTooLong && (
          <div>
            <h1 id="test-complete-flex-page-heading1">{savingHdr}</h1>
            <div className="text-center">
              {isPrometricsUser ? (
                <p className="test-complete-flex-page-text-content">
                  {takingTooLongMessagePM}
                </p>
              ) : (
                <>
                  <p className="test-complete-flex-page-text-content">
                    {takingTooLongMessage}
                  </p>
                  {!testFinished ? (
                    <button
                      className="test-complete-flex-page-close-button"
                      type="button"
                      onClick={saveLocally}
                    >
                      <span className="test-complete-flex-page-close-button-text">
                        <FormattedMessage {...messages.saveText} />
                      </span>
                    </button>
                  ) : (
                    <button
                      className="test-complete-flex-page-close-button"
                      type="button"
                      onClick={finishTest}
                    >
                      <span className="test-complete-flex-page-close-button-text">
                        <FormattedMessage {...messages.finishTest} />
                      </span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {!takingTooLong && (
          <div>
            <h1 id="test-complete-flex-page-heading1">{savingHdr}</h1>
            <div className="text-center">
              <p className="test-complete-flex-page-text-content">
                {savingMsg}
              </p>
              <div className="spinner-border" role="status" />
            </div>
          </div>
        )}
      </div>
      <ConfirmExamSaved
        getTestInstanceStatus={onGetTestInstanceStatus}
        testInstanceStatus={testInstanceStatus}
        testInstanceId={testInstanceId}
        redirect={redirect}
        timeoutCallback={onTimeOut}
        clearTestInstanceStatus={onClearTestInstanceStatus}
      />
    </div>
  );
}

TestCompleteFlexPage.propTypes = {
  intl: intlShape.isRequired,
  onClearTestInstanceStatus: PropTypes.func,
  onGetTestInstanceStatus: PropTypes.func,
  testInstanceStatus: PropTypes.object,
  error: PropTypes.object,
  passwordHash: PropTypes.string,
  examType: PropTypes.string,
  modules: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  instances: instancesSelector(),
  passwordHash: passwordHashSelector(),
  testInstanceStatus: testInstanceStatusSelector(),
  modules: flexExamsSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onClearTestInstanceStatus: () => dispatch(clearTestInstanceStatusAction()),
    onGetTestInstanceStatus: (userId, instanceId) =>
      dispatch(getTestInstanceStatusAction(userId, instanceId)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(TestCompleteFlexPage));
