import React, { useEffect } from 'react';
import './index.css';
import { signupUrl } from '../../auth';
const SignupPage = () => {
  useEffect(() => {
    const redirectSignup = async () => {
      const signupUrlData = await signupUrl();
      window.location.href = signupUrlData;
    };

    redirectSignup();
  }, []);

  return <>Redirecting...</>;
};

export default SignupPage;
