/*
 *
 * Authentication helper
 * */
import * as authProvider from './authProvider';

export function logout() {
  authProvider.logout();
}

export function getUser() {
  return authProvider.getUser();
}

export async function getAccessToken() {
  let token;
  await authProvider.getAccessToken().then(response => {
    token = response?.accessToken;
  });
  return token;
}

export function resetPassword() {
  authProvider.resetPassword();
}

export function signupUrl() {
  return authProvider.signupUrl();
}
