/*
 *
 * DirectionsPage actions
 *
 */

import {
  UPDATE_BREADCRUMB,
  DISPLAY_SIDENAV_BAR,
  UPDATE_WINDOW_SIZE,
} from './constants';

/**
 * Update breadcrumb
 *
 */
export function updateBreadCrumb(settings) {
  return {
    type: UPDATE_BREADCRUMB,
    settings,
  };
}

/**
 * Display side nav bar
 *
 */
export function displaySideNavBar(showSidenNavBar) {
  return {
    type: DISPLAY_SIDENAV_BAR,
    showSidenNavBar,
  };
}

/**
 * Update window size
 *
 */
export function updateWindowSize(size) {
  return {
    type: UPDATE_WINDOW_SIZE,
    size,
  };
}
