import { persistenceState } from './persistence/common/persistenceState';

export function getTechnicalPauseData() {
  let data;

  persistenceState.module.sections
    .slice()
    .sort((a, b) => (a.sectionOrder > b.sectionOrder ? -1 : 1))
    .forEach(section => {
      if (section.isCompleted !== null && section.isCompleted !== undefined) {
        if (section.isCompleted === false) {
          data = {
            sectionLocation: section.sectionOrder,
            sectionTimeRemaining: section.timeRemaining,
          };
        }
      }
    });

  return data;
}
