import PropTypes from 'prop-types';
import textModel from './textModel';

/**
 * The stimulus model
 * @class
 */

class StimulusModel extends textModel {
  constructor(props) {
    super(props);
    /**
     * @property {string} stimulusId The stimulus id
     */
    this.stimulusId = null;

    /**
     * @property {string} text The stimulus text
     */
    this.text = null;
  }
}

StimulusModel.propTypes = {
  stimulusId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default StimulusModel;
