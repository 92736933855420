/* eslint-disable no-param-reassign */
/*
 *
 * MasterPage reducer
 *
 */
import produce from 'immer';
import {
  UPDATE_BREADCRUMB,
  UPDATE_WINDOW_SIZE,
  BREADCRUMB,
  WINDOW_SIZE,
  DISPLAY_SIDENAV_BAR,
} from './constants';

/**
 *
 * Master page default state
 *
 */
export const initialState = { ...BREADCRUMB, ...WINDOW_SIZE };

/**
 *
 * Directions page reducer for each action
 *
 */
const masterPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case UPDATE_BREADCRUMB:
        draft.settings = action.settings;
        break;
      case DISPLAY_SIDENAV_BAR:
        draft.settings.displaySideNavBar = action.showSidenNavBar;
        break;
      case UPDATE_WINDOW_SIZE:
        draft.windowSize = action.size;
        break;
      default:
        break;
    }
  });

export default masterPageReducer;
