/**
 *
 * secttion helper
 *
 */
import SectionModel from 'models/sectionModel';
import AnswerOptionModel from 'models/answerOptionModel';
import ItemModel from 'models/itemModel';
import { OptionState } from 'enumerations/OptionState';
import { OptionMode } from 'enumerations/OptionMode';
import { ExamMode } from 'enumerations/ExamMode';
import { isNullOrUndefined } from 'util';
import history from '../utils/history';
import * as moduleHelper from './moduleHelper';
import { EXAM_MODULE_PATH } from '../containers/App/constants';

/**
 * Section page
 *
 * @param  {sectionModel} section The section data
 *
 */
export function reformatSection(section) {
  const newSection = Object.assign(new SectionModel(), section);
  if (newSection.items !== null && newSection.stems !== null) {
    for (let i = 0; i < newSection.items.length; i += 1) {
      const item = newSection.items[i];
      newSection.stems[i].words = [];
      newSection.stems[i].highlightList = [];
      for (let o = 0; o < item.answerOptions.length; o += 1) {
        item.answerOptions[o] = Object.assign(
          new AnswerOptionModel(),
          item.answerOptions[o],
        );
        if (item.answerOptions[o].optionState === undefined) {
          item.answerOptions[o].optionState = OptionState.Unselected;
        }
        if (item.answerOptions[o].optionMode === undefined) {
          item.answerOptions[o].optionMode = OptionMode.Expanded;
        }
      }
      newSection.items[i] = Object.assign(new ItemModel(), item);
    }

    if (newSection.stimulus !== null) {
      for (let i = 0; i < newSection.stimulus.length; i += 1) {
        newSection.stimulus[i].words = [];
        newSection.stimulus[i].highlightList = [];
      }
    }
  }
  return newSection;
}

/**
 *Verifies exam mode logic in section page.
 *
 * @param {object} The selectedInstance
 * @param {object} The section
 */
export function verifyExamModeSectionPage(selectedInstance, section) {
  if (!isNullOrUndefined(selectedInstance) && !isNullOrUndefined(section)) {
    const { module, testInstanceId } = selectedInstance;
    if (
      !isNullOrUndefined(module) &&
      !isNullOrUndefined(module.examMode) &&
      module.examMode === ExamMode.ExamTime &&
      section.isCompleted &&
      !moduleHelper.hasAllSectionsCompleted(module) &&
      section?.options?.nextBreakType !== 'intermission'
    ) {
      history.push(`${EXAM_MODULE_PATH}/${testInstanceId}`);
    }
  }
}

/**
 *Verifies section page pause functionality.
 *
 * @param {object} The selectedInstance
 * @param {object} The section
 */
export function verifySectionPause(selectedInstance, onUndoSectionPause) {
  if (
    !isNullOrUndefined(selectedInstance) &&
    !isNullOrUndefined(selectedInstance.module.isSectionPaused) &&
    selectedInstance.module.isSectionPaused
  ) {
    const { pausedOnPage, pausedSectionId } = selectedInstance.module;
    const pausedSection = selectedInstance.module.sections.find(
      s => s.formId === pausedSectionId,
    );

    onUndoSectionPause(
      selectedInstance.module,
      selectedInstance.testInstanceId,
    );
    if (!isNullOrUndefined(pausedSection)) {
      history.push(
        `/${pausedOnPage}/${selectedInstance.testInstanceId}/${
          pausedSection.title
        }`,
      );
    }
  }
}

/**
 * To verify whether section is paper test or not
 * @param {string} sectionName THe section name
 */
export function isPaperTest(sectionName) {
  let isPaperAndPencilTest = false;
  if (!isNullOrUndefined(sectionName)) {
    isPaperAndPencilTest = sectionName.toLowerCase().startsWith('paper');
  }
  return isPaperAndPencilTest;
}

const DEFAULT_ANALYSIS_TIME_IN_MINUTES = 15;
export function getAnalysisTimes(state, section, userDetails) {
  const analysisTime =
    state?.analysisTime ||
    userDetails?.userSettings?.minutesPerAnalysis ||
    DEFAULT_ANALYSIS_TIME_IN_MINUTES;
  let analysisTimeRemaining = section?.analysisTimeRemaining || 0;
  let totalAnalysisSectionTime = section?.totalAnalysisSectionTime || 0;
  if (section.analysisTimeRemaining === 0) {
    analysisTimeRemaining = analysisTime * 1000 * 60;
  }
  if (section.totalAnalysisSectionTime === 0) {
    totalAnalysisSectionTime = analysisTime * 1000 * 60;
  }

  return { analysisTimeRemaining, totalAnalysisSectionTime };
}
