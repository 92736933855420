/**
 *
 * FlexConnectivityErrorPage
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { isNullOrUndefined } from 'util';
import { createStructuredSelector } from 'reselect';
import * as moduleHelper from 'helpers/moduleHelper';
import { getModuleOptions } from 'helpers/examHelper';
import { useInjectSaga } from 'utils/injectSaga';
import {
  userDetailsSelector,
  subscribeUserSelector,
} from 'domains/session/selectors';
import { useSessionReduxAndSaga } from 'domains/session/hooks';
import { instancesSelector } from '../DirectionsPage/selectors';
import messages from './messages';
import { getHasInstanceChanged } from '../../helpers/persistence/repoManagementHelper';
import history from '../../utils/history';
import './index.css';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
// eslint-disable-next-line import/order
import { useInjectReducer } from 'utils/injectReducer';
// support for getting state that contains the acknowledgements
import directionsPageReducer from '../DirectionsPage/reducer';
import {
  setRetrieveFlexExams,
  getFlexExamListAction,
} from '../TestSelectPage/actions';
import { retrievedFlexExamsSelector } from '../TestSelectPage/selectors';
import { fetchKeyAction } from '../../domains/session/actions';
import { getUserId } from '../../helpers/userHelper';
import { unsubscribePush, subscribePush } from '../../helpers/pushHelper';
import { routerSelector } from '../App/selectors';
import { config } from '../../config';

const url = require('url');
let retryCount = 0;

export function FlexConnectivityErrorPage({
  onFetchKeyAction,
  testInstanceId,
  instances,
  intl,
  subscribeUser,
  location,
  onGetFlexExams,
  retrievedFlexExams,
  clearExamList,
}) {
  const retrySectionId = 'retrySection';
  const spinnerId = 'retrySpinner';
  const stopSpinnerClassName = 'stopSpinner';
  const retryInitialTime = 0.5 * 60 * 1000;
  const timeToRetryConnection = 1 * 60 * 1000;
  let retryCompletedTimer = null;
  let timer = null;
  const [hasRetryTimeCompleted, setRetryTimeComplete] = useState(false);
  const selectedInstance = moduleHelper.getInstance(instances, testInstanceId);
  let moduleOptions = selectedInstance?.module?.options;
  if (!moduleOptions) {
    const examType = location
      ? url.parse(location.search, true).query.type
      : 'flex';
    moduleOptions = getModuleOptions(examType);
  }

  // required in order to get user details
  useSessionReduxAndSaga();

  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  // required in order to get test instances
  useInjectReducer({ key: 'directionsPage', reducer: directionsPageReducer });

  // required in order to get user details
  useEffect(() => {
    clearExamList();
    window.setTimeout(
      tick,
      parseInt(config.REACT_APP_FLEX_SAVE_INITIAL_DELAY, 10),
    );
  }, []);

  useEffect(() => {
    if (retrievedFlexExams) {
      retryCount = -1;
      const redirectPath = moduleOptions?.reconnectedPath;
      if (redirectPath === undefined) {
        history.push(moduleOptions.startPath);
      } else {
        history.push(
          // eslint-disable-next-line no-template-curly-in-string
          redirectPath.replace('${testInstanceId}', testInstanceId),
        );
      }
    }
  }, [retrievedFlexExams]);

  useEffect(() => {
    retryCount = 0;
    window.setTimeout(() => {
      onRetry(true);
    }, retryInitialTime);

    return () => {
      unsubscribePush();
      if (!isNullOrUndefined(timer)) {
        window.clearInterval(timer);
      }
      timer = null;
      retryCount = -1;
    };
  }, []);

  function tick() {
    if (retryCount >= 0) {
      if (retrievedFlexExams) {
        retryCount = -1;
      } else {
        onGetFlexExams(getUserId());
        window.setTimeout(
          tick,
          parseInt(config.REACT_APP_FLEX_SAVE_RETRY_DELAY_MIL_SECS, 10),
        );
      }
    }
  }
  //* ******************************* Push starts ***************************/
  useEffect(() => {
    subscribePush(subscribeUser, onFetchKeyAction);
  }, [subscribeUser]);

  //* ************************  Push ends ***********************************/

  function onRetry(isRetrying) {
    const isRetryingNow = isNullOrUndefined(isRetrying) ? false : isRetrying;
    const retrySpinnerDiv = document.getElementById(spinnerId);

    if (!isNullOrUndefined(retrySpinnerDiv)) {
      if (isRetryingNow) {
        retrySpinnerDiv.classList.add(stopSpinnerClassName);
      } else {
        retrySpinnerDiv.classList.remove(stopSpinnerClassName);
      }
    }

    setRetryTimeComplete(isRetryingNow);
  }

  function onRetryConnection() {
    if (!isNullOrUndefined(retryCompletedTimer)) {
      clearTimeout(retryCompletedTimer);
    }

    if (!getHasInstanceChanged(testInstanceId)) {
      history.push(moduleOptions.startPath);
    } else {
      onRetry(false);
      retryCompletedTimer = window.setTimeout(() => {
        onRetry(true);
      }, timeToRetryConnection);
    }
  }

  const errorHeader = 'We seem to be experiencing a connection issue.';
  const errorMessage =
    // eslint-disable-next-line react/prop-types
    moduleOptions.connectivityErrorMessage;

  const retryMesage =
    // eslint-disable-next-line react/prop-types
    moduleOptions.connectivityRetryMessage;

  return (
    <div className="flex-connection-error-page-container">
      <div className="flex-connection-error-page">
        <div className="retrying-content">
          <h1 id="pageHeader">{errorHeader}</h1>
          <br />
          {errorMessage}
          <div className="text-center">
            <br />
            {`${intl.formatMessage({ ...messages.attemptingToRetry })}`}
            <div id={spinnerId} className="spinner-border ml-2" role="status" />
          </div>
        </div>
        {hasRetryTimeCompleted && (
          <div id={retrySectionId}>
            {`${retryMesage}`}
            <div className="text-center">
              <br />
              <button
                type="button"
                className="retryConnection"
                onClick={() => {
                  onRetryConnection();
                }}
                onKeyPress={() => {
                  onRetryConnection();
                }}
              >
                Retry
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

FlexConnectivityErrorPage.propTypes = {
  intl: intlShape.isRequired,
  testInstanceId: PropTypes.string,
  instances: PropTypes.array,
  subscribeUser: PropTypes.bool,
  onFetchKeyAction: PropTypes.func.isRequired,
  location: PropTypes.object,
  retrievedFlexExams: PropTypes.bool,
  clearExamList: PropTypes.func,
  onGetFlexExams: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onFetchKeyAction: () => dispatch(fetchKeyAction()),
    clearExamList: () => dispatch(setRetrieveFlexExams(false)),
    onGetFlexExams: (userId, examType) =>
      dispatch(getFlexExamListAction(userId, examType)),
  };
}

const mapStateToProps = createStructuredSelector({
  userDetails: userDetailsSelector(),
  instances: instancesSelector(),
  subscribeUser: subscribeUserSelector(),
  location: routerSelector(),
  retrievedFlexExams: retrievedFlexExamsSelector(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(FlexConnectivityErrorPage));
