import React from 'react';
import { FormattedMessage } from 'react-intl';
import CautionSign from '../../images/caution-sign.svg';
import './index.css';
import messages from './messages';

function WritingErrorUI() {
  const isStyleImported = import('./error.css');
  return (
    isStyleImported && (
      <div className="error-ui-container">
        <div className="box header">
          <img src={CautionSign} alt="" />
        </div>
        <div className="box content">
          <h1 id="regionHeading">
            <FormattedMessage {...messages.header} />
          </h1>
          <p>
            <FormattedMessage {...messages.message} />
          </p>
        </div>
      </div>
    )
  );
}

export default WritingErrorUI;
