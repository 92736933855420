/* eslint-disable indent */
import PropTypes from 'prop-types';
import EmbeddedHighChartModel from './embeddedHighChartModel';

/**
 * User ArticleContentModel
 * @class
 */
class ArticleContentModel {
  /**
   *
   * @param {object} dto The data transfer object
   * @returns {ArticleContentModel} The newly created articleContent model
   */
  static fromDto(dto) {
    const chartDtos = dto.charts;
    const charts = [];

    if (
      !(chartDtos === null || chartDtos === undefined) &&
      chartDtos.length > 0
    ) {
      chartDtos.forEach(item => {
        if (!Object.keys(charts).includes(item.referenceId)) {
          charts[item.referenceId] = EmbeddedHighChartModel.fromDto(item);
        }
      });
    }

    return new ArticleContentModel(dto.id, dto.title, dto.body, charts);
  }

  /**
   *
   * @param {string} id The article id
   * @param {string} title title of the article
   * @param {string} body body of the article
   * @param {array} charts Array of EmbeddedHighChartModel
   */
  constructor(id, title, body, charts) {
    this.id = id;
    this.title = title;
    this.body = body;
    this.charts = charts;
  }
}

ArticleContentModel.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  charts: PropTypes.arrayOf(EmbeddedHighChartModel).isRequired,
};

export default ArticleContentModel;
