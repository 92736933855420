import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { injectIntl } from 'react-intl';
import { userDetailsSelector } from 'domains/session/selectors';
import { useSessionRedux } from 'domains/session/hooks';
import saga from './saga';
import reducer from './reducer';
import { initializeProctoringTestAction } from './actions';
import { errorSelector, initializeExamSelector } from './selector';
import { reactPlugin } from '../TestSelectPage/testselect.util';
import history from '../../utils/history';
import { LIBRARY_PATH } from '../App/constants';

export function ProctorWritingPage({
  userDetails,
  proctorExamSettings,
  error,
  initializeExam,
}) {
  useSessionRedux();
  useEffect(() => {
    const examId = window.location.search
      .slice(1)
      .split('&')[0]
      .split('=')[1];

    if (examId && userDetails && userDetails.userId) {
      // after we return to the WritingExamPage we need to know which exam
      // is assigned
      const lsKey = `${userDetails.userId}-examid`;
      window.localStorage.setItem(lsKey, examId);
      initializeExam(examId, userDetails.userId);
    }
  }, [userDetails]);

  useEffect(() => {
    if (proctorExamSettings && proctorExamSettings.reservationUrl) {
      window.location.href = proctorExamSettings.reservationUrl;
    }
  }, [proctorExamSettings]);

  useEffect(() => {
    if (error) {
      history.push(LIBRARY_PATH);
    }
  }, [error]);

  return <div />;
}

ProctorWritingPage.propTypes = {
  userDetails: PropTypes.object,
  proctorExamSettings: PropTypes.object,
  error: PropTypes.object,
  initializeExam: PropTypes.func,
};

/**
 *
 * Redux map state to props method
 *
 */
const mapStateToProps = createStructuredSelector({
  proctorExamSettings: initializeExamSelector(),
  userDetails: userDetailsSelector(),
  error: errorSelector(),
});

/**
 *
 * Redux map dispatch to props method
 *
 */
export function mapDispatchToProps(dispatch) {
  return {
    initializeExam: (examId, userId) =>
      dispatch(initializeProctoringTestAction(examId, userId)),
  };
}

/**
 *
 * Redux connect method
 *
 */
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'proctorWritingPage', saga });
const withReducer = injectReducer({ key: 'proctorWritingPage', reducer });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(
  withAITracking(
    reactPlugin,
    injectIntl(ProctorWritingPage),
    'ProctorWritingPage',
  ),
);
