/*
 *
 * ArticlePage reducer
 *
 */
import produce from 'immer';
import ArticleContentModel from 'models/articleContentModel';

import {
  GET_ARTICLE_CONTENT_SUCCESS,
  GET_ARTICLE_CONTENT_ERROR,
} from './constants';

export const initialState = {
  shouldUpdateContent: true,
};

/* eslint-disable default-case, no-param-reassign */
const articlePageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_ARTICLE_CONTENT_SUCCESS:
        draft.articleContent = ArticleContentModel.fromDto(action.articleDto);
        draft.shouldUpdateContent = false;
        draft.error = undefined;
        break;
      case GET_ARTICLE_CONTENT_ERROR:
        draft.error = action.error;
        draft.shouldUpdateContent = true;
        break;
    }
  });

export default articlePageReducer;
