import PropTypes from 'prop-types';
import { parseDateString } from 'helpers/timeHelper';
import { isNullOrUndefined } from 'util';
import { ExamMode } from '../enumerations/ExamMode';
import { ModuleType } from '../enumerations/ModuleType';

/**
 * The module model
 * @class
 */

class ModuleModel {
  /**
   *
   * @param {string} moduleId The module id
   * @param {string} moduleName The module name
   * @param {array<SectionModel>} sections An aray of sections
   * @param {string} description The description
   * @param {string} adminDate The admin date
   * @param {array} history An array of history
   * @param {ExamMode} examMode The exam mode
   * @param {number} customTime The custom time
   * @param {object} options The options
   * @param {object} directions The directions
   * @param {string} browser The browser
   */
  constructor(
    moduleId,
    moduleName,
    sections,
    description,
    adminDate,
    history,
    examMode,
    customTime,
    options,
    directions,
    browser,
    allowSubmitEarly,
    moduleType,
  ) {
    this.moduleId = moduleId;
    this.moduleName = moduleName;
    this.sections = sections;
    this.description = description;
    this.adminDate = parseDateString(adminDate);
    this.history = history;
    this.isNeverShowPauseMessage =
      moduleType === ModuleType.DrillSet ||
      moduleType === ModuleType.DrillSetAdditional;
    this.examMode = examMode;
    this.customTime = customTime;
    this.isFlexPaused = false;
    this.flexPausedOn = null;
    this.options = !isNullOrUndefined(options) ? options : {};
    this.directions = directions;
    this.browser = browser;
    this.allowSubmitEarly = allowSubmitEarly;
  }
}

ModuleModel.propTypes = {
  moduleId: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  adminDate: PropTypes.instanceOf(Date),
  history: PropTypes.array,
  isNeverShowPauseMessage: PropTypes.bool,
  examMode: PropTypes.objectOf(ExamMode.SelfPaced, ExamMode.ExamTime),
  customTime: PropTypes.number,
  options: PropTypes.object,
  directions: PropTypes.object,
  isFlexPaused: PropTypes.bool,
  flexPausedOn: PropTypes.string,
  browser: PropTypes.string,
  allowSubmitEarly: PropTypes.bool,
};

export default ModuleModel;
