/**
 *
 * ValidateCode
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Button, Label, FormGroup, Form } from 'reactstrap';
import ReactLoading from 'react-loading';
import Cleave from 'cleave.js/react';
import messages from '../messages';
import './index.css';

function ValidateCode({
  intl,
  isSubmitting,
  offerCode,
  offerErrorMsg,
  setOfferCode,
  debouncedValidate,
  onClearOfferCodeError,
}) {
  // The inputted code without delimiters
  const [rawInputValue, setRawInputValue] = useState('');
  // Error states
  const [isInputEmptyError, setIsInputEmptyError] = useState(false);
  const [isCodeTooShortError, setIsCodeTooShortError] = useState(false);
  const hasFormError = Boolean(
    isInputEmptyError || isCodeTooShortError || offerErrorMsg,
  );

  const errorRegionRef = useRef(null);

  // Cleanup validation calls on unmount
  useEffect(() => () => debouncedValidate.cancel(), [debouncedValidate]);

  // Apply focus to error region if it is populated
  useEffect(() => {
    if (errorRegionRef.current && hasFormError) {
      errorRegionRef.current.focus();
    }
  }, [hasFormError]);

  const handleValidate = e => {
    e.preventDefault();
    const isNoInput = offerCode.length === 0;
    const requiredCodeLength = 16;
    const isCodeTooShort =
      rawInputValue.length >= 1 && rawInputValue.length < requiredCodeLength;

    // Reset the error messages
    if (isInputEmptyError) {
      setIsInputEmptyError(false);
    }
    if (isCodeTooShortError) {
      setIsCodeTooShortError(false);
    }
    if (offerErrorMsg) {
      onClearOfferCodeError();
    }

    // Set error messsages with a delay
    // to allow SR to pick up on DOM changes
    if (isNoInput) {
      setTimeout(() => setIsInputEmptyError(true));
    }
    if (isCodeTooShort) {
      setTimeout(() => setIsCodeTooShortError(true));
    }

    // Prevent form submission if there are errors
    // or if the network call is in progress
    if (isNoInput || isCodeTooShort || isSubmitting) {
      return;
    }

    debouncedValidate(offerCode);
  };

  const handleChange = e => {
    // Reset form errors if they exist
    if (isInputEmptyError) {
      setIsInputEmptyError(false);
    }
    if (isCodeTooShortError) {
      setIsCodeTooShortError(false);
    }
    if (offerErrorMsg) {
      onClearOfferCodeError();
    }

    setRawInputValue(e.target.rawValue);
    setOfferCode(e.target.value);
  };

  const handleKeyDown = e => {
    // Prevent spaces from being entered
    const isSpaceKey = e.which === 32;
    if (isSpaceKey) {
      e.preventDefault();
    }
  };

  return (
    <Form
      onSubmit={e => handleValidate(e)}
      className="validate-offer vertical-layout"
    >
      <div
        ref={errorRegionRef}
        className={hasFormError ? 'error-message text-danger' : 'sr-only'}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={hasFormError ? '0' : '-1'}
      >
        {hasFormError && (
          <React.Fragment>
            <p>
              {intl.formatMessage({
                ...messages.formErrorMessage,
              })}
            </p>
            <ul>
              {isInputEmptyError && (
                <li>
                  {intl.formatMessage({
                    ...messages.inputEmptyError,
                  })}
                </li>
              )}
              {isCodeTooShortError && (
                <li>
                  {intl.formatMessage({
                    ...messages.codeIsTooShortError,
                  })}
                </li>
              )}
              {offerErrorMsg && (
                <li>
                  {intl.formatMessage({
                    ...offerErrorMsg,
                  })}
                </li>
              )}
            </ul>
          </React.Fragment>
        )}
      </div>

      <p id="offer-code-directions">
        {intl.formatMessage({ ...messages.validateCodeInstructions })}
        <span className="sr-only">
          {intl.formatMessage({ ...messages.validateCodeSrHelperText })}
        </span>
      </p>
      <FormGroup className="floating-label">
        <Cleave
          type="text"
          className={
            hasFormError ? 'form-control input-has-error' : 'form-control'
          }
          name="offer-code"
          id="offer-code"
          placeholder="Enter Code"
          value={offerCode}
          aria-invalid={hasFormError}
          aria-describedby="offer-code-directions example-code"
          onChange={e => handleChange(e)}
          onKeyDown={e => handleKeyDown(e)}
          options={{
            blocks: [4, 4, 4, 4],
            delimiter: '-',
            uppercase: true,
          }}
        />
        <Label for="offer-code">Enter Code</Label>
      </FormGroup>
      <Button className="submit" onClick={e => handleValidate(e)}>
        {isSubmitting && (
          <div className="vertical-layout">
            <ReactLoading
              type="spin"
              height="100%"
              width="1rem"
              aria-label="Loading"
            />
          </div>
        )}
        <div
          data-testid="hide-text-div"
          className={isSubmitting ? 'hide-text' : undefined}
        >
          {intl.formatMessage({ ...messages.redeemNowBtn })}
        </div>
      </Button>
    </Form>
  );
}

ValidateCode.propTypes = {
  intl: intlShape,
  isSubmitting: PropTypes.bool,
  offerCode: PropTypes.string,
  offerErrorMsg: PropTypes.object,
  setOfferCode: PropTypes.func,
  debouncedValidate: PropTypes.func,
  onClearOfferCodeError: PropTypes.func,
};

export default ValidateCode;
