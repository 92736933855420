/*
 * RedeemOffer Messages
 *
 * This contains all the text for the RedeemOffer component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.RedeemOffer';

export default defineMessages({
  validateCodeInstructions: {
    id: `${scope}.validateCodeInstructions`,
    defaultMessage: 'Redeem your valid 16-character offer code below.',
  },
  validateCodeSrHelperText: {
    id: `${scope}.validateCodeSrHelperText`,
    defaultMessage: 'Enter alphanumeric characters only.',
  },
  formErrorMessage: {
    id: `${scope}.formErrorMessage`,
    defaultMessage:
      'An error occurred. Please address the following errors and retry.',
  },
  inputEmptyError: {
    id: `${scope}.inputEmptyError`,
    defaultMessage: 'No code has been provided. Please enter one.',
  },
  codeIsTooShortError: {
    id: `${scope}.codeIsTooShortError`,
    defaultMessage: 'Offer codes must be 16 characters in length. ',
  },
  invalidOfferCode: {
    id: `${scope}.invalidOfferCode`,
    defaultMessage:
      'The offer code entered is not valid. Check your code and try again. For further assistance, please contact the organization that provided your code.',
  },
  expiredOfferCode: {
    id: `${scope}.expiredOfferCode`,
    defaultMessage: 'The offer code entered has expired.',
  },
  usedOfferCode: {
    id: `${scope}.usedOfferCode`,
    defaultMessage: 'The offer code entered has already been redeemed.',
  },
  confirmOfferDetails: {
    id: `${scope}.confirmOfferDetails`,
    defaultMessage: 'Please confirm that you wish to redeem:',
  },
  redeemSuccessHeading: {
    id: `${scope}.redeemSuccessHeading`,
    defaultMessage: 'Success!',
  },
  redeemSuccessMsg: {
    id: `${scope}.redeemSuccessMsg`,
    defaultMessage: 'You have successfully redeemed your offer!',
  },
  redeemErrorHeading: {
    id: `${scope}.redeemErrorHeading`,
    defaultMessage: 'Something went wrong',
  },
  redeemNowBtn: {
    id: `${scope}.redeemNowBtn`,
    defaultMessage: 'Redeem Now',
  },
  confirmCodeBtn: {
    id: `${scope}.confirmCodeBtn`,
    defaultMessage: 'Confirm',
  },
  cancelCodeBtn: {
    id: `${scope}.cancelCodeBtn`,
    defaultMessage: 'Cancel',
  },
  tryAgainBtn: {
    id: `${scope}.tryAgainBtn`,
    defaultMessage: 'Try Again',
  },
  noProductStartDate: {
    id: `${scope}.noProductStartDate`,
    defaultMessage: 'immediately',
  },
  noTermOrFrequency: {
    id: `${scope}.noTermOrFrequency`,
    defaultMessage: 'Online',
  },
});
