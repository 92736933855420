import PropTypes from 'prop-types';

/**
 * User programs settings model
 * @class
 */
class UserProgramsSettingsModel {
  /**
   *
   * @param {string} settingName The setting name
   * @param {string} settingValue The setting value
   */
  constructor(settingName, settingValue) {
    this.settingName = settingName;
    this.settingValue = settingValue;
  }
}

UserProgramsSettingsModel.propTypes = {
  settingName: PropTypes.string.isRequired,
  settingValue: PropTypes.string.isRequired,
};

export default UserProgramsSettingsModel;
