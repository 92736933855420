/* eslint-disable prettier/prettier */
import './index.css';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import * as examHelper from 'helpers/examHelper';
import * as browserHelper from 'helpers/browserHelper';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import isObjectEmpty from '../../utils/isObjectEmpty';
import { config } from '../../config';
import { displaySideNavBar } from '../MasterPage/actions';
import testSelectPageReducer from '../TestSelectPage/reducer';
import directionsPageReducer from '../DirectionsPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
import {
  getFlexExamListAction,
  getFlexExamListErrorAction,
  setRetrieveFlexExams,
  updateLaunchDetailsAction,
} from '../TestSelectPage/actions';
import {
  getFlexInstanceAction,
  getInstanceAction,
  getExistingInstanceFailureAction,
  resumeSectionAction,
} from '../DirectionsPage/actions';
import makeSelectTestSelectPage, {
  initSagaLibrarySelector,
  retrievedFlexExamsSelector,
  flexExamsSelector,
  errorSelector,
  flexExamErrorSelector,
} from '../TestSelectPage/selectors';
import {
  getInstanceErrorSelector,
  selectedInstanceIdSelector,
} from '../DirectionsPage/selectors';
import messages from './messages';
import arrowInCircle from '../../images/arrow-in-circle.png';
import CertifyingContent from '../../components/CertifyingContent';
import {
  HOME_PATH,
  CONNECTIVITY_ERROR_PATH,
  INTERMISSION_PATH,
  FLEX_CONGRATS_PATH,
  TEST_COMPLETE_FLEX_PATH,
  FLEX_LANDING_PATH,
} from '../App/constants';
import { PRO_METRICS_PARAM, PRO_METRICS_LAUNCH_START } from './constants';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import {
  getUserId,
  getUserDetails,
  extensionCandSeq,
} from '../../helpers/userHelper';
import {
  isInstanceCompleted,
  setLaunchStartParams,
  getLaunchStartParams,
} from '../../helpers/persistence/repoManagementHelper';
import lsatLogo from '../../images/lsat-logo.png';
import { TITLE_TEST_FLEX_EXAM } from '../MasterPage/constants';
import history from '../../utils/history';
import { getHashedPasswordForExam } from '../../helpers/encryptHelper';
import { parseDateString, getMonthAsString } from '../../helpers/timeHelper';
import { restrictBack } from '../../helpers/restrictBackHelper';
import HamburgerButton from '../../components/HamburgerButton';
import { getStateFromLocal } from '../../helpers/persistence/common/storageHelper';
import { sendStateNow } from '../../helpers/persistence/instanceStateManagementHelper';

function uniqueid() {
  // always start with a letter (for DOM friendlyness)
  let idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
  do {
    // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
    const ascicode = Math.floor(Math.random() * 42 + 48);
    if (ascicode < 58 || ascicode > 64) {
      // exclude all chars between : (58) and @ (64)
      idstr += String.fromCharCode(ascicode);
    }
  } while (idstr.length < 32);

  return idstr;
}

const pwdId = uniqueid();

export function CertifyingStatementPage({
  initSagaLibrary,
  onGetFlexExams,
  modules,
  retrievedFlexExams,
  onShowSideBar,
  onSectionResume,
  onGetExistingInstance,
  onUnLoad,
  intl,
  onFlexInstance,
  error,
  getInstanceError,
  flexExamError,
  onClearError,
  selectedInstanceId,
  pushExamLaunchDetails,
}) {
  const [state, setState] = useState({
    showPasswordError: false,
    showLockDownError: false,
    showConnectivityError: false,
    password: '',
    lockDownStartTime: null,
    lockDown: false,
    retrievingTest: false,
    hasUpdatedLaunchDetails: false,
  });

  const [stateCheckboxChecked, setStateCheckboxChecked] = useState(false);
  const [stateHidePasswordInput, setStateHidePasswordInput] = useState(false);
  const [stateStartButtonDisabled, setStateStartButtonDisabled] = useState(
    true,
  );
  const [isPrometricsUser, setIsPrometricsUser] = useState(false);
  const [hasPrometricsError, setHasPrometricsError] = useState('0'); // 0 = no error , 100, 200, 300 are errors

  const passwordInputRef = useRef();
  let timerId = null;

  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectReducer({ key: 'directionsPage', reducer: directionsPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  useEffect(() => {
    restrictBack();
    printState('useEffect');
    checkError(getInstanceError);

    if (initSagaLibrary && !retrievedFlexExams) {
      if (isNullOrUndefined(flexExamError)) {
        onGetFlexExams(getUserId());
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          !isNullOrUndefined(flexExamError.message) &&
          !isNullOrUndefined(modules) &&
          flexExamError.message === 'Network Error'
        ) {
          history.push(`${CONNECTIVITY_ERROR_PATH}?type=flexStart`);
        } else {
          onGetFlexExams(getUserId());
        }
      }
    }
    document.title = TITLE_TEST_FLEX_EXAM;

    // Enabling the Strat test button, if the api fails
    if (getInstanceError?.response?.status) {
      setStateStartButtonDisabled(false);
    }
  }, [
    initSagaLibrary,
    error,
    getInstanceError,
    retrievedFlexExams,
    flexExamError,
  ]);

  useEffect(() => {
    const hasFlexExams = !isNullOrUndefined(modules) && modules.length > 0;
    const inProgressExamIndex = hasFlexExams
      ? modules.findIndex(e => e.examStatus === 'In Progress')
      : -1;
    if (hasFlexExams && inProgressExamIndex >= 0) {
      setStateCheckboxChecked(true);
    }
    const hidePasswordInput = hasFlexExams
      ? examHelper.shouldHidePasswordInput(modules[0])
      : false;
    setStateHidePasswordInput(hidePasswordInput);

    const pageHeader = document.getElementById('pageHeader');
    const certPage = document.getElementById('certPage');
    pageHeader?.classList?.add('cert-heading');
    certPage?.classList?.add('cert-no-top-padding');

    return () => {
      pageHeader?.classList?.remove('cert-heading');
      certPage?.classList?.remove('cert-no-top-padding');
    };
  }, [modules]);

  useEffect(() => {
    if (selectedInstanceId && modules && modules.length > 0) {
      const currentExam = examHelper.getCurrentExam(modules);

      currentExam.testInstanceId = selectedInstanceId;
    }
  }, [selectedInstanceId]);

  // PROMETRICS CHECK
  useEffect(() => {
    let params = PRO_METRICS_PARAM;
    const hasFlexExams = !isNullOrUndefined(modules) && modules.length > 0;
    const isPrometrics = hasFlexExams
      ? examHelper.isPrometrics(modules[0])
      : false;
    let launchDetails = browserHelper.getUrlParams(window.location.href);
    let launchDetailsFromURL = false;

    // if we have launch details from the url save them
    if (!isObjectEmpty(launchDetails)) {
      setLaunchStartParams(launchDetails);
      launchDetailsFromURL = true;
    }

    // if the URL contains launchDetails check the cand seq
    if (!isPrometrics && launchDetailsFromURL) {
      if (
        typeof launchDetails[params.ELIGID] !== 'undefined' &&
        // we only need the first 8 characters of the url param")
        extensionCandSeq.value !== launchDetails[params.ELIGID].substring(0, 8)
      ) {
        /* Not prometrics , but for some reason the URL contained a CandSeq 
          And the mysteriously passed in CandSeq doesn't match anyway.
          -- 8851
        */
        setHasPrometricsError('200');

        /**
         * In this case - we also need to hide the main navigation.
         * This is done elsewhere when the test isPrometrics. But in this case, we're detecting an error for a user that is not "technically" prometrics
         * -- 9189
         */
        const leftNavEl = document.getElementById('masterPageSidebar');
        leftNavEl.style.display = 'none';

        return;
      }
    }

    if (!isPrometrics) {
      return;
    }

    setIsPrometricsUser(isPrometrics);

    // Close SidebarNav
    onShowSideBar(false);

    if (!browserHelper.isAcceptableUserAgent()) {
      setHasPrometricsError('100'); // wrong user Agent
      return;
    }

    /**
     * This far means that we are dealing with a Prometrics test, and since the URL doesn't have any url params, we must be coming to this page from a redirect
     * So we can attemp to read them from a previously saved state (testInstance.launchDetails)
     */

    if (Object.keys(launchDetails).length === 0 && modules.length) {
      launchDetailsFromURL = false;
      launchDetails = getLaunchStartParams(modules[0].testInstanceId); // retrieve the previously stored start params
      params = PRO_METRICS_LAUNCH_START; // the names of the keys to check are different from the server vs the URL params
    }

    // check for any missing values:
    if (!launchDetails || isObjectEmpty(launchDetails)) {
      setHasPrometricsError('300'); // missing all
      return;
    }

    if (!(params.ELIGID in launchDetails)) {
      setHasPrometricsError('300.1');
      return;
    }
    if (!(params.CONFIRMATIONID in launchDetails)) {
      setHasPrometricsError('300.2');
      return;
    }
    if (!(params.SITE_CODE in launchDetails)) {
      setHasPrometricsError('300.3');
      return;
    }
    if (!(params.SCHEDULE_DATE_TIME in launchDetails)) {
      setHasPrometricsError('300.4');
      return;
    }

    // check org id matches Eligibility ID
    if (
      typeof extensionCandSeq.value === 'undefined' ||
      extensionCandSeq.value === null ||
      typeof launchDetails[params.ELIGID] === 'undefined' ||
      extensionCandSeq.value !== launchDetails[params.ELIGID].substring(0, 8)
    ) {
      // we only need the first 8 characters of the url param")
      setHasPrometricsError('200'); // candidate seq and eligibility id mismatch
      return;
    }

    // no errors
    if (launchDetailsFromURL && modules.length) {
      // only save the params if they're from the URL
      setLaunchStartParams(launchDetails);
    }
  }, [modules]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      // Unload
      printState('unload');
      onUnLoad(false);
      clearTimer();
      onClearError();
      printState('unload 1');
    };
  }, []);

  // accessibility support for keyboard navigation
  document.body.addEventListener('mousedown', () => {
    document.body.classList.remove('keyboard-focus');
    document.body.classList.add('mouse-focus');
  });

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('mouse-focus');
    document.body.classList.add('keyboard-focus');
  });

  // eslint-disable-next-line no-unused-vars
  function printState(message) {
    // // logHelper.log(LogLevelType.Info, `${message}:showPasswordError:${state.showPasswordError},showLockDownError:${state.showLockDownError}, lockDwonStartTime:${state.lockDwonStartTime},lockDown:${state.lockDown}, Checked:${stateCheckboxChecked}`);
  }

  function clearTimer() {
    printState('clearTimer');
    if (!isNullOrUndefined(timerId)) {
      clearInterval(timerId);
      timerId = null;
    }
  }

  function handlePassWordError(errorMessage) {
    if (!isNullOrUndefined(errorMessage)) {
      if (
        !isNullOrUndefined(passwordInputRef) &&
        !isNullOrUndefined(passwordInputRef.current)
      ) {
        passwordInputRef.current.style.outline = '2px solid red';
      }
      if (errorMessage.message.indexOf('403') >= 0) {
        setState(prevState => ({
          ...prevState,
          retrievingTest: false,
          showPasswordError: true,
          showLockDownError: false,
          lockDownStartTime: null,
          showConnectivityError: false,
        }));
      }
    }
  }

  function checkError(errorMessage) {
    if (!isNullOrUndefined(getInstanceError)) {
      if (getInstanceError.message === 'Network Error') {
        setState(prevState => ({
          ...prevState,
          retrievingTest: false,
          showPasswordError: false,
          showLockDownError: false,
          lockDownStartTime: null,
          showConnectivityError: true,
        }));
      } else if (errorMessage.message.indexOf('429') >= 0) {
        setState(prevState => ({
          ...prevState,
          retrievingTest: false,
          showPasswordError: false,
          showLockDownError: true,
          lockDownStartTime: null,
          showConnectivityError: false,
        }));
      } else {
        handlePassWordError(errorMessage);
      }
    } else if (!isNullOrUndefined(errorMessage)) {
      if (
        !isNullOrUndefined(passwordInputRef) &&
        !isNullOrUndefined(passwordInputRef.current)
      ) {
        passwordInputRef.current.style.outline = '2px solid red';
      }
      if (errorMessage.message === 'Network Error') {
        setState(prevState => ({
          ...prevState,
          retrievingTest: false,
          showPasswordError: false,
          showLockDownError: false,
          lockDownStartTime: null,
          showConnectivityError: true,
        }));
      } else if (errorMessage.message.indexOf('403') >= 0) {
        setState(prevState => ({
          ...prevState,
          retrievingTest: false,
          showPasswordError: true,
          showLockDownError: false,
          lockDownStartTime: null,
          showConnectivityError: false,
        }));
      } else {
        setState(prevState => ({ ...prevState, retrievingTest: false }));
      }
    }
  }

  function showSidebar() {
    if (!isPrometricsUser) {
      onShowSideBar(true);
    }
  }

  function closeTheTestHandler() {
    history.push(HOME_PATH);
  }

  function inputChangeHandler(e) {
    const updatedValue = e?.target?.value ?? '';
    passwordInputRef.current.style.outline = '';
    setState(prevState => ({
      ...prevState,
      showPasswordError: false,
      password: updatedValue,
      showLockDownError: false,
      lockDownStartTime: null,
      lockDown: false,
    }));
  }

  // Disbales Input Field Paste Option
  const pasteDisabled = e => {
    if (config.REACT_APP_PASSWORD_PASTE_DISABLED === 'true') {
      e.preventDefault();
    }
    return !config.REACT_APP_PASSWORD_PASTE_DISABLED;
  };

  function onCheckChanged(checked) {
    printState('onCheck');
    // reset state
    if (!checked && !stateHidePasswordInput) {
      setState(prevState => ({ ...prevState, password: '' }));
      passwordInputRef.current.value = '';
      passwordInputRef.current.style.outline = '';
    }
    setStateCheckboxChecked(checked);
  }

  async function startTheTestHandler(item) {
    // Disabling the Start The Test button after the user clikcs on it
    setStateStartButtonDisabled(true);
    setState(prevState => ({ ...prevState, showConnectivityError: false }));
    const { testInstanceId } = item;

    // Check for unsaved state data and update if it exists.
    const repo = await getStateFromLocal();
    const repoDataExist = repo?.instances?.some(
      instance => instance.testInstanceId === testInstanceId,
    );
    if (repoDataExist) {
      sendStateNow(testInstanceId);
    }
    printState('startTest');
    if (
      !isNullOrUndefined(passwordInputRef) &&
      !isNullOrUndefined(passwordInputRef.current)
    ) {
      passwordInputRef.current.value = '';
      passwordInputRef.current.style.outline = '';
    }
    setState(prevState => ({
      ...prevState,
      retrievingTest: true,
      showPasswordError: false,
      password: state.password,
      showLockDownError: false,
      lockDownStartTime: null,
      lockDown: false,
    }));

    window.setTimeout(
      itm => {
        // start test
        const examPasswordHash = getHashedPassword();
        if (isNullOrUndefined(testInstanceId)) {
          onFlexInstance({
            userId: getUserId(),
            productId: itm.productId,
            examPassword: examPasswordHash,
          });
        } else {
          onSectionResume(true, testInstanceId);
          onGetExistingInstance({
            testInstanceId,
            userId: getUserId(),
            isResuming: true,
            examPassword: examPasswordHash,
          });
        }
      },
      1000,
      item,
    );
  }

  function getHashedPassword() {
    const { password } = state;
    return getHashedPasswordForExam(password);
  }

  let exam = examHelper.getCurrentExam(modules);
  const userId = getUserId();

  if (userId && exam && !state.hasUpdatedLaunchDetails) {
    // send launch details to log what url we are coming through - 11966
    const examLaunchDetails = {
      launchURL: window.location.href,
      productId: exam?.productId ?? '',
      timestamp: new Date(),
    };
    pushExamLaunchDetails(userId, examLaunchDetails);
    setState(prevState => ({ ...prevState, hasUpdatedLaunchDetails: true }));
  }

  // we want to show the 200 error - other than that it should redirect if there is a flex exam scheduled
  if (retrievedFlexExams && !(hasPrometricsError === '200')) {
    const isExamPending = examHelper.examIsPending(modules);
    if (exam === null) {
      if (!isExamPending) {
        history.replace(HOME_PATH);
      } else {
        history.replace(FLEX_LANDING_PATH);
      }
    } else if (
      config.REACT_APP_IS_TERMINATED_ENABLED === 'true' &&
      exam.examStatus === 'Terminated'
    ) {
      history.replace(`${INTERMISSION_PATH}/${exam.testInstanceId}`);
      exam = null;
    } else if (exam.examStatus === 'Completed') {
      history.replace(`${FLEX_CONGRATS_PATH}?id=${exam.testInstanceId}`);
      exam = null;
    } else if (isInstanceCompleted(exam.testInstanceId)) {
      // completed locally, but not showing complete on the server yet
      history.replace(`${TEST_COMPLETE_FLEX_PATH}?id=${exam.testInstanceId}`);
      exam = null;
    }
  }

  let adminMonth = '';
  if (exam !== null && exam !== undefined) {
    const displayDate = exam.adminDate || exam.startDate;
    if(displayDate) {
      adminMonth = getMonthAsString(parseDateString(displayDate).getMonth());
    }
  }

  let passwordError = '';
  if (getInstanceError) {
    passwordError =
      getInstanceError.response?.data?.error?.indexOf('200') >= 0
        ? 'Invalid password (error code 200). Please try again.'
        : 'Invalid password (error code 100). Please try again.';
  }
  const inputDisabled =
    !stateCheckboxChecked || state.lockDown || state.retrievingTest;

  // start button should be disabled if the input is disabled or if the input is empty only if the feature flag is false
  useEffect(() => {
    let disableStartButton = true;
    if (stateHidePasswordInput) {
      disableStartButton = !stateCheckboxChecked;
    } else {
      disableStartButton =
        inputDisabled || isNullOrUndefined(passwordInputRef)
          ? true
          : passwordInputRef?.current?.value.length === 0;
    }

    setStateStartButtonDisabled(disableStartButton);
  }, [
    stateHidePasswordInput,
    stateCheckboxChecked,
    passwordInputRef?.current?.value,
  ]);

  function shouldShowErrorPage() {
    const launchDetails = browserHelper.getUrlParams(window.location.href);
    return !(
      isPrometricsUser &&
      exam?.isReadyToStart &&
      (!launchDetails || isObjectEmpty(launchDetails))
    );
  }

  if (hasPrometricsError !== '0') {
    if (shouldShowErrorPage()) {
      return (
        <div className="certifying-statement-page-container">
          <div className="certifying-statement-page-header">
            <div className="certifying-statement-page-header-div">
              {!isPrometricsUser && (
                <HamburgerButton
                  id="Certhamburger"
                  onClick={showSidebar}
                  onKeyPress={showSidebar}
                  ariaLabel={`${intl.formatMessage({
                    ...messages.mainNavigation,
                  })}`}
                />
              )}
              <div
                className="certifying-statement-page-header-text"
                id="pageHeader"
              >
                LSAT Launch Error
              </div>
            </div>
          </div>
          <div className="certifying-statement-page-top-decoration" />
          <div className="certifying-statement-page">
            <img
              className="certifying-statement-page-lsat-flex-logo"
              src={lsatLogo}
              alt={`${intl.formatMessage({ ...messages.logoText })}`}
            />
            <hr
              className="certifying-statement-page-horizontal-rule"
              role="none"
            />
            <div>
              <div className="certifying-statement-page-text-content">
                <h1>Error code {hasPrometricsError} - LSAT Launch Error</h1>
                <p>
                  We are unable to start your exam. Error code{' '}
                  {hasPrometricsError}. Please reach out to LSAC at
                  LSACinfo@LSAC.org or call 215-966-6640 or 1-855-296-7479.
                </p>
              </div>
              <button
                className="browser-error-page-close-button"
                type="button"
                onClick={closeTheTestHandler}
              >
                <span className="browser-error-page-close-button-text">
                  Return to LawHub
                </span>
              </button>
            </div>
          </div>
        </div>
      );
    }
    history.replace(FLEX_LANDING_PATH);
  }

  if (!isNullOrUndefined(exam)) {
    return (
      <div className="certifying-statement-page-container">
        <div className="certifying-statement-page-header">
          <div className="certifying-statement-page-header-div">
            <div
              className="certifying-statement-page-header-text"
              id="pageHeader"
            >
              <FormattedMessage {...messages.header} />
            </div>
          </div>
        </div>
        <div className="certifying-statement-page-top-decoration" />
        <div id="certPage" className="certifying-statement-page">
          <img
            className="certifying-statement-page-lsat-flex-logo"
            src={lsatLogo}
            alt={`${intl.formatMessage({ ...messages.logoText })}`}
          />
          <hr
            className="certifying-statement-page-horizontal-rule"
            role="none"
          />
          <h1 id="certifying-statement-page-heading1">
            <FormattedMessage
              {...messages.heading1}
              values={{
                full_name: getUserDetails().fullName,
                admin_date: adminMonth,
              }}
            />
          </h1>
          <CertifyingContent
            certContent={exam.certifyingStatements}
            checkboxDisabled={state.lockDown || state.retrievingTest}
            onCheckboxChanged={onCheckChanged}
            checkboxChecked={stateCheckboxChecked}
          />
          {!stateHidePasswordInput && (
            <React.Fragment>
              <label
                htmlFor={pwdId}
                className="certifying-statement-page-password-label"
              >
                <FormattedMessage {...messages.heading4} />
              </label>
              <form autoComplete="off">
                <input
                  id={pwdId}
                  data-testid="passwordField"
                  disabled={inputDisabled}
                  className="certifying-statement-page-password-input"
                  type="text"
                  ref={passwordInputRef}
                  aria-describedby="passwordAgree"
                  onChange={inputChangeHandler}
                  aria-hidden="true"
                  autoComplete="off"
                  onPaste={pasteDisabled}
                  onContextMenu={pasteDisabled}
                />
              </form>
            </React.Fragment>
          )}

          {state.showPasswordError &&
            config.REACT_APP_EXAM_PASSWORD_HAS_SEQUENCE !== 'true' && (
            <div
              className="certifying-statement-page-password-error"
              role="alert"
              aria-live="assertive"
            >
              <FormattedMessage {...messages.invalidPasswordText} />
            </div>
          )}
          {state.showPasswordError &&
            config.REACT_APP_EXAM_PASSWORD_HAS_SEQUENCE === 'true' && (
            <div
              className="certifying-statement-page-password-error"
              role="alert"
              aria-live="assertive"
            >
              {passwordError}
            </div>
          )}
          {state.showLockDownError && (
            <div
              className="certifying-statement-page-password-error"
              role="alert"
              aria-live="assertive"
            >
              <FormattedMessage {...messages.lockDownText} />
            </div>
          )}
          {state.showConnectivityError && (
            <div
              className="certifying-statement-page-password-error"
              role="alert"
              aria-live="assertive"
            >
              Network Error. Please try again.
            </div>
          )}
          {state.retrievingTest && (
            <div className="text-center">
              <div className="spinner-border" role="status" />
            </div>
          )}
          <button
            disabled={stateStartButtonDisabled}
            className={
              stateStartButtonDisabled
                ? 'certifying-statement-page-start-button-disabled'
                : 'certifying-statement-page-start-button-enabled'
            }
            type="button"
            onClick={() => startTheTestHandler(exam)}
          >
            <span className="certifying-statement-page-start-button-text">
              <FormattedMessage {...messages.buttonText} />
            </span>
            <img
              className="certifying-statement-page-start-button-image"
              src={arrowInCircle}
              alt=" "
            />
          </button>
        </div>
      </div>
    );
  }

  return (
    <Container fluid style={{ width: '100vw' }}>
      <Row>
        <AriadneLoading text="library" />
      </Row>
    </Container>
  );
}

CertifyingStatementPage.propTypes = {
  initSagaLibrary: PropTypes.bool.isRequired,
  modules: PropTypes.array,
  onGetFlexExams: PropTypes.func.isRequired,
  retrievedFlexExams: PropTypes.bool.isRequired,
  onShowSideBar: PropTypes.func.isRequired,
  onUnLoad: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  onFlexInstance: PropTypes.func.isRequired,
  onSectionResume: PropTypes.func.isRequired,
  onGetExistingInstance: PropTypes.func.isRequired,
  onClearError: PropTypes.func.isRequired,
  onGetFlexExamError: PropTypes.func.isRequired,
  pushExamLaunchDetails: PropTypes.func.isRequired,
  error: PropTypes.object,
  getInstanceError: PropTypes.object,
  flexExamError: PropTypes.object,
  selectedInstanceId: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  testSelectPage: makeSelectTestSelectPage(),
  modules: flexExamsSelector(),
  initSagaLibrary: initSagaLibrarySelector(),
  retrievedFlexExams: retrievedFlexExamsSelector(),
  error: errorSelector(),
  getInstanceError: getInstanceErrorSelector(),
  flexExamError: flexExamErrorSelector(),
  selectedInstanceId: selectedInstanceIdSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onGetFlexExams: userId => dispatch(getFlexExamListAction(userId)),
    onGetFlexExamError: error => dispatch(getFlexExamListErrorAction(error)),
    onShowSideBar: hasToShowSideBar =>
      dispatch(displaySideNavBar(hasToShowSideBar)),
    onFlexInstance: userModule => dispatch(getFlexInstanceAction(userModule)),
    onGetExistingInstance: userDetails =>
      dispatch(getInstanceAction(userDetails)),
    onUnLoad: isFlexExamListRetrieved =>
      dispatch(setRetrieveFlexExams(isFlexExamListRetrieved)),
    onSectionResume: (isSectionResumed, testInstanceId) =>
      dispatch(resumeSectionAction(isSectionResumed, testInstanceId)),
    onClearError: () => dispatch(getExistingInstanceFailureAction()),
    pushExamLaunchDetails: (userId, details) =>
      dispatch(updateLaunchDetailsAction(userId, details)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(CertifyingStatementPage));
