/*
 *
 * TestSelectPage actions
 *
 */

import {
  INIT_LIBRARY_SAGA,
  GET_LIBRARY,
  GET_LIBRARY_SUCCESS,
  GET_LIBRARY_ERROR,
  GET_FLEX_EXAM_LIST,
  GET_FLEX_EXAM_LIST_SUCCESS,
  GET_FLEX_EXAM_LIST_ERROR,
  PUT_INSTANCE_STATE,
  PUT_INSTANCE_STATE_SUCCESS,
  PUT_INSTANCE_STATE_ERROR,
  SET_RETRIEVE_LIBRARY,
  SET_RETRIEVE_FLEX_EXAMS,
  SET_ERROR,
  STORE_EXAMPASSWORD_HASH,
  GET_TEST_INSTANCE_STATUS,
  GET_TEST_INSTANCE_STATUS_SUCCESS,
  CLEAR_TEST_INSTANCE_STATUS,
  CLEAR_EXAM_LIST_ERROR,
  POST_EXAM_LAUNCH_DETAILS,
  POST_EXAM_LAUNCH_DETAILS_SUCCESS,
  POST_EXAM_LAUNCH_DETAILS_ERROR,
} from './constants';

/**
 * Initialize saga library
 *
 */
export function initializeSagaLibraryAction() {
  return {
    type: INIT_LIBRARY_SAGA,
  };
}

/**
 * Get the list of modules
 *
 * @param {string} userId The user id
 *
 */
export function getLibraryAction(userId) {
  return {
    type: GET_LIBRARY,
    userId,
  };
}

/**
 * Notifity when the section is retrieved
 *
 * @param {module[]} libraryData The list of modules
 *
 */
export function getLibrarySuccessAction(libraryData) {
  return {
    type: GET_LIBRARY_SUCCESS,
    libraryData,
  };
}

/**
 * Notify when the section retrieval encounters an error
 *
 * @param  {object} error The error object
 *
 */
export function getLibraryErrorAction(error) {
  return {
    type: GET_LIBRARY_ERROR,
    error,
  };
}

/**
 * Get the list of flex exams
 *
 * @param {string} userId The user id
 *
 */
export function getFlexExamListAction(userId, examType) {
  if (examType === undefined) {
    return {
      type: GET_FLEX_EXAM_LIST,
      userId,
    };
  }

  return {
    type: GET_FLEX_EXAM_LIST,
    userId,
    examType,
  };
}

/**
 * Notifity when the flex exam list is retrieved
 *
 * @param {module[]} flexExamData The list of exams
 *
 */
export function getFlexExamListSuccessAction(flexExamData) {
  return {
    type: GET_FLEX_EXAM_LIST_SUCCESS,
    libraryData: flexExamData,
  };
}

/**
 * Notify when the flex exam retrieval encounters an error
 *
 * @param  {object} error The error object
 *
 */
export function getFlexExamListErrorAction(error) {
  return {
    type: GET_FLEX_EXAM_LIST_ERROR,
    error,
  };
}

export function clearExamListErrorAction() {
  return {
    type: CLEAR_EXAM_LIST_ERROR,
  };
}

export function putInstanceStateAction(data) {
  return {
    type: PUT_INSTANCE_STATE,
    data,
  };
}

export function putInstanceStateSuccessAction(data) {
  return {
    type: PUT_INSTANCE_STATE_SUCCESS,
    data,
  };
}

export function putInstanceStateErrorAction(testInstanceId, error) {
  return {
    type: PUT_INSTANCE_STATE_ERROR,
    testInstanceId,
    error,
  };
}

export function setRetrieveLibrary(isLibraryRetrieved) {
  return {
    type: SET_RETRIEVE_LIBRARY,
    isLibraryRetrieved,
  };
}

export function setRetrieveFlexExams(isFlexExamsRetrieved) {
  return {
    type: SET_RETRIEVE_FLEX_EXAMS,
    isFlexExamsRetrieved,
  };
}

export function getTestInstanceStatusAction(userId, testInstanceId) {
  return {
    type: GET_TEST_INSTANCE_STATUS,
    userId,
    testInstanceId,
  };
}

export function getTestInstanceStatusSuccessAction(data) {
  return {
    type: GET_TEST_INSTANCE_STATUS_SUCCESS,
    data,
  };
}

export function getTestInstanceStatusErrorAction(error) {
  return {
    type: GET_TEST_INSTANCE_STATUS_SUCCESS,
    error,
  };
}

export function updateLaunchDetailsAction(userId, details) {
  return {
    type: POST_EXAM_LAUNCH_DETAILS,
    userId,
    details,
  };
}

export function updateLaunchDetailsSuccessAction(data) {
  return {
    type: POST_EXAM_LAUNCH_DETAILS_SUCCESS,
    data,
  };
}

export function updateLaunchDetailsErrorAction(error) {
  return {
    type: POST_EXAM_LAUNCH_DETAILS_ERROR,
    error,
  };
}

export function clearTestInstanceStatusAction() {
  return {
    type: CLEAR_TEST_INSTANCE_STATUS,
  };
}

/**
 * Notify when user settings error
 *
 * @param  {object} error The error object
 *
 */
export function setErrorAction(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function storeExamPasswordHashAction(passwordHash) {
  return {
    type: STORE_EXAMPASSWORD_HASH,
    passwordHash,
  };
}
