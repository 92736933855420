/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import CautionSign from '../../../images/caution-sign.svg';
import { HOME_PATH } from '../../../containers/App/constants';
import './index.css';
import history from '../../../utils/history';

function AppErrorUI({ isComingFromErrorBoundry }) {
  const errorRefreshStorageKey = 'errorRefresh';
  try {
    // redirect back to home page if there is no API failure, and
    // this is not coming from ErrorBoundry
    const location = useLocation();
    if (!location?.state?.handlingFailedAPI && !isComingFromErrorBoundry) {
      history.push(HOME_PATH);
    }
  } catch (error) {
    // normally useLocation will fail when the error is more serious, this will attempt to refresh the page (just once)
    const hasRetriedRefreshing = window.sessionStorage.getItem(
      errorRefreshStorageKey,
    );
    if (hasRetriedRefreshing !== 'true') {
      window.sessionStorage.setItem(errorRefreshStorageKey, 'true');
      window.location.reload();
    }
  }

  // Manage Keyboard Focus on masterContentPane div only when user lands firt time on the page
  useEffect(() => {
    const masterContentPane = document.getElementById('masterContentPane');

    if (masterContentPane) {
      masterContentPane.focus();
    }
  }, []);

  if (isComingFromErrorBoundry) {
    const isStyleImported = useLateLoadImport(() => import('./main.css'));

    return (
      isStyleImported && (
        <>
          <Helmet>
            <title>LawHub Test Preparation - Error Page</title>
          </Helmet>
          <div className="error-ui-container">
            <div className="box header">
              <img src={CautionSign} alt="" />
            </div>
            <div className="box content">
              <h1>Oops, looks like something went wrong!</h1>
              <p>
                We track these errors automatically, but if the problem persists
                see <a href="https://lsac.zendesk.com/hc/en-us">ZenDesk</a> for
                additional troubleshooting steps. In the meantime, try
                refreshing.
              </p>
            </div>
          </div>
        </>
      )
    );
  }
  return (
    <>
      <div className="error-ui-container">
        <div className="box header">
          <img src={CautionSign} alt="" />
        </div>
        <div className="box content">
          <h1 id="regionHeading">Oops, looks like something went wrong!</h1>
          <p>
            The application encountered an error. Please return to the{' '}
            <a href="/">home</a> page to resolve this issue.
          </p>
        </div>
      </div>
    </>
  );
}

function useLateLoadImport(importCb) {
  const [isImported, setIsImported] = useState(false);
  // Async import styles on mount; we need to overide the min-width
  // body style defined globally by the Zoom SDK without changing the
  // body styles when this component is unmounted.
  useEffect(() => {
    if (isImported === false) {
      (async () => {
        await importCb();
        setIsImported(true);
      })();
    }
  }, []);

  return isImported;
}

AppErrorUI.propTypes = {
  isComingFromErrorBoundry: PropTypes.bool,
};

export default AppErrorUI;
