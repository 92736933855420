let isStorageFull = false;

export function storeValueInLocal(key, value) {
  if (!isStorageFull) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      isStorageFull = e.name === 'QuotaExceededError';
    }
  }
}

export function removeItemFromLocal(key) {
  localStorage.removeItem(key);
  isStorageFull = false;
}
