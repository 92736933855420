/**
 *
 * startup helper
 *
 */
import { isNullOrUndefined } from 'util';
import * as logHelper from './logHelper';
import { LogLevelType } from '../enumerations/LogLevelType';
import { storeValueInLocal } from './localStorageHelper';
export const startupName = 'LawHubStartup';
let initTime = null;

/**
 * Start the startup listener
 *
 */
export function startListener() {
  stopListener();
  if (window.addEventListener) {
    window.addEventListener('storage', storageChangeHandler, false);
  } else {
    // for IE (why make your life more difficult)
    window.attachEvent('onstorage', storageChangeHandler);
  }
}

/**
 * Check the init value
 *
 */
export function checkStartupValue(value) {
  logHelper.log(LogLevelType.Debug, 'compare', value, initTime);
  return value === initTime;
}

/**
 * Stop listener
 *
 */
export function stopListener() {
  if (window.addEventListener) {
    window.removeEventListener('storage', storageChangeHandler);
  } else {
    // for IE (why make your life more difficult)
    window.detachEvent('onstorage', storageChangeHandler);
  }
}

/**
 * Storage change handler
 *
 */
export async function checkStartup() {
  startListener();
  initTime = `${new Date().getTime()}`;
  storeValueInLocal(startupName, initTime);
  await sleepAsync(1000);
}

/**
 * Sleep function
 *
 */

function sleepAsync(ms) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, ms);
  });
}

/**
 * Storage change handler
 *
 */
function storageChangeHandler() {
  const startupParam = localStorage.getItem(startupName);
  let deltaTime = new Date().getTime() - parseInt(startupParam, 10);
  logHelper.log(
    LogLevelType.Debug,
    'startupParam',
    deltaTime,
    startupParam,
    initTime,
  );
  if (
    !isNullOrUndefined(startupParam) &&
    startupParam.indexOf('~') < 0 &&
    startupParam !== initTime
  ) {
    storeValueInLocal(startupName, `${startupParam}~`);
    deltaTime = new Date().getTime() - parseInt(startupParam, 10);
    logHelper.log(
      LogLevelType.Debug,
      'startupParam update',
      deltaTime,
      localStorage.getItem(startupName),
    );
  }
}
