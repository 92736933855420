/**
 *
 * persistence attribute helper
 *
 */

import { isNullOrUndefined } from 'util';
/**
 * sets the value in the target if it is not null or undefined in the source
 * @param {object} target the target object
 * @param {object} source the source object
 * @param {string} attributeName the attribute name
 */
export function setAttributeIfExists(target, source, attributeName) {
  if (!isNullOrUndefined(source) && !isNullOrUndefined(target)) {
    const sourceValue = source[attributeName];
    if (!isNullOrUndefined(sourceValue)) {
      // eslint-disable-next-line no-param-reassign
      target[attributeName] = sourceValue;
    }
  }
}

/**
 * sets the value in the target if it is not null or undefined in the source
 * for each attribute name specfied
 * @param {object} target the target object
 * @param {object} source the source object
 * @param {Array} attrs the attribute names
 */
export function setAttributesIfExist(target, source, ...attrs) {
  attrs.forEach(element => {
    setAttributeIfExists(target, source, element);
  });
}
