import { defineMessages } from 'react-intl';
import { config } from '../../config';

export const scope = 'app.container.ExamStartPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'LSAT Writing / Registration Notification',
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage:
      'Welcome, {full_name}! You are scheduled for your writing session.',
  },
  heading2: {
    id: `${scope}.heading2`,
    defaultMessage: 'CERTIFYING STATEMENT',
  },
  heading3: {
    id: `${scope}.heading3`,
    defaultMessage:
      'You must agree to this statement in order to proceed to the test.',
  },
  certifyingStatementFirstParagraph: {
    id: `${scope}.certifyingStatementFirstParagraph`,
    defaultMessage:
      'I certify that I am the candidate whose name appears at the top of this screen and that I am here to take the LSAT Writing for the sole purpose of being considered for admission to law school. I further certify that I will neither assist nor receive assistance from any other candidate during the LSAT Writing and I agree that after I take the LSAT Writing, I will not discuss, share, disclose, copy, or otherwise misappropriate the LSAT Writing or any intellectual property related to the LSAT Writing (including, but not limited to, any questions, answers, or other content appearing on the LSAT Writing), whether orally, in writing, on the Internet or via any other means or media, nor will I assist any person or entity in doing so. Any such discussion, sharing, disclosure, copying or misappropriation could constitute a crime and may result in enforcement actions against me, to the fullest extent permitted by law, including but not limited to, civil or criminal penalties.',
  },
  certifyingStatementSecondParagraph: {
    id: `${scope}.certifyingStatementSecondParagraph`,
    defaultMessage:
      'I certify that I have read and understand this certifying statement. I agree to be bound by all policies of the Law School Admission Council, Inc. relating to the LSAT Writing.',
  },
  logoText: {
    id: `${scope}.logoText`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME}`,
  },
  buttonText: {
    id: `${scope}.buttonText`,
    defaultMessage: 'START the test',
  },
  returnToOnlineServicesHeading: {
    id: `${scope}.returnToOnlineServicesHeading`,
    defaultMessage: 'Return to Online Services',
  },
  returnToOnlineServicesText: {
    id: `${scope}.returnToOnlineServicesText`,
    defaultMessage:
      '<br><br>Please log into your Online Account, and navigate to the LSAT Writing page where you will be able to relaunch, or will have instructions for your options.<br><br><a href="https://os.lsac.org">https://os.lsac.org</a>',
  },
  privateModeHeading: {
    id: `${scope}.privateModeHeading`,
    defaultMessage: 'Private Mode',
  },
  privateModeText: {
    id: `${scope}.privateModeText`,
    defaultMessage:
      'The LSAT Writing Exam cannot be completed in private mode. Please switch browsers, with out private mode, to continue.',
  },
});
