/*
 * ApplicationStatusOnlinePage Messages
 *
 * This contains all the text for the ApplicationStatusOnlinePage component.
 */

import { defineMessages } from 'react-intl';
import { config } from '../../config';
import ExternalLinkIcon from '../../images/external_link_outward_rebrand.svg';

export const scope = 'app.container.ApplicationStatusOnlinePage';

export default defineMessages({
  heading: {
    id: `${scope}.heading`,
    defaultMessage: `Application Status Tracker`,
  },
  headingHelperText: {
    id: `${scope}.headingHelperText`,
    defaultMessage: `Select a school to view your application details. You may be asked to enter your school-specific login credentials.`,
  },
  headingHelperText1: {
    id: `${scope}.headingHelperText1`,
    defaultMessage: `If you do not see a school to which you’ve applied, you can continue to track your application with that school directly. Some schools do not post application statuses online.`,
  },
  emailNotifications: {
    id: `${scope}.emailNotifications`,
    defaultMessage: `Email Notifications`,
  },
  coming: {
    id: `${scope}.coming`,
    defaultMessage: `coming`,
  },
  soon: {
    id: `${scope}.soon`,
    defaultMessage: `soon`,
  },
  expiryWarningBannerHeading: {
    id: `${scope}.expiryWarningBannerHeading`,
    defaultMessage:
      'Your LawHub Advantage subscription expires {remainingDaysAsText}.',
  },
  expiryWarningHeaderText: {
    id: `${scope}.expiryWarningHeaderText`,
    defaultMessage: config.REACT_APP_ASO_BANNER_HEADING_TEXT,
  },
  renewNow: {
    id: `${scope}.renewNow`,
    defaultMessage: `Renew Now`,
  },
  upgradeNow: {
    id: `${scope}.upgradeNow`,
    defaultMessage: `Upgrade Now`,
  },
  noSubscriptionBannerHeader: {
    id: `${scope}.noSubscriptionBannerHeader`,
    defaultMessage: "You don't have a LawHub Advantage subscription",
  },
  noSubscriptionBannerDesc: {
    id: `${scope}.noSubscriptionBannerDesc`,
    defaultMessage:
      'Upgrade now to keep track of your law school applications in one convenient place.',
  },
  noAppsBannerHeader: {
    id: `${scope}.noAppsBannerHeader`,
    defaultMessage: 'Submit an Application to Get Started',
  },
  noAppsBannerDesc: {
    id: `${scope}.noAppsBannerDesc`,
    defaultMessage:
      'You can submit applications to every ABA-approved law school through your LSAC JD Account. Once you’ve applied, use Applications Status Tracker to monitor the progress for your law school applications.',
  },
  applyNow: {
    id: `${scope}.applyNow`,
    defaultMessage: `Apply Now`,
  },
  promoBannerHeading: {
    id: `${scope}.promoBannerHeading`,
    defaultMessage: 'Have you Checked out Ultimate Law School Prep Program?',
  },
  promoBannerBody: {
    id: `${scope}.promoBannerBody`,
    defaultMessage: 'Everything you need to ace your important 1L year.',
  },
  learnMore: {
    id: `${scope}.learnMore`,
    defaultMessage: 'Learn More',
  },
  astHeadingHelperText: {
    id: `${scope}.astHeadingHelperText`,
    defaultMessage:
      'A better way to monitor your Law School applications. Included with <b> LawHub Advantage.</b>',
  },
  subscribeToLawhubAdvantage: {
    id: `${scope}.subscribeToLawhubAdvantage`,
    defaultMessage: 'Subscribe to lawhub advantage',
  },
  howAstHelpsYou: {
    id: `${scope}.howAstHelpsYou`,
    defaultMessage: 'How Application Status Tracker Helps You',
  },
  keepTrackOfYourLsa: {
    id: `${scope}.keepTrackOfYourLsa`,
    defaultMessage:
      'Keeping track of your law school applications can be time-consuming. With LawHub’s easy-to-use <b>Application Status Tracker</b>, you’ll be able to:',
  },
  keepTrackPoint1: {
    id: `${scope}.keepTrackPoint1`,
    defaultMessage:
      'View the progress of your applications with each participating law school from one convenient, secure dashboard.',
  },
  keepTrackPoint2: {
    id: `${scope}.keepTrackPoint2`,
    defaultMessage:
      'See which applications are currently being reviewed by law schools and the final decision on each application once it’s been made.',
  },
  keepTrackPoint3: {
    id: `${scope}.keepTrackPoint3`,
    defaultMessage:
      'Receive email notifications to get updates whenever your statuses change.',
  },
  keepTrackPoint4: {
    id: `${scope}.keepTrackPoint4`,
    defaultMessage:
      ' Application Status Tracker is available anytime, anywhere and is included with your LawHub Advantage subscription. ',
  },
  keepTrackPoint5: {
    id: `${scope}.keepTrackPoint4`,
    defaultMessage: 'Subscribe today for $115/year.',
  },
  accessYourAccount: {
    id: `${scope}.accessYourAccount`,
    defaultMessage: 'Access your account',
  },
  frequentlyAskedQuestions: {
    id: `${scope}.frequentlyAskedQuestions`,
    defaultMessage: 'Frequently Asked Questions',
  },
  faqQuestion1: {
    id: `${scope}.faqQuestion1`,
    defaultMessage: 'What is the Application Status Tracker?',
  },
  faqAnswer1: {
    id: `${scope}.faqAnswer1`,
    defaultMessage:
      'The Application Status Tracker is a feature in LawHub&trade; that offers a convenient and secure way for you to see your law school applications in one easy&#45;to&#45;use dashboard. The application statuses displayed will refresh based on updates from the law schools. The feature is available exclusively to LawHub Advantage subscribers.',
  },
  faqQuestion2: {
    id: `${scope}.faqQuestion2`,
    defaultMessage:
      'What information will I see in the Application Status Tracker?',
  },
  faqAnswer2: {
    id: `${scope}.faqAnswer2`,
    defaultMessage: `Your dashboard provides a real&#45;time overview of the decision and status of each application you've submitted through your <a href='https://os.lsac.org/Logon/Access.aspx' class='fd-account' target='_blank' title='will open in a new window'>LSAC JD Account <img src="${ExternalLinkIcon}" alt='will open in a new window' class='outward-icon' /></a>. On your Application Status Tracker dashboard, you'll see the statuses of your applications, when the status of the applications is reported by the law schools to which you applied, and the date each status was last updated by the law school. You'll also be able to click "View Details" to be directed to the law school's website. The most recently updated law school will be listed at the top of the page. If you have any questions about a specific status that is displayed, we encourage you to check the applicable law school's website for more details.`,
  },
  faqQuestion3: {
    id: `${scope}.faqQuestion3`,
    defaultMessage: 'How do I access the Application Status Tracker?',
  },
  faqAnswer3: {
    id: `${scope}.faqAnswer3`,
    defaultMessage:
      'If you have a LawHub Advantage subscription and have submitted applications in the current admission cycle, you will be able to click on the "Application Status Tracker" tile on your LawHub homepage after signing into your LawHub account. If you do not have a LawHub Advantage subscription, you will not be able to view any application statuses through LawHub.',
  },
  moreReasonsToSubscribe: {
    id: `${scope}.moreReasonsToSubscribe`,
    defaultMessage: 'More Reasons to Subscribe',
  },
  lsatPrepTest: {
    id: `${scope}.lsatPrepTest`,
    defaultMessage: 'LSAT PrepTests',
  },
  lsatPrepTestDescription: {
    id: `${scope}.lsatPrepTestDescription`,
    defaultMessage:
      'Build test-day confidence with 75+ official practice tests.',
  },
  startPracticing: {
    id: `${scope}.startPracticing`,
    defaultMessage: 'Start Practicing',
  },
  knowB4UGo: {
    id: `${scope}.knowB4UGo`,
    defaultMessage: 'KnowB4UGo',
  },
  knowB4UGoDescription: {
    id: `${scope}.knowB4UGoDescription`,
    defaultMessage:
      "Use an innovative method to learn the terms you'll need for 1L success.",
  },
  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: 'Get Started',
  },
  lawSchoolJumpstart: {
    id: `${scope}.lawSchoolJumpstart`,
    defaultMessage: 'Law School Jumpstart',
  },
  lawSchoolJumpstartDesc: {
    id: `${scope}.lawSchoolJumpstartDesc`,
    defaultMessage:
      "Mock classes give you an insider's look at a law school curriculum.",
  },
  opensInNewWindow: {
    id: `${scope}.opensInNewWindow`,
    defaultMessage: 'Opens in a new browser window.',
  },
  getReadyToApply: {
    id: `${scope}.getReadyToApply`,
    defaultMessage: 'Get Ready to Apply',
  },
  iAmTheLaw: {
    id: `${scope}.iAmTheLaw`,
    defaultMessage: 'I Am The Law',
  },
  iAmTheLawDesc: {
    id: `${scope}.iAmTheLawDesc`,
    defaultMessage:
      "Hear from today's lawyers about what a career in law is like.",
  },
  listenNow: {
    id: `${scope}.listenNow`,
    defaultMessage: 'Listen Now',
  },
  findtheBestFit: {
    id: `${scope}.findtheBestFit`,
    defaultMessage: 'Find Your Law School',
  },
  findtheBestFitDesc: {
    id: `${scope}.findtheBestFitDesc`,
    defaultMessage:
      'This personalized report will help you determine the best schools for you.',
  },
  lsatPrepTestAuthDesc: {
    id: `${scope}.lsatPrepTestAuthDesc`,
    defaultMessage:
      'Build test-day confidence with 75+ official practice tests.',
  },
});
