import React, { useEffect, useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { getUser, signIn } from '../../authProvider';
import { HOME_PATH } from '../../containers/App/constants';

/**
 * A memoized component that checks if a user is logged in. Triggers auth if not or a redirect
 * back to the dashboard if they are
 * @returns {JSX.Element} Either a Redirect (if signed in) or a Fragment
 */
const LoginRedirect = React.memo(() => {
  // Capture the search string to append to the redirect
  const { search } = useLocation();

  // Memoize the signIn function
  const handleSignIn = useCallback(() => signIn(), []);
  // If not logged in, add home route to session history and trigger auth.
  // Adding home route to history allows B2C to quickly redirect there after
  // signin
  useEffect(() => {
    if (!getUser()) {
      handleSignIn();
    }
  }, []);

  // Redirect back to home route
  if (getUser()) {
    return <Redirect to={`${HOME_PATH}${search}`} />;
  }

  return <></>;
});

export default LoginRedirect;
