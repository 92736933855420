/**
 * Detect if the entire element is the viewport;
 * uses the window / html element as the containing element
 *
 * @param {HTMLElement} element
 * @returns {bool}
 */

export function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
Checks if an element is inside a containing element
@param {Element} element - The element to check if it's inside the containing element
@param {Element} containingEl - The containing element to check if the element is inside
@returns {boolean} Returns true if the element is inside the containing element, otherwise false
*/
export function isInContainingEl(element, containingEl) {
  const childRect = element.getBoundingClientRect();
  const containingElRect = containingEl.getBoundingClientRect();
  return (
    childRect.top >= containingElRect.top &&
    childRect.left >= containingElRect.left &&
    childRect.bottom <= containingElRect.bottom &&
    childRect.right <= containingElRect.right
  );
}

/**

Determines whether the target element's x-axis is mostly visible within the container element.
@param {Element} containerElement - The container element that the target element is contained within.
@param {Element} targetElement - The element whose visibility is being determined.
@param {number} threshold - The minimum percentage of visible width required for the target element to be considered mostly visible.
@returns {boolean} Whether the target element's x-axis is mostly visible within the container element.
*/

export function isXAxisMostlyVisible(
  containerElement,
  targetElement,
  threshold = 50,
) {
  const containerRect = containerElement.getBoundingClientRect();
  const targetRect = targetElement.getBoundingClientRect();

  // Calculate the horizontal overlap between the two rectangles
  const overlapLeft = Math.max(containerRect.left, targetRect.left);
  const overlapRight = Math.min(containerRect.right, targetRect.right);
  const overlapWidth = overlapRight - overlapLeft;
  // Calculate the visible width of the target element inside the container
  const visibleWidth = Math.min(targetRect.width, overlapWidth);

  // Calculate the percentage of visible width
  const visiblePercentage = (visibleWidth / targetRect.width) * 100;
  // Return whether the visiblePercentage is greater or equal to the threshold
  return visiblePercentage >= threshold;
}

// Checks if the size of the visual el (what you see) is less than
// the size of entire el (including what is hidden/overflowing)
export const isOverflowingHeight = el => el?.offsetHeight < el?.scrollHeight;
