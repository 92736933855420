import PropTypes from 'prop-types';
import { OptionMode } from 'enumerations/OptionMode';
import { OptionState } from 'enumerations/OptionState';
import textModel from './textModel';

/**
 * Answer option model
 * @class
 */

class AnswerOptionModel extends textModel {
  constructor(props) {
    super(props);

    /**
     * @property {string} text The answer option text
     */
    this.text = null;

    /**
     * @property {string} letter The answer option letter
     */
    this.letter = null;

    /**
     * @property {OptionMode} optionMode The option mode
     */
    this.optionMode = OptionMode.Expanded;

    /**
     * @property {OptionState} optionState The option state
     */
    this.optionState = OptionState.Unselected;
  }
}

AnswerOptionModel.propTypes = {
  text: PropTypes.string.isRequired,
  letter: PropTypes.string,
  optionMode: PropTypes.oneOf([OptionMode.Expand, OptionMode.Collapsed]),
  optionState: PropTypes.oneOf([
    OptionState.Unselected,
    OptionState.Selected,
    OptionState.Eliminated,
  ]),
};

export default AnswerOptionModel;
