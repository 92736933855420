import {
  CERTIFYING_STATEMENT_PATH,
  CONNECTIVITY_ERROR_PATH,
  DIRECTION_PATH,
  EXAM_COMPLETE_PATH,
  EXAM_DIRECTION_PATH,
  EXAM_MODULE_PATH,
  EXAM_PASSAGE_PATH,
  EXAM_PAUSE_PATH,
  EXAM_QUESTION_PATH,
  EXAM_TEST_COMPLETE_PATH,
  EXAM_TEST_REVIEW_PATH,
  FLEX_CONGRATS_PATH,
  FLEX_LANDING_PATH,
  INTERMISSION_PATH,
  MODULE_PATH,
  PASSAGE_PATH,
  PROMETRICS_COMPLETE_PATH,
  QUESTION_PATH,
  TEST_COMPLETE_FLEX_PATH,
  TEST_COMPLETE_PATH,
  TEST_REVIEW_PATH,
  WRITING_EXAM_COMPLETE_FINAL_PATH,
} from '../containers/App/constants';

export const PROMETRICS_USER_AGENT =
  'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/83.0.4103.122 Electron/9.4.4 Safari/537.36';

export const EXAM_PATHS = [
  DIRECTION_PATH,
  EXAM_DIRECTION_PATH,
  PASSAGE_PATH,
  EXAM_PASSAGE_PATH,
  QUESTION_PATH,
  EXAM_QUESTION_PATH,
  TEST_COMPLETE_PATH,
  EXAM_TEST_COMPLETE_PATH,
  TEST_REVIEW_PATH,
  EXAM_TEST_REVIEW_PATH,
  MODULE_PATH,
  EXAM_MODULE_PATH,
  EXAM_PAUSE_PATH,
  INTERMISSION_PATH,
  CERTIFYING_STATEMENT_PATH,
  TEST_COMPLETE_FLEX_PATH,
  PROMETRICS_COMPLETE_PATH,
  EXAM_COMPLETE_PATH,
  WRITING_EXAM_COMPLETE_FINAL_PATH,
  FLEX_CONGRATS_PATH,
  FLEX_LANDING_PATH,
  CONNECTIVITY_ERROR_PATH,
];

export const EXAM_PATH_PREFIX = '/exam';
