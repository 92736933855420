/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/**
 *
 * repo management helper
 *
 */
import { isNullOrUndefined } from 'util';
import { remove } from 'lodash';
import { currentTimeAsISO } from '../timeHelper';
import { deleteInstanceState } from './common/idbHelper';
import {
  cleanupDB,
  getStateFromLocal,
  persistNow,
  requestSaveToLocal,
} from './common/storageHelper';
import {
  mergeModuleState,
  logModuleExamSettings,
} from './examManagementHelper';
import { setCurrentInstanceForHistory } from '../../utils/history';
import { persistenceState } from './common/persistenceState';
import {
  setEntireInstanceDirty,
  setInstanceChanged,
} from './common/instanceHelper';
import { calculateInstanceMetrics } from './common/summaryHelper';
import { getInstanceRepo } from './common/repoHelper';

export async function initializeRepo() {
  const repo = await getStateFromLocal();
  if (!isNullOrUndefined(repo)) {
    persistenceState.repo = repo;
  }
  const instancesToCleanUp = [];
  const r = persistenceState.repo;
  if (r.instances) {
    r.instances.forEach(instance => {
      if (!instance.serverNeedsLatest) {
        instancesToCleanUp.push(instance.testInstanceId);
      }
    });
    instancesToCleanUp.forEach(id => {
      removeInstance(id);
    });
  }
  persistenceState.initialized = true;
  return true;
}

/**
 * Checks if local storage shows that instance is complete
 *
 * @param {string} testInstanceId the instance id
 */
export function isInstanceCompleted(testInstanceId) {
  if (isNullOrUndefined(persistenceState.repo.instances)) {
    return false;
  }
  const instance = persistenceState.repo.instances.find(
    item => item.testInstanceId === testInstanceId,
  );
  if (!isNullOrUndefined(instance)) {
    return instance.state.isCompleted === true;
  }
  return false;
}

function removeInstance(testInstanceId) {
  remove(
    persistenceState.repo.instances,
    item => item.testInstanceId === testInstanceId,
  );
  if (persistenceState.useIndexedDB) {
    window.setTimeout(deleteInstanceState(testInstanceId), 0);
  } else {
    requestSaveToLocal();
  }
}

export async function checkForDuplicates() {
  let found = true;
  let changed = false;
  while (found) {
    found = false;
    const check = [];
    const tids = persistenceState.repo.instances.map(
      item => item.testInstanceId,
    );
    // TODO: replace this "some" logic with a loop
    for (let index = 0; index < tids.length; index += 1) {
      const item = tids[index];
      const foundIndex = check.indexOf(item);
      if (foundIndex > -1) {
        mergeModuleState(
          persistenceState.repo.instances[foundIndex].state,
          persistenceState.repo.instances[index].state,
        );
        remove(
          persistenceState.repo.instances,
          (_item, instanceIndex) => instanceIndex === index,
        );
        persistenceState.repo.instances[foundIndex].serverNeedsLatest = true;
        await setEntireRepoDirty(persistenceState.repo);
        requestSaveToLocal();
        changed = true;
        found = true;
      }
      check.push(item);
    }
  }
  if (changed) {
    requestSaveToLocal();
  }
}

async function setEntireRepoDirty(r) {
  await cleanupDB();
  r.instances.forEach(instance => {
    setEntireInstanceDirty(instance);
  });
}

/**
 * Persist Prometrics URL Params
 */
let launchStartParams = null;
export function setLaunchStartParams(prometricsValues) {
  if (launchStartParams) return;
  launchStartParams = {
    eligibilityId: prometricsValues.elidId,
    confirmationId: prometricsValues.confirmation,
    siteCode: prometricsValues.site,
    scheduledStartTime: prometricsValues.startTime,
  };
}

export function getLaunchStartParams(testInstanceId) {
  // technical pause will have the local `launchStartParams` populated
  if (launchStartParams) return launchStartParams;
  const instance = persistenceState.repo.instances.find(
    item => item.testInstanceId === testInstanceId,
  );
  return instance?.launchDetails ? instance.launchDetails : {};
}

/**
 *
 * @param {object} instance
 * @param {object} newLaunchDetails
 *
 * @return {array} launchDetais - array of existing launchDetails, or an array with appended newLaunchDetails
 */
function addOrAppendStartParams(instance, newLaunchDetails) {
  const { launchDetails } = instance;
  if (instance === null || newLaunchDetails === null) {
    return [];
  }

  if (!launchDetails) {
    // no existing launchDetails, just return the new ones ( in an array )
    return [newLaunchDetails];
  }

  const launchDetailsArray = [...launchDetails];

  const incomingStartTime = newLaunchDetails.scheduledStartTime;

  // compare year/month/day values of two date strings regardless of the formatting of the date.
  function areDatesEqual(dateString1, dateString2) {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    return (
      date1.getUTCFullYear() === date2.getFullYear() &&
      date1.getUTCMonth() === date2.getMonth() &&
      date1.getUTCDate() === date2.getDate()
    );
  }

  // Check if incomingStartTime is already in the launchDetailsArray
  const isAlreadyPresent = launchDetailsArray.some(detail =>
    // Compare the year/month/day values of the incoming start time and the detail's scheduled start time
    areDatesEqual(incomingStartTime, detail.scheduledStartTime),
  );

  // If incomingStartTime is not already in the launchDetailsArray, add it
  if (!isAlreadyPresent) {
    launchDetailsArray.push(newLaunchDetails);
  }
  return launchDetailsArray;
}

/**
 * set the instance so it doesn't need to be passed in on each transaction
 * and create an object if necessary to hold instance state. The instance
 * itself is needed so that we can calculate total answered and total correct.
 * @param {object} instance the test instance
 * @param {function} putInstanceState the function to send state
 */
export function setCurrentInstance(instance, putInstanceState, exitFlex) {
  if (!isNullOrUndefined(instance)) {
    const { testInstanceId, module } = instance;

    const launchDetails = addOrAppendStartParams(
      instance,
      getLaunchStartParams(),
    );
    if (persistenceState.testInstanceId !== testInstanceId) {
      persistenceState.testInstanceId = testInstanceId;
      persistenceState.putInstanceStateCall = putInstanceState;
      persistenceState.module = module;
      persistenceState.exitFlex = exitFlex;
      persistenceState.launchDetails = launchDetails;

      // update instance repo
      const instanceRepo = getInstanceRepo();
      if (isNullOrUndefined(instanceRepo.state)) {
        instanceRepo.state = {};
        mergeModuleState(instanceRepo.state, module, false);
      }

      // Add launch details only if it is a real exam
      if (module?.options?.isRealExam || module?.options?.isRealExamMode) {
        instanceRepo.launchDetails = launchDetails;
      }

      const { state } = instanceRepo;
      if (isNullOrUndefined(state.startTime)) {
        state.startTime = currentTimeAsISO();
        setInstanceChanged(null, 'startTime');
        persistNow();
      }
      instanceRepo.summary = calculateInstanceMetrics(module);
      logModuleExamSettings(module);
    }
  } else {
    persistenceState.launchDetails = getLaunchStartParams();
    persistenceState.isShowingAlert = false;
    persistenceState.module = null;
    persistenceState.testInstanceId = null;
    if (!isNullOrUndefined(putInstanceState)) {
      persistenceState.putInstanceStateCall = putInstanceState;
    }
  }
  setCurrentInstanceForHistory(instance);
}

export function getHasInstanceChanged(instance) {
  let instanceRepo = instance;
  if (isNullOrUndefined(instanceRepo)) {
    instanceRepo = getInstanceRepo();
  }
  if (!isNullOrUndefined(instanceRepo)) {
    return instanceRepo.serverNeedsLatest;
  }
  return true;
}
