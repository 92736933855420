import React from 'react';
import { Button } from 'reactstrap';
import { signIn } from '../../../authProvider';
import './index.css';

const SignInButton = () => {
  const handleClick = async () => {
    signIn();
  };

  return (
    <Button onClick={handleClick} className="sign-in-button">
      Sign-in
    </Button>
  );
};

export default SignInButton;
