/*
 * SectionDetails Messages
 *
 * This contains all the text for the SectionDetails component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.SectionDetails';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the SectionDetails component!',
  },
  notStartedText: {
    id: `${scope}.notStartedText`,
    defaultMessage: 'Not started',
  },
  answeredText: {
    id: `${scope}.answeredText`,
    defaultMessage: 'answered',
  },
  answeredQuestions: {
    id: `${scope}.answeredQuestions`,
    defaultMessage: 'answered out of',
  },
  questionsTotal: {
    id: `${scope}.questionsTotal`,
    defaultMessage: 'total questions',
  },
  questionsCorrect: {
    id: `${scope}.questionsCorrect`,
    defaultMessage: 'questions correctly answered',
  },
});
