/*
 *
 * ProctorWritingPage reducer
 *
 */
import produce from 'immer';
import {
  FULFILL_PROCTORING_TEST_ERROR,
  FULFILL_PROCTORING_TEST_SUCCESS,
  INITIALIZE_PROCTORING_TEST_ERROR,
  INITIALIZE_PROCTORING_TEST_SUCCESS,
} from './constants';

export const initialState = {
  reservationStartTime: undefined,
  reservationUrl: undefined,
  timestamp: undefined,
  error: undefined,
};

/* eslint-disable default-case, no-param-reassign */
const proctorWritingReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case INITIALIZE_PROCTORING_TEST_SUCCESS:
        draft.reservationUrl = action.testInfo.reservationUrl;
        draft.error = undefined;
        break;
      case INITIALIZE_PROCTORING_TEST_ERROR:
        draft.error = action.error;
        break;
      case FULFILL_PROCTORING_TEST_SUCCESS:
        draft.error = undefined;
        break;
      case FULFILL_PROCTORING_TEST_ERROR:
        draft.error = action.error;
        break;
    }
  });

export default proctorWritingReducer;
