/**
 *
 * saga helper
 *
 */
import axios from 'axios';
import { config } from 'config';
import { call } from 'redux-saga/effects';
import history from 'utils/history';
import isNullOrUndefined from '../utils/isNullOrUndefined';
import {
  getUserId,
  setIsUserVerified,
  getIsUserVerified,
  getUserDetails,
  isPLUSUser,
  isPLUSRegisteredUser,
  extensionCandSeq,
} from './userHelper';
import { getAPIFullPath } from './configHelper';
import {
  PLUS_PROGRAM_REGISTRATION,
  PLUS_PROGRAM_REGISTRATION_COMPLETE,
  HOME_PATH,
  LIBRARY_PATH,
  UPGRADE_PATH,
  LSAC_ORGANIZATION_IDENTIFIER_TYPE,
} from '../containers/App/constants';
import * as logHelper from './logHelper';
import { LogLevelType } from '../enumerations/LogLevelType';

import { logException } from './logHelper';
import * as authProvider from '../authProvider';
const util = require('util');

/**
 * getNonAuthApi for non-authenticated api calls
 * @function
 * @param {string} method GET, PUT, PATCH, POST, DELETE
 * @param {string} requestURL the URL string
 * @param {object} data data to send
 * @param {bool} isNoCache
 */
export async function getNonAuthApi(method, requestURL, data, isNoCache) {
  const headers = {
    'X-LawHub-Api-Key': `${config.REACT_APP_X_LAWHUB_API_KEY}`,
  };
  if (!isNullOrUndefined(isNoCache) && isNoCache) {
    headers.pragma = 'no-cache';
  }
  return axios.request({
    method,
    url: requestURL,
    data: !isNullOrUndefined(data) ? data : undefined,
    headers,
  });
}

/**
 * getSectionApi data function to retrieve data
 *
 * @function
 * @param {string} method GET, PUT, PATCH, POST, DELETE
 * @param {string} requestURL the URL string
 * @param {object} data data to send
 * @param {bool} isNoCache
 */
export async function getApi(method, requestURL, data, isNoCache) {
  let token;
  await authProvider.getAccessToken().then(response => {
    token = response?.accessToken;
  });
  const headers = { Authorization: `Bearer ${token}` };
  if (!isNullOrUndefined(isNoCache) && isNoCache) {
    headers.pragma = 'no-cache';
  }
  return axios.request({
    method,
    url: requestURL,
    data: !isNullOrUndefined(data) ? data : undefined,
    headers,
  });
}

export async function getThirdPartyApi(method, requestURL) {
  return axios.request({
    method,
    url: requestURL,
  });
}

export function* createUser() {
  try {
    // Retrieve referral code from sessionStorage
    const REFERRAL_CODE_KEY = 'refcode';
    const refCodeVal = window.sessionStorage.getItem(REFERRAL_CODE_KEY);

    const authUser = authProvider.getUser();
    // eslint-disable-next-line no-console
    logHelper.log(LogLevelType.Info, 'createUser', authUser);

    const shouldSuppressEmail = document.URL.includes('SuppressEmail%3Dtrue');

    const user = {
      // Ensure userId & emailAddress are uppercase before creating user
      userId: authUser.idTokenClaims.username.toUpperCase(),
      emailAddress: authUser.idTokenClaims.emails[0].toUpperCase(),
      firstName: authUser.idTokenClaims.given_name,
      lastName: authUser.idTokenClaims.family_name,
      referralCode: refCodeVal,
    };
    let url = `${getAPIFullPath(config.REACT_APP_CREATE_USER_URL)}`;
    url = shouldSuppressEmail ? `${url}?suppressEmail=true` : url;
    yield call(getApi, 'post', url, user);
    setIsUserVerified(true);
  } catch (err) {
    if (err?.response?.status === 409) {
      setIsUserVerified(true);
    } else if (err?.response?.status === 400) {
      // eslint-disable-next-line no-alert
      window.confirm(
        'Invalid user data. Select "OK" to retry or close the tab to exit the application.',
      );
    } else {
      throw err;
    }
  }
}

export function* getUser() {
  try {
    const username = getUserId();
    const apiEndURL = `${util.format(config.REACT_APP_GET_USER_URL, username)}`;
    // Perform the http call
    const repos = yield call(
      getApi,
      'get',
      `${getAPIFullPath(apiEndURL)}`,
      null,
      true,
    );
    setIsUserVerified(true);

    const crntCandSeq = repos?.data?.userOrganizationIdentifiers?.find(
      elem => elem?.identifierType === LSAC_ORGANIZATION_IDENTIFIER_TYPE,
    )?.organizationIdentifier;
    if (crntCandSeq !== extensionCandSeq?.value) {
      yield updateCandSeq();
    }
    return repos;
  } catch (err) {
    throw err;
  }
}

let plusJustRegistered = false;

export function setPlusJustRegistered() {
  plusJustRegistered = true;
}

export function getPlusJustRegistered() {
  return plusJustRegistered;
}

export function PLUSRedirectIfNeeded(userDetails) {
  const isPlus = isPLUSUser(userDetails);
  const isRegistered = isPLUSRegisteredUser(userDetails);
  const pathName = window.location.pathname;
  const plusPaths = [
    PLUS_PROGRAM_REGISTRATION,
    PLUS_PROGRAM_REGISTRATION_COMPLETE,
  ];
  const isPlusPath = plusPaths.some(path =>
    pathName.toLowerCase().startsWith(path.toLowerCase()),
  );
  if (!isPlus || isRegistered) {
    if (isPlusPath && !plusJustRegistered) {
      history.replace('/');
    }
  } else if (!isPlusPath) {
    history.replace(PLUS_PROGRAM_REGISTRATION);
  }
}

function* updateCandSeq() {
  if (extensionCandSeq.value) {
    const userId = getUserId();
    const apiEndURL = `${util.format(
      config.REACT_APP_UPDATE_CAND_SEQ,
      userId,
    )}`;
    const payload = { candSeq: extensionCandSeq.value };

    try {
      yield call(getApi, 'put', `${getAPIFullPath(apiEndURL)}`, payload);
    } catch (error) {
      logException(error);
    }
  }
}

export function* verifyExistingUser() {
  const username = getUserId();
  const needsFreshUserDetails =
    history.location.pathname === HOME_PATH ||
    history.location.pathname === LIBRARY_PATH ||
    history.location.pathname === UPGRADE_PATH;
  if (getIsUserVerified() || username === config.REACT_APP_UI_TEST_USER) {
    // TO DO: This is a temporary fix to check if user is on a page that needs updated
    // user details in the event of a hold or renewal initiated outside the application,
    // i.e., a hold or renewal. Reevaluate when app refactor is implemented.
    if (!needsFreshUserDetails) {
      return getUserDetails();
    }
  }
  return null;
}

export async function getScoreReportPDF(testInstanceId) {
  let token;
  await authProvider.getAccessToken().then(response => {
    token = response?.accessToken;
  });

  const headers = { Authorization: `Bearer ${token}` };

  const username = getUserId();

  const apiEndURL = `${util.format(
    config.REACT_APP_GET_SCORE_REPORT_PDF_URL,
    username,
    testInstanceId,
  )}`;

  return axios
    .request({
      url: `${getAPIFullPath(apiEndURL)}`,
      method: 'get',
      responseType: 'blob',
      headers,
    })
    .then(response => response.data);
}

/**
 * getPdf
 */
export async function getPdf(method, requestURL, isNoCache) {
  let token;
  await authProvider.getAccessToken().then(response => {
    token = response?.accessToken;
  });
  const headers = { Authorization: `Bearer ${token}` };
  if (!isNullOrUndefined(isNoCache) && isNoCache) {
    headers.pragma = 'no-cache';
  }
  return axios.request({
    method,
    url: requestURL,
    responseType: 'blob',
    headers,
  });
}
