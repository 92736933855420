export const INITIALIZE_PROCTORING_TEST =
  'Aetha/ProctorWritingPage/InitializeProctoringTest';
export const INITIALIZE_PROCTORING_TEST_SUCCESS =
  'Aetha/ProctorWritingPage/InitializeProctoringTestSuccess';
export const INITIALIZE_PROCTORING_TEST_ERROR =
  'Aetha/ProctorWritingPage/InitializeProctoringTestError';
export const FULFILL_PROCTORING_TEST =
  'Aetha/ProctorWritingPage/FulfillProctoringTest';
export const FULFILL_PROCTORING_TEST_SUCCESS =
  'Aetha/ProctorWritingPage/FulfillProctoringTestSuccess';
export const FULFILL_PROCTORING_TEST_ERROR =
  'Aetha/ProctorWritingPage/FulfillProctoringTestError';
