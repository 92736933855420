/*
 * SectionResult Messages
 *
 * This contains all the text for the SectionResult component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.SectionResult';

export default defineMessages({
  noResponse: {
    id: `${scope}.noResponse`,
    defaultMessage: 'No Response',
  },
  flagged: {
    id: `${scope}.flagged`,
    defaultMessage: 'Flagged',
  },
  notFlagged: {
    id: `${scope}.notFlagged`,
    defaultMessage: 'Not flagged',
  },
  correct: {
    id: `${scope}.correct`,
    defaultMessage: 'correct',
  },
  incorrect: {
    id: `${scope}.incorrect`,
    defaultMessage: 'incorrect',
  },
  review: {
    id: `${scope}.review`,
    defaultMessage: 'Review',
  },
  flaggedQuestion: {
    id: `${scope}.flaggedQuestion`,
    defaultMessage: 'Flagged Question',
  },
  response: {
    id: `${scope}.response`,
    defaultMessage: 'Response',
  },
  answerStatus: {
    id: `${scope}.answerStatus`,
    defaultMessage: 'Answer Status',
  },
  answer: {
    id: `${scope}.answer`,
    defaultMessage: 'Answer',
  },
  sortingMessage: {
    id: `${scope}.sortingMessage`,
    defaultMessage: '{sortedKey} sort by {orderType} order',
  },
  defaultSortingMessage: {
    id: `${scope}.defaultSortingMessage`,
    defaultMessage: '{sortedKey}, sort by {orderType}',
  },
  itemRemoved: {
    id: `${scope}.itemRemoved`,
    defaultMessage: 'Item Removed',
  },
});
