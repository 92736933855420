/*
 *
 * ArticlePage saga
 *
 */
import { takeLatest, call, put } from 'redux-saga/effects';
import { getAPIFullPath } from 'helpers/configHelper';
import { getApi } from 'helpers/sagaHelper';
import { config } from 'config';
import { GET_ARTICLE_CONTENT } from './constants';
import {
  getArticleContentSuccessAction,
  getArticleContentErrorAction,
} from './actions';
import handleAPIErrorAndRetry from '../../helpers/apiResponseHelper';

const util = require('util');

export function* getArticleContent(data) {
  function* successCallback(repoData) {
    yield put(getArticleContentSuccessAction(repoData.data));
  }
  function* failureCallback(error) {
    yield put(getArticleContentErrorAction(error));
  }
  const articleId = data?.articleId;
  const apiEndURL = `${util.format(
    config.REACT_APP_GET_ARTICLE_CONTENT,
    articleId,
  )}`;
  const fullPath = `${getAPIFullPath(apiEndURL)}`;
  const api = () => getApi('get', fullPath);

  // Perform the http call
  try {
    const repos = yield call(getApi, 'get', fullPath);
    // send the result back to the reducer
    yield put(getArticleContentSuccessAction(repos.data));
  } catch (err) {
    // send the error back to the reducer
    yield put(getArticleContentErrorAction(err));

    // Retry. If API failure persists, send error to the reducer
    // and redirect to API Error Page.
    if (err?.response?.status >= 500) {
      yield* handleAPIErrorAndRetry(err, api, successCallback, failureCallback);
    }
  }
}

/**
 * Manages watcher lifecycle
 */
export default function* articleData() {
  // Watches for GetSection action and call get when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_ARTICLE_CONTENT, getArticleContent);
}
