/**
 *
 * ErrorBoundary
 *
 */

import React from 'react';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import PropTypes from 'prop-types';
import { reactPlugin } from '../../containers/TestSelectPage/testselect.util';

/**
 *
 * @param {React.FC || React.Component} ui component to render via the onError prop
 * @param {React.FC || React.Component} children component children being wrapped by ErrorBoundary
 * @description Catches uncaught errors and renders a fallback UI when the render tree unmounts.
 * @description It also reports the error to Application Insights in Azure.
 */

function ErrorBoundary({ ui = <></>, children }) {
  return (
    <AppInsightsErrorBoundary onError={() => ui} appInsights={reactPlugin}>
      {children}
    </AppInsightsErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  ui: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};

export default ErrorBoundary;
