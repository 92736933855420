/**
 * Enum for Module Type
 * @category enumerations
 * @enum {string}
 */
const ModuleType = {
  /**
   * @type {string}
   * @description Test instance module type
   */
  DrillSet: 'DrillSet',
  /**
   * @type {string}
   * @description Test instance module type
   */
  Writing: 'Writing',
  /**
   * @type {string}
   * @description Test instance module type
   */
  MultipleChoice: 'MultipleChoice',
  /**
   * @type {string}
   * @description Test instance module type
   */
  WritingWithPerspectives: 'WritingWithPerspectives',
  /**
   * @type {string}
   * @description Test instance module type
   */
  NextGenPrepTest: 'NextGenPrepTest',
  /**
   * @type {string}
   * @description Test instance module type
   */
  DrillSetAdditional: 'DrillSetAdditional',
};

export { ModuleType };
