import produce from 'immer';

import { SET_TOKEN_ACTION_SUCCESS, SET_TOKEN_ACTION_ERROR } from './constants';
import * as logHelper from '../../helpers/logHelper';
import { LogLevelType } from '../../enumerations/LogLevelType';

export const initialState = {
  isTokenSet: false,
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const writingExamPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_TOKEN_ACTION_SUCCESS:
        logHelper.log(
          LogLevelType.Info,
          'WritingExamPage: the token has been successfully set',
        );
        draft.isTokenSet = true;
        break;
      case SET_TOKEN_ACTION_ERROR:
        draft.error = action.error;
        break;
    }
  });

export default writingExamPageReducer;
