/**
 *
 * WritingResponse
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

function WritingResponse({ writingSectionResponse }) {
  function onCopyResponse(e) {
    e.preventDefault();
  }

  return (
    <div className="writing-response-container">
      <div
        className="writing-response"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: writingSectionResponse }}
        onCopy={onCopyResponse}
      />
    </div>
  );
}

WritingResponse.propTypes = {
  /** 
    The text to be seen in the writing section
  */
  writingSectionResponse: PropTypes.string.isRequired,
};

export default WritingResponse;
