// authProvider.js
import {
  EventType,
  PublicClientApplication,
  Logger,
  LogLevel,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { config } from './config';
import * as logHelper from './helpers/logHelper';
import { LogLevelType } from './enumerations/LogLevelType';
import { RECORDED_LOGIN, AUTH_STATE } from './domains/session/constants';
import { getQueryVariable } from './helpers/historyHelper';
import { removeItemFromLocal } from './helpers/localStorageHelper';

// Check if the userSession var is in localStorage; allows deferred login
const isUserSession = () =>
  window.sessionStorage.getItem('userSession') === 'true';

async function loggerCallback(logLevel, message, piiLoggingEnabled) {
  logHelper.log(
    LogLevelType.Debug,
    'loggerCallback',
    logLevel,
    message,
    piiLoggingEnabled,
  );
}

const logger = new Logger(loggerCallback, {
  level: LogLevel.Verbose,
});

const isAccountForLoginPolicy = accountObj =>
  accountObj.idTokenClaims?.acr ===
  config.REACT_APP_SIGN_IN_POLICY.toLowerCase();

function getLoginConfig() {
  const authorityUrl = `${config.REACT_APP_INSTANCE}${
    config.REACT_APP_LOGIN_URL
  }${config.REACT_APP_SIGN_IN_POLICY}`;
  const authInfo = getConfig(authorityUrl);
  logHelper.log(LogLevelType.Debug, 'LoginConfig', JSON.stringify(authInfo));
  return authInfo;
}

function getResetPasswordConfig() {
  const authorityUrl = `${config.REACT_APP_INSTANCE}${
    config.REACT_APP_LOGIN_URL
  }${config.REACT_APP_RESET_PASSWORD_POLICY}`;
  const authInfo = getConfig(authorityUrl);
  logHelper.log(
    LogLevelType.Debug,
    'ResetPasswordConfig',
    JSON.stringify(authInfo),
  );
  return authInfo;
}

function getConfig(authority) {
  const cacheLocation = BrowserCacheLocation.SessionStorage;

  return {
    auth: {
      authority,
      knownAuthorities: [config.REACT_APP_INSTANCE],
      clientId: config.REACT_APP_APPLICATION_ID,
      redirectUri: document.location.origin,
      validateAuthority: false,
      postLogoutRedirectUri: document.location.origin,
    },
    cache: {
      cacheLocation,
      storeAuthStateInCookie: false,
    },
    system: {
      logger,
    },
  };
}

const msal = new PublicClientApplication(getLoginConfig());

msal.addEventCallback(
  event => {
    // If the returned account is for B2C login policy
    // set active account for the login policy after loginRedirect,
    // loginPopup, ssoSilent, and acquireTokenSilent
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event?.payload?.account &&
      isAccountForLoginPolicy(event.payload)
    ) {
      window.sessionStorage.setItem(AUTH_STATE, 'authenticated');
      msal.setActiveAccount(event.payload.account);
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
      // Automatically log the user out and redirect to root; not valid for unauthed
      // because we need to let auth fail when silently checking B2C with popup
      if (!config.REACT_APP_ENABLE_UNAUTHED_ACCESS === 'true') {
        logout();
      }
    }
  },
  error => {
    logHelper.log(LogLevelType.Error, 'callback error', error);
    if (!config.REACT_APP_ENABLE_UNAUTHED_ACCESS === 'true') {
      logout();
    }
  },
);

export async function getRemoteAccountSession() {
  // If there is no access token in cache, no userSession var in sessionStorage,
  // and no querystring var that requires a redirect login, try to get the B2C
  // session non-interactively
  if (
    !getUser() &&
    !isUserSession() &&
    !shouldPromptLogin() &&
    config.REACT_APP_ENABLE_UNAUTHED_ACCESS === 'true'
  ) {
    // Check the B2C session cookie silently to continue a user session
    // This may fail if third-party cookies are blocked
    try {
      // Check the B2C session cookie
      await msal.ssoSilent({
        scopes: [config.REACT_APP_SCOPES],
      });
    } catch (error) {
      // Call the popup login method if the third-party cookie method fails
      // This may fail if pop-ups are blocked
      await msal
        .acquireTokenPopup({
          scopes: [config.REACT_APP_SCOPES],
          prompt: 'none',
          popupWindowAttributes: {
            popupSize: {
              height: 20,
              width: 200,
            },
            popupPosition: {
              top: 1000,
              left: 0,
            },
          },
        })
        .catch(() => {
          // There may be an error because there is no session, which is
          // fine for this implementation as that is what we need to know.
          // If this is the case, then the app will load in an unauthed state
        });
    }
  }
}

export async function getAccessToken() {
  // handle auth redirect/do all initial setup for msal

  // Check if user is signed in, first checking for the active account in
  // browser storage
  const account = getUser();

  return msal
    .handleRedirectPromise()
    .then(() => {
      // If no account or unauthed is off, enter interactive redirect flow
      if (
        !account &&
        (isUserSession() ||
          config.REACT_APP_ENABLE_UNAUTHED_ACCESS === 'false' ||
          shouldPromptLogin())
      ) {
        window.sessionStorage.removeItem('userSession');
        // redirect anonymous user to login page
        const tokenRequest = {
          scopes: [config.REACT_APP_SCOPES],
        };
        return msal
          .loginRedirect(tokenRequest)
          .then(response => response)
          .catch(error => {
            /* eslint-disable-next-line no-console */
            logHelper.log(LogLevelType.Error, 'login redirect error', error);
            return null;
          });
      }
      // Else silently log the user in
      const request = {
        scopes: [config.REACT_APP_SCOPES],
        account,
      };
      return msal
        .acquireTokenSilent(request)
        .then(response => response)
        .catch(error => {
          // If there is a token cache in browser storage and an error,
          // we should remove userSession var from localStorage and clear
          // the cache of invalid tokens
          if (account && error) {
            logout();
            return null;
          }
          /* eslint-disable-next-line no-console */
          logHelper.log(LogLevelType.Error, 'token silent error', error);
          return null;
        });
    })
    .catch(error => {
      /* eslint-disable-next-line no-console */
      logHelper.log(LogLevelType.Error, 'redirect promise error', error);
      return null;
    });
}

// Return the account associated with the login policy
// if it exists, otherwise null.
export function getUser() {
  const msalAccounts = msal.getAllAccounts();
  if (msalAccounts.length >= 1) {
    return msalAccounts.find(account => isAccountForLoginPolicy(account));
  }
  return null;
}

function shouldPromptLogin() {
  const LOGIN_VAR_KEY = 'prompt';
  const LOGIN_VAR_VALUE = 'login';
  const PROMETRIC_VAR_KEY = 'elidId';

  // Parse the prompt var from the querystring and compare it
  // with the login value
  const loginQuery = getQueryVariable(LOGIN_VAR_KEY)?.toLowerCase();
  const hasLoginQuery = loginQuery === LOGIN_VAR_VALUE;
  // Parse the elidId prometric-prompt var from querystring
  const hasPrometricQuery = !!getQueryVariable(PROMETRIC_VAR_KEY);
  // Force login if trigger vars are present and there is no user in cache
  const shouldForceLogin = (hasLoginQuery || hasPrometricQuery) && !getUser();

  // Remove the prompt=login part of the querystring from the URL if present
  if (hasLoginQuery) {
    const newURL = new URL(window.location.href);
    const searchParams = new URLSearchParams(newURL.search);

    searchParams.delete(LOGIN_VAR_KEY);
    newURL.search = searchParams;
    window.history.pushState({}, '', newURL);
  }

  return shouldForceLogin;
}

const removeTemporaryMsalVariables = () => {
  Object.keys(sessionStorage).forEach(key => {
    if (key.includes('msal')) {
      sessionStorage.removeItem(key);
    }
  });
};

export function signIn() {
  if (config.REACT_APP_ENABLE_MSAL_SESSION_VAR_PURGE === 'true') {
    removeTemporaryMsalVariables();
  }
  window.sessionStorage.setItem(AUTH_STATE, 'authenticated');
  window.sessionStorage.setItem('userSession', `true`);
  getAccessToken();
}

export function logout() {
  window.sessionStorage.removeItem('userSession');

  // Remove test instance from local storage when user logsout
  removeItemFromLocal('testInstanceInLocal');

  // Remove the login record from session storage;
  // if it's not found, nothing happens and no error is thrown
  sessionStorage.removeItem(RECORDED_LOGIN);
  sessionStorage.setItem(AUTH_STATE, 'unauthenticated');

  msal.logoutRedirect();
}

export function resetPassword() {
  const msalForResetPassword = new PublicClientApplication(
    getResetPasswordConfig(),
  );
  msalForResetPassword
    .handleRedirectPromise()
    .then(() =>
      msalForResetPassword
        .loginRedirect()
        .then(response => {
          if (
            response.idTokenClaims.acr ===
            config.REACT_APP_RESET_PASSWORD_POLICY
          ) {
            logout();
          }
        })
        .catch(error => {
          /* eslint-disable-next-line no-console */
          logHelper.log(LogLevelType.Error, 'reset password error', error);
          logout();
        }),
    )
    .catch(error => {
      /* eslint-disable-next-line no-console */
      logHelper.log(LogLevelType.Error, 'reset password error', error);
      logout();
    });
}

export async function signupUrl() {
  const request = {
    scopes: [config.REACT_APP_SCOPES],
  };

  // Generate a random string of characters for the verifier
  const verifier = Array.from(
    window.crypto.getRandomValues(new Uint8Array(32)),
    byte => String.fromCharCode(byte),
  ).join('');

  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  return crypto.subtle
    .digest('SHA-256', data)
    .then(hashBuffer => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');

      // Base64-URL encode the hash
      const codeChallenge = btoa(hashHex)
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');

      // Use the "S256" code challenge method
      const codeChallengeMethod = 'S256';

      const authorityInfo = getLoginConfig().auth;
      const loginUrl =
        `${authorityInfo.authority}/oauth2/v2.0/authorize` +
        `?client_id=${authorityInfo.clientId}` +
        `&response_type=code` +
        `&redirect_uri=${encodeURIComponent(authorityInfo.redirectUri)}` +
        `&response_mode=query` +
        `&scope=${encodeURIComponent(request.scopes.join(' '))}` +
        `&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}&option=signup`;
      return loginUrl;
    })
    .catch(error => logHelper.log(LogLevelType.Error, 'callback error', error));
}
