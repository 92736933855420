/**
 * Section type enumeration that describes the type of section
 * @category enumerations
 * @enum {number}
 */
const SectionType = {
  /**
   * @type {number}
   * @description The Logical Reasoning section
   */
  LogicalReasoning: 1,

  /**
   * @type {number}
   * @description The Analytical Reasoning section
   */
  AnalyticalReasoning: 2,

  /**
   * @type {number}
   * @description The Reading Comprehension section
   */
  ReadingComprehension: 3,

  /**
   * @type {number}
   * @description The Writing section
   */
  WritingSample: 4,
};

export { SectionType };
