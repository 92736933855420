import PropTypes from 'prop-types';
import textModel from './textModel';

/**
 * The stem model
 * @class
 */
class StemModel extends textModel {
  constructor(props) {
    super(props);
    /**
     * @property {string} text The stem text
     */
    this.text = null;

    /**
     * @property {string} stemId The stem id
     */
    this.stemId = null;
  }
}

StemModel.propTypes = {
  text: PropTypes.string.isRequired,
  stemId: PropTypes.string.isRequired,
};

export default StemModel;
