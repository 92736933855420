/*
 * UserProfile Messages
 *
 * This contains all the text for the UserProfile component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.UserProfile';

export default defineMessages({
  welcomeLable: {
    id: `${scope}.welcomeLable`,
    defaultMessage: 'Welcome, ',
  },
  myAccount: {
    id: `${scope}.myAccount`,
    defaultMessage: 'My Account',
  },
  privacy: {
    id: `${scope}.privacy`,
    defaultMessage: 'Privacy',
  },
  upgradeToPremium: {
    id: `${scope}.upgradeToPremium`,
    defaultMessage: 'Upgrade to LawHub&nbsp;Advantage',
  },
  signOut: {
    id: `${scope}.signOut`,
    defaultMessage: 'Sign-Out',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: ' Privacy Policy',
  },
  upgradeToPremiumHeader: {
    id: `${scope}.upgradeToPremiumHeader`,
    defaultMessage: 'Upgrade To LSAT Prep Plus',
  },
  legalNotice: {
    id: `${scope}.legalNotice`,
    defaultMessage: 'Legal Notice',
  },
  legalNoticeHeader: {
    id: `${scope}.legalNoticeHeader`,
    defaultMessage: 'LEGAL NOTICE',
  },
  aboutLawHub: {
    id: `${scope}.aboutLawHub`,
    defaultMessage: 'About LawHub',
  },
  aboutLawHubHeader: {
    id: `${scope}.aboutLawHubHeader`,
    defaultMessage: 'About LawHub',
  },
  redeemOffer: {
    id: `${scope}.redeemOffer`,
    defaultMessage: 'Redeem Offer',
  },
  termsOfUse: {
    id: `${scope}.termsOfUse`,
    defaultMessage: 'Terms of Use',
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: 'Terms and Conditions',
  },
  accessibilityPolicy: {
    id: `${scope}.accessibilityPolicy`,
    defaultMessage: 'Accessibility Policy',
  },
  cookiePolicy: {
    id: `${scope}.cookiePolicy`,
    defaultMessage: 'Cookie Policy',
  },
});
