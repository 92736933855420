// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./../../images/hamburgerIcon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../images/hamburger_asymm_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".hamburgerButton {\n  display: none;\n  background: transparent;\n  background-repeat: no-repeat;\n}\n\n.hamburgerImgButton {\n  background-size: 22px 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  padding: 5px 12px 12px 12px;\n  margin: 6px 15px 2px 12px;\n}\n\n.right-side-nav .homePage {\n  background-size: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  margin-left: 1.5rem;\n  margin-right: unset;\n  padding: 5px 12px 12px 12px;\n}\n.homePage {\n  background-size: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  margin-left: unset;\n  margin-right: 0.75rem;\n  padding: 5px 12px 12px 12px;\n}\n\n@media (max-width: 1170px) {\n  .hamburgerButton {\n    display: block;\n  }\n\n  .hamburgerImgButton {\n    display: inline;\n  }\n  #Certhamburger {\n    padding: 20px 12px 12px 12px;\n  }\n}\n\n@media (max-width: 735px) {\n  .hamburgerImgButton {\n    background-size: 18px 15px;\n  }\n}\n", ""]);
// Exports
module.exports = exports;
