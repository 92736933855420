/**
 * Line height enumeration that describes the line height
 * @category enumerations
 * @enum {number}
 */
const LineHeight = {
  /**
   * @type {number}
   * @description The default line height (1.15 multiple)
   */
  Default: 1,
  /**
   * @type {number}
   * @description The medium line height (1.7 multiple)
   */
  Medium: 2,
  /**
   * @type {number}
   * @description The large line height (2.2 multiple)
   */
  Large: 3,
  /**
   * @type {object}
   * @description The properties describing the values
   */
  properties: {
    1: { name: 'Default', value: 1, h: 1.15 },
    2: { name: 'Medium', value: 2, h: 1.7 },
    3: { name: 'Large', value: 3, h: 2.2 },
  },
};

export { LineHeight };
