/* eslint-disable no-param-reassign */
import { ExamMode } from 'enumerations/ExamMode';
import {
  FLEX_CONGRATS_PATH,
  EXAM_COMPLETE_PATH,
  CERTIFYING_STATEMENT_PATH,
  WRITING_ERROR_SCREEN_PATH,
} from '../containers/App/constants';
import {
  TITLE_TEST_FLEX_EXAM,
  TITLE_LSAT_WRITING_EXAM,
} from '../containers/MasterPage/constants';
import { EXAM_START_PATH } from '../containers/TestSelectPage/constants';
import { config } from '../config';
import { parseDateString } from './timeHelper';
import { persistenceSet } from './persistence/persistenceManagementHelper';
import * as logHelper from './logHelper';
import { LogLevelType } from '../enumerations/LogLevelType';
import { ModuleType } from '../enumerations/ModuleType';

function isNullOrUndefined(val) {
  return val === null || val === undefined;
}

export function getOptions(moduleOrInstance) {
  const module = moduleOrInstance?.module
    ? moduleOrInstance.module
    : moduleOrInstance;
  return module?.options ? module.options : {};
}

export function getSectionOptions(section) {
  return section?.options ? section.options : {};
}

function compareDates(date1, date2) {
  const yearDiff = date1.getFullYear() - date2.getFullYear();
  if (yearDiff !== 0) {
    return yearDiff;
  }
  const monthDiff = date1.getMonth() - date2.getMonth();
  if (monthDiff !== 0) {
    return monthDiff;
  }
  const dayDiff = date1.getDate() - date2.getDate();
  return dayDiff;
}

export function examIsPending(modules) {
  let i = 0;
  let isExamPending = false;
  for (i = 0; i < modules.length; i += 1) {
    const m = modules[i];
    if (!isNullOrUndefined(m.isReadyToStart)) {
      isExamPending = !m.isReadyToStart;
    } else {
      const startDate = parseDateString(m.startDate);
      const now = new Date();
      const compare = compareDates(startDate, now);
      if (compare > 0) {
        // start date is in the future
        isExamPending = true;
      }
    }
  }
  return isExamPending;
}

export function getCurrentExam(modules) {
  let selectedExam = null;

  if (modules === undefined || modules === null || modules.length === 0) {
    /* eslint-disable-next-line no-console */
    console.error('modules is undefined, or null, or non-null and empty');
    return null;
  }

  const exams = modules
    .filter(
      exam => exam.isReadyToStart !== undefined && exam.isReadyToStart === true,
    )
    .sort((a, b) => (a.startDate > b.startDate ? -1 : 1));

  logHelper.log(LogLevelType.Info, 'exams', exams);

  if (exams.length > 0) {
    [selectedExam] = exams;
  }

  logHelper.log(LogLevelType.Info, 'selected exam', selectedExam);

  return selectedExam;
}

export function getExamCompletePath(examType, testInstanceId) {
  const type = !isNullOrUndefined(examType) ? examType.toLowerCase() : 'flex';
  if (type === 'flex') {
    return `${FLEX_CONGRATS_PATH}?id=${testInstanceId}`;
  }
  return `${EXAM_COMPLETE_PATH}/${examType}/${testInstanceId}`;
}

export function setUpExamOptions(
  module,
  userDetails,
  examType,
  testInstanceId,
  moduleType,
  defaultExamSettings,
) {
  if (!examType) {
    setUpPrepExam(module, moduleType);
  } else if (
    !isNullOrUndefined(examType) &&
    (examType.toLowerCase() === 'writing' ||
      examType.toLowerCase() === 'writingwithperspectives')
  ) {
    setUpWritingExam(module, userDetails, defaultExamSettings, examType);
  } else {
    setUpFlexExam(module, userDetails, defaultExamSettings);
  }
  persistenceSet(`${testInstanceId}_options`, module.options);
}

function getMatchingInfo(sectionIndex, sectionInfos) {
  if (!sectionInfos) return null;
  return sectionInfos.filter(
    info => parseInt(info.sectionLocation, 10) === sectionIndex,
  )[0];
}

function applyExamSettings(module, defaultExamSettings, examType) {
  const { sections } = module;
  if (!defaultExamSettings) return;
  let prevSection = null;
  sections.forEach((section, index) => {
    if (!section.options) {
      section.options = {};
    }
    const breakInfo = getMatchingInfo(
      index,
      defaultExamSettings.breakSectionInfos,
    );
    if (breakInfo) {
      section.options.breakType = 'break';
      section.options.breakTime = parseInt(breakInfo.sectionTime, 10);
    } else {
      const intermissionInfo = getMatchingInfo(
        index,
        defaultExamSettings.intermissionSectionInfos,
      );
      if (intermissionInfo) {
        section.options.breakType = 'intermission';
        section.options.breakTime = parseInt(intermissionInfo.sectionTime, 10);
      }
    }
    if (prevSection) {
      prevSection.options.nextBreakType = section.options.breakType;
      prevSection.options.nextBreakTime = section.options.breakTime;
    }
    const sectionInfo = getMatchingInfo(
      index + 1,
      defaultExamSettings.examSectionInfos,
    );
    if (sectionInfo) {
      section.options.sectionTime = parseInt(sectionInfo.sectionTime, 10);
    }
    // for perspectives writing, we have to manually grab the two times. Since we just have one "section"
    if (examType?.toLowerCase() === 'writingwithperspectives') {
      const responseSectionInfo = getMatchingInfo(
        2,
        defaultExamSettings.examSectionInfos,
      );
      if (responseSectionInfo && sectionInfo) {
        const totalAnalysisTime = parseInt(sectionInfo.sectionTime, 10);
        section.totalAnalysisSectionTime = totalAnalysisTime * 1000 * 60;
        const totalResponseTime = parseInt(responseSectionInfo.sectionTime, 10);
        section.totalSectionTime = totalResponseTime * 1000 * 60;

        section.options.analysisSectionTime = parseInt(
          sectionInfo.sectionTime,
          10,
        );
        section.options.sectionTime = parseInt(
          responseSectionInfo.sectionTime,
          10,
        );
      }
    }
    prevSection = section;
  });
}

function setUpPrepExam(module, moduleType) {
  module.moduleType = moduleType;
  if (!module.options) {
    module.options = {};
  }
  module.options.moduleType = moduleType;
  const isWriting =
    moduleType === ModuleType.Writing ||
    moduleType === ModuleType.WritingWithPerspectives;
  module.sections.forEach(section => {
    section.options = {
      showCompleteSectionButton:
        !isWriting && module.examMode === ExamMode.SelfPaced,
      showPauseSectionButton:
        !isWriting || module.examMode === ExamMode.SelfPaced,
    };
  });

  if (
    moduleType === ModuleType.Writing ||
    moduleType === ModuleType.WritingWithPerspectives
  ) {
    module.options.hasNotes = true;
    module.options.hasEarlySubmit = true;
    module.sections.forEach(section => {
      section.options.sectionType = moduleType;
      section.options.hideQuestionCount = true;
    });
  }
}

function setUpFlexExam(module, userDetails, defaultExamSettings) {
  module.examMode = ExamMode.ExamTime;
  let hasStartStopBreaks = false;
  let sectionBreakTime = config.REACT_APP_FLEX_SECTION_BREAK_TIME;
  applyExamSettings(module, defaultExamSettings);
  if (!isNullOrUndefined(userDetails.examSettings)) {
    if (userDetails.examSettings.hasStartStopBreaks) {
      hasStartStopBreaks = true;
    }
    if (!isNullOrUndefined(userDetails.examSettings.accomSectionTime)) {
      module.customTime = userDetails.examSettings.accomSectionTime;
    }
    if (!isNullOrUndefined(userDetails.examSettings.accomSectionBreakTime)) {
      sectionBreakTime = userDetails.examSettings.accomSectionBreakTime;
    }

    module.allowSubmitEarly = userDetails.examSettings.allowSubmitEarly;
  }
  module.options = getFlexOptions(sectionBreakTime, hasStartStopBreaks);
  module.sections.forEach(section => {
    if (!section.options) {
      section.options = {};
    }
    section.options.showCompleteSectionButton = false;
    section.options.showPauseSectionButton = false;
  });
}

export function getModuleOptions(examType = 'flex') {
  if (
    examType.toLowerCase() === 'writing' ||
    examType.toLowerCase() === 'writingwithperspectives'
  ) {
    return getWritingOptions();
  }
  if (examType.toLowerCase() === 'flexstart') {
    return {
      ...getFlexOptions(),
      connectivityErrorMessage:
        'Please do not close the browser or navigate from this screen.',
    };
  }
  return getFlexOptions();
}

function getFlexOptions(sectionBreakTime, hasStartStopBreaks) {
  return {
    showFullScreen: true,
    isLsatFlex: true,
    sectionBreakTime,
    hasStartStopBreaks,
    showApolloPause: true,
    disableBackButton: true,
    allowSectionSelect: false,
    showCorrectAnswers: false,
    exitOnConnectionFailure: true,
    isRealExamMode: true,
    testCompletePath: '/exam/examTestComplete?id=',
    title: TITLE_TEST_FLEX_EXAM,
    showReconnectFullScreen: false,
    reconnectedPath: CERTIFYING_STATEMENT_PATH,
    connectivityErrorMessage:
      'Your test has been paused. Please do not close the browser or navigate from this screen.',
    connectivityRetryMessage:
      'Please check your system settings and try to reestablish a connection.When you have a connection select Retry to return to the Start screen.',
    accomodatedReturnToStartPage: true,
    startPath: CERTIFYING_STATEMENT_PATH,
    allowBeginButton: false,
  };
}

function setUpWritingExam(module, userDetails, defaultExamSettings, examType) {
  module.examMode = ExamMode.ExamTime;
  let hasStartStopBreaks = false;
  const sectionBreakTime = config.REACT_APP_BAR_SECTION_BREAK_TIME;

  module.sections.forEach(section => {
    section.options = {
      sectionType: examType, // either 'Writing' or 'WritingWithPerspectives'
      hideQuestionCount: true,
      showCompleteSectionButton: false,
      showPauseSectionButton: false,
    };
  });

  applyExamSettings(module, defaultExamSettings, examType);

  if (!isNullOrUndefined(userDetails.examSettings)) {
    hasStartStopBreaks = userDetails.examSettings.hasStartStopBreaksWriting;
    if (
      !isNullOrUndefined(userDetails.examSettings.accomSectionTimeWriting) &&
      module.sections &&
      module.sections.length > 0
    ) {
      module.customTime = userDetails.examSettings.accomSectionTimeWriting;
      module.sections[0].options.sectionTime =
        userDetails.examSettings.accomSectionTimeWriting;
    }
  }
  module.options = getWritingOptions(
    sectionBreakTime,
    hasStartStopBreaks,
    examType,
  );
}

function getWritingOptions(sectionBreakTime, hasStartStopBreaks, examType) {
  return {
    showFullScreen: true,
    sectionBreakTime,
    hasStartStopBreaks,
    showApolloPause: true,
    disableBackButton: true,
    allowSectionSelect: false,
    showCorrectAnswers: false,
    exitOnConnectionFailure: true,
    isRealExamMode: true,
    testCompletePath: `/exam/examConfirmComplete/${examType}?id=`,
    title: TITLE_LSAT_WRITING_EXAM,
    showReconnectFullScreen: true,
    // eslint-disable-next-line no-template-curly-in-string
    reconnectedPath: WRITING_ERROR_SCREEN_PATH,
    connectivityErrorMessage:
      'Your test has been paused. Please do not close the browser or navigate from this screen.',
    connectivityRetryMessage:
      'Please check your system settings and try to reestablish a connection.When you have a connection select Retry to return to the Start screen.',
    accomodatedReturnToStartPage: false,
    startPath: `${EXAM_START_PATH}/${examType}`,
    allowBeginButton: false,
    hasEarlySubmit: true,
    hasNotes: true,
  };
}

export function shouldHidePasswordInput(settings) {
  if (!settings || !settings.authenticationType) {
    return false;
  }

  const authTypes = {
    PASSWORD: 'PASSWORD',
  };

  return !(settings.authenticationType.toUpperCase() === authTypes.PASSWORD);
}

export function isPrometrics(settings) {
  if (!settings || !settings.authenticationType) {
    return false;
  }

  const authTypes = {
    PROMETRICS: 'PROMETRICS',
  };

  return settings.authenticationType.toUpperCase() === authTypes.PROMETRICS;
}
