/* eslint-disable react/button-has-type */
/**
 * Duplicate page
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalBody } from 'reactstrap';
import { AccessibleModal } from '../../components/AccessibleModal';
import messages from './messages';
import './index.css';

export default function DuplicatePage() {
  return (
    <div>
      <AccessibleModal isOpen className="duplicateModePopUp">
        <ModalBody className="duplicateModeModalBody">
          <div className="popUpHeader">
            <div className="popUpMessage">
              <button style={{ display: 'none' }} aria-label="DummyClose" />
              <div className="valigndiv">
                <h1>
                  <FormattedMessage {...messages.message} />
                </h1>
              </div>
            </div>
          </div>
        </ModalBody>
      </AccessibleModal>
    </div>
  );
}
