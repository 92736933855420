/*
 *
 * MasterPage constants
 *
 */
import {
  TUTORIALS_PATH,
  LIBRARY_PATH,
  DRILLSET_PATH,
  ADDITIONAL_PRACTICE_TEST_PATH,
} from '../App/constants';
import { config } from '../../config';

export const GET_ISBREADCRUMBSEANBLED = 'true';
export const UPDATE_BREADCRUMB = 'Update_BreadCrumb';
export const UPDATE_WINDOW_SIZE = 'Update_Window_Size';
export const DISPLAY_SIDENAV_BAR = 'Display_SideNav_Bar';
export const PRACTISE_TEST_TEXT = 'Practice Test';
export const TITLE_TEST_PREP_HOME = 'Welcome to LawHub™ - Home';
export const TITLE_TEST_PREP_LIBRARY = 'LawHub™ Library - LSAT Test Prep';
export const TITLE_TEST_PREP_PLUS_HISTORY =
  'LawHub™ Library - LSAT Test Prep, History';
export const TITLE_TEST_FLEX_EXAM = `LSAC ${config.REACT_APP_LSAT_NAME}`;
export const TITLE_LSAT_WRITING_EXAM = 'LawHub™ - LSAT Writing';
export const TITLE_TEST_PREP_TUTORIAL = 'LawHub™ - Test Prep Tutorials';
export const TITLE_SUBSCRIBE_LH_PP = 'LawHub™ - Subscribe to LawHub™ Today';
export const TITLE_BUY_PP = 'LawHub™ - Upgrade to Prep Plus Subscription';
export const TITLE_BUY_LSS = 'LawHub™ - Subscribe to Law School Success';
export const TITLE_UPGRADE_FROM_LSS =
  'LawHub™ - Upgrade Your LawHub™ Subscription Today';
export const TITLE_ACCOUNT_SETTINGS = 'LawHub™ - My Account';
export const TITLE_PREP_PLUS_CONFIRM = 'LSAC PLUS Program';
export const TITLE_PREP_PLUS_WELCOME = 'LSAC PREP PLUS PROGRAM';
export const PLUS_PROGRAM_ID = 'LSACPlus';
export const PLUS_PROGRAM_SETTING_NAME = 'registrationComplete';
export const TITLE_LEARNING_LIBRARY_PAGE = 'LawHub™ - Learning Library';
export const TITLE_EVENTS_PAGE = 'Events — LawHub™ Learning Library';
export const TITLE_COURSES_PAGE = 'Courses — LawHub™ Learning Library';
export const TITLE_TOOLS_PAGE = 'LawHub™ Tools — Get Ready for Law School';
export const TITLE_MARKETPLACE_PAGE = 'LawHub™ - Marketplace';
export const TITLE_RECOMMENDED_READING_PAGE = 'LawHub™ - Recommended Reading';
export const TITLE_APPLICATION_STATUS_ONLINE_PAGE =
  'LawHub™ - Application Status Tracker';

export const BREADCRUMB = {
  settings: {
    isVisible: false,
    isShowAnswerVisible: false,
    isCompleteSectionVisible: false,
    displaySideNavBar: false,
  },
};
export const WINDOW_SIZE = {
  windowSize: { width: window.innerWidth, height: window.innerHeight },
};

export const DEFAULT_BREADCRUMB_ITEMS = [
  { pathName: 'Library', location: LIBRARY_PATH },
];
export const DEFAULT_DRILLSET_BREADCRUMB_ITEMS = [
  { pathName: 'Library', location: DRILLSET_PATH },
];
export const DEFAULT_ADDITIONAL_PRACTICE_SET_BREADCRUMB_ITEMS = [
  { pathName: 'Library', location: ADDITIONAL_PRACTICE_TEST_PATH },
];
export const DEFAULT_TUTORIAL_BREADCRUMB_ITEMS = [
  { pathName: 'Tutorials', location: TUTORIALS_PATH },
];
export const COMPLETE_PAGE_REDIRECTION_TIME = 30000;
