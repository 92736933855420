export const validCardTypes = {
  event: 'event',
  course: 'course',
  podcast: 'podcast',
  toolsExplore: 'toolsExplore',
  marketplaceForRecommendReading: 'marketplaceForRecommendReading',
  marketplaceForBooks: 'marketplaceForBooks',
  marketplaceForStudyAids: 'marketplaceForStudyAids',
  marketplaceForServices: 'marketplaceForServices',
};
