/* eslint-disable no-param-reassign */
/**
 *
 * option state helper
 *
 */

import { OptionState } from 'enumerations/OptionState';

/**
 * Section page
 *
 * @param  {SectionModel} section The section model
 * @param  {number} optionIndex The option index
 * @param  {string} optionType The bubble type (A or E)
 *
 */
export function setOptionState(section, optionIndex, optionType) {
  const updatedItem = section.items[section.currentItemIndex];
  const clearAnswerSelection = (item, index) => {
    if (item.answer === String.fromCharCode(65 + index)) {
      item.answer = '';
      item.isAnswered = false;
    }
  };
  for (let i = 0; i < updatedItem.answerOptions.length; i += 1) {
    if (optionType === 'A') {
      if (
        optionIndex === i &&
        updatedItem.answerOptions[i].optionState !== OptionState.Selected
      ) {
        updatedItem.answerOptions[i].optionState = OptionState.Selected;
        updatedItem.answer = String.fromCharCode(65 + i);
        updatedItem.isAnswered = true;
      } else if (
        updatedItem.answerOptions[i].optionState !== OptionState.Eliminated
      ) {
        updatedItem.answerOptions[i].optionState = OptionState.Unselected;
        clearAnswerSelection(updatedItem, i);
      }
    } else if (optionType === 'E') {
      if (optionIndex === i) {
        updatedItem.answerOptions[i].optionState =
          updatedItem.answerOptions[i].optionState !== OptionState.Eliminated
            ? OptionState.Eliminated
            : OptionState.Unselected;
        clearAnswerSelection(updatedItem, i);
      }
    }
  }
  return updatedItem;
}
