/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/**
 *
 * history helper
 *
 */
import { isNullOrUndefined } from 'util';

/**
 * Retrieve a history model array from historyList
 *
 * @param  {array} modules The user history data
 *
 */
export function toHistoryModel(historyList) {
  let historyModels = null;

  if (!isNullOrUndefined(historyList) && historyList.length > 0) {
    historyModels = historyList.map(item => ({
      ...item,
      startDate: isNullOrUndefined(item.startDate)
        ? null
        : new Date(item.startDate),
      isCollapsed: true,
    }));
  }
  return historyModels;
}

/**
 * Retrieve a module from historyList
 *
 * @param  {array} modules The user history data
 *
 */
export function moduleFromHistory(historyList) {
  let moduleObj = {};

  if (!isNullOrUndefined(historyList) && historyList.length > 0) {
    const firstHistoryRecord = historyList[0];
    moduleObj = {
      moduleId: firstHistoryRecord.moduleId,
      moduleName: firstHistoryRecord.moduleName,
      description: firstHistoryRecord.moduleDescription,
      releaseDate: new Date(firstHistoryRecord.moduleAdminDate),
      moduleType: firstHistoryRecord.moduleType,
      options: { moduleType: firstHistoryRecord.moduleType },
    };
  }
  return moduleObj;
}

/**
 *
 * @param {string} variable The query string variable name
 */
export function getQueryVariable(variable) {
  const query = decodeURI(window.location.search.substring(1));
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (pair[0]?.toLowerCase() === variable?.toLowerCase()) {
      return pair[1];
    }
  }
  return null;
}

/**
 * Returns a new URLSearchParams object with lowercase keys based on
 * the current window's location search parameters.
 * If the 'url' parameter is present and mutated by the AppInit component,
 * the URL is reconstructed and the search parameters are extracted.
 * @returns {URLSearchParams} A new URLSearchParams object with lowercase keys.
 */
export const getLowerCaseSearchParams = () => {
  // Get the URL parameters from the current window's location
  let searchString = window.location.search;
  const params = new URLSearchParams(searchString);
  // Extract the 'url' param value with the original encoded URI fragment
  // if the AppInit component has mutated it
  const URL_KEY = 'url';
  const UriFrag = params.get(URL_KEY);
  // If urlVal exists, create new URL and update the searchString
  if (UriFrag) {
    const newURL = new URL(UriFrag, window.location.origin);
    searchString = newURL.searchParams;
  }
  // Create a new URLSearchParams object based on the searchString with
  // lowercase keys
  const lcSearchParams = new URLSearchParams(
    [...new URLSearchParams(searchString)].map(([key, value]) => [
      key.toLowerCase(),
      value,
    ]),
  );
  return lcSearchParams;
};

/**
 * Given a key, retrieve a search pararm from the search string
 * and, if it exists, store it in sessionStorage.
 * @param {string} key
 */
export function getAndPersistSearchParamForSession(key) {
  const lcSearchParams = getLowerCaseSearchParams();
  const val = lcSearchParams.get(key);
  // Persist in session storage
  if (val) {
    window.sessionStorage.setItem(key, val);
  }
}
