export const GET_USER_DETAILS = 'Aetha/TestSelectPage/GetUserDetails';
export const GET_USER_DETAILS_ERROR =
  'Aetha/TestSelectPage/GetUserDetailsError';
export const GET_USER_DETAILS_SUCCESS =
  'Aetha/TestSelectPage/GetUserDetailsSuccess';
export const PRO_METRICS_PARAM = {
  ELIGID: 'elidId',
  CONFIRMATIONID: 'confirmation',
  SITE_CODE: 'site',
  SCHEDULE_DATE_TIME: 'startTime',
};
export const PRO_METRICS_LAUNCH_START = {
  ELIGID: 'eligibilityId',
  CONFIRMATIONID: 'confirmationId',
  SITE_CODE: 'siteCode',
  SCHEDULE_DATE_TIME: 'scheduledStartTime',
};
