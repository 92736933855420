import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the testSelectPage state domain
 */

const selectTestSelectPageDomain = state =>
  state.testSelectPage || initialState;

/**
 * Default selector used by TestSelectPage
 */

const makeSelectTestSelectPage = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate,
  );

/**
 * initialize saga selector
 */
const initSagaLibrarySelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.initSagaLibrary,
  );

const itemsSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.items,
  );

/**
 * retrieved library selector
 */
const retrievedLibrarySelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.retrievedLibrary,
  );

/**
 * retrieved flex exams selector
 */
const retrievedFlexExamsSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.retrievedFlexExams,
  );

const testInstanceStatusSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.testInstanceStatus,
  );

const testInstanceStatusErrorSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.testInstanceStatusError,
  );

const testInstanceSummarySelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.testSummary,
  );

const testInstanceRemoteStatusSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.testInstanceRemoteStatus,
  );

/**
 * flex exams selector
 */
const flexExamsSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.flexExams,
  );

const flexExamErrorSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.flexExamError,
  );

/**
 * error selector
 */
const errorSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.error,
  );

/**
 * Password hash selector
 */
const passwordHashSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.passwordHash,
  );

/**
 * Password hash selector
 */
const sequenceHashSelector = () =>
  createSelector(
    selectTestSelectPageDomain,
    substate => substate.sequenceHash,
  );

export default makeSelectTestSelectPage;
export {
  passwordHashSelector,
  sequenceHashSelector,
  selectTestSelectPageDomain,
  errorSelector,
  initSagaLibrarySelector,
  retrievedLibrarySelector,
  itemsSelector,
  flexExamsSelector,
  flexExamErrorSelector,
  retrievedFlexExamsSelector,
  testInstanceStatusSelector,
  testInstanceStatusErrorSelector,
  testInstanceSummarySelector,
  testInstanceRemoteStatusSelector,
};
