/*
 *
 * AthenaSession actions
 *
 */

import {
  INIT_LIBRARY_SAGA,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  PUT_IS_APP_READY,
  PUT_USER_SETTINGS,
  PUT_USER_SETTINGS_SUCCESS,
  PUT_USER_SETTINGS_ERROR,
  PUT_CONFIRM_USER,
  PUT_CONFIRM_USER_SUCCESS,
  PUT_CONFIRM_USER_ERROR,
  SET_USER_EMAIL,
  SET_USER_EMAIL_SUCCESS,
  PUT_USER_EMAIL,
  SET_USER_EMAIL_VERIFICATION,
  PUT_USER_EMAIL_ERROR,
  SET_COMPLETE_EMAIL_UPDATE,
  SET_USER_EMAIL_ERROR,
  POST_LOGIN_COUNT,
  POST_LOGIN_COUNT_ERROR,
  POST_LOGIN_COUNT_SUCCESS,
  GET_OFFER_CODE,
  GET_OFFER_CODE_SUCCESS,
  GET_OFFER_CODE_ERROR,
  CLEAR_OFFER_CODE_ERROR,
  CLEAR_OFFER_DETAILS,
  POST_USER_OFFER,
  POST_USER_OFFER_SUCCESS,
  POST_USER_OFFER_ERROR,
  CLEAR_USER_OFFER,
  SET_PLUS_REGISTERED_FLAG,
  SET_PLUS_REGISTERED_FLAG_SUCCESS,
  SET_PLUS_REGISTERED_FLAG_ERROR,
  GET_TAX_INFO,
  CLEAR_PAYMENT_INFO,
  SET_PAYMENT_IN_PROGRESS,
  GET_TAX_INFO_SUCCESS,
  GET_TAX_INFO_ERROR,
  GET_PRODUCT_INFO,
  GET_PRODUCT_INFO_SUCCESS,
  GET_PRODUCT_INFO_ERROR,
  POST_PRODUCT_PAYMENT,
  POST_PRODUCT_PAYMENT_SUCCESS,
  POST_PRODUCT_PAYMENT_ERROR,
  POST_PREP_PLUS_PAYMENT,
  POST_PREP_PLUS_PAYMENT_SUCCESS,
  POST_PREP_PLUS_PAYMENT_ERROR,
  SHOW_CALL_TO_ACTION,
  FETCH_KEY,
  FETCH_KEY_SUCCESS,
  FETCH_KEY_ERROR,
  RESET_VENDOR_INFORMATION,
  SET_ERROR,
  GET_CONFIG_DETAILS,
  GET_CONFIG_DETAILS_SUCCESS,
  GET_CONFIG_DETAILS_ERROR,
  GET_CONFIG_HASH,
} from './constants';

/**
 * Initialize saga library
 *
 */
export function initializeSagaLibraryAction() {
  return {
    type: INIT_LIBRARY_SAGA,
  };
}

/**
 * Get user details  action
 *
 */
export function getUserDetails() {
  return {
    type: GET_USER_DETAILS,
  };
}

/**
 * Get user details success action
 *
 * @param {object} userModel
 */
export function getUserDetailsSuccessAction(userModel) {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    userModel,
  };
}

/**
 * Notify when the user details retrieval encounters an error
 *
 * @param  {object} error The error object
 *
 */
export function getUserDetailsErrorAction(error) {
  return {
    type: GET_USER_DETAILS_ERROR,
    error,
  };
}

/**
 * Indicate whether the app is done initializing. Mater Page uses this value to show the initializing page or not
 *
 * @param  {boolean} isAppReady set to true when the app is done initializing
 *
 */
export function putIsAppReadyAction(isAppReady = true) {
  return {
    type: PUT_IS_APP_READY,
    isAppReady,
  };
}

/**
 *
 * Save user test preparation settings
 * @param {srting} userId
 * @param {User settings model} userSettings
 */
export function putUserSettingsAction(data) {
  return {
    type: PUT_USER_SETTINGS,
    data,
  };
}

/**
 * save user settings action
 *
 * @param {User settings model} userSettings
 */
export function putUserSettingsSuccessAction(userSettings) {
  return {
    type: PUT_USER_SETTINGS_SUCCESS,
    userSettings,
  };
}

/**
 * Notify when user settings error
 *
 * @param  {object} error The error object
 *
 */
export function putUserSettingsErrorAction(error) {
  return {
    type: PUT_USER_SETTINGS_ERROR,
    error,
  };
}

/**
 *
 * To accept or link user
 *
 */
export function putConfirmUserAction(data) {
  return {
    type: PUT_CONFIRM_USER,
    data,
  };
}

/**
 * Confirm user success action
 *
 * @param {VendorModel} Vendor information
 */
export function putConfirmUserSuccessAction(vendorData) {
  return {
    type: PUT_CONFIRM_USER_SUCCESS,
    vendorData,
  };
}

/**
 * Notify when user settings error
 *
 * @param  {object} error The error object
 *
 */
export function putConfirmUserErrorAction(error) {
  return {
    type: PUT_CONFIRM_USER_ERROR,
    error,
  };
}

/**
 * Notify when user email details sending
 *
 * @param  {object} userEmailDetails The user email Detail object
 *
 */
export function putEmailAction(userEmailDetails) {
  return {
    type: PUT_USER_EMAIL,
    userEmailDetails,
  };
}
/**
 * Notify when user email success
 *
 * @param  {object} userEmailDetails gets userNewEmailDetails
 *
 */
export function setEmailVerificationAction(userNewEmailDetails) {
  return {
    type: SET_USER_EMAIL_VERIFICATION,
    userNewEmailDetails,
  };
}

/**
 * Notify when user email detail failure
 *
 * @param  {object} error The error object
 *
 */
export function putEmailErrorAction(error) {
  return {
    type: PUT_USER_EMAIL_ERROR,
    error,
  };
}

/**
 * Notify when user email details set
 *
 * @param  {object} userEmailDetails The user email Detail object
 *
 */
export function setUserEmailAction(userEmailDetails) {
  return {
    type: SET_USER_EMAIL,
    userEmailDetails,
  };
}

/**
 * Notify when user email success
 *
 * @param  {object} userEmailDetails The user email Detail object
 *
 */
export function setUserEmailSuccessAction(userEmailDetails) {
  return {
    type: SET_USER_EMAIL_SUCCESS,
    userEmailDetails,
  };
}

/**
 * Notify when user email completed
 *
 *
 */
export function setUserEmailUpdateComplete() {
  return {
    type: SET_COMPLETE_EMAIL_UPDATE,
  };
}

/**
 * Notify when user email detail failure
 *
 * @param  {object} error The error object
 *
 */
export function setUserEmailErrorAction(error) {
  return {
    type: SET_USER_EMAIL_ERROR,
    error,
  };
}

/**
 * Post the login count once per session
 *
 */
export function postLoginCountAction() {
  return {
    type: POST_LOGIN_COUNT,
  };
}

/**
 * Post the login count success action
 *
 * @param  {object} loginRecord The login record with timestamps and count
 *
 */
export function postLoginCountSuccessAction(loginRecord) {
  return {
    type: POST_LOGIN_COUNT_SUCCESS,
    loginRecord,
  };
}

/**
 * Post the login count error action
 *
 * @param  {object} error The error object
 *
 */
export function postLoginCountErrorAction(error) {
  return {
    type: POST_LOGIN_COUNT_ERROR,
    error,
  };
}

/**
 * Validate offer code
 *
 */
export function getOfferCodeAction(offerCode) {
  return {
    type: GET_OFFER_CODE,
    offerCode,
  };
}

/**
 * Validate offer code success
 *
 */
export function getOfferCodeSuccessAction(offer) {
  return {
    type: GET_OFFER_CODE_SUCCESS,
    offer,
  };
}

/**
 * Validate offer code error
 *
 */
export function getOfferCodeErrorAction(error) {
  return {
    type: GET_OFFER_CODE_ERROR,
    error,
  };
}

/**
 * Reset error code status
 *
 */
export function clearOfferCodeErrorAction() {
  return {
    type: CLEAR_OFFER_CODE_ERROR,
  };
}

/**
 * Reset offer details
 *
 */
export function clearOfferDetailsAction() {
  return {
    type: CLEAR_OFFER_DETAILS,
  };
}

/**
 * Post user offer
 *
 */
export function postUserOfferAction(offerCode) {
  return {
    type: POST_USER_OFFER,
    offerCode,
  };
}

/**
 * Post user offer
 *
 */
export function postUserOfferSuccessAction(data) {
  return {
    type: POST_USER_OFFER_SUCCESS,
    data,
  };
}

/**
 * Post user offer
 *
 */
export function postUserOfferErrorAction(error) {
  return {
    type: POST_USER_OFFER_ERROR,
    error,
  };
}

/**
 * Clear redeemed offer
 *
 */
export function clearRedeemedOfferAction() {
  return {
    type: CLEAR_USER_OFFER,
  };
}

/**
 * Set PLUS registered flag
 *
 *
 */
export function setPlusRegisteredFlag() {
  return {
    type: SET_PLUS_REGISTERED_FLAG,
  };
}

/**
 * PLUS registered flag set successfully
 *
 *
 */
export function setPlusRegisteredFlagSuccessAction() {
  return {
    type: SET_PLUS_REGISTERED_FLAG_SUCCESS,
  };
}

/**
 * PLUS registered flag not set successfully
 *
 *
 */
export function setPlusRegisteredFlagErrorAction(error) {
  return {
    type: SET_PLUS_REGISTERED_FLAG_ERROR,
    error,
  };
}

export function getTaxInfoAction(taxRequest) {
  return {
    type: GET_TAX_INFO,
    taxRequest,
  };
}

export function clearPaymentInfoAction() {
  return {
    type: CLEAR_PAYMENT_INFO,
  };
}

export function setPaymentInProgressAction(isPaymentInProgress) {
  return {
    type: SET_PAYMENT_IN_PROGRESS,
    isPaymentInProgress,
  };
}

export function getConfigDetailsAction() {
  return {
    type: GET_CONFIG_DETAILS,
  };
}

export function getConfigDetailsSuccessAction(
  configDetails,
  isFromCache = false,
) {
  return {
    type: GET_CONFIG_DETAILS_SUCCESS,
    configDetails,
    isFromCache,
  };
}

export function getConfigDetailsErrorAction(error) {
  return {
    type: GET_CONFIG_DETAILS_ERROR,
    error,
  };
}

export function getConfigHashAction() {
  return {
    type: GET_CONFIG_HASH,
  };
}

export function getTaxInfoSuccessAction(taxInfo) {
  return {
    type: GET_TAX_INFO_SUCCESS,
    taxInfo,
  };
}

export function getTaxInfoErrorAction(error) {
  return {
    type: GET_TAX_INFO_ERROR,
    error,
  };
}

export function getProductInfoAction(productId) {
  return {
    type: GET_PRODUCT_INFO,
    productId,
  };
}
export function getProductInfoSucessAction(productData) {
  return {
    type: GET_PRODUCT_INFO_SUCCESS,
    productData,
  };
}
export function getProductInfoErrorAction(error) {
  return {
    type: GET_PRODUCT_INFO_ERROR,
    error,
  };
}

export function postProductPayment(paymentInfo) {
  return {
    type: POST_PRODUCT_PAYMENT,
    paymentInfo,
  };
}

export function postProductPaymentSuccess(paymentDetails) {
  return {
    type: POST_PRODUCT_PAYMENT_SUCCESS,
    paymentDetails,
  };
}

export function postProductPaymentError(error) {
  return {
    type: POST_PRODUCT_PAYMENT_ERROR,
    error,
  };
}

export function postPrepPlusPayment(paymentInfo) {
  return {
    type: POST_PREP_PLUS_PAYMENT,
    paymentInfo,
  };
}

export function postPrepPlusPaymentSuccess(paymentDetails) {
  return {
    type: POST_PREP_PLUS_PAYMENT_SUCCESS,
    paymentDetails,
  };
}

export function postPrepPlusPaymentError(error) {
  return {
    type: POST_PREP_PLUS_PAYMENT_ERROR,
    error,
  };
}

export function showCallToActionAction(key, isVisible) {
  return {
    type: SHOW_CALL_TO_ACTION,
    key,
    isVisible,
  };
}

export function fetchKeyAction() {
  return {
    type: FETCH_KEY,
  };
}

export function fetchKeySuccessAction() {
  return {
    type: FETCH_KEY_SUCCESS,
  };
}

export function fetchKeyErrorAction(error) {
  return {
    type: FETCH_KEY_ERROR,
    error,
  };
}

/**
 * Reset vendor information
 *
 */
export function resetVendorDataAction() {
  return {
    type: RESET_VENDOR_INFORMATION,
  };
}

/**
 * Notify when user settings error
 *
 * @param  {object} error The error object
 *
 */
export function setErrorAction(error) {
  return {
    type: SET_ERROR,
    error,
  };
}
