import PropTypes from 'prop-types';

/**
 * User settings model
 * @class
 */
class UserSettingsModel {
  /**
   *
   * @param {bool} isExtraTime Is extra time
   * @param {string} extraTimeAmount Extra time amount
   * @param {number} minutesPerSection Minutes per section
   * @param {bool} isUsingFindTextFeature Enable Ctrl-F - Find Text feature
   * @param {number} minutesPerAnalysis Minutes for Writing Analysis
   */
  constructor(
    isExtraTime,
    extraTimeAmount,
    minutesPerSection,
    isUsingFindTextFeature,
    minutesPerAnalysis,
  ) {
    this.isExtraTime = isExtraTime;
    this.extraTimeAmount = extraTimeAmount;
    this.minutesPerSection = minutesPerSection;
    this.isUsingFindTextFeature = isUsingFindTextFeature;
    this.minutesPerAnalysis = minutesPerAnalysis;
  }
}

UserSettingsModel.propTypes = {
  isExtraTime: PropTypes.bool.isRequired,
  extraTimeAmount: PropTypes.string.isRequired,
  minutesPerSection: PropTypes.number.isRequired,
  isUsingFindTextFeature: PropTypes.bool.isRequired,
  minutesPerAnalysis: PropTypes.number,
};

export default UserSettingsModel;
