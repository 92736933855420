/**
 *
 * Event Details helper
 *
 */
import isNullOrUndefined from '../utils/isNullOrUndefined';
import { config } from '../config';
import { getNumberOfDaysBetweenDates } from '../containers/TestSelectPage/testselect.util';

/**
 * Returns sorted application list based on last updated parameter
 * @param  {object} school list
 * @returns {object} sorted based on last updated
 */
export function getSortedApplicationList(schoolList) {
  if (schoolList?.schools?.length > 0) {
    schoolList?.schools?.map(school => {
      school?.applications?.sort(
        (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated),
      );
    });
    schoolList?.schools?.sort(
      (sh1, sh2) =>
        new Date(sh2.applications[0]?.lastUpdated) -
        new Date(sh1.applications[0]?.lastUpdated),
    );
    return schoolList;
  }
  return {};
}

/**
 * Returns text with capitalized first letter
 * @param  {string} text which needs to be transformed
 * @returns {string} text with capitalized first letter
 */
export function getCapitalizedString(inputStr) {
  if (!isNullOrUndefined(inputStr)) {
    return inputStr.charAt(0).toUpperCase() + inputStr.slice(1);
  }

  return '';
}

/**
 * Returns object with subscription details
 * @param subscriptionDetails the subscription information of user
 */
export function getActiveSubscription(subscriptionDetails) {
  const hasPrepPlusSubscription =
    !isNullOrUndefined(subscriptionDetails) &&
    subscriptionDetails.subscriptionType ===
      config.REACT_APP_USER_SUBSCRIPTION_PREP_PLUS;

  if (subscriptionDetails?.expirationDate) {
    const remainingDays = getNumberOfDaysBetweenDates(
      new Date(subscriptionDetails.expirationDate),
      new Date(),
    );

    return {
      hasPrepPlusSubscription,
      isExpiring:
        hasPrepPlusSubscription &&
        remainingDays <= config.REACT_APP_AST_PREPPLUS_EXPIRATION_LIMIT,
      remainingDays,
    };
  }
  return { hasPrepPlusSubscription, isExpiring: false };
}
