import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the Application status online Page state domain
 */

const applicationStatusOnlineSelector = state =>
  state.applicationDetails || initialState;

/**
 * makeUserApplicationStatusOnlinePageSelector
 */

const makeUserApplicationStatusOnlinePageSelector = () =>
  createSelector(
    applicationStatusOnlineSelector,
    substate => substate,
  );

/**
 * applicationStatusOnlinePageErrorSelector
 */
const applicationStatusOnlineErrorSelector = () =>
  createSelector(
    applicationStatusOnlineSelector,
    substate => substate.application_status_details_error,
  );

/**
 * postNotificationErrorSelector
 */
const postNotificationErrorSelector = () =>
  createSelector(
    applicationStatusOnlineSelector,
    substate => substate.post_notification_info_error,
  );

/**
 * makeUserGetNotificationStatusSelector
 */

const makeUserGetNotificationStatusSelector = () =>
  createSelector(
    applicationStatusOnlineSelector,
    substate => substate,
  );

/**
 * getNotificationErrorSelector
 */
const getNotificationErrorSelector = () =>
  createSelector(
    applicationStatusOnlineSelector,
    substate => substate.post_notification_info_error,
  );

export default applicationStatusOnlineSelector;
export {
  makeUserApplicationStatusOnlinePageSelector,
  applicationStatusOnlineErrorSelector,
  postNotificationErrorSelector,
  makeUserGetNotificationStatusSelector,
  getNotificationErrorSelector,
};
