/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable global-require */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { intlShape, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { Media, ModalBody, ModalHeader } from 'reactstrap';
import { isNullOrUndefined } from 'util';
import history from 'utils/history';
import { UPGRADE_PATH, ACCOUNT_SETTINGS_PATH } from 'containers/App/constants';
import ExternalLinkIcon from 'images/external_link_outward_rebrand.svg';
import {
  userDetailsSelector,
  userSubscriptionSelector,
  offerSelector,
  offerErrorSelector,
  redeemedOfferSelector,
} from 'domains/session/selectors';
import {
  clearPaymentInfoAction,
  getOfferCodeAction,
  clearOfferCodeErrorAction,
  clearOfferDetailsAction,
  postUserOfferAction,
  clearRedeemedOfferAction,
} from 'domains/session/actions';
import { AccessibleModal } from '../AccessibleModal';
import LegalNotice from '../LegalNotice';
import AboutLSAC from '../AboutLSAC';
import RedeemOffer from '../RedeemOffer';
import messages from './messages';
import './index.css';

import { config } from '../../config';
import DropdownMenu, { DropdownItem } from '../Menu';
import { logout } from '../../authProvider';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.intl = this.props.intl;
    this.toggle = this.toggle.bind(this);
    this.togglePrivacyPolicy = this.togglePrivacyPolicy.bind(this);
    this.displayAccountSettingsPage = this.displayAccountSettingsPage.bind(
      this,
    );
    this.displayUpgradeToPremium = this.displayUpgradeToPremium.bind(this);
    this.dispalyLegalNotice = this.dispalyLegalNotice.bind(this);
    this.displayAboutLSAC = this.displayAboutLSAC.bind(this);
    this.displayRedeemOffer = this.displayRedeemOffer.bind(this);
    this.menuTimeOutId = null;

    this.state = {
      isOpen: false,
      isAccountSettingsOpen: false,
      isPrivacyModalOpen: false,
      isChangingEmailAddress: false,
      isLegalNoticeOpen: false,
      isAboutLSACOpen: false,
      isRedeemOfferOpen: false,
    };
    this.userName = '';
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  onMenuBlur = () => {
    this.menuTimeOutId = setTimeout(() => {
      this.setState({ isOpen: false });
    }, 200);
  };

  onMenuFocus = () => {
    clearTimeout(this.menuTimeOutId);
  };

  togglePrivacyPolicy() {
    this.setState(prevState => ({
      isPrivacyModalOpen: !prevState.isPrivacyModalOpen,
    }));
  }

  displayAccountSettingsPage() {
    history.push(ACCOUNT_SETTINGS_PATH, {
      from: history.location.pathname,
    });
    this.setState({
      isOpen: false,
    });
    // Manually switch the focus from the UserProfile to the top
    // level div with id="app"
    const appDiv = document.getElementById('app');
    if (appDiv && appDiv.focus) {
      appDiv.focus();
    }
    // Close SidebarNav
    if (this.props.onDisplaySideNavBar) {
      this.props.onDisplaySideNavBar(false);
    }
  }

  dispalyLegalNotice() {
    this.setState(
      prevState => ({
        isLegalNoticeOpen: !prevState.isLegalNoticeOpen,
        isOpen: false,
      }),
      () => {
        if (!this.state.isLegalNoticeOpen) {
          const welcomeButton = document.getElementById('welcome-menu');
          if (welcomeButton && welcomeButton.focus) {
            welcomeButton.focus();
          }
        }
      },
    );
  }

  displayAboutLSAC() {
    this.setState(
      prevState => ({
        isAboutLSACOpen: !prevState.isAboutLSACOpen,
        isOpen: false,
      }),
      () => {
        if (!this.state.isAboutLSACOpen) {
          const welcomeButton = document.getElementById('welcome-menu');
          if (welcomeButton && welcomeButton.focus) {
            welcomeButton.focus();
          }
        }
      },
    );
  }

  displayRedeemOffer() {
    this.setState(
      prevState => ({
        isRedeemOfferOpen: !prevState.isRedeemOfferOpen,
        isOpen: false,
      }),
      () => {
        if (!this.state.isRedeemOfferOpen) {
          const welcomeButton = document.getElementById('welcome-menu');
          if (welcomeButton && welcomeButton.focus) {
            welcomeButton.focus();
          }
        }
      },
    );
  }

  displayUpgradeToPremium() {
    this.props.onClearPaymentInfo();
    if (!isNullOrUndefined(this.props.onDisplaySideNavBar)) {
      this.props.onDisplaySideNavBar(false);
    }
    history.push(UPGRADE_PATH);
  }

  logout = () => {
    logout();
  };

  render() {
    const isExamRoute = window.location.pathname.indexOf('/exam') >= 0;
    if (!isNullOrUndefined(this.props.userDetails)) {
      this.userName = this.props.userDetails.firstName;
    }

    const hasPrepPlusSubscription =
      !isNullOrUndefined(this.props.subscriptionDetails) &&
      this.props.subscriptionDetails.subscriptionType ===
        config.REACT_APP_USER_SUBSCRIPTION_PREP_PLUS &&
      config.REACT_APP_ALLOW_BUY_WHEN_ALREADY_PURCHASED.toLowerCase() !==
        'true';

    const legalNoticeCloseButton = (
      <button
        className="as_closeModal"
        onClick={this.dispalyLegalNotice}
        aria-label="close"
      >
        <Media src={require('images/btn-close_white.png')} alt="" />
      </button>
    );

    const aboutLSACCloseButton = (
      <button
        className="as_closeModal"
        onClick={this.displayAboutLSAC}
        aria-label="close"
      >
        <Media src={require('images/btn-close_white.png')} alt="" />
      </button>
    );

    const redeemOfferCloseButton = (
      <button
        className="as_closeModal"
        onClick={this.displayRedeemOffer}
        aria-label="close"
      >
        <Media src={require('images/btn-close_white.png')} alt="" />
      </button>
    );

    return (
      <React.Fragment>
        <div id="userProfile">
          <DropdownMenu
            title={`${this.props.intl.formatMessage({
              ...messages.welcomeLable,
            })} ${this.userName}`}
            accessibleName="welcome-menu"
            toggle={this.toggle}
            isOpen={this.state.isOpen}
            onBlurHandler={this.onMenuBlur}
            onFocusHandler={this.onMenuFocus}
            label={`${this.props.intl.formatMessage({
              ...messages.welcomeLable,
            })} ${this.userName}`}
          >
            {!isExamRoute && (
              <>
                <DropdownItem
                  onClick={this.displayAccountSettingsPage}
                  role="link"
                >
                  {`${this.props.intl.formatMessage({
                    ...messages.myAccount,
                  })}`}
                </DropdownItem>

                {!hasPrepPlusSubscription && (
                  <DropdownItem
                    className="updateToPremium"
                    onClick={this.displayUpgradeToPremium}
                    role="button"
                  >
                    {config.REACT_APP_BRAND_UPDATE === 'true' ? (
                      <FormattedHTMLMessage {...messages.upgradeToPremium} />
                    ) : (
                      'Upgrade to Prep Plus'
                    )}
                  </DropdownItem>
                )}
                <DropdownItem onClick={this.displayRedeemOffer} role="button">
                  {`${this.props.intl.formatMessage({
                    ...messages.redeemOffer,
                  })}`}
                </DropdownItem>
                <DropdownItem onClick={this.displayAboutLSAC} role="button">
                  <span id="about-lh-btn" className="menu_displayAboutLSAC">
                    {`${this.props.intl.formatMessage({
                      ...messages.aboutLawHub,
                    })}`}
                  </span>
                </DropdownItem>
                {config.REACT_APP_BRAND_UPDATE === 'true' && (
                  <DropdownItem
                    role="link"
                    tooltip="will open in a new browser window."
                    onClick={() => {
                      const targetHref =
                        'https://www.lawhub.org/privacy-policy';
                      window.open(targetHref);
                    }}
                  >
                    <img
                      src={ExternalLinkIcon}
                      className="external-link-icon"
                      alt=""
                    />
                    {this.props.intl.formatMessage({
                      ...messages.privacyPolicy,
                    })}
                  </DropdownItem>
                )}
                {config.REACT_APP_BRAND_UPDATE === 'true' && (
                  <DropdownItem
                    role="link"
                    tooltip="will open in a new browser window."
                    onClick={() => {
                      const targetHref =
                        'https://www.lawhub.org/accessibility-statement';
                      window.open(targetHref);
                    }}
                  >
                    <img
                      src={ExternalLinkIcon}
                      className="external-link-icon"
                      alt=""
                    />
                    {this.props.intl.formatMessage({
                      ...messages.accessibilityPolicy,
                    })}
                  </DropdownItem>
                )}
                {config.REACT_APP_BRAND_UPDATE === 'true' && (
                  <DropdownItem
                    role="link"
                    tooltip="will open in a new browser window."
                    onClick={() => {
                      const targetHref = 'https://www.lawhub.org/cookie-policy';
                      window.open(targetHref);
                    }}
                  >
                    <img
                      src={ExternalLinkIcon}
                      className="external-link-icon"
                      alt=""
                    />
                    {this.props.intl.formatMessage({
                      ...messages.cookiePolicy,
                    })}
                  </DropdownItem>
                )}
                {config.REACT_APP_BRAND_UPDATE !== 'true' && (
                  <DropdownItem onClick={this.dispalyLegalNotice} role="button">
                    {`${this.props.intl.formatMessage({
                      ...messages.legalNotice,
                    })}`}
                  </DropdownItem>
                )}
                <DropdownItem
                  role="link"
                  tooltip="will open in a new browser window."
                  onClick={() => {
                    const targetHref =
                      config.REACT_APP_BRAND_UPDATE === 'true'
                        ? 'https://www.lawhub.org/terms-and-conditions'
                        : 'https://www.lsac.org/about/lsac-policies/privacy-policy';
                    window.open(targetHref);
                  }}
                >
                  {config.REACT_APP_BRAND_UPDATE === 'true' ? (
                    <img
                      src={ExternalLinkIcon}
                      className="external-link-icon"
                      alt=""
                    />
                  ) : (
                    <i className="fas fa-external-link-alt tab-ic" />
                  )}
                  {config.REACT_APP_BRAND_UPDATE === 'true'
                    ? `${this.props.intl.formatMessage({
                        ...messages.termsAndConditions,
                      })}`
                    : `${this.props.intl.formatMessage({
                        ...messages.termsOfUse,
                      })}`}{' '}
                </DropdownItem>
              </>
            )}
            <DropdownItem
              className="signOut"
              onClick={this.logout}
              role="button"
            >
              {`${this.props.intl.formatMessage({
                ...messages.signOut,
              })}`}
            </DropdownItem>
          </DropdownMenu>

          {/* Legal notice modal start */}
          <AccessibleModal
            isOpen={this.state.isLegalNoticeOpen}
            size="lg"
            id="us_LegalNotice"
            aria-modal="true"
            role="dialog"
            className={`${
              config.REACT_APP_BRAND_UPDATE === 'true' ? '' : 'old-branding'
            }`}
          >
            <ModalHeader
              toggle={this.dispalyLegalNotice}
              aria-label={`${this.props.intl.formatMessage({
                ...messages.legalNotice,
              })}`}
              close={legalNoticeCloseButton}
              tag="h1"
            >
              {`${this.props.intl.formatMessage({
                ...messages.legalNoticeHeader,
              })}`}
            </ModalHeader>
            <ModalBody>
              <LegalNotice />
            </ModalBody>
          </AccessibleModal>
          {/* Legal notice modal end */}

          {/* About LSAC modal start */}
          <AccessibleModal
            isOpen={this.state.isAboutLSACOpen}
            size="lg"
            id="us_AboutLSAC"
            aria-modal="true"
            role="dialog"
            className={`${
              config.REACT_APP_BRAND_UPDATE === 'true' ? '' : 'old-branding'
            }`}
          >
            <ModalHeader
              toggle={this.displayAboutLSAC}
              id="lawhub-title"
              aria-label={`${this.props.intl.formatMessage({
                ...messages.aboutLawHub,
              })}`}
              close={aboutLSACCloseButton}
              tag="h1"
            >
              {`${this.props.intl.formatMessage({
                ...messages.aboutLawHubHeader,
              })}`}
            </ModalHeader>
            <ModalBody>
              <AboutLSAC />
            </ModalBody>
          </AccessibleModal>
          {/* About LSAC modal end */}

          {/* Redeem Offer modal start */}
          <AccessibleModal
            isOpen={this.state.isRedeemOfferOpen}
            size="lg"
            id="us_RedeemOffer"
            aria-modal="true"
            role="dialog"
            className={`${
              config.REACT_APP_BRAND_UPDATE === 'true' ? '' : 'old-branding'
            }`}
          >
            <ModalHeader
              toggle={this.displayRedeemOffer}
              aria-label={`${this.props.intl.formatMessage({
                ...messages.redeemOffer,
              })}`}
              close={redeemOfferCloseButton}
              tag="h1"
            >
              {`${this.props.intl.formatMessage({
                ...messages.redeemOffer,
              })}`}
            </ModalHeader>
            <ModalBody>
              <RedeemOffer
                intl={this.props.intl}
                offerDetails={this.props.offerDetails}
                offerError={this.props.offerError}
                redeemedOffer={this.props.redeemedOffer}
                onValidateOffer={this.props.onValidateOffer}
                onClearOfferCodeError={this.props.onClearOfferCodeError}
                onClearOfferDetails={this.props.onClearOfferDetails}
                onRedeemOffer={this.props.onRedeemOffer}
                onClearRedeemedOffer={this.props.onClearRedeemedOffer}
                onCloseModal={this.displayRedeemOffer}
              />
            </ModalBody>
          </AccessibleModal>
          {/* About LSAC modal end */}
        </div>
        <div className="resp_userProfile">
          <div className="wide-nav-divider" />

          <div className="welcome-label">
            {`${this.props.intl.formatMessage({
              ...messages.welcomeLable,
            })} ${this.userName}`}
          </div>
          <div
            role="link"
            onClick={this.displayAccountSettingsPage}
            onKeyPress={this.displayAccountSettingsPage}
            tabIndex="0"
            aria-label={`${this.props.intl.formatMessage({
              ...messages.myAccount,
            })}`}
          >
            {`${this.props.intl.formatMessage({
              ...messages.myAccount,
            })}`}
          </div>
          {!hasPrepPlusSubscription && (
            <div
              role="button"
              onClick={this.displayUpgradeToPremium}
              onKeyPress={this.displayUpgradeToPremium}
              tabIndex="0"
              aria-label={`${
                config.REACT_APP_BRAND_UPDATE === 'true'
                  ? 'Upgrade to LawHub Advantage'
                  : 'Upgrade to Prep Plus'
              }`}
            >
              {config.REACT_APP_BRAND_UPDATE === 'true' ? (
                <FormattedHTMLMessage {...messages.upgradeToPremium} />
              ) : (
                'Upgrade to Prep Plus'
              )}
            </div>
          )}
          <div
            role="button"
            className="menu_redeemOffer"
            onClick={this.displayRedeemOffer}
            onKeyPress={this.displayRedeemOffer}
            tabIndex="0"
            aria-label={`${this.props.intl.formatMessage({
              ...messages.redeemOffer,
            })}`}
          >
            {`${this.props.intl.formatMessage({
              ...messages.redeemOffer,
            })}`}{' '}
          </div>
          <div
            role="button"
            id="about-lh-btn"
            className="menu_displayAboutLSAC"
            onClick={this.displayAboutLSAC}
            onKeyPress={this.displayAboutLSAC}
            tabIndex="0"
            aria-label={`${this.props.intl.formatMessage({
              ...messages.aboutLawHub,
            })}`}
          >
            {`${this.props.intl.formatMessage({
              ...messages.aboutLawHub,
            })}`}{' '}
          </div>
          {config.REACT_APP_BRAND_UPDATE === 'true' && (
            <div
              title="will open in a new browser window."
              data-testid="menuItem.privacyPolicy"
              role="link"
              onClick={() => {
                const targetHref = 'https://www.lawhub.org/privacy-policy';
                window.open(targetHref);
                // Close sidebarNav
                this.props.onDisplaySideNavBar(false);
              }}
              onKeyPress={() => {
                const targetHref = 'https://www.lawhub.org/privacy-policy';
                window.open(targetHref);
                // Close sidebarNav
                this.props.onDisplaySideNavBar(false);
              }}
              tabIndex="0"
              aria-label={this.props.intl.formatMessage({
                ...messages.privacyPolicy,
              })}
            >
              <img
                src={ExternalLinkIcon}
                className="external-link-icon"
                alt=""
              />{' '}
              {this.props.intl.formatMessage({
                ...messages.privacyPolicy,
              })}
            </div>
          )}
          {config.REACT_APP_BRAND_UPDATE !== 'true' && (
            <div
              role="button"
              onClick={this.dispalyLegalNotice}
              onKeyPress={this.dispalyLegalNotice}
              tabIndex="0"
              aria-label={`${this.props.intl.formatMessage({
                ...messages.legalNotice,
              })}`}
            >
              {`${this.props.intl.formatMessage({
                ...messages.legalNotice,
              })}`}{' '}
            </div>
          )}
          {config.REACT_APP_BRAND_UPDATE === 'true' && (
            <div
              title="will open in a new browser window."
              data-testid="menuItem.accessibilityPolicy"
              role="link"
              onClick={() => {
                const targetHref =
                  'https://www.lawhub.org/accessibility-statement';
                window.open(targetHref);
                // Close sidebarNav
                this.props.onDisplaySideNavBar(false);
              }}
              onKeyPress={() => {
                const targetHref =
                  'https://www.lawhub.org/accessibility-statement';
                window.open(targetHref);
                // Close sidebarNav
                this.props.onDisplaySideNavBar(false);
              }}
              tabIndex="0"
              aria-label={this.props.intl.formatMessage({
                ...messages.accessibilityPolicy,
              })}
            >
              <img
                src={ExternalLinkIcon}
                className="external-link-icon"
                alt=""
              />{' '}
              {this.props.intl.formatMessage({
                ...messages.accessibilityPolicy,
              })}
            </div>
          )}
          {config.REACT_APP_BRAND_UPDATE === 'true' && (
            <div
              title="will open in a new browser window."
              data-testid="menuItem.cookiePolicy"
              role="link"
              onClick={() => {
                const targetHref = 'https://www.lawhub.org/cookie-policy';
                window.open(targetHref);
                // Close sidebarNav
                this.props.onDisplaySideNavBar(false);
              }}
              onKeyPress={() => {
                const targetHref = 'https://www.lawhub.org/cookie-policy';
                window.open(targetHref);
                // Close sidebarNav
                this.props.onDisplaySideNavBar(false);
              }}
              tabIndex="0"
              aria-label={this.props.intl.formatMessage({
                ...messages.cookiePolicy,
              })}
            >
              <img
                src={ExternalLinkIcon}
                className="external-link-icon"
                alt=""
              />{' '}
              {this.props.intl.formatMessage({
                ...messages.cookiePolicy,
              })}
            </div>
          )}
          <div
            title="will open in a new browser window."
            data-testid="menuItem.termsofuse"
            role="link"
            onClick={() => {
              const targetHref =
                config.REACT_APP_BRAND_UPDATE === 'true'
                  ? 'https://www.lawhub.org/terms-and-conditions'
                  : 'https://www.lsac.org/about/lsac-policies/privacy-policy';
              window.open(targetHref);
              // Close sidebarNav
              this.props.onDisplaySideNavBar(false);
            }}
            onKeyPress={() => {
              const targetHref =
                config.REACT_APP_BRAND_UPDATE === 'true'
                  ? 'https://www.lawhub.org/terms-and-conditions'
                  : 'https://www.lsac.org/about/lsac-policies/privacy-policy';
              window.open(targetHref);
              // Close sidebarNav
              this.props.onDisplaySideNavBar(false);
            }}
            tabIndex="0"
            aria-label={
              config.REACT_APP_BRAND_UPDATE === 'true'
                ? `${this.props.intl.formatMessage({
                    ...messages.termsAndConditions,
                  })}`
                : `${this.props.intl.formatMessage({
                    ...messages.termsOfUse,
                  })}`
            }
          >
            {config.REACT_APP_BRAND_UPDATE === 'true' ? (
              <img
                src={ExternalLinkIcon}
                className="external-link-icon"
                alt=""
              />
            ) : (
              <i className="fas fa-external-link-alt" />
            )}{' '}
            {config.REACT_APP_BRAND_UPDATE === 'true'
              ? `${this.props.intl.formatMessage({
                  ...messages.termsAndConditions,
                })}`
              : `${this.props.intl.formatMessage({
                  ...messages.termsOfUse,
                })}`}
          </div>
        </div>
        <div className="resp_userProfile">
          <div className="wide-nav-divider" />

          <div
            role="button"
            onClick={this.logout}
            onKeyPress={this.logout}
            className="respSingOut"
            tabIndex="0"
            aria-label={`${this.props.intl.formatMessage({
              ...messages.signOut,
            })}`}
          >
            {`${this.props.intl.formatMessage({
              ...messages.signOut,
            })}`}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  intl: intlShape.isRequired,
  userDetails: PropTypes.object,
  subscriptionDetails: PropTypes.object,
  taxInfo: PropTypes.object,
  paymentDetails: PropTypes.object,
  offerDetails: PropTypes.object,
  offerError: PropTypes.object,
  redeemedOffer: PropTypes.object,
  onValidateOffer: PropTypes.func,
  onClearOfferCodeError: PropTypes.func,
  onClearOfferDetails: PropTypes.func,
  onClearPaymentInfo: PropTypes.func,
  onDisplaySideNavBar: PropTypes.func,
  onRedeemOffer: PropTypes.func,
  onClearRedeemedOffer: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  userDetails: userDetailsSelector(),
  subscriptionDetails: userSubscriptionSelector(),
  offerDetails: offerSelector(),
  offerError: offerErrorSelector(),
  redeemedOffer: redeemedOfferSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onValidateOffer: data => dispatch(getOfferCodeAction(data)),
    onClearOfferCodeError: () => dispatch(clearOfferCodeErrorAction()),
    onClearOfferDetails: () => dispatch(clearOfferDetailsAction()),
    onClearPaymentInfo: () => dispatch(clearPaymentInfoAction()),
    onRedeemOffer: data => dispatch(postUserOfferAction(data)),
    onClearRedeemedOffer: () => dispatch(clearRedeemedOfferAction()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(UserProfile));
