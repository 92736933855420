/**
 * Enum for exam mode
 * @category enumerations
 * @enum {number}
 */
const ExamMode = {
  /**
   * @type {number}
   * @description The user is in self-paced mode which means they can complete their current section and start the next section
   * whenever they choose to
   */
  SelfPaced: 0,
  /**
   * @type {number}
   * @description The user is in exam timed mode which means they can not complete their current section on their own and
   * have to wait until the section time expires before moving on to the next section
   */
  ExamTime: 1,
};

export { ExamMode };
