/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import root apps
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import '!file-loader?name=[name].[ext]!./images/apple-touch-icon.png';
import '!file-loader?name=[name].[ext]!./images/safari-pinned-tab.svg';
import 'file-loader?name=.htaccess!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ErrorBoundary from './components/ErrorBoundary';
import AppErrorUI from './components/ErrorBoundary/AppErrorUI';
import LoginRedirect from './components/LoginRedirect';
import MasterPage from './containers/MasterPage/index';
import configureStore from './configureStore';
// Import i18n messages
import { translationMessages } from './i18n';
import { config } from './config';
import './global.css';
import { initializeGoogleAnalytics } from './helpers/googleAnalyticsHelper';
import * as logHelper from './helpers/logHelper';
import { LogLevelType } from './enumerations/LogLevelType';
import {
  checkStartup,
  checkStartupValue,
  startupName,
  stopListener,
} from './helpers/startupHelper';
import * as serviceWorker from './serviceWorker';
import { reactPlugin } from './containers/TestSelectPage/testselect.util';
import {
  getAccessToken,
  getRemoteAccountSession,
  getUser,
} from './authProvider';
import { LOGIN_PATH } from './containers/App/constants';
import { isPageReloaded } from './helpers/browserHelper';
import { removeItemFromLocal } from './helpers/localStorageHelper';

serviceWorker.register();

const SIGNUP_PAGE_PATH = '/signup';
const isStaticSitePath = history.location.pathname === SIGNUP_PAGE_PATH;

checkStartup().then(async () => {
  // Run redirect login flow on login initiation / silent auth check
  if (getUser() == null && !isStaticSitePath) await getAccessToken();

  // If not logged in, check B2C session cookie with popup
  await getRemoteAccountSession();

  const value = localStorage.getItem(startupName);
  logHelper.log(LogLevelType.Debug, 'Config', JSON.stringify(config));
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: config.REACT_APP_INSTRUMENTATION_KEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
    },
  });

  if (isPageReloaded()) {
    // Remove test instance from local storage when portal is launched/reloaded
    removeItemFromLocal('testInstanceInLocal');
  }

  appInsights.loadAppInsights();
  appInsights.trackPageView();

  logHelper.initialize(appInsights);
  initializeGoogleAnalytics();

  // Create redux store with history
  const initialState = {};
  const store = configureStore(initialState, history);
  const MOUNT_NODE = document.getElementById('app');
  let testPageExist = true;
  const renderContent = messages => {
    if (checkStartupValue(value)) {
      testPageExist = false;
    } else {
      stopListener();
    }
    ReactDOM.render(
      <ErrorBoundary ui={<AppErrorUI isComingFromErrorBoundry />}>
        <Provider store={store}>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <Switch>
                {config.REACT_APP_ENABLE_UNAUTHED_ACCESS === 'true' && (
                  <Route exact path={LOGIN_PATH}>
                    <LoginRedirect />
                  </Route>
                )}
                <Route exact path={SIGNUP_PAGE_PATH}>
                  <App testPageCheck={testPageExist} />
                </Route>
                <Route>
                  <MasterPage history={history}>
                    <App testPageCheck={testPageExist} />
                  </MasterPage>
                </Route>
              </Switch>
            </ConnectedRouter>
          </LanguageProvider>
        </Provider>
      </ErrorBoundary>,
      MOUNT_NODE,
    );
  };

  const render = messages => {
    renderContent(messages);
  };

  if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['./i18n', 'containers/App'], () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
      render(translationMessages);
    });
  }

  // Chunked polyfill for browsers without Intl support
  if (!window.Intl) {
    new Promise(resolve => {
      resolve(import('intl'));
    })
      .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
      .then(() => render(translationMessages))
      .catch(err => {
        throw err;
      });
  } else {
    render(translationMessages);
  }

  // Install ServiceWorker and AppCache in the end since
  // it's not most important operation and if main code fails,
  // we do not want it installed
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line global-require
    const offline = require('offline-plugin/runtime');

    offline.install({
      onUpdating: () => {
        logHelper.log(LogLevelType.Debug, 'SW Event:', 'onUpdating');
      },
      onUpdateReady: () => {
        logHelper.log(LogLevelType.Debug, 'SW Event:', 'onUpdateReady');
        // Tells to new SW to take control immediately
        offline.applyUpdate();
      },
      onUpdated: () => {
        logHelper.log(LogLevelType.Debug, 'SW Event:', 'onUpdated');
        // // When using ReloadingLink it will auto-refresh on the next route
        // window.swUpdate = true;
      },
      onUpdateFailed: () => {
        logHelper.log(LogLevelType.Debug, 'SW Event:', 'onUpdateFailed');
      },
    });
  }
});
