import PropTypes from 'prop-types';

/**
 * Text model
 * @class
 */
class textModel {
  constructor() {
    /**
     * @property {string} text The text
     */
    this.text = null;

    /**
     * @property {array<string>} words An array of words
     */
    this.words = [];

    /**
     * @property {array} highlightList An array of highlights
     */
    this.highlightList = [];
  }
}

textModel.propTypes = {
  text: PropTypes.string.isRequired,
  words: PropTypes.array,
  highlightList: PropTypes.array,
};

export default textModel;
