/* eslint-disable no-param-reassign */
/*
 *
 * ApplicationStatusOnlinePage reducer
 *
 */
import produce from 'immer';
import {
  GET_USER_APPLICATION_STATUS,
  GET_USER_APPLICATION_STATUS_SUCCESS,
  GET_USER_APPLICATION_STATUS_ERROR,
  POST_APPLICATION_NOTIFICATION_STATUS_SUCCESS,
  POST_APPLICATION_NOTIFICATION_STATUS_ERROR,
  GET_APPLICATION_NOTIFICATION_STATUS_SUCCESS,
  GET_APPLICATION_NOTIFICATION_STATUS_ERROR,
} from './constants';

export const initialState = {
  items: null,
  postNotificationInfo: false,
  isChecked: false,
  isLoading: false,
  applicationLoaded: false,
  postApplicationLoaded: false,
  notificationLoaded: false,
  post_notification_info_error: null,
  get_notification_info_error: null,
};
/* eslint-disable default-case, no-param-reassign */
const applicationStatusOnlinePageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_USER_APPLICATION_STATUS:
        draft.isLoading = true;
        break;
      case GET_USER_APPLICATION_STATUS_SUCCESS:
        draft.items = action.applicationData;
        draft.applicationLoaded = true;
        draft.application_status_details_error = null;
        draft.isLoading = false;
        break;
      case GET_USER_APPLICATION_STATUS_ERROR:
        draft.application_status_details_error = action.error;
        draft.isLoading = false;
        break;
      case POST_APPLICATION_NOTIFICATION_STATUS_SUCCESS:
        draft.postNotificationInfo = action.isChecked;
        draft.postApplicationLoaded = true;
        draft.post_notification_info_error = null;
        break;
      case POST_APPLICATION_NOTIFICATION_STATUS_ERROR:
        draft.post_notification_info_error = action.error;
        break;
      case GET_APPLICATION_NOTIFICATION_STATUS_SUCCESS:
        draft.isChecked = action.notificationInfo;
        draft.notificationLoaded = true;
        draft.get_notification_info_error = null;
        break;
      case GET_APPLICATION_NOTIFICATION_STATUS_ERROR:
        draft.get_notification_info_error = action.error;
        break;
    }
  });

export default applicationStatusOnlinePageReducer;
