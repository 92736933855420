import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
/**
 * ToolbarButton - Buttons to be used only in Breadcrumbs area of the UI.
 * @param {string} props.children Sets the button's inner text.
 * @example <ToolbarButton>Show Answer</ToolbarButton>
 */
const ToolbarButton = props => (
  <button
    type="button"
    id={props.id}
    onClick={props.onToolBarButtonClick}
    className={`ToolbarButton ${props.className}`}
  >
    {props.children}
  </button>
 );

ToolbarButton.propTypes = {
  /** 
    The inner portion of the toolbar button
  */
  children: PropTypes.string.isRequired,
  /** 
    Function to be called when the toolbar button is clicked.
  */
  onToolBarButtonClick: PropTypes.func,
  /** 
    Html ID for the button
  */
  id: PropTypes.string,
/**
 * Additional class required for future dynamic custom button
 */
  className: PropTypes.string,
};

ToolbarButton.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  children: 'Toolbar Button',
  className: '',
};

export default ToolbarButton;
