/**
 *
 * SectionDetails
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';
import LineIcon from '../../images/line_icon.svg';
import './index.css';
import messages from './messages';

function SectionDetails({
  sectionName,
  isWritingSection,
  moduleId,
  totalAnswered,
  totalQuestions,
  isCompleted,
  totalCorrect,
  index,
  showCorrectAns,
  history,
  intl,
  currentActive,
  isExamMode,
  isRealExam,
  isPaused,
  pagePaused,
  allowSectionSelect,
  showTotalCorrect,
  historySectionButton,
}) {
  const navigationCompletePathForRealExam = `/${
    isCompleted ? 'exam/question' : 'exam/section'
  }/${moduleId}/${sectionName}`;

  const navigationCompletePathForPrepExam = `/${
    isCompleted ? 'question' : 'section'
  }/${moduleId}/${sectionName}`;

  const pausedNavigationPathForRealExam = `/${
    pagePaused !== null ? pagePaused : 'exam/section'
  }/${moduleId}/${sectionName}`;

  const pausedNavigationPathForPrepExam = `/${
    pagePaused !== null ? pagePaused : 'section'
  }/${moduleId}/${sectionName}`;

  const isButtonActive = currentActive && allowSectionSelect;

  let navigationPath;

  if (isRealExam) {
    navigationPath = isPaused
      ? pausedNavigationPathForRealExam
      : navigationCompletePathForRealExam;
  } else {
    navigationPath = isPaused
      ? pausedNavigationPathForPrepExam
      : navigationCompletePathForPrepExam;
  }
  return (
    <>
      {historySectionButton ? (
        <div className="section-details">
          <button
            className="section-btn"
            type="button"
            onClick={() => history.push(navigationPath)}
            disabled={!isButtonActive}
          >
            <span className="section-name">
              {sectionName}
              {!isWritingSection &&
                (!isExamMode && (totalAnswered > 0 || isCompleted) && (
                  <span aria-hidden="true">:</span>
                ))}
            </span>
            {!isWritingSection &&
              (!isExamMode && (totalAnswered > 0 || isCompleted) && (
                <>
                  <span aria-hidden="true">
                    <span>{totalAnswered}</span>/{totalQuestions}
                  </span>
                  <span className="hidden">
                    {` - ${totalAnswered} ${intl.formatMessage({
                      ...messages.answeredQuestions,
                    })} ${totalQuestions}  ${intl.formatMessage({
                      ...messages.questionsTotal,
                    })} - ${showCorrectAns && totalCorrect} correct`}
                  </span>
                </>
              ))}
            {isCompleted && !isWritingSection && (
              <>
                <img src={LineIcon} alt="" />
                <div aria-hidden="true">{showCorrectAns && totalCorrect}</div>
                <FontAwesome
                  name={`sectionCorrectAns__${index}`}
                  className="fa fa-check"
                  aria-hidden="true"
                />
                {showTotalCorrect && (
                  <span className="hidden">{`${totalCorrect} ${intl.formatMessage(
                    {
                      ...messages.questionsCorrect,
                    },
                  )}`}</span>
                )}
              </>
            )}
          </button>
        </div>
      ) : (
        <div>
          <button
            className="sectionDetailsButton sectionRoundButton"
            type="button"
            onClick={() => history.push(navigationPath)}
            disabled={!isButtonActive}
          >
            {sectionName}
          </button>
          <div>
            {!isWritingSection && (
              <div className="answerTextContainer">
                {!isExamMode && (
                  <div className="answeredText answeredFontColor">
                    {totalAnswered === 0 && !isCompleted ? (
                      <FormattedMessage {...messages.notStartedText} />
                    ) : (
                      <FormattedMessage {...messages.answeredText} />
                    )}
                  </div>
                )}
                {!isExamMode && (totalAnswered > 0 || isCompleted) && (
                  <div>
                    <strong aria-hidden="true">
                      <span className="answeredFontColor">{totalAnswered}</span>
                      /{totalQuestions}
                    </strong>
                    <span className="hidden">{`${sectionName} ${totalAnswered} ${intl.formatMessage(
                      {
                        ...messages.answeredQuestions,
                      },
                    )}${totalQuestions}${intl.formatMessage({
                      ...messages.questionsTotal,
                    })}`}</span>
                  </div>
                )}
              </div>
            )}
            {isCompleted && !isWritingSection && (
              <div>
                <div className="correctAnswersSection" aria-hidden="true">
                  {showCorrectAns && totalCorrect}
                  {showCorrectAns && <span className="pr-1" />}
                  <FontAwesome
                    name={`sectionCorrectAns__${index}`}
                    className="fa fa-check text-red"
                    aria-hidden="true"
                  />
                </div>
                {!showTotalCorrect && (
                  <span className="hidden">{`${sectionName} Complete`}</span>
                )}
                {showTotalCorrect && (
                  <span className="hidden">{`${sectionName} ${totalCorrect} ${intl.formatMessage(
                    {
                      ...messages.questionsCorrect,
                    },
                  )}`}</span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

SectionDetails.propTypes = {
  sectionName: PropTypes.string,
  isWritingSection: PropTypes.bool,
  moduleId: PropTypes.string,
  totalAnswered: PropTypes.number,
  totalQuestions: PropTypes.number,
  intl: intlShape.isRequired,
  isCompleted: PropTypes.bool,
  totalCorrect: PropTypes.number,
  index: PropTypes.number,
  showCorrectAns: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  currentActive: PropTypes.bool,
  isExamMode: PropTypes.bool,
  isRealExam: PropTypes.bool,
  isPaused: PropTypes.bool,
  pagePaused: PropTypes.string,
  allowSectionSelect: PropTypes.bool,
  showTotalCorrect: PropTypes.bool,
  historySectionButton: PropTypes.bool,
};

export default withRouter(injectIntl(SectionDetails));
