/* eslint-disable no-await-in-loop */
/**
 *
 * persistence indexed db helper
 *
 */
/* eslint-disable no-param-reassign */
import { isNullOrUndefined } from 'util';
import * as idb from 'idb-keyval';
import * as constants from 'containers/DirectionsPage/constants';
import { decryptObject, encryptObject } from '../../webCryptoHelper';
import { getUserId } from '../../userHelper';
import { getInitialRepo } from './persistenceState';
import { arrayUnique } from './arrayHelper';

let useMockedIdb = false;
let mockedIdb = {};

export function setMockIdb(mock) {
  useMockedIdb = true;
  mockedIdb = mock;
}

/* eslint-disable no-restricted-syntax */
function shallowCopySome(inObject, ...fieldsToInclude) {
  if (inObject === null) {
    return inObject;
  }
  const outObject = {};
  let key = null;
  for (key in inObject) {
    if (fieldsToInclude[0].indexOf(key) >= 0) {
      outObject[key] = inObject[key];
    }
  }
  return outObject;
}

function shallowCopyExcept(inObject, ...fieldsToExclude) {
  if (inObject === null) {
    return inObject;
  }
  const outObject = {};
  let key = null;
  for (key in inObject) {
    if (fieldsToExclude[0].indexOf(key) < 0) {
      outObject[key] = inObject[key];
    }
  }
  return outObject;
}

function getStateKey(testInstanceId) {
  return `p|${getUserId()}|${testInstanceId}|state`;
}

function getSectionStateKey(testInstanceId, formId) {
  return `p|${getUserId()}|${testInstanceId}|section|${formId}`;
}

function getSectionTimeStateKey(testInstanceId, formId) {
  return `p|${getUserId()}|${testInstanceId}|sectiontime|${formId}`;
}

async function saveSectionState(testInstanceId, section, changedValues) {
  if (isNullOrUndefined(changedValues)) return;
  const nonTimeChangedValues = shallowCopyExcept(changedValues, [
    constants.CHANGE_TIME_REMAINING,
    constants.CHANGE_ANALYSIS_TIME_REMAINING,
    constants.CHANGE_BREAK_TIME_REMAINING,
  ]);
  if (Object.keys(nonTimeChangedValues).length > 0) {
    const state = shallowCopyExcept(section, [
      'timeRemaining',
      'analysisTimeRemaining',
      'breakTimeRemaining',
    ]);
    const stateKey = getSectionStateKey(testInstanceId, section.formId);
    await saveEncrypted(stateKey, state);
  }
  if (
    changedValues[constants.CHANGE_TIME_REMAINING] !== undefined ||
    changedValues[constants.CHANGE_ANALYSIS_TIME_REMAINING] !== undefined ||
    changedValues[constants.CHANGE_BREAK_TIME_REMAINING] !== undefined ||
    changedValues['*'] !== undefined
  ) {
    const timeStateKey = getSectionTimeStateKey(testInstanceId, section.formId);
    const timeState = shallowCopySome(section, [
      'timeRemaining',
      'analysisTimeRemaining',
      'breakTimeRemaining',
      '*',
    ]);
    await saveEncrypted(timeStateKey, timeState);
  }
}

async function saveEncrypted(key, value) {
  if (!useMockedIdb) {
    const buf = await encryptObject(value);
    await idb.set(key, buf);
  } else {
    mockedIdb[key] = value;
  }
}

async function getDecrypted(key) {
  if (!useMockedIdb) {
    const buf = await idb.get(key);
    const value = await decryptObject(buf);
    return value;
  }
  return mockedIdb[key];
}

async function deleteEntry(key) {
  if (!useMockedIdb) {
    await idb.del(key);
  } else {
    delete mockedIdb[key];
  }
}

async function getIdbKeys() {
  if (!useMockedIdb) {
    const keys = await idb.keys();
    return keys;
  }
  return Object.keys(mockedIdb);
}

export async function saveInstanceState(instanceRepo, changedValues) {
  if (isNullOrUndefined(changedValues)) return;
  let saveAll = false;
  if (
    changedValues.state !== undefined &&
    changedValues.state['*'] !== undefined
  ) {
    saveAll = true;
  }
  await saveModuleState(instanceRepo, saveAll ? '*' : changedValues.state);
  if (changedValues.sections !== undefined || saveAll) {
    for (let i = 0; i < instanceRepo.state.sections.length; i += 1) {
      const section = instanceRepo.state.sections[i];
      await saveSectionState(
        instanceRepo.testInstanceId,
        section,
        saveAll ? changedValues.state : changedValues.sections[section.formId],
      );
    }
  }
}

async function saveModuleState(instanceRepo, changedValues) {
  if (changedValues !== undefined) {
    const state = shallowCopyExcept(instanceRepo.state, 'sections');
    const stateKey = getStateKey(instanceRepo.testInstanceId);
    await saveEncrypted(stateKey, state);
  }
}

export async function restoreStateFromDB() {
  const userId = getUserId();
  if (userId === null) {
    return null;
  }
  const repo = getInitialRepo();
  const keys = await getIdbKeys();
  const prefix = `p|${userId}|`;
  const stateKeys = keys.filter(
    key => key.startsWith(prefix) && key.indexOf('|state') >= 0,
  );
  const instances = stateKeys.map(key =>
    key.replace(prefix, '').replace('|state', ''),
  );
  for (let i = 0; i < instances.length; i += 1) {
    const testInstanceId = instances[i];
    const state = await getDecrypted(getStateKey(testInstanceId));
    repo.instances.push({ testInstanceId, serverNeedsLatest: true, state });
    const sectionPrefix = `p|${userId}|${testInstanceId}|section|`;
    const sectionKeys = keys.filter(key => key.startsWith(sectionPrefix));
    const sectionTimePrefix = `p|${userId}|${testInstanceId}|sectiontime|`;
    const sectionTimeKeys = keys.filter(key =>
      key.startsWith(sectionTimePrefix),
    );
    const sectionIds1 = sectionKeys.map(key => key.replace(sectionPrefix, ''));
    const sectionIds2 = sectionTimeKeys.map(key =>
      key.replace(sectionTimePrefix, ''),
    );
    const sectionIds = arrayUnique(sectionIds1.concat(sectionIds2));
    if (sectionIds.length > 0) {
      state.sections = [];
      for (let j = 0; j < sectionIds.length; j += 1) {
        const sectionId = sectionIds[j];
        const sectionKey = `${sectionPrefix}${sectionId}`;
        let section = null;
        if (keys.indexOf(sectionKey) >= 0) {
          section = await getDecrypted(sectionKey);
        } else {
          section = {};
        }
        const sectionTimeKey = `${sectionTimePrefix}${sectionId}`;
        if (keys.indexOf(sectionTimeKey) >= 0) {
          const sectionTime = await getDecrypted(sectionTimeKey);
          section = { ...section, ...sectionTime };
        }
        state.sections.push(section);
      }
    }
  }
  return repo;
}

export async function deleteInstanceState(testInstanceId) {
  const keys = await getIdbKeys();
  const prefix = `p|${getUserId()}|${testInstanceId}`;
  const instanceKeys = keys.filter(key => key.startsWith(prefix));
  for (let i = 0; i < instanceKeys.length; i += 1) {
    const key = instanceKeys[i];
    await deleteEntry(key);
  }
}
