/* eslint-disable no-restricted-syntax */
/**
 *
 * ModulePanel
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import ExternalLinkIcon from 'images/external_link_outward_rebrand.svg';
import messages from './messages';
import ConfettiSVG from '../../svg/confetti.svg';
import {
  millisToHoursMinsTextShortVersion,
  millisToHoursMinsTextLongVersion,
  getTotalTime,
} from '../../helpers/timeHelper';
import { getRawScoreText } from '../../helpers/rawScoreHelper';
import { QUESTION_PATH } from '../../containers/App/constants';
import history from '../../utils/history';

function ModulePanel({
  testInstanceId,
  instance,
  sections,
  isWriting,
  headingText1,
  headingText2,
  goToLibrary,
  loadScoreReportPDF,
  isDrillSet,
  isAdditionalPracticeSet,
  onChangeSelection,
}) {
  function isVariableSection() {
    for (const section of sections) {
      if (section?.isVariableSection === true) {
        return true;
      }
    }
    return false;
  }

  const { convertedScore, totalScore } = instance?.summary ?? {};

  const totalTime =
    isDrillSet || isAdditionalPracticeSet
      ? sections[0].elapsedTime
      : getTotalTime(sections);

  const timeInShort = millisToHoursMinsTextShortVersion(totalTime);
  const timeInLong = millisToHoursMinsTextLongVersion(totalTime);

  const getDisplayTime = () => (
    <>
      <h2 className="ellapsed-time">
        {isDrillSet || isAdditionalPracticeSet ? 'Elapsed Time' : 'Test time'}
      </h2>
      <div className="module-panel-time-and-score-text" id="totalTime">
        <span aria-labelledby="timeMinutes" aria-hidden="true">
          {timeInShort}
        </span>
        <span className="hidden" id="timeMinutes">
          {timeInLong}
        </span>
      </div>
    </>
  );

  const getScore = () => (
    <div>
      <h2 className="raw-score">
        {isAdditionalPracticeSet ? 'Score' : 'Raw Score'}
      </h2>
      <div
        className="module-panel-time-and-score-text"
        id={isAdditionalPracticeSet ? 'score' : 'rawScore'}
      >
        {isAdditionalPracticeSet && getRawScoreText(sections)}
        {totalScore ?? getRawScoreText(sections)}
      </div>
    </div>
  );

  const navigateBack = () => {
    if (instance) {
      const currentIndex = 0;
      onChangeSelection(currentIndex);
      const section = instance.module.sections[0];
      history.push(`${QUESTION_PATH}/${testInstanceId}/${section.title}`);
    }
  };

  return (
    <div className="module-panel-container">
      <ConfettiSVG className="module-panel-confetti" />
      <div className="module-panel-content">
        <div className="module-panel-heading">
          <h1 id="pageHeader" tabIndex="-1">
            <span>{headingText1}</span>
            <span>{headingText2}</span>
          </h1>
        </div>
        <div className="module-panel-time-and-score">
          <div className="module-panel-time-and-score-left ">
            {!isDrillSet && !isAdditionalPracticeSet
              ? getDisplayTime()
              : getScore()}
          </div>
          {!isWriting && (
            <div
              className="module-panel-time-and-score-right"
              style={{ flex: isWriting ? 0 : 1 }}
            >
              {!isDrillSet && !isAdditionalPracticeSet
                ? getScore()
                : getDisplayTime()}
            </div>
          )}
          {!isWriting && convertedScore && (
            <div
              className="module-panel-time-and-score-right"
              style={{ flex: isWriting ? 0 : 1 }}
            >
              <div>
                <h2>Scaled score</h2>
                <div
                  className="module-panel-time-and-score-text"
                  id="scaledScore"
                >
                  {convertedScore}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* only display if one of the sections is flagged as variable section */}
        {isVariableSection() && (
          <table className="module-panel-variable-section-disclaimer">
            <tr>
              <td valign="top">*&nbsp;</td>
              <td>
                Note that this section was the variable section and it will not
                be included in scoring.
              </td>
            </tr>
          </table>
        )}
        <div className="module-panel-buttons">
          <div className="panel-button-wrapper">
            {(isDrillSet || isAdditionalPracticeSet) && (
              <button
                className="module-panel-button review-button"
                onClick={navigateBack}
                onKeyPress={navigateBack}
                type="button"
              >
                Review
              </button>
            )}
            <button
              className="module-panel-button library-button"
              onClick={goToLibrary}
              onKeyPress={goToLibrary}
              type="button"
            >
              {!isDrillSet && !isAdditionalPracticeSet
                ? 'Return to Library'
                : 'Return to LSAT Test Prep'}
            </button>
          </div>
          {!isWriting && (
            <button
              className="module-panel-button print-button"
              onClick={() => loadScoreReportPDF(testInstanceId)}
              onKeyPress={() => loadScoreReportPDF(testInstanceId)}
              type="button"
              title={messages.opensInNewBrowserWindow.defaultMessage}
              aria-label={messages.printReportPdf.defaultMessage}
            >
              <span>Print Report (PDF)</span>
              {(isDrillSet || isAdditionalPracticeSet) && (
                <img
                  src={ExternalLinkIcon}
                  alt=""
                  className="external-link-icon"
                />
              )}
            </button>
          )}
        </div>
        {(isDrillSet || isAdditionalPracticeSet) && (
          <div className="standing-image-mobile" />
        )}
      </div>
    </div>
  );
}

ModulePanel.propTypes = {
  testInstanceId: PropTypes.string.isRequired,
  instance: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  isWriting: PropTypes.bool.isRequired,
  headingText1: PropTypes.string.isRequired,
  headingText2: PropTypes.string.isRequired,
  goToLibrary: PropTypes.func.isRequired,
  loadScoreReportPDF: PropTypes.func.isRequired,
  isDrillSet: PropTypes.bool.isRequired,
  isAdditionalPracticeSet: PropTypes.bool.isRequired,
  onChangeSelection: PropTypes.func,
};

export default ModulePanel;
