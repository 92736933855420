/*
 * Direction Panel Messages
 *
 * This contains all the text for the Directions panel component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.DirectionsPanel';

export default defineMessages({
  question: {
    id: `${scope}.question`,
    defaultMessage: 'Questions',
  },
  direction: {
    id: `${scope}.direction`,
    defaultMessage: 'Directions',
  },
  begin: {
    id: `${scope}.begin`,
    defaultMessage: 'Begin',
  },
  resume: {
    id: `${scope}.resume`,
    defaultMessage: 'Resume',
  },
  pauseSectionText: {
    id: `${scope}.pauseSectionText`,
    defaultMessage: 'Test is currently paused.',
  },
  sessionStartText: {
    id: `${scope}.sessionStartText`,
    defaultMessage: 'Session Will Start In:',
  },
  analyticalReasoningNote: {
    id: `${scope}.analyticalReasoningNote`,
    defaultMessage:
      '<strong> Note: </strong>The analytical reasoning section is no longer presented on the Official LSAT.',
  },
});
