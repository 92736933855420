/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/**
 *
 * DirectionsPanel
 *
 */

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { isNullOrUndefined } from 'util';
import './index.css';
import { ModuleType } from 'enumerations/ModuleType';
import * as progressTimerHelper from 'helpers/progressTimerHelper';
import history from '../../utils/history';
import messages from './messages';
import { ActionBarType } from '../../enumerations/ActionBarType';
import { FLEX_BREAK_TIMER_NAME } from './constants';
import * as moduleHelper from '../../helpers/moduleHelper';
import { restrictBack } from '../../helpers/restrictBackHelper';
import { setInstanceBreakTimeCompleted } from '../../helpers/persistence/examManagementHelper';
import { getInstanceById } from '../../helpers/persistence/common/repoHelper';
function DirectionsPanel({
  testInstanceId,
  section,
  selectedInstance,
  onChangeBreakTime,
}) {
  const minSectionBreakTime = 60000;
  const disableBackButton =
    !isNullOrUndefined(section) &&
    moduleHelper.disableBackButton(selectedInstance);
  const analyticalReasoningNote =
    messages.analyticalReasoningNote.defaultMessage;

  if (selectedInstance && !selectedInstance.state) {
    // eslint-disable-next-line no-param-reassign
    selectedInstance.state = getInstanceById(testInstanceId).state ?? {};
  }

  const optionsBreakTime = getOptionsBreakTime();
  const hasBreakTime =
    disableBackButton &&
    !isNullOrUndefined(optionsBreakTime) &&
    (section.sectionOrder > 1 ||
      (!isNullOrUndefined(selectedInstance.module.isReloaded) &&
        selectedInstance.module.isReloaded)) &&
    optionsBreakTime > 0;

  const [hasExtraBreakTime, setHasExtraBreakTime] = useState(false);
  const [remainingBreakTime, setRemainingBreakTime] = useState();
  const [timeTextColor, setBreakTimeColor] = useState('#444444');

  useEffect(() => {
    restrictBack();
    const extraBreakTimeSpent = selectedInstance?.state?.breakTimeSpent;
    setHasExtraBreakTime(
      hasBreakTime &&
        (extraBreakTimeSpent
          ? extraBreakTimeSpent > 0
          : getSectionBreakTime() > minSectionBreakTime),
    );
  }, []);

  function initializeTimer() {
    if (hasBreakTime) {
      let tr = null;
      tr = getSectionBreakTime();
      progressTimerHelper.hookTimer(FLEX_BREAK_TIMER_NAME, tr, runTimer);
      return tr;
    }
    return 0;
  }

  function getOptionsBreakTime() {
    if (section?.options?.breakType === 'intermission') {
      return 1;
    }

    let breakTime = selectedInstance?.state?.breakTimeSpent;

    if (!breakTime || breakTime < 0) {
      if (section?.options?.breakTime) {
        breakTime = section?.options?.breakTime;
      } else {
        breakTime = selectedInstance?.module?.options?.sectionBreakTime;
      }
    }

    return breakTime;
  }

  function getSectionBreakTime() {
    let tr = minSectionBreakTime;
    const extraBreakTimeSpent = selectedInstance?.state?.breakTimeSpent;

    if (extraBreakTimeSpent && extraBreakTimeSpent > 0) {
      tr = extraBreakTimeSpent;
    } else if (!isNullOrUndefined(section.breakTimeRemaining)) {
      tr = section.breakTimeRemaining;
    } else if (section.sectionOrder > 1) {
      const breakTime = getOptionsBreakTime();
      if (breakTime) {
        tr = breakTime * 60 * 1000;
      }
    }
    return tr;
  }

  function runTimer(timeRemaining) {
    if (
      hasBreakTime &&
      (remainingBreakTime >= 0 || remainingBreakTime === undefined)
    ) {
      if (timeRemaining > 0 && timeRemaining <= 60 * 1000) {
        setBreakTimeColor('#9B0064');
      } else if (timeRemaining <= 0) {
        setBreakTimeColor('#00DDBE');
      }
      if (timeRemaining <= 0) {
        if (
          hasExtraBreakTime &&
          selectedInstance.module?.options?.accomodatedReturnToStartPage ===
            true
        ) {
          history.push(selectedInstance.module.options.startPath);
        } else {
          startCurrentSection();
        }
      }
      if (!isNullOrUndefined(onChangeBreakTime)) {
        onChangeBreakTime(timeRemaining, section.formId);
      }
      if (hasExtraBreakTime) {
        setInstanceBreakTimeCompleted(
          timeRemaining >= 1000 ? timeRemaining : 0,
        );
      }
      setRemainingBreakTime(timeRemaining);
    }
  }

  useEffect(() => {
    initializeTimer();
    return () => {
      progressTimerHelper.unhookTimer(FLEX_BREAK_TIMER_NAME);
    };
  }, [selectedInstance, hasExtraBreakTime]);

  function startCurrentSection() {
    if (hasBreakTime && !isNullOrUndefined(onChangeBreakTime)) {
      onChangeBreakTime(0, section.formId);
      setInstanceBreakTimeCompleted(null);
    }

    if (
      hasBreakTime &&
      (!isNullOrUndefined(selectedInstance.module.isReloaded) &&
        !isNullOrUndefined(selectedInstance.module.currentPage) &&
        selectedInstance.module.isReloaded)
    ) {
      const { currentPage } = selectedInstance.module;
      let navigateTo = `section`;
      let searchString = '';
      if (!selectedInstance.module.isFlexPaused) {
        if (
          currentPage === ActionBarType.Questions ||
          currentPage === ActionBarType.QuestionsWithPassage
        ) {
          navigateTo = `question`;
        } else if (currentPage === ActionBarType.Passage) {
          navigateTo = 'passage';
        }
        if (currentPage === ActionBarType.DirectionsFromPassage) {
          searchString = '?source=passage';
        }
      }
      const EXAM_PATH = `/exam/${
        section.pagePaused !== null ? section.pagePaused : navigateTo
      }/${testInstanceId}/${section.title}${searchString}`;
      const PREP_PATH = `/${
        section.pagePaused !== null ? section.pagePaused : navigateTo
      }/${testInstanceId}/${section.title}${searchString}`;
      history.push(
        selectedInstance?.module?.options?.isRealExamMode === true
          ? EXAM_PATH
          : PREP_PATH,
      );
    } else {
      const EXAM_PATH = `/exam/${
        section.pagePaused !== null ? section.pagePaused : 'section'
      }/${testInstanceId}/${section.title}`;
      const PREP_PATH = `/${
        section.pagePaused !== null ? section.pagePaused : 'section'
      }/${testInstanceId}/${section.title}`;
      history.push(
        selectedInstance?.module?.options?.isRealExamMode === true
          ? EXAM_PATH
          : PREP_PATH,
      );
    }
  }

  function formatTime(millis) {
    if (millis === undefined) {
      return '';
    }
    const tr = Math.max(0, millis);
    let minutes = 0;
    // Helper internal function for converting to user friendly time.
    let seconds = tr < 0 ? 0 : Math.floor(tr / 1000);
    minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  function sectionBreaktimeMessage(time) {
    if (time === '') {
      return '';
    }
    // Helper internal function for converting to user friendly time.
    const message = !isNullOrUndefined(time) ? time.split(':', 2) : 'undefined';
    const timeFormat = { minutes: 0, seconds: 1 };
    const getTimeFormatText = (timeValue, timeFormatVal) => {
      if (!isNullOrUndefined(timeValue)) {
        if (timeFormatVal === timeFormat.minutes) {
          return timeValue === 1 ? 'minute' : 'minutes';
        }
        if (timeFormatVal === timeFormat.seconds) {
          return timeValue === 1 ? 'second' : 'seconds';
        }
      }
      return '';
    };

    return message === 'undefined'
      ? ''
      : `section will start in ${message[0]}
      ${getTimeFormatText(message[0], timeFormat.minutes)} and
      ${message[1]} ${getTimeFormatText(message[1], timeFormat.seconds)} `;
  }
  // const isRunningBreakTime = hasBreakTime && remainingBreakTime > 0;
  const primaryButtonClassName = 'testReview_primaryButton';

  // if we in the bar writing exam then use the section.sectionName
  // from the database in place of the section.title. so default to title
  // and override if bar writing exam
  let { title } = section;
  if (
    moduleHelper.isWritingSection(section) ||
    moduleHelper.isScoredWritingSection(section)
  ) {
    title = section.sectionName;
  }

  const showBeginButton =
    !disableBackButton ||
    (disableBackButton && !hasExtraBreakTime) ||
    selectedInstance?.module?.options?.allowBeginButton === true;
  // default to module directions, if there isn't any, then show section directions
  let dirs = selectedInstance?.module?.directions;
  let hasModuleDirections = false;
  if (isNullOrUndefined(dirs) || dirs === '') {
    dirs = section.directions;
  } else {
    hasModuleDirections = true;
  }

  const isSectionPaused =
    section.isPaused ||
    (selectedInstance?.state?.isSectionPaused &&
      selectedInstance?.state?.pausedSectionId === section.formId);

  return (
    <React.Fragment>
      <Row>
        <Col xs={{ size: 12 }} className="text-center">
          <h2 className="sectionHeader directionsPanelSectionTitle">{title}</h2>
        </Col>
      </Row>
      {section?.options?.hideQuestionCount !== true && (
        <Row className="pt-3">
          <Col xs={{ size: 12 }} className="text-center">
            <h3 className="questionHeader">
              <FormattedMessage {...messages.question} />
            </h3>
            <div className="questionTotal">{section.numberOfQuestions}</div>
          </Col>
        </Row>
      )}
      {hasBreakTime && (
        <Row className="pt-3 mb-1">
          <Col xs={{ size: 12 }} className="text-center">
            <div className="sessionStartText">
              <FormattedMessage {...messages.sessionStartText} />
            </div>
            <div
              className="sectionBreakTime"
              aria-labelledby="timeMessage"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
            >
              <span
                id="timeMessage"
                className="visuallyhidden"
                aria-hidden="false"
              >
                {sectionBreaktimeMessage(formatTime(remainingBreakTime))}
              </span>
              <span
                style={{
                  color: timeTextColor,
                }}
                aria-hidden="true"
              >
                {formatTime(remainingBreakTime)}
              </span>
            </div>
          </Col>
        </Row>
      )}
      {isSectionPaused && (
        <Row className="pt-3">
          <Col xs={{ size: 12 }} className="text-center">
            <strong data-testid="pauseSectionText">
              <FormattedMessage {...messages.pauseSectionText} />
            </strong>
          </Col>
        </Row>
      )}
      <Row className={isSectionPaused || hasBreakTime ? 'pt-3' : 'pt-5'}>
        {showBeginButton && (
          <Col xs={{ size: 12 }} className="text-center">
            <button
              type="button"
              className={primaryButtonClassName}
              onClick={startCurrentSection}
            >
              {isSectionPaused ? (
                <FormattedMessage {...messages.resume} />
              ) : (
                <FormattedMessage {...messages.begin} />
              )}
            </button>
            <br />
            <br />
          </Col>
        )}
      </Row>
      {!hasModuleDirections && (
        <Row className="pt-4" aria-hidden="true">
          <Col xs={{ size: 12 }} className="text-center">
            <h3 className="hidden">
              <FormattedMessage {...messages.direction} />
            </h3>
          </Col>
        </Row>
      )}
      {hasModuleDirections && (
        <Row className="pt-4">
          <Col xs={{ size: 12 }} className="text-left pl-10">
            <h3 className="dirpanel-direction-header">
              <strong>
                <FormattedMessage {...messages.direction} />:
              </strong>
            </h3>
          </Col>
        </Row>
      )}
      <Row className="pt-2 directions-Content" data-testid="directionsNotes">
        <Col xs={{ size: 12 }} className="directionsHTMLBox">
          <div
            dangerouslySetInnerHTML={{
              __html: dirs,
            }}
          />
          {(!selectedInstance?.module?.moduleType ||
            selectedInstance?.module?.moduleType ===
              ModuleType.MultipleChoice) &&
            section.formId &&
            section.formId.startsWith('AR') && (
              <p data-testid="analyticalNote">
                <FormattedHTMLMessage {...messages.analyticalReasoningNote} />
              </p>
            )}
        </Col>
      </Row>
    </React.Fragment>
  );
}

DirectionsPanel.propTypes = {
  testInstanceId: PropTypes.string,
  section: PropTypes.object,
  selectedInstance: PropTypes.object,
  onChangeBreakTime: PropTypes.func,
};

export default DirectionsPanel;
