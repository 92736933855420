/**
 * Action bar type enumeration that describes the state of the currently displayed action bar within the test
 * container. The enumeration values really describe the buttons visible on the action bar. For example, if the Action
 * bar type is Directions, then the user is likley on the questions page and the directions button is available
 * in the action bar and when clicked would take the user to the directions page
 * @category enumerations
 * @enum {number}
 */
const ActionBarType = {
  /**
   * @type {number}
   * @description The Directions type
   */
  Directions: 0,
  /**
   * @type {number}
   * @description The DirectionsFromPassage type
   */
  DirectionsFromPassage: 1,
  /**
   * @type {number}
   * @description The Questions type
   */
  Questions: 2,
  /**
   * @type {number}
   * @description The QuestionsWithPassage type
   */
  QuestionsWithPassage: 3,
  /**
   * @type {number}
   * @description The Passage type
   */
  Passage: 4,
};

export { ActionBarType };
