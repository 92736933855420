/**
 * Answer option mode enumeration that describes the state of the answer option. This option allows for less scrolling
 * for the user and the ability to see as many relevant answer options on the screen at once
 * @category enumerations
 * @enum {number}
 */
const OptionMode = {
  /**
   * @type {number}
   * @description The answer option is collapsed and not visible on the screen
   */
  Collapsed: 0,
  /**
   * @type {number}
   * @description The answer options is expanded and is visible on the screen
   */
  Expanded: 1,
};

export { OptionMode };
