/**
 *
 * RedeemOffer
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { debounce } from 'lodash';
import ValidateCode from './ValidateCode';
import ConfirmOffer from './ConfirmOffer';
import RedemptionSuccess from './RedemptionSuccess';
import RedemptionError from './RedemptionError';
import messages from './messages';
import './index.css';

function RedeemOffer({
  intl,
  offerDetails,
  offerError,
  redeemedOffer,
  onRedeemOffer,
  onValidateOffer,
  onClearOfferCodeError,
  onClearOfferDetails,
  onClearRedeemedOffer,
  onCloseModal,
}) {
  const [offerCode, setOfferCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Rendering steps
  const validateCodeStep = !offerDetails && !redeemedOffer;
  const confirmOfferStep = offerDetails && !offerError && !redeemedOffer;
  const redeemSuccessStep = offerDetails && !offerError && redeemedOffer;
  const redeemErrorStep = offerDetails && offerError && !redeemedOffer;

  // When we have a response, we are no longer submitting
  useEffect(() => {
    if (!!offerDetails || !!offerError || !!redeemedOffer) {
      setIsSubmitting(false);
    }
  }, [offerDetails, offerError, redeemedOffer]);

  // Cleanup offer Redux state on component dismount
  useEffect(
    () => () => {
      onClearOfferDetails();
      onClearOfferCodeError();
      onClearRedeemedOffer();
    },
    [],
  );

  // Debounce validation call
  const debouncedValidate = useRef(
    debounce(
      code => {
        setIsSubmitting(true);
        onValidateOffer(code);
      },
      300,
      { leading: true, trailing: false },
    ),
  ).current;

  // Debounce redeem call
  const debouncedRedeem = useRef(
    debounce(
      code => {
        setIsSubmitting(true);
        onRedeemOffer(code);
      },
      300,
      { leading: true, trailing: false },
    ),
  ).current;

  const handleRestart = () => {
    onClearOfferCodeError();
    onClearOfferDetails();
  };

  const getErrorMsg = () => {
    let errorMsg;
    if (offerError?.response?.data) {
      const { statusCode, error } = offerError.response.data;

      if (statusCode === 400 && error.includes('expired')) {
        errorMsg = messages.expiredOfferCode;
      }
      if (statusCode === 400 && error.includes('redeemed')) {
        errorMsg = messages.usedOfferCode;
      }
      if (statusCode === 404) {
        errorMsg = messages.invalidOfferCode;
      }
    }
    return errorMsg;
  };

  return (
    <div className="redeem-offer-container" aria-live="polite">
      {validateCodeStep && (
        <ValidateCode
          intl={intl}
          offerCode={offerCode}
          offerErrorMsg={getErrorMsg()}
          isSubmitting={isSubmitting}
          setOfferCode={setOfferCode}
          debouncedValidate={debouncedValidate}
          onClearOfferCodeError={onClearOfferCodeError}
        />
      )}
      {confirmOfferStep && (
        <ConfirmOffer
          intl={intl}
          offerCode={offerCode}
          offerDetails={offerDetails}
          isSubmitting={isSubmitting}
          onCloseModal={onCloseModal}
          debouncedRedeem={debouncedRedeem}
        />
      )}
      {redeemSuccessStep && (
        <RedemptionSuccess intl={intl} onCloseModal={onCloseModal} />
      )}
      {redeemErrorStep && (
        <RedemptionError
          intl={intl}
          offerErrorMsg={getErrorMsg()}
          handleRestart={handleRestart}
        />
      )}
    </div>
  );
}

RedeemOffer.propTypes = {
  intl: intlShape,
  offerDetails: PropTypes.object,
  offerError: PropTypes.object,
  redeemedOffer: PropTypes.object,
  onValidateOffer: PropTypes.func,
  onRedeemOffer: PropTypes.func,
  onClearOfferCodeError: PropTypes.func,
  onClearOfferDetails: PropTypes.func,
  onClearRedeemedOffer: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default RedeemOffer;
