/*
 *
 * This contains all the text for Module Panel.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ModulePanel';

export default defineMessages({
  opensInNewBrowserWindow: {
    id: `${scope}.opensInNewBrowserWindow`,
    defaultMessage: 'Opens in a new browser window.',
  },
  printReportPdf: {
    id: `${scope}.printReportPdf`,
    defaultMessage: 'Print Report PDF.',
  },
});
