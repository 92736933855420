/*
 *
 * TestSelectPage constants
 *
 */

export const INIT_LIBRARY_SAGA = 'Aetha/TestSelectPage/InitLibrarySaga';
export const GET_LIBRARY = 'Aetha/TestSelectPage/GetLibrary';
export const GET_LIBRARY_SUCCESS = 'Aetha/TestSelectPage/GetLibrarySuccess';
export const GET_LIBRARY_ERROR = 'Aetha/TestSelectPage/GetLibraryError';
export const GET_FLEX_EXAM_LIST = 'Aetha/TestSelectPage/GetFlex';
export const GET_FLEX_EXAM_LIST_SUCCESS = 'Aetha/TestSelectPage/GetFlexSuccess';
export const GET_FLEX_EXAM_LIST_ERROR = 'Aetha/TestSelectPage/GetFlexError';
export const PUT_INSTANCE_STATE = 'Aetha/TestSelectPage/PutInstanceState';
export const PUT_INSTANCE_STATE_SUCCESS =
  'Aetha/TestSelectPage/PutInstanceStateSuccess';
export const PUT_INSTANCE_STATE_ERROR =
  'Aetha/TestSelectPage/PutInstanceStateError';
export const SET_RETRIEVE_LIBRARY = 'Aetha/TestSelectPage/SetRetrieveLibrary';
export const SET_RETRIEVE_FLEX_EXAMS =
  'Aetha/TestSelectPage/SetRetrieveFlexExams';
export const SET_ERROR = 'Aetha/TestSelectPage/SetError';
export const LSAC_FLEX_START_PATH = '/exam/certifyingStatement';
export const EXAM_START_PATH = '/examStart';
export const STORE_EXAMPASSWORD_HASH =
  'Aetha/TeseSelectPage/StoreExamPasswordHash';
export const GET_TEST_INSTANCE_STATUS =
  'Athena/TestSelectPage/GetTestInstanceStatus';
export const GET_TEST_INSTANCE_STATUS_SUCCESS =
  'Athena/TestSelectPage/GetTestInstanceStatusSuccess';
export const GET_TEST_INSTANCE_STATUS_ERROR =
  'Athena/TestSelectPage/GetTestInstanceStatusError';
export const POST_EXAM_LAUNCH_DETAILS =
  'Athena/TestSelectPage/PostExamLaunchDetails';
export const POST_EXAM_LAUNCH_DETAILS_SUCCESS =
  'Athena/TestSelectPage/PostExamLaunchDetailsSuccess';
export const POST_EXAM_LAUNCH_DETAILS_ERROR =
  'Athena/TestSelectPage/PostExamLaunchDetailsError';
export const CLEAR_TEST_INSTANCE_STATUS =
  'Athena/TestSelectPage/ClearTestInstanceStatusError';
export const CLEAR_EXAM_LIST_ERROR = 'Aetha/TestSelectPage/ClearExamListError';
