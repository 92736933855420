/* eslint-disable prettier/prettier */
import './index.css';
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import messages from './messages';
import HamburgerButton from '../../components/HamburgerButton';
import UserProfile from '../../components/UserProfile';
import { displaySideNavBar } from '../MasterPage/actions';

const WritingExamCompletePage = ({onShowSideBar, intl}) => {

  function showSidebar() {
    onShowSideBar(true);
  }

  return (
    <div className="test-complete-flex-page-container">
      <div className="test-complete-flex-page-header">
        <div className="test-complete-flex-page-header-div">
          <HamburgerButton
            id="hamburger"
            onClick={showSidebar}
            onKeyPress={showSidebar}
            ariaLabel={`${intl.formatMessage({
              ...messages.mainNavigation,
            })}`}
          />
          <div className="test-complete-flex-page-header-text">
            <FormattedMessage {...messages.header} />
          </div>
        </div>
        <div className="test-complete-flex-page-user-profile">
          <UserProfile />
        </div>
      </div>
      <div className="test-complete-flex-page-top-decoration" />
      <div className="test-complete-flex-page">
        <div id="heading1" className="heading-color">
          LSAT Writing
        </div>
        <hr className="test-complete-flex-page-horizontal-rule" role="none" />
        <div>
          <h1 className="test-complete-flex-page-heading1">
            <FormattedMessage {...messages.heading1} />
          </h1>
        </div>
      </div>
    </div>
  )
};

WritingExamCompletePage.propTypes = {
  onShowSideBar: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
}

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onShowSideBar: hasToShowSideBar =>
      dispatch(displaySideNavBar(hasToShowSideBar))
  };
}

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(WritingExamCompletePage));
