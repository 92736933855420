import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import './index.css';
import { HOME_PATH } from '../App/constants';
import history from '../../utils/history';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  TEST_CENTER_ERROR_MESSAGE,
  TEST_CENTER_ERROR_TITLE,
} from './constant';

export default function ApiErrorPage() {
  // redirect back to home page if there is no API failure
  const location = useLocation();
  if (!location?.state?.handlingFailedAPI) {
    history.push(HOME_PATH);
  }

  return (
    <div className="api_error_page">
      <h1 className="heading-color">
        {location?.state?.isRealExam ? TEST_CENTER_ERROR_TITLE : ERROR_TITLE}
      </h1>
      <hr className="api_error_page-horizontal-rule" role="none" />
      <div>
        <p className="api_error_page-heading1">
          {location?.state?.isRealExam
            ? TEST_CENTER_ERROR_MESSAGE
            : ERROR_MESSAGE}
        </p>
      </div>
    </div>
  );
}
