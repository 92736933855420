/*
 * IntermissionPage Messages
 *
 * This contains all the text for the IntermissionPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.IntermissionPage';

export default defineMessages({
  intermissionHeader: {
    id: `${scope}.intermissionHeader`,
    defaultMessage: 'Intermission',
  },
  waitingHeader: {
    id: `${scope}.waitingHeader`,
    defaultMessage: 'Waiting Room',
  },
  endedHeader: {
    id: `${scope}.endedHeader`,
    defaultMessage: 'Test Session Ended',
  },
  content1: {
    id: `${scope}.content1`,
    defaultMessage:
      'The proctor will allow you to proceed to <strong>Section {section_number}</strong> after a short intermission. During the intermission, you may leave view of the camera to use the restroom or have a snack.',
  },
  content2: {
    id: `${scope}.content2`,
    defaultMessage:
      'You may not use any prohibited items, including cell phones, or discuss your test with anyone during the intermission. Leave all proctoring software open and do not navigate away from this screen.',
  },
  content3: {
    id: `${scope}.content3`,
    defaultMessage:
      'Before the timer below has expired, return to your seat, and click the ‘Ready to Check In’ button. Then remain seated and wait for further instructions from your proctor.',
  },
  attention: {
    id: `${scope}.attention`,
    defaultMessage: 'Attention: Intermission Ending Soon',
  },
  expired1: {
    id: `${scope}.expired1`,
    defaultMessage: 'Your break time has expired.',
  },
  expired2: {
    id: `${scope}.expired2`,
    defaultMessage:
      "Please click the 'Ready to Check In' button now to avoid termination of your testing session.",
  },
  ready1: {
    id: `${scope}.ready1`,
    defaultMessage:
      'You have indicated that you are ready to check in.<br/>Please remain seated and wait for further instructions from your proctor.',
  },
  ready2: {
    id: `${scope}.ready2`,
    defaultMessage:
      '<strong>Section {section_number}</strong> will not begin until the proctor completes the check in and enters the password.',
  },
  terminated1: {
    id: `${scope}.terminated1`,
    defaultMessage:
      'Your testing session has ended because you did not return from your break within the time allotted.',
  },
  terminated2: {
    id: `${scope}.terminated2`,
    defaultMessage: 'Please refer to the ',
  },
  terminated3: {
    id: `${scope}.terminated3`,
    defaultMessage:
      '<a href="https://www.lsac.org/lsat/taking-lsat/about-10-minute-intermission" target="_blank">Intermission FAQs<span class="hidden">Will open in a new browser window.</span></a>',
  },
  terminated4: {
    id: `${scope}.terminated4`,
    defaultMessage: ' for more information.',
  },
  returnToLawhubButtonText: {
    id: `${scope}.returnToLawhubButtonText`,
    defaultMessage: 'Close Session',
  },
  pmSubtitleStep1: {
    id: `${scope}.pmSubtitleStep1`,
    defaultMessage:
      'Notify your proctor in the chat box that you have reached your intermission before leaving the view of the camera.',
  },
  pmContent1: {
    id: `${scope}.pmContent1`,
    defaultMessage:
      'During the intermission, you may leave the work area to use the rest room or have a snack with proctor approval. <u>Do not access any electronic devices (example cell phone).</u> Once the 10-minute intermission time has expired, you can continue to the check-in process. Once the check-in process has been completed, your test will continue.',
  },
  pmContent2: {
    id: `${scope}.pmContent2`,
    defaultMessage:
      'When the Intermission time has expired you must be back in your seat to select the "Continue to Step 2" button and proceed with your test.',
  },
  pmSubtitleStep2: {
    id: `${scope}.pmSubtitleStep2`,
    defaultMessage:
      'Notify your proctor in the chat box that your intermission has ended and follow the instructions below.',
  },
  pmContent3: {
    id: `${scope}.pmContent3`,
    defaultMessage:
      "Agree to the items below by selecting each check box. Selecting the check boxes certifies that you have followed the LSAC's rules and regulations during your intermission.",
  },
  pmContentWarning: {
    id: `${scope}.pmContentWarning`,
    defaultMessage:
      'Failure to follow these rules and to complete the recheck-in process after the intermission will result in a cancellation of your test score.',
  },
  modalHeading: {
    id: `${scope}.modalHeading`,
    defaultMessage: 'Intermission time expired, continue to Step 2',
  },
  modalButtonText: {
    id: `${scope}.modalButtonText`,
    defaultMessage: 'Continue to Step 2',
  },
});
