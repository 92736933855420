import { defineMessages } from 'react-intl';

export const scope = 'app.containers.TestCompleteFlexPage';

export default defineMessages({
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage: 'Welcome to the LSAC PLUS Program!',
  },
  heading2: {
    id: `${scope}.heading2`,
    defaultMessage:
      'You are now part of the LSAC PLUS Program with nearly 250 other undergraduate students at 8 different law schools around the country! Your participation in the LSAC PLUS Program can help set you on the path to success in law school and beyond.',
  },
  heading3: {
    id: `${scope}.heading3`,
    defaultMessage:
      'The next step to finalize your enrollment is to submit the PLUS Program registration form.',
  },
  heading4: {
    id: `${scope}.heading4`,
    defaultMessage: 'Here are a few more things to know:',
  },
  bulletPoint1: {
    id: `${scope}.bulletPoint1`,
    defaultMessage:
      'Look for a pre-survey that will be sent to you before your program begins. It will be one of several opportunities to share your perspectives and experiences with the LSAC Diversity, Equity, and Inclusion (DEI) team.',
  },
  bulletPoint2: {
    id: `${scope}.bulletPoint2`,
    defaultMessage:
      'You have immediate access to free Official LSAT Prep<span class="sup">SM</span>, including two Official LSAT PrepTests and one Official LSAT-Flex Sample test.',
  },
  bulletPoint3: {
    id: `${scope}.bulletPoint3`,
    defaultMessage:
      'Upon successful completion of the program, you will be eligible to receive an LSAC Fee Waiver which includes two free LSAT registrations and upgraded access to Official LSAT Prep Plus<span class="sup">SM</span> in addition to invitations to exclusive educational events. Details will be provided at the conclusion of your program.',
  },
  bulletPoint4: {
    id: `${scope}.bulletPoint5`,
    defaultMessage:
      'Explore <strong><a href="https://www.lsac.org/" target="_blank"> <i class="fas fa-external-link-alt"></i> LSAC.org<span class="hidden">Will open in a new browser window.</span></a></strong> for more information and resources about researching and applying to law school.',
  },
  bulletPoint5: {
    id: `${scope}.bulletPoint6`,
    defaultMessage:
      'Connect with us on social media to keep up with the latest news and events.',
  },
  text1: {
    id: `${scope}.text1`,
    defaultMessage:
      '<strong>Questions?</strong> please contact us at <strong><a href="mailto:diversityoffice@LSAC.org">diversityoffice@LSAC.org</a><strong>.',
  },
  buttonText: {
    id: `${scope}.buttonText`,
    defaultMessage: 'Complete Your Registration',
  },
});
