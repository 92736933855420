import { useEffect, useRef } from 'react';
import useAnimationFrameThrottle from './useAnimationFrameThrottle';
import { logException } from '../helpers/logHelper';

const useThrottledResizeObserver = (
  ref,
  onHeightChange,
  throttleInterval = 50,
) => {
  const lastHeight = useRef(0);
  const observerRef = useRef(null);

  const throttledHandleResize = useAnimationFrameThrottle(entries => {
    if (entries.length > 0) {
      const newHeight = entries[0].target.offsetHeight;

      if (Math.abs(newHeight - lastHeight.current) > 5) {
        lastHeight.current = newHeight;
        if (onHeightChange) {
          onHeightChange(newHeight);
        }
      }
    }
  }, throttleInterval);

  useEffect(() => {
    if (!ref.current || !onHeightChange) {
      // return a clean up function to satisfy the consistent-return error
      return () => {};
    }
    try {
      const observer = new ResizeObserver(throttledHandleResize);

      observer.observe(ref.current);
      observerRef.current = observer;
    } catch (error) {
      logException(error);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [ref, throttledHandleResize]);
};

export default useThrottledResizeObserver;
