/**
 *
 * IntermissionPage
 *
 */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isNullOrUndefined } from 'util';
import * as examHelper from 'helpers/examHelper';
import reducer from '../TestSelectPage/reducer';
import messages from './messages';
import { hookTimer, unhookTimer } from '../../helpers/progressTimerHelper';
import { config } from '../../config';
import './index.css';
import * as moduleHelper from '../../helpers/moduleHelper';
import { instancesSelector } from '../DirectionsPage/selectors';
import history from '../../utils/history';
import {
  CERTIFYING_STATEMENT_PATH,
  EXAM_MODULE_PATH,
  HOME_PATH,
} from '../App/constants';
import {
  passwordHashSelector,
  sequenceHashSelector,
  flexExamsSelector,
} from '../TestSelectPage/selectors';
import { getHashedPasswordForExam } from '../../helpers/encryptHelper';
import {
  intermissionTimeRemainingAction,
  readyToCheckinAction,
} from '../DirectionsPage/actions';
import { persistNow } from '../../helpers/persistence/common/storageHelper';
import { setInstanceTerminated } from '../../helpers/persistence/examManagementHelper';
import { addReadyToCheckIn } from '../../helpers/persistence/persistenceManagementHelper';
import { restrictBack } from '../../helpers/restrictBackHelper';
import Checkbox from '../../components/Checkbox';
import PopupComponent from '../../components/PopupComponent';

let fullyExpired = false;
let expired = false;
let lessThanAMin = false;

export function setPasswordError(pw1, pw2) {
  const split1 = pw1.split('|');
  const split2 = pw2.split('|');
  let error = '';
  if (split1[1] !== split2[1]) {
    error = 'Invalid password (error code 200). Please try again.';
  } else if (split1[0] !== split2[0]) {
    error = 'Invalid password (error code 100). Please try again.';
  }
  return error;
}

export function IntermissionPage({
  instances,
  flexInstances,
  testInstanceId,
  sectionId,
  passwordHash,
  sequenceHash,
  onChangeIntermissionTime,
  updateReadyToCheckinStatus,
  intl,
}) {
  useInjectReducer({ key: 'testSelectPage', reducer });
  const [state, setState] = useState({
    timeRemaining: null,
    password: '',
    module: null,
    instance: null,
    selectedSection: null,
    nextSection: null,
    nextSectionNumber: 0,
    passwordError: null,
    pwdId: null,
    intermissionTime: null,
    extendedTime: null,
    totalTime: null,
    initialized: false,
    warning: false,
  });

  const [stateHidePasswordInput, setStateHidePasswordInput] = useState(false);
  const [isPrometricsTest, setIsPrometricsTest] = useState(false);
  const [currentStepPrometrics, setCurrentStepPrometrics] = useState(1);
  const [isCheckbox1Checked, setisCheckbox1Checked] = useState(false);
  const [isCheckbox2Checked, setisCheckbox2Checked] = useState(false);
  const [isCheckbox3Checked, setisCheckbox3Checked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const passwordInputRef = useRef();

  useEffect(() => {
    restrictBack();
    document.title = 'LSAT Test - Intermission';

    const selection = moduleHelper.getInstanceSection(
      instances,
      testInstanceId,
      sectionId,
    );
    const instance = selection.selectedInstance;
    const { section } = selection;
    let intermissionTime =
      parseInt(config.REACT_APP_INTERMISSION_SECONDS, 10) * 1000;
    if (section) {
      const bt = section?.options?.nextBreakTime;
      if (bt) {
        intermissionTime = bt * 60 * 1000;
      }
    }
    const extendedTime =
      parseInt(config.REACT_APP_INTERMISSION_EXTENDED_SECONDS, 10) * 1000;
    const totalTime = intermissionTime + extendedTime + 999;

    // in order to get the test session ended screen when redirecting from
    // the certifying statement page, for terminated exams, we need to first
    // determine if the exam is terminated and if so we do not start the timer
    // which gives a time remaining of zero which will display the correct
    // test session ended screen
    if (!isTerminated()) {
      hookTimer('intermission', totalTime, onTick);
    }

    if (isTerminated()) {
      // the exam is terminated so set the time remaining to zero to
      // set the state so that the test session ended screen will be displayed
      setState(prevState => ({
        ...prevState,
        timeRemaining: 0,
        initialized: true,
      }));
    } else if (!instance) {
      // just abort, no recovery if we don't have a test instance
      goToCertPage();
    } else {
      const module = !isNullOrUndefined(instance) ? instance.module : null;
      const info = moduleHelper.getSectionBreakInfo(module, sectionId);
      const nextSectionNumber = module.sections.indexOf(info.nextSection) + 1;
      if (!info.selectedSection) {
        goToCertPage();
      }
      setState(prevState => ({
        ...prevState,
        module,
        instance,
        selectedSection: info.selectedSection,
        nextSection: info.nextSection,
        nextSectionNumber,
        pwdId: uniqueid(),
        intermissionTime,
        extendedTime,
        totalTime,
        timeRemaining: totalTime,
        initialized: true,
        checkedIn: section.isReadyToCheckin,
      }));
    }

    return () => {
      unhookTimer('intermission');
    };
  }, []);

  useEffect(() => {
    if (state.timeRemaining && state.extendedTime) {
      expired = state.timeRemaining - state.extendedTime < 1000;

      if (expired && !showModal && currentStepPrometrics === 1) {
        setShowModal(true);
      }

      if (!state.warning) {
        lessThanAMin = state.timeRemaining - state.extendedTime <= 60000;

        // Screen readers need to read the warning after initial rendering
        setTimeout(() => {
          setState(prevState => ({
            ...prevState,
            warning: lessThanAMin && !state.checkedIn,
          }));
        }, 3000);
      }
    }
  }, [state.timeRemaining]);

  useEffect(() => {
    const hidePasswordInput = flexInstances
      ? examHelper.shouldHidePasswordInput(flexInstances[0])
      : false;
    setStateHidePasswordInput(hidePasswordInput);

    // PROMETRICS CHECK
    const hasFlexExams =
      !isNullOrUndefined(flexInstances) && flexInstances.length > 0;
    const isPrometrics = hasFlexExams
      ? examHelper.isPrometrics(flexInstances[0])
      : false;
    setIsPrometricsTest(isPrometrics);
  }, [flexInstances]);

  useEffect(() => {
    const rootDiv = document.getElementById('intermission');
    if (rootDiv?.focus) {
      rootDiv.focus();
    }
  }, [currentStepPrometrics]);

  function isTerminated() {
    // get the flex instance so we can check if the exam was terminated. when redirected
    // to this page from the cert page (when the test is terminated), only the testInstanceId
    // will be passed as the sectionId is not in context so this contributes to the criteria
    // in determined if they are terminated
    if (
      testInstanceId !== undefined &&
      testInstanceId !== null &&
      sectionId === undefined
    ) {
      const flexInstance = moduleHelper.getInstance(
        flexInstances,
        testInstanceId,
      );

      if (flexInstance !== undefined && flexInstance !== null) {
        if (flexInstance.examStatus === 'Terminated') {
          return true;
        }
      }
    }

    return false;
  }

  function goToCertPage() {
    history.replace(CERTIFYING_STATEMENT_PATH);
  }

  function closeTheTestHandler() {
    updateReadyToCheckinStatus(false);
    history.push(HOME_PATH);
  }

  function onTick(tr) {
    setState(prevState => ({ ...prevState, timeRemaining: tr }));
    onChangeIntermissionTime(tr, sectionId);
  }

  function getDisplayTime() {
    const { minutes, seconds } = getMinutesSeconds();
    return minutes === Number.NaN
      ? ''
      : `${minutes}min ${seconds < 10 ? '0' : ''}${seconds}sec`;
  }

  function getAriaTime() {
    const { minutes, seconds } = getMinutesSeconds();
    return minutes === Number.NaN
      ? ''
      : `Time remaining is ${minutes} minutes and ${seconds} seconds`;
  }

  function getMinutesSeconds() {
    if (!state.timeRemaining || !state.extendedTime) {
      return { minutes: Number.NaN, seconds: Number.NaN };
    }
    const tr = Math.max(state.timeRemaining - state.extendedTime, 0);
    let seconds = tr < 0 ? 0 : Math.floor(tr / 1000);
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return { minutes, seconds };
  }

  function checkIn() {
    setState(prevState => ({ ...prevState, checkedIn: true }));
    updateReadyToCheckinStatus(true);
    addReadyToCheckIn(sectionId);
  }

  function allCheckboxesChecked() {
    return isCheckbox1Checked && isCheckbox2Checked && isCheckbox3Checked;
  }

  function uniqueid() {
    // always start with a letter (for DOM friendlyness)
    let idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      const ascicode = Math.floor(Math.random() * 42 + 48);
      if (ascicode < 58 || ascicode > 64) {
        // exclude all chars between : (58) and @ (64)
        idstr += String.fromCharCode(ascicode);
      }
    } while (idstr.length < 32);

    return idstr;
  }

  function inputChangeHandler(e) {
    const pwd = e.target.value;
    setState(prevState => ({
      ...prevState,
      password: pwd,
      passwordError: null,
    }));
  }

  // Disbales Input Field Paste Option
  const pasteDisabled = e => {
    if (config.REACT_APP_PASSWORD_PASTE_DISABLED === 'true') {
      e.preventDefault();
    }
    return !config.REACT_APP_PASSWORD_PASTE_DISABLED;
  };

  let hdrMsg = messages.intermissionHeader;
  if (state.checkedIn) {
    hdrMsg = messages.waitingHeader;
  }

  if (
    !isPrometricsTest &&
    !state.checkedIn &&
    state.timeRemaining <= 0 &&
    state.initialized
  ) {
    if (!fullyExpired && config.REACT_APP_IS_TERMINATED_ENABLED === 'true') {
      fullyExpired = true;
      if (sectionId) {
        setInstanceTerminated();
        persistNow();
      }
    }
    fullyExpired = true;
    hdrMsg = messages.endedHeader;
  }

  const hdr = intl.formatMessage({ ...hdrMsg });
  const timeBoxClass = state.warning ? 'timeBox warning' : 'timeBox';
  const pwdClass =
    state.password.length > 0 ? 'password-input has-text' : 'password-input';
  const submittedClass = expired ? 'submitted pwd-visible' : 'submitted';

  function getHashedPassword() {
    const { password } = state;
    return getHashedPasswordForExam(password);
  }

  function goToNextSection() {
    const newPassword = getHashedPassword();
    const fullHash = passwordHash + (sequenceHash ? `|${sequenceHash}` : '');
    if (newPassword === fullHash) {
      updateReadyToCheckinStatus(false);
      history.push(`${EXAM_MODULE_PATH}/${testInstanceId}/${sectionId}`);
    } else {
      setState(prevState => ({
        ...prevState,
        passwordError: setPasswordError(newPassword, fullHash),
      }));
    }
  }

  // Close the Modal and continue to the next Intermission step 2
  const hideModal = () => {
    setShowModal(false);
    setCurrentStepPrometrics(2);
  };

  function renderIntermissionContent() {
    return (
      <>
        <div className="topBox" role="status" aria-atomic="true">
          <h1>{hdr}</h1>
          <p className="attention" aria-live="assertive" role="alert">
            {state.warning && !expired && !fullyExpired ? (
              <FormattedHTMLMessage {...messages.attention} />
            ) : (
              ''
            )}
          </p>
          {!expired && !fullyExpired && !state.checkedIn && (
            <>
              <p className="content">
                <FormattedHTMLMessage
                  {...messages.content1}
                  values={{ section_number: state.nextSectionNumber }}
                />
              </p>
              <p className="content">
                <FormattedHTMLMessage {...messages.content2} />
              </p>
              <p className="content">
                <FormattedHTMLMessage {...messages.content3} />
              </p>
            </>
          )}
          {!fullyExpired && expired && !state.checkedIn && (
            <>
              <div className="spacer" />
              <p className="expired">
                <FormattedHTMLMessage
                  {...messages.expired1}
                  values={{ section_number: state.nextSectionNumber }}
                />
              </p>
              <p className="expired">
                <FormattedHTMLMessage {...messages.expired2} />
              </p>
              <div className="spacer" />
            </>
          )}
          {state.checkedIn && (
            <>
              <p className="content">
                <FormattedHTMLMessage {...messages.ready1} />
              </p>
              <p className="content">
                <FormattedHTMLMessage
                  {...messages.ready2}
                  values={{ section_number: state.nextSectionNumber }}
                />
              </p>
            </>
          )}
          {fullyExpired && (
            <>
              <p className="attention">
                <FormattedHTMLMessage {...messages.terminated1} />
              </p>
              <span className="content link-window">
                <FormattedHTMLMessage {...messages.terminated2} />
                <i className="fas fa-external-link-alt" />
                <FormattedHTMLMessage {...messages.terminated3} />
                <FormattedHTMLMessage {...messages.terminated4} />
              </span>
              <button
                className="returnToLawhubButton"
                type="button"
                onClick={closeTheTestHandler}
              >
                <span className="returnToLawhubButtonText">
                  <FormattedHTMLMessage
                    {...messages.returnToLawhubButtonText}
                  />
                </span>
              </button>
            </>
          )}
        </div>
        {!fullyExpired && (
          <div className="lowerBox">
            <div className="lowerFlex">
              <div
                className={timeBoxClass}
                aria-label={getAriaTime()}
                role="region"
              >
                {getDisplayTime()}
                {!expired && ' remain'}
              </div>
              {state.checkedIn && (
                <div className={submittedClass}>
                  Test taker ready&nbsp;&nbsp;
                  <i className="fa fa-check" aria-hidden="true" />
                </div>
              )}
              {!state.checkedIn && (
                <button
                  type="button"
                  className="readyButton"
                  onClick={checkIn}
                  onKeyPress={checkIn}
                >
                  Ready to Check In
                </button>
              )}
              {state.checkedIn && expired && !stateHidePasswordInput && (
                <form autoComplete="off">
                  <input
                    id={state.pwdId}
                    placeholder="Proctor enters password"
                    className={pwdClass}
                    type="text"
                    ref={passwordInputRef}
                    onChange={inputChangeHandler}
                    aria-hidden="true"
                    autoComplete="off"
                    onPaste={pasteDisabled}
                    onContextMenu={pasteDisabled}
                  />
                </form>
              )}
            </div>
            {state.checkedIn && expired && (
              <button
                className="beginButton"
                type="button"
                disabled={
                  stateHidePasswordInput ? false : state.password.length === 0
                }
                onClick={goToNextSection}
              >
                Begin
              </button>
            )}

            <div
              data-testid="proctor-password"
              aria-live="assertive"
              className="passwordError"
            >
              {state.passwordError ? state.passwordError : ''}
            </div>
          </div>
        )}
      </>
    );
  }

  function renderPMIntermissionContent() {
    return (
      <>
        <div className="topBox">
          <h1 id="pageHeader">
            {hdr} - Step {currentStepPrometrics} of 2
          </h1>
          <p
            className="attention"
            aria-live={currentStepPrometrics === 1 ? 'assertive' : 'off'}
            role={currentStepPrometrics === 1 ? 'alert' : undefined}
          >
            {state.warning ? (
              <FormattedHTMLMessage {...messages.attention} />
            ) : (
              ''
            )}
          </p>

          <PopupComponent
            onClose={hideModal}
            onKeyPress={hideModal}
            show={showModal}
            message={messages.modalHeading.defaultMessage}
            titleText={messages.modalHeading.defaultMessage}
            buttonText={messages.modalButtonText.defaultMessage}
          />

          {currentStepPrometrics === 1 && (
            <>
              <p className="notice-subtitle attention">
                <FormattedHTMLMessage {...messages.pmSubtitleStep1} />
              </p>
              <p className="content">
                <FormattedHTMLMessage {...messages.pmContent1} />
              </p>
              <p className="content attention">
                <strong>
                  <FormattedHTMLMessage {...messages.pmContent2} />
                </strong>
              </p>
              <p className="notice-warning attention">
                <FormattedHTMLMessage {...messages.pmContentWarning} />
              </p>
            </>
          )}
          {currentStepPrometrics === 2 && (
            <>
              <p className="notice-subtitle attention">
                <FormattedHTMLMessage {...messages.pmSubtitleStep2} />
              </p>
              <p className="content">
                <FormattedHTMLMessage {...messages.pmContent3} />
              </p>
              <p className="notice-warning attention">
                <FormattedHTMLMessage {...messages.pmContentWarning} />
              </p>
              <div className="intermission-checkbox-container">
                <div className="intermission-checkbox">
                  <Checkbox
                    id="checkbox-1"
                    className="intermission-checkbox"
                    onCheckChanged={() =>
                      setisCheckbox1Checked(!isCheckbox1Checked)
                    }
                    checked={isCheckbox1Checked}
                    labelClassName="intermission-checkbox-label"
                    labelTextContent="I did not use any prohibited items, including a cell phone, during the intermission."
                  />
                </div>
                <div className="intermission-checkbox">
                  <Checkbox
                    id="checkbox-2"
                    className="intermission-checkbox"
                    onCheckChanged={() =>
                      setisCheckbox2Checked(!isCheckbox2Checked)
                    }
                    checked={isCheckbox2Checked}
                    labelClassName="intermission-checkbox-label"
                    labelTextContent="I did not discuss the test content with anyone during the intermission."
                  />
                </div>
                <div className="intermission-checkbox">
                  <Checkbox
                    id="checkbox-3"
                    className="intermission-checkbox"
                    onCheckChanged={() =>
                      setisCheckbox3Checked(!isCheckbox3Checked)
                    }
                    checked={isCheckbox3Checked}
                    labelClassName="intermission-checkbox-label"
                    labelTextContent="I completed the required check-in process with my proctor before continuing my test."
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {/* <!-- topbox --> */}
        {currentStepPrometrics === 1 && (
          <div className="lowerBox-container">
            <div className="lowerBox">
              <div className="lowerFlex">
                <div
                  className={timeBoxClass}
                  aria-label={getAriaTime()}
                  role="region"
                >
                  {getDisplayTime()}
                  {!expired && ' remain'}
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStepPrometrics === 2 && (
          <div className="lowerBox-container">
            <div className="lowerBox">
              <div className="lowerFlex">
                <button
                  className="beginButton floating-begin-button readyButton"
                  type="button"
                  disabled={!allCheckboxesChecked()}
                  onClick={goToNextSection}
                >
                  Continue to the Test
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div id="intermission" className="intermission-box" tabIndex="0">
      {!isPrometricsTest && renderIntermissionContent()}
      {isPrometricsTest && renderPMIntermissionContent()}
    </div>
  );
}

IntermissionPage.propTypes = {
  instances: PropTypes.array,
  flexInstances: PropTypes.array,
  testInstanceId: PropTypes.string,
  sectionId: PropTypes.string,
  passwordHash: PropTypes.string,
  sequenceHash: PropTypes.string,
  onChangeIntermissionTime: PropTypes.func,
  updateReadyToCheckinStatus: PropTypes.func,
  intl: intlShape.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onChangeIntermissionTime: (timeRemaining, sectionId) =>
      dispatch(intermissionTimeRemainingAction(timeRemaining, sectionId)),
    updateReadyToCheckinStatus: (status, sectionId) =>
      dispatch(readyToCheckinAction(status, sectionId)),
  };
}

const mapStateToProps = createStructuredSelector({
  instances: instancesSelector(),
  flexInstances: flexExamsSelector(),
  passwordHash: passwordHashSelector(),
  sequenceHash: sequenceHashSelector(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(IntermissionPage));
