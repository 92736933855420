/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import { reactPlugin } from '../TestSelectPage/testselect.util';
import reducer from './reducer';
import saga from './saga';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
import { setTokenAction } from './actions';
import { getUserDetails } from '../../domains/session/actions';
import { errorSelector, isTokenSetSelector } from './selectors';
import {
  retrievedUserDetailsSelector,
  userDetailsSelector,
} from '../../domains/session/selectors';
import { EXAM_START_PATH } from '../App/constants';
import history from '../../utils/history';
import * as logHelper from '../../helpers/logHelper';
import { LogLevelType } from '../../enumerations/LogLevelType';

export function WritingExamPage({
  userDetails,
  retrievedUserDetails,
  error,
  onLoadUserDetails,
  setToken,
  isTokenSet,
}) {
  useEffect(() => {
    if (!retrievedUserDetails && isNullOrUndefined(userDetails)) {
      onLoadUserDetails();
    }
  }, [retrievedUserDetails]);

  useEffect(() => {
    if (!isNullOrUndefined(userDetails)) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const jwt = urlSearchParams.get('jwt');

      if (!isNullOrUndefined(jwt) && jwt !== '' && userDetails && userDetails.userId) {
        logHelper.log(LogLevelType.Info, `setToken(${ jwt }, ${ userDetails.userId})`);
        setToken(jwt, userDetails.userId); 
      } else {
        console.error('WritingExamPage: invalid jwt and/or invalid user details; redirecting to online services');
        window.location.href = 'https://os.lsac.org';
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (isTokenSet) {
      let examId = '';
      let lsKey = '';
      if (userDetails && userDetails.userId) {
        // after we return to the WritingExamPage we need to know which exam is assigned.
        // This is set on the ProctorWritingPage
        lsKey = `${userDetails.userId}-examid`;
        examId = window.localStorage.getItem(lsKey) ?? '';
      }
      if (
        examId.toLowerCase() === 'lsac-writingexam' ||
        examId.toLowerCase() === 'lsac-writingexamspanish'
      ) {
        logHelper.log(
          LogLevelType.Info,
          'Token was successfully set so redirecting to writing start page',
        );
        window.localStorage.removeItem(lsKey);
        history.push(`${EXAM_START_PATH}/Writing`, { from: 'ProctorU' });
      } else {
        logHelper.log(
          LogLevelType.Info,
          'Token was successfully set so redirecting to writing analysis start page',
        );
        window.localStorage.removeItem(lsKey);
        history.push(`${EXAM_START_PATH}/WritingWithPerspectives`, {
          from: 'ProctorU',
        });
      }
    }
  }, [isTokenSet, userDetails]);

  useEffect(() => {
    if (error) {
      console.error('WritingExamPage: set token API (/api/proctor/remoteProctoringJWT) returned an error; redirecting to online services', error);
      window.location.href = 'https://os.lsac.org';
    }
  }, [error]);

  return <div />;
}

WritingExamPage.propTypes = {
  userDetails: PropTypes.object,
  retrievedUserDetails: PropTypes.bool,
  onLoadUserDetails: PropTypes.func,
  error: PropTypes.object,
  setToken: PropTypes.func,
  isTokenSet: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  retrievedUserDetails: retrievedUserDetailsSelector(),
  userDetails: userDetailsSelector(),
  isTokenSet: isTokenSetSelector(),
  error: errorSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    setToken: (jwt, userId) => dispatch(setTokenAction(jwt, userId)),
    onLoadUserDetails: () => dispatch(getUserDetails()),
  };
}

const withSaga = injectSaga({ key: 'writingExamPage', saga });
const withReducer = injectReducer({ key: 'writingExamPage', reducer });
const withTestSelectSaga = injectSaga({
  key: 'testSelectPage',
  saga: testSelectPageSaga,
});
const withTestSelectReducer = injectReducer({
  key: 'testSelectPage',
  reducer: testSelectPageReducer,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withReducer,
  withTestSelectReducer,
  withSaga,
  withTestSelectSaga,
  withConnect,
)(withAITracking(reactPlugin, injectIntl(WritingExamPage), 'WritingExamPage'));
