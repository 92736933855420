/* eslint-disable react/no-danger */
/**
 *
 * CertifyingContent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';

function CertifyingContent({
  certContent,
  checkboxDisabled,
  onCheckboxChanged,
  checkboxChecked,
}) {
  if (!certContent) {
    return <></>;
  }
  return (
    <>
      <h2
        className="certifying-statement-page-heading2"
        dangerouslySetInnerHTML={{ __html: certContent[0] }}
      />
      <p
        className="certifying-statement-page-heading2"
        dangerouslySetInnerHTML={{ __html: certContent[1] }}
      />
      <p
        className="certifying-statement-page-text-content1"
        dangerouslySetInnerHTML={{ __html: certContent[2] }}
      />
      <div className="certifying-statement-page-agree-container">
        <Checkbox
          id="checkbox"
          disabled={checkboxDisabled}
          className="certifying-statement-page-checkbox"
          onCheckChanged={onCheckboxChanged}
          checked={checkboxChecked}
          labelClassName="certifying-statement-page-text-content2"
          labelTextContent={certContent[3]}
        />
      </div>
    </>
  );
}

CertifyingContent.propTypes = {
  certContent: PropTypes.array,
  checkboxChecked: PropTypes.bool,
  checkboxDisabled: PropTypes.bool,
  onCheckboxChanged: PropTypes.func,
};

export default CertifyingContent;
